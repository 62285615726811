<script>
import { AxiosAPI } from '@/axios';
import { useMainStore } from '../../stores/index';
import { useBusStore } from '../../stores/index';
import Modal_view from '../../components/documents/Modals/Modal_View.vue';
import Loading from '../../components/documents/Modals/Loading.vue';
import Anexo from '../documents/Modals/Modal_Anexo_Parecer.vue';
export default {
	name: 'DocRecp',
	components:{
		Modal_view,
		Loading,
		Anexo
	},
	setup(){
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		StoreAll.Token();
		return{
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			Docs_recp: [],
			autores: [],
			document_status: '...',
			documento_name: 'Carregando...',
			documento_src: '',
			documento_statusid: 0,
			documento_id: 0,
			form: {
				NameDoc: '',
				TypeDoc: 1,
			},
			id_doc: 0,
			id_doc_safe: 0,
			NameDoc: '',
			id_session: '',
			ordem_id: 0,
			ordem_select: null,
			error_msg: '',
			header_error: '',
			body_error:'',
			categories: [],
			options: [
				{ id: 0, name: 'Sessão' },
				{ id: 1, name: 'Procurador' },
			],
			ordem_dia: [
				{ id: 1, name: 'Ordem do Dia' },
				{ id: 0, name: 'Expediente do Dia' },
			],
			result: '',
			choice: null,
			parecer_education: null,
			parecer_farm: null,
			parecer_health: null,
			parecer_human: null,
			parecer_infra: null,
			parecer_justice: null
		};
	},

	watch: {
		'Bus.AxexoSecretario': function (newValue, oldPath) {
			if (newValue === true) {
				this.Bus.AxexoSecretario = null;
				this.Refresh_table();
			}   
		},

		'Bus.RemoveParecer': function (newValue, oldPath) {
			if (newValue === true) {
				this.Bus.RemoveParecer = null;
				this.Refresh_table();
				this.showSuccessDelParecer();
			}   
		},
	},

	mounted() {
		this.Getparecer();
		this.Get_Docs();
		/* this.GetSession(); */
		this.GetSessionOther();
		this.GetUser();
		this.GetUsersAll();
	},

	methods: {
		Refresh_table() {
			this.Get_Docs();
		},

		async GetUsersAll() {
			await this.StoreAll.UserAllEnvio();
			this.vereadores = this.StoreAll.user_envio;  
		},

		async GetUser(){
			await this.StoreAll.User(); 
			this.category_user = this.StoreAll.user.user_category_id;
		},

		async GetSessionOther() {
			await this.StoreAll.Get_Data();
			await this.StoreAll.DocCategory();

			this.result = this.StoreAll.sessionToSend;
			let OnSystem = setInterval(() => {
				if (this.StoreAll.document_category != null) {
					clearInterval(OnSystem);
					this.categories = this.StoreAll.document_category;
				}
			}, 500);
		},

		Anexo_send(event) {
			this.$refs.Anexo.openModal(event);
		},

		Get_Docs(){
			AxiosAPI
				.get('/documents?document_status_movement_id=1', {
					headers: {
						Authorization: 'Bearer '+ this.StoreAll.accessToken,
					},
				})
				.then((resp) => {
					this.Docs_recp = resp.data.data;
				});
		},

		Author(value){
			let Co_user = [];
			for (let i = 0; i < value.length; i++) {
				Co_user.push(value[i].nickname);
			}
			return Co_user.join(', ');
		},

		EventDoc_send(event){
			this.documento_id  = event.id;
			this.documento_name = event.name;
			this.form.NameDoc = event.name;
		},
		EventDoc_edit(event){
			this.autores = [];
			this.autores = event.authors;
			this.documento_id  = event.id;
			this.documento_name = event.name;
			this.form.NameDoc = event.name;
			this.form.TypeDoc = event.document_category_id;
		},
		EventDoc(event){
			console.log(event);
			this.StoreAll.document_details = event;

			if (event.carimbo_camara_pdf == null) {
				this.StoreAll.Leitura = false;
				if (event.attachment_partial != null) {
					this.StoreAll.document_src = event.attachment_partial;
				} else {
					this.StoreAll.document_src = event.attachment;
				}
			} else if (event.carimbo_camara_pdf != null && event.carimbo_prefeitura_pdf != null) {
				this.StoreAll.Leitura = true;
				this.StoreAll.document_src = event.carimbo_prefeitura_pdf;
			} else if (event.carimbo_camara_pdf != null && event.carimbo_prefeitura_pdf == null) {
				this.StoreAll.Leitura = true;
				this.StoreAll.document_src = event.carimbo_camara_pdf;
			} else {
				this.StoreAll.Leitura = false;
				if (event.attachment_partial != null) {
					this.StoreAll.document_src = event.attachment_partial;
				} else {
					this.StoreAll.document_src = event.attachment;
				}
			}
			if(event.parecer_procurador_pdf != null){
				this.StoreAll.parecer_procurador_pdf = event.parecer_procurador_pdf;
			}else{
				this.StoreAll.parecer_procurador_pdf = null;
			}
			this.$refs.Modal_view.openModal2(this.Docs_recp);
		},

		/* async GetSession() {
			await AxiosAPI
				.get('/sessions', {
					headers: {
						Authorization: 'Bearer '+this.StoreAll.accessToken,
					},
				})
				.then((response) => {
					this.result = [response.data.data[response.data.data.length - 1]];
				});
		}, */

		change() {
			if (this.choice == 0) {
				this.GetSessionOther();
			}
		},

		ValidatInputFile(){
			const selectedFile = document.getElementById('formFile').files[0];
			this.header_error = '';
			this.body_error = '';
			if (selectedFile) {
				if (selectedFile.type !== 'application/pdf') {
					this.header_error = 'Documento inválido';
					this.body_error = 'Apenas documentos PDF são permitidos para envio.';
					this.show_error();
					document.getElementById('formFile').value = '';
					return;
				}
			}

		},

		async EditDoc(){
			this.$refs.Loading.openModal();

			let autores = [];

			for(let i = 0; i < this.autores.length; i++){
				autores.push(this.autores[i].id);
			}

			if (document.getElementById('formFile').files[0] === undefined) {
				await AxiosAPI.put('document/' + this.documento_id,{
					name: this.form.NameDoc,
					document_category_id: this.form.TypeDoc,
				},
				{
					headers: {
						authorization: 'Bearer '+ this.StoreAll.accessToken,
					},
				}
				).then((resp)=>{
					if(resp.status === 200){
						let dat = JSON.stringify({
							document_id: this.documento_id,
							authors: autores,
						});
						AxiosAPI.patch('document/update-authors',dat,
							{
								headers: {
									'Content-Type': 'application/json',
									authorization: 'Bearer '+ this.StoreAll.accessToken,
								},
							}
						).then((resp)=>{
							if(resp.status === 200){
								this.$refs.Loading.closeModal();
								this.showSuccess_edit();
								this.Get_Docs();}
						});}else{
						this.$refs.Loading.closeModal();
						this.showError_edit();
					}
				});
			}else{
				let file = document.getElementById('formFile').files[0];
				const formData = new FormData();
				formData.append('_method', 'PATCH');
				formData.append('document_id', this.documento_id);
				formData.append('attachment', file);

				await AxiosAPI.post('document/update-attachment', formData,{
					headers: {
						authorization: 'Bearer '+ this.StoreAll.accessToken,
					},
				}
				).then((resp)=>{
					if(resp.status === 200){
						document.getElementById('formFile').value = '';

						AxiosAPI.put('document/' + this.documento_id,{
							name: this.form.NameDoc,
							document_category_id: this.form.TypeDoc,
						},
						{
							headers: {
								authorization: 'Bearer '+ this.StoreAll.accessToken,
							},
						}
						).then((resp)=>{
							if(resp.status === 200){
								let dat = JSON.stringify({
									document_id: this.documento_id,
									authors: autores,
								});
								AxiosAPI.patch('document/update-authors',dat,
									{
										headers: {
											'Content-Type': 'application/json',
											authorization: 'Bearer '+ this.StoreAll.accessToken,
										},
									}
								).then((resp)=>{
									if(resp.status === 200){
										this.$refs.Loading.closeModal();
										this.showSuccess_edit();
										this.Get_Docs();}
								});}else{
								this.$refs.Loading.closeModal();
								this.showError_edit();
							}});
					}
				}).catch(error => {
					this.error_msg = error.response.data.message;
					document.getElementById('formFile').value = '';
					this.$refs.Loading.closeModal();
					this.showError_edit_model();
				});
			}

		},
		DeleteDoc(){
			AxiosAPI.delete('/documents/' + this.documento_id,
				{
					headers: {
						authorization: 'Bearer '+ this.StoreAll.accessToken,
					},
				}
			).then((resp)=>{
				if(resp.status === 200){
					this.showSuccess_del();
					this.Get_Docs();
				}else{
					this.showError_del();
				}
			});
		},
		Enviar() {
			if (this.choice == 0) {
				let ordem = [];

				for (let i = 0; i< this.ordem_select.length; i++) {
					ordem.push(this.ordem_select[i].id);
				}

				let date = {
					session_id: this.id_session,
					document_id: this.documento_id,
					ordem_do_dia: ordem
				};

				const dat = JSON.stringify(date);

				AxiosAPI.post('/attach-document',dat, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer '+this.StoreAll.accessToken,
					},
				})
					.then((resp) => {
						if (resp.status === 200) {
							document.getElementById('Doc_Ok').style.display = 'flex';
							this.Get_Docs();
						}else{
							document.getElementById('Doc_Erro').style.display = 'flex';
						}
					});
			} else {
				AxiosAPI.post('/document/change-status-move?document_id=' + this.documento_id + '&document_status_movement_id=3&user_id='+ this.StoreAll.user.id ,{}, {
					headers: {
						Authorization: 'Bearer '+this.StoreAll.accessToken,
					},
				})
					.then((resp) => {
						if (resp.status === 200) {
							document.getElementById('Doc_procurador').style.display = 'flex';
							this.Get_Docs();
						}else{
							document.getElementById('Doc_Erro').style.display = 'flex';
						}
					});
			}},

		Getparecer() {
			this.parecer_education = null;
			this.parecer_farm = null;
			this.parecer_health = null;
			this.parecer_human = null;
			this.parecer_infra = null;
			this.parecer_justice = null;

			let parecer_education = [];
			let parecer_farm = [];
			let parecer_health = [];
			let parecer_human = [];
			let parecer_infra = [];
			let parecer_justice = [];

			AxiosAPI
				.get('/parecer-comissao?document_id=' + this.documento_id, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				})
				.then((response) => {
					if (response.data.parecer.length <= 0) {
						this.parecer_on = false;
					} else {
						this.parecer_on = true;
					}
					for (let i = 0; i < response.data.parecer.length; i++) {
						if (response.data.parecer[i].comission_id == 1) {
							parecer_education.push(response.data.parecer[i]);
						}
						if (response.data.parecer[i].comission_id == 2) {
							parecer_farm.push(response.data.parecer[i]);
						}
						if (response.data.parecer[i].comission_id == 4) {
							parecer_health.push(response.data.parecer[i]);
						}
						if (response.data.parecer[i].comission_id == 5) {
							parecer_human.push(response.data.parecer[i]);
						}
						if (response.data.parecer[i].comission_id == 3) {
							parecer_infra.push(response.data.parecer[i]);
						}
						if (response.data.parecer[i].comission_id == 6) {
							parecer_justice.push(response.data.parecer[i]);
						}

						if (parecer_education.length <= 0) {
							this.parecer_education = null;
						} else {
							this.parecer_education = parecer_education[0].parecer_pdf;
						}

						if (parecer_farm.length <= 0) {
							this.parecer_farm = null;
						} else {
							this.parecer_farm = parecer_farm[0].parecer_pdf;
						}

						if (parecer_health.length <= 0) {
							this.parecer_health = null;
						} else {
							this.parecer_health = parecer_health[0].parecer_pdf;
						}

						if (parecer_human.length <= 0) {
							this.parecer_human = null;
						} else {
							this.parecer_human = parecer_human[0].parecer_pdf;
						}

						if (parecer_infra.length <= 0) {
							this.parecer_infra = null;
						} else {
							this.parecer_infra = parecer_infra[0].parecer_pdf;
						}

						if (parecer_justice.length <= 0) {
							this.parecer_justice = null;
						} else {
							this.parecer_justice = parecer_justice[0].parecer_pdf;
						}

					}
				});
		},

		finallyloading() {
			document.getElementById('Doc_Ok').style.display = 'none';
			document.getElementById('Doc_procurador').style.display = 'none';
			document.getElementById('Doc_Erro').style.display = 'none';
		},

		show_error() {
			this.$toast.add({severity: 'error', summary: this.header_error , detail: this.body_error , group: 'tl', life: 5000});
		},

		showSuccess_del() {
			this.$toast.add({severity:'success', summary: 'Documento deletado', detail:'O documento foi deletado com sucesso', life: 3000});
		},
		showSuccess_edit() {
			this.$toast.add({severity:'success', summary: 'Documento editado', detail:'O documento foi editado com sucesso', life: 3000});
		},
		showError_del() {
			this.$toast.add({severity:'error', summary: 'Erro ao deletar', detail:'O documento selecionado não foi deletado', life: 3000});
		},
		showError_edit() {
			this.$toast.add({severity:'error', summary: 'Erro ao editar', detail:'Documento selecioando não foi editado', life: 3000});
		},

		showError_edit_model() {
			this.$toast.add({severity:'error', summary: 'Erro ao editar documento', detail: this.error_msg, life: 3000});
		},

		showSuccessDelParecer() {
			this.$toast.add({severity:'success', summary: 'Parecer removido', detail:'O parecer foi removido com sucesso', life: 3000});
		},
	},
};
</script>
<template>
<!-- ADICIONAR DELETE DOCUMENTO OU EDITAR -->
  <div class="container-fluid">
    <Modal_view ref="Modal_view"></Modal_view>
    <Loading ref="Loading"></Loading>
    <Anexo ref="Anexo"></Anexo>

    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">Documentos Recepcionados!</h5>
            <p class="mb-0 text-sm">Agora é so dar seguimento a eles.</p>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="table-responsive">
                  <DataTable :value="Docs_recp" show-gridlines responsive-layout="scroll" :paginator="true" :rows="10">
                      <Column field="id" header="Protocolo" class="text-sm font-weight-bold text-wrap text-uppercase" :sortable="true"></Column>
                      <Column field="name" header="Documento" class="text-sm font-weight-bold text-wrap text-uppercase"></Column>
                      <Column field="document_status_vote_id" header="Status" class="text-sm font-weight-bold text-wrap text-uppercase">
                          <template #body="slotProps">
                            <p v-if="slotProps.data.document_status_vote_id == 1" class="bg-danger text-center font-weight-bolder text-white" style="border-radius: 10px;" > Aguardando movimentação </p>
                            <p v-else class="text-center font-weight-bolder text-white" style="border-radius: 10px; background-color:rgb(249 167 0);"> Retornado ao secretário </p>
                          </template>
                      </Column>
                      <Column field="comissions" header="Pareceres"
                      class="text-sm font-weight-bold text-wrap text-uppercase">
                      <template #body="{ data }">
                        <div v-if="data.parecer_procurador_pdf === null && data.comissions <= 0">
                          <Tag
                            style="border: 1px solid rgb(231, 0, 0);background-color: rgba(9,167, 6, 0.1);color: rgb(231, 0, 0);">
                            <div class="flex align-items-center gap-2">
                              <i class="fa fa-times-circle-o mr-1" aria-hidden="true"></i>
                              <span class="text-base text-uppercase">Nenhum parecer</span>
                            </div>
                          </Tag>
                        </div>

                        <div v-else>

                        <div v-if="data.parecer_procurador_pdf !== null" class="mb-2">
                          <Tag
                              style="border: 1px solid rgb(9 167 6);background-color: rgba(9,167, 6, 0.1);color: rgb(9 167 6);">
                              <div class="flex align-items-center gap-2">
                                <i class="fa fa-check mr-2" aria-hidden="true"></i>
                                <span class="text-base text-uppercase">Procurador</span>
                              </div>
                            </Tag>
                        </div>

                        <div v-else></div>
                        <div v-if="data.comissions <= 0"></div>

                        <div v-else>
                          <div v-for="comission in data.comissions" :key="comission" class="mb-2">

                            <Tag
                              style="border: 1px solid rgb(9 167 6);background-color: rgba(9,167, 6, 0.1);color: rgb(9 167 6);">
                              <div class="flex align-items-center gap-2">
                                <i class="fa fa-check mr-2" aria-hidden="true"></i>
                                <span class="text-base text-uppercase">{{ comission.comission_name }}</span>
                              </div>
                            </Tag>

                          </div>
                        </div>
                        </div>

                      </template>
                    </Column>
                      <Column field="authors" header="Autor(es)"
                        class="text-sm font-weight-bold text-wrap text-uppercase" :sortable="true">
                        <template #body="{ data }">
                          <span>{{Author(data.authors)}} </span>
                        </template>
                      </Column>
                      <Column field="document_category.name" header="Tipo" class="text-sm font-weight-bold text-wrap text-uppercase"></Column>
                      <Column header="Assinado" class="text-sm font-weight-bold text-wrap text-uppercase text-center">
                      <template #body="slotProps">
                        <i v-if="slotProps.data.status_sign === 2 || slotProps.data.status_sign === 1" class="fa fa-check-circle-o text-success" aria-hidden="true" style="font-size: 20px;"></i>
                        <i v-else class="fa fa-times-circle-o text-danger" aria-hidden="true"  style="font-size: 20px;"></i>
                      </template>
                    </Column>
                      <Column  header="Enviar" class="text-sm font-weight-bold text-wrap text-uppercase TT text-center">
                        <template #body="slotProps">
                          <button  data-toggle="modal" style="margin-bottom: 0rem !important;" data-target=".Doc_send" type="button" class="btn bg-smartlegis btnEdit envio" @click="EventDoc_send(slotProps.data)"><i style="color: white !important;" class="fa fa-paper-plane"></i></button>
                </template>
                        </Column>
                      <Column  header="Deletar/Editar" class="text-sm font-weight-bold text-wrap text-uppercase TT text-center">
                        <template #body="slotProps">
                          <button  data-toggle="modal" style="margin-bottom: 0rem !important;" data-target=".edit_doc" type="button" class="btn bg-smartlegis btnEdit envio" @click="EventDoc_edit(slotProps.data)"><i style="color: white !important;" class="fa fa-pencil"></i></button>
                </template>
                        </Column>
                        <Column  header="Anexar Parecer" class="text-sm font-weight-bold text-wrap text-uppercase TT text-center">
                          <template #body="slotProps">
                            <button  data-toggle="modal" style="margin-bottom: 0rem !important;" type="button" class="btn bg-smartlegis btnEdit envio" @click="Anexo_send(slotProps.data)"><i style="color: white !important;" class="fa fa-file" aria-hidden="true"></i></button>
                          </template>
                          </Column>
                        <Column header="Visualizar" class="text-sm font-weight-bold text-wrap text-uppercase TT text-center">
                        <template #body="slotProps">
                          <button  style="margin-bottom: 0rem !important;" type="button" class="btn bg-smartlegis btnEdit envio" @click="EventDoc(slotProps.data)">
                            <i style="color: white !important;" class="fa fa-eye"></i></button>
                        </template>
                        </Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade bd-example-modal-lg Doc_send"
      tabindex="-1"
      role="dialog"
      aria-labelledby="mySmallModalLabel"
      aria-hidden="true"
      style="z-index: 999; display: none"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">
              <span> Enviar documento: </span> <span id="NameDoc" style="color: red"> {{documento_name}} </span>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Fechar"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label>Local de envio</label>
            <select
              id="choices-category"
              v-model="choice"
              class="form-control"
              name="choices-category"
              @change="change"
            >
              <option
                v-for="option in options"
                :key="option.id"
                :value="option.id"  
              >
                {{ option.name }}
              </option>
            </select>

            <div v-if="choice == 0" class="mt-2 mb-3">
              <label> Sessão </label>
              <select
                  id="choices-category"
                  v-model="id_session"
                  class="form-control"
                  name="choices-category"
                  >
                  <option
                    v-for="option in result"
                    :key="option.id"
                    :value="option.id" 
                  >
                    {{ option.name }}
                  </option>
                  <option
                    v-for="optionss in results"
                    :key="optionss.id"
                    :value="optionss.id"
                  >
                    {{ optionss.name }}
                  </option>
                </select>
            </div>
            <div v-else></div>
            

            <div v-if="choice == 0" class="mt-3 mb-3" >
              <label>Enviar para</label>

              <MultiSelectPrime id="choices-category" v-model="ordem_select" display="chip" :options="ordem_dia" option-label="name" placeholder="Selecione local de envio"
                 class="form-control w-full md:w-20rem" style="height: calc(3.5rem + 2px) !important"/> 
                
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button
              type="button"
              class="btn btn-success"
              data-dismiss="modal"
              data-toggle="modal"
              data-target=".envio_x"
              @click="Enviar(); finallyloading();"
            >
              Enviar documento!
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- edit e delete-->

    <div
      class="modal fade bd-example-modal-lg edit_doc"
      tabindex="-1"
      role="dialog"
      aria-labelledby="mySmallModalLabel"
      aria-hidden="true"
      style="z-index:999; display: none"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">
              <span> Editar ou deletar documento - </span>  <span id="NameDoc"> {{documento_name}} </span>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Fechar"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label>Altere o nome do documento</label>
            <input
              v-model="form.NameDoc"
              class="mb-3 multisteps-form__input form-control"
              type="text"
              placeholder="Projeto de lei - organização de cadeiras"
              />
              <label>Altere o tipo do documento</label>
                <select
                  id="choices-category"
                  v-model="form.TypeDoc"
                  class="form-control"
                  name="choices-category"
                  >
                  <option
                  v-for="option in categories"
                  :key="option.id"
                  :value="option.id"
                  >
                  {{ option.name }}
                  </option>
                 </select>

                 <div class="mt-3">
                    <label>Selecione o(s) autor(es)</label>
                      <MultiSelectPrime id="choices-category" v-model="autores" display="chip" :options="vereadores" option-label="name" placeholder="Selecione o(s) autor(es)"
                      class="form-control w-full md:w-20rem" style="height: calc(3.5rem + 2px) !important"/> 
                  </div>

                 <div class="mt-3 mb-3">
                    <label for="formFile" class="form-label"
                        >Altere o documento enviado:</label>
                          <input
                              id="formFile"
                              class="form-control"
                              type="file"
                              accept=".pdf"
                              @change="ValidatInputFile"
                          />
                  </div>

          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-success"
              data-dismiss="modal"
              @click="EditDoc();"
            >
              Salvar edição
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="DeleteDoc();"
            >
              Deletar Documento
            </button>

            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            
          </div>
        </div>
      </div>
    </div>


    <div
                class="modal fade bd-example-modal-sm envio_x"
                tabindex="-1"
                role="dialog"
                aria-labelledby="mySmallModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-sm">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h6>STATUS DO DOCUMENTO</h6>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        @click="finallyloading()"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div
                        id="Doc_Erro"
                        class="justify-content-center"
                        style="display: none"
                      >
                        <span
                          class="
                            justify-content-center
                            text-uppercase
                            font-weight-bold
                          "
                          style="font-size: 13px; color: red"
                        >
                          Erro ao fazer envio para sessão, contate o administrador
                        </span>
                      </div>
                      
                      <div
                        id="Doc_Ok"
                        class="justify-content-center"
                        style="display: none"
                      >
                        <span
                          class="
                            justify-content-center
                            text-uppercase
                            font-weight-bold
                          "
                          style="font-size: 13px; color: green"
                        >
                          Documento Enviado para sessão com sucesso!
                        </span>
                      </div>

                      <div
                        id="Doc_procurador"
                        class="justify-content-center"
                        style="display: none"
                      >
                        <span
                          class="
                            justify-content-center
                            text-uppercase
                            font-weight-bold
                          "
                          style="font-size: 13px; color: green"
                        >
                          Documento Enviado para procurador com sucesso!
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Toast />

  </div>
</template>
<style>
.previous {
  padding-right: 11px;
}
.close{
  font-size: 2.3rem !important;
}
</style>
