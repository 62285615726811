<script>
import ModalBig from './ModalBig.vue';
import moment from 'moment';
import Modal_view from './Modal_View.vue';
import { useMainStore, useBusStore } from '../../../stores/index';
export default {
	components: {
		Modal_view, ModalBig,
	},

	setup() {
		const StoreAll = useMainStore();
		const Bus = useBusStore();

		return {
			StoreAll: StoreAll,
			Bus
		};
	},

	data() {
		return {
			documents: [],
			font: '',
		};
	},

	methods: {
		openModal(value) {
			this.font = 'check';
			this.documents = value;
			this.$refs.viewDocument.openModal();
		},

		openModal2(value) {
			this.font = 'analise';
			this.documents = value;
			this.$refs.viewDocument.openModal();
		},

		closeModal() {
			this.$refs.viewDocument.closeModal();
		},

		SendDoc () {
			this.emitter.emit('GptSend', 1);
			this.$refs.viewDocument.closeModal();
		},

		Author(value){
			let Co_user = [];
			for (let i = 0; i < value.length; i++) {
				Co_user.push(value[i].nickname);
			}
			return Co_user.join(', ');
		},

		formatDate(value) {
			moment.locale('pt-br');
			return moment(value).format('DD/MMMM/YYYY');
		},

		editProduct(event) {
			this.StoreAll.document_details = [];
			this.StoreAll.document_src = [];
			this.StoreAll.document_details = event;
			this.StoreAll.carimbo_camara = false;
			this.StoreAll.carimbo_prefeitura = false;

			if (event.carimbo_camara_pdf == null) {
				this.StoreAll.Leitura = false;
				if (event.attachment_partial != null) {
					this.StoreAll.document_src = event.attachment_partial;
				} else {
					this.StoreAll.document_src = event.attachment;
				}
				this.StoreAll.carimbo_camara = false;
				this.StoreAll.carimbo_prefeitura = false;
			} else if (event.carimbo_camara_pdf != null && event.carimbo_prefeitura_pdf != null) {
				this.StoreAll.Leitura = true;
				this.StoreAll.document_src = event.carimbo_prefeitura_pdf;
				this.StoreAll.carimbo_camara = false;
				this.StoreAll.carimbo_prefeitura = true;
			} else if (event.carimbo_camara_pdf != null && event.carimbo_prefeitura_pdf == null) {
				this.StoreAll.Leitura = true;
				this.StoreAll.document_src = event.carimbo_camara_pdf;
				this.StoreAll.carimbo_camara = true;
				this.StoreAll.carimbo_prefeitura = false;
			} else {
				this.StoreAll.Leitura = false;
				if (event.attachment_partial != null) {
					this.StoreAll.document_src = event.attachment_partial;
				} else {
					this.StoreAll.document_src = event.attachment;
				}
				this.StoreAll.carimbo_camara = false;
				this.StoreAll.carimbo_prefeitura = false;
			}

			if(event.parecer_procurador_pdf != null){
				this.StoreAll.parecer_procurador_pdf = event.parecer_procurador_pdf;
			}else{
				this.StoreAll.parecer_procurador_pdf = null;
			}

			this.$refs.Modal_view.openModal2();
		},
	}
};
</script>
<template>
    <div>
        <Modal_view ref="Modal_view"/>
        <ModalBig ref="viewDocument" style="z-index: 997 !important;">
            <template #header>
                <div class="flex-end">
                    <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </template>

            <template #body>
            <div>
                <div class="d-flex justify-content-center">
                    <h5 class="text-black">Os seguintes documentos tem o mesmo teor:</h5>
                </div>
                <DataTable :value="documents" responsive-layout="scroll" :paginator="true" :rows="5" filter-display="menu">
                      <Column field="id" header="Protocolo" :sortable="true" class="text-sm font-weight-bold text-wrap text-uppercase"></Column>
                      <Column field="name" header="Documento" class="text-sm font-weight-bold text-wrap text-uppercase"></Column>
                      <Column field="document_category.name" header="Tipo" class="text-sm font-weight-bold text-wrap text-uppercase"></Column>
                      <Column field="authors" header="Autor(es)"
                        class="text-sm font-weight-bold text-wrap text-uppercase" :sortable="true">
                        <template #body="{ data }">
                          <span>{{Author(data.authors)}} </span>
                        </template>
                      </Column>
                      <Column field="created_at" header="Data" class="text-sm font-weight-bold text-wrap text-uppercase">
                        <template #body="{ data }">
                          {{ formatDate(data.created_at) }}
                        </template>
                      </Column>
                      <Column header="Visualizar" class="justify-content-center">
                        <template #body="slotProps">
                          <div class="text-center"><button style="margin-bottom: 0rem !important;" type="button"
                              class="btn bg-smartlegis btnEdit envio" @click="editProduct(slotProps.data)">
                              <i style="color: white !important;" class="fa fa-eye"></i></button>
                          </div>
                        </template>
                      </Column>
                    </DataTable>
            </div>
            </template>

            <template #footer>
                <div v-if="font === 'check'" class="d-flex justify-content-center">
                    <h5 class="text-black">Deseja mandar o documento para analize do(a) secretário(a) ?</h5>
                </div>
                <div v-if="font === 'check'" class="d-flex justify-content-center">
                    <button type="button" class="btn btn-success mr-3" @click="SendDoc()">Confirmar</button>
                    <button type="button" class="btn btn-primary" @click="closeModal()">voltar</button>
                </div>
            </template>
        </ModalBig>

    </div>
</template>
<style></style>