<script>
import { AxiosAPI } from '@/axios';
import moment from 'moment';
import { useMainStore } from '../../stores/index';
import Modal_view from '../../components/documents/Modals/Modal_View.vue';

export default {
	name: 'DocSearch',
	components: {
		Modal_view,
	},
	setup(){
		const StoreAll = useMainStore();
		StoreAll.Token();
		return{
			StoreAll: StoreAll,
		};
	},
	data() {
		return {
			messages: [],
			Search_my: [],
			session_id: 'realizadass',
			documento_session_id: 0,
			document_status: '...',
			documento_name: 'Carregando...',
			documento_src: '',
			document_status_vote_id: 0,
			documento_id: 0,
			filter: {
				protocol: null,
				name: null,
				type_doc: 0,
				status_doc: 0,
				date_creat: null,
				authors: [],
			},
			result_vote: [
				{ key: '0', result: 'Selecione o status do documento' },
				{ key: '1', result: 'Aprovado' },
				{ key: '2', result: 'Reprovado' },
				{ key: '3', result: 'Em vista' },
			],
			categories: [{id: 0, name: 'Selecione o tipo do documento'}],
		};
	},
	mounted() {
		this.GetUser();
		this.GetUsersAll();
		this.GetSession();
	},
	methods: {

		async GetUsersAll() {
			await this.StoreAll.UserAllEnvio();
			this.vereadores = this.StoreAll.user_envio;  
		},

		async GetUser(){
			await this.StoreAll.User(); 
			this.Get_Search_my();
		},

		async GetSession() {
			await this.StoreAll.Get_Data();
			await this.StoreAll.DocCategory();

			let OnSystem = setInterval(() => {
				if (this.StoreAll.document_category != null) {
					clearInterval(OnSystem);
					this.categories = this.StoreAll.document_category;
				}
			}, 500);
		},

		resultvote(value) {

			let inner = 'Documento' + ' ';
			let result_one = '';
			let result_two = '';

			if (value.voting_result_1 === 1) {
				result_one = 'aprovado em 1° votação';
			} else if (value.voting_result_1 === 2) {
				result_one = 'reprovado em 1° votação';
			} else {
				result_one = 'em vista em 1° votação';
			}

			if (value.voting_result_2 === 1) {
				result_two = ' ' + 'e aprovado em 2° votação';
			} else if (value.voting_result_2 === 2) {
				result_two = ' ' + 'e reprovado em 2° votação';
			} else {
				result_two = ' ' + 'e em vista em 2° votação';
			}

			let result = inner + result_one + result_two;
			return result;
		},

		FilterDoc() {
			let user_now = [];
			user_now.push(this.StoreAll.user.id);
			let filet_user = JSON.stringify(user_now);

			function getValueOrEmpty(value) {
				return value != null && value !== '' ? value : '';
			}

			// Filtrar os valores e criar o objeto de filtro
			const filteredValues = {
				protocolo: getValueOrEmpty(this.filter.protocol),
				name: getValueOrEmpty(this.filter.name),
				type_doc: this.filter.type_doc !== 0 ? this.filter.type_doc : '',
				status_doc: this.filter.status_doc !== 0 ? this.filter.status_doc : '',
				date_creat: getValueOrEmpty(this.filter.date_creat),
			};

			// Verificar se todos os campos estão vazios
			const allFieldsEmpty = Object.values(filteredValues).every(value => value === '');

			let filt_no_id = '?authors='+filet_user+'&document_category_id=' + filteredValues.type_doc + '&name=' + filteredValues.name + '&id=' + filteredValues.protocolo + '&voting_result=' + filteredValues.status_doc + '&created_at=' + filteredValues.date_creat;

			if (allFieldsEmpty) {
				AxiosAPI
					.get('/documents?authors='+filet_user, {
						headers: {
							Authorization: `Bearer ${this.StoreAll.accessToken}`,
						},
					})
					.then((resp) => {
						this.Search_my = resp.data.data;
					});
			} else {
				AxiosAPI
					.get('/documents' + filt_no_id, {
						headers: {
							Authorization: `Bearer ${this.StoreAll.accessToken}`,
						},
					})
					.then((resp) => {
						this.Search_my = resp.data.data;
					});
			}

		},

		Get_Search_my(){
			let user_now = [];
			user_now.push(this.StoreAll.user.id);
			let filet_user = JSON.stringify(user_now);
			AxiosAPI
				.get('/documents?authors='+filet_user, {
					headers: {
						Authorization: 'Bearer '+ this.StoreAll.accessToken,
					},
				})
				.then((resp) => {
					this.Search_my = resp.data.data;
				});
		},

		Author(value){
			let Co_user = [];
			for (let i = 0; i < value.length; i++) {
				Co_user.push(value[i].nickname);
			}
			return Co_user.join(', ');
		},

		showError() {
			this.$toast.add({severity:'error', summary: 'Sessão Não Encotrada', detail:'Documento não possui sessão', life: 3000});
		},
		Go_History(event) {
			if(event.sessions.length <= 0){
				this.showError();     
				return;
			}else{
				localStorage.setItem('Session_id', event.sessions[0].id);
				window.location.href = '/historico_sessao/';
			}     
		},
		formatDate(value) {
			moment.locale('pt-br');
			return moment(value).format('DD/MMMM/YYYY');
		},
        
		ViewDoc(event){
			this.StoreAll.document_details = event;
			/* Populando a variavel global */

			if (event.carimbo_camara_pdf == null) {
				this.StoreAll.Leitura = false;
				if (event.attachment_partial != null) {
					this.StoreAll.document_src = event.attachment_partial;
				} else {
					this.StoreAll.document_src = event.attachment;
				}
			} else if (event.carimbo_camara_pdf != null && event.carimbo_prefeitura_pdf != null) {
				this.StoreAll.Leitura = true;
				this.StoreAll.document_src = event.carimbo_prefeitura_pdf;
			} else if (event.carimbo_camara_pdf != null && event.carimbo_prefeitura_pdf == null) {
				this.StoreAll.Leitura = true;
				this.StoreAll.document_src = event.carimbo_camara_pdf;
			} else {
				this.StoreAll.Leitura = false;
				if (event.attachment_partial != null) {
					this.StoreAll.document_src = event.attachment_partial;
				} else {
					this.StoreAll.document_src = event.attachment;
				}
			}

			this.$refs.Modal_view.openModal2();
		},
         
	},
};
</script>
<template>
<div>
      <Modal_view ref="Modal_view"></Modal_view>
  </div>
  <div class="container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">Pesquise por seus documentos enviados!</h5>
            <p class="mb-0 text-sm">
              Aqui você pode visualizar seus documentos enviados.
            </p>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="table-responsive">                  
                  <DataTable :value="Search_my" show-gridlines responsive-layout="scroll" :paginator="true" :rows="10">

                    <template #header>
                      <div class="d-flex row">
                        <div class="col-md-4 col-md-auto col-sm">
                          <div class="card flex justify-content-center">
                            <label for="username">Protocolo</label>
                          </div>
                          <div>
                            <input placeholder="Protocolo do documento" v-model="filter.protocol" class="my_inp" />
                          </div>
                          <div class="card flex justify-content-center">
                            <small id="username-help">Informe o protocolo do documento.</small>
                          </div>
                        </div>


                        <div class="col-md-4 col-md-auto col-sm">
                          <div class="card flex justify-content-center">
                            <label for="username">Objeto do documento</label>
                          </div>
                          <div>
                            <input class="my_inp" placeholder="Objeto do documento" v-model="filter.name" />
                          </div>
                          <div class="card flex justify-content-center">
                            <small id="username-help">Informe o objeto do documento.</small>
                          </div>
                        </div>

                        <div class="col-md-4 col-md-auto col-sm">
                          <div class="card flex justify-content-center">
                            <label for="username">Tipo do documento</label>
                          </div>
                          <select class="my_inp" id="choices-category" v-model="filter.type_doc" name="choices-category">
                            <option v-for="option in categories" :key="option.id" :value="option.id">
                              {{ option.name }}
                            </option>
                          </select>
                          <div class="card flex justify-content-center">
                            <small id="username-help">Informe o tipo do documento.</small>
                          </div>
                        </div>

                        <div class="col-md-4 col-md-auto col-sm">
                          <div class="card flex justify-content-center">
                            <label for="username">Status do documento</label>
                          </div>
                          <select class="my_inp" id="choices-category" v-model="filter.status_doc"
                            name="choices-category">
                            <option v-for="option in result_vote" :key="option.key" :value="option.key">
                              {{ option.result }}
                            </option>
                          </select>
                          <div class="card flex justify-content-center">
                            <small id="username-help">Informe o status do documento</small>
                          </div>
                        </div>

                        <div class="col-md-4 col-md-auto col-sm">
                          <div class="card flex justify-content-center">
                            <label for="username">Data de envio</label>
                          </div>
                          <input type="date" class="mb-3 multisteps-form__input form-control my_inp"
                            v-model="filter.date_creat">
                          <div class="card flex justify-content-center">
                            <small id="username-help">Informe a data de envio</small>
                          </div>
                        </div>

                        <div class="d-flex justify-content-center">
                          <button class="btn bg-smartlegis text-white" style="width: 100%;"
                            @click="FilterDoc()">Filtrar</button>
                        </div>
                      </div>
                    </template>
                  
                      <Column field="id" header="Protocolo" class="text-sm font-weight-bold text-wrap text-uppercase" :sortable="true"></Column>
                      <Column field="name" header="Documento" class="text-sm font-weight-bold text-wrap text-uppercase"></Column>
                      <Column field="document_category.name" header="Tipo" class="text-sm font-weight-bold text-wrap text-uppercase"></Column>
                      <Column field="authors" header="Autor(es)"
                        class="text-sm font-weight-bold text-wrap text-uppercase" :sortable="true">
                        <template #body="{ data }">
                          <span>{{Author(data.authors)}} </span>
                        </template>
                      </Column>
                      <Column field="created_at" header="Data" class="text-sm font-weight-bold text-wrap text-uppercase">
                        <template #body="{data}">
                        {{formatDate(data.created_at)}}
                    </template>
                      </Column>
                      <Column field="voting_result_1" header="Resultado" class="text-sm font-weight-bold text-uppercase">
                        <template #body="{ data }">
                          <span v-if="data.voting_result_1 === null" class="text-center font-weight-bolder text-white"
                            style="background-color: #261e1e; border-radius: 6px;font-size: 15px;padding: 4px;"> Não votado
                          </span>

                          <span v-else-if="data.voting_result_1 === 1 && data.voting_result_2 === null"
                            class="bg-success text-center font-weight-bolder text-white"
                            style="border-radius: 6px;font-size: 15px;padding: 4px;"> Documento aprovado
                          </span>
                          <span v-else-if="data.voting_result_1 === 2 && data.voting_result_2 === null"
                            class="bg-danger text-center font-weight-bolder text-white"
                            style="border-radius: 6px;font-size: 15px;padding: 4px;"> Documento reprovado
                          </span>
                          <span v-else-if="data.voting_result_1 === 3 && data.voting_result_2 === null"
                            class="bg-secondary text-center font-weight-bolder text-white"
                            style="border-radius: 6px;font-size: 15px;padding: 4px;"> Documento em vista
                          </span>
                          <span v-else-if="data.voting_result_1 != null && data.voting_result_2 != null"
                            class="text-center font-weight-bolder text-white"
                            style="background-color: #2a8b89; border-radius: 6px;font-size: 15px;padding: 4px;">
                            {{ resultvote(data) }} </span>

                          <span v-else class="text-center font-weight-bolder text-white"
                            style="background-color: #261e1e; border-radius: 6px;font-size: 15px;padding: 4px;"> Não
                            votado</span>
                        </template>
                      </Column>
                      <Column v-if="this.StoreAll.user.user_category_id == 1" header="Carimbo" class="text-sm font-weight-bold text-wrap text-uppercase text-center">
                      <template #body="slotProps">
                        <i v-if="slotProps.data.carimbo_camara_pdf != null" class="fa fa-check-circle-o text-success" aria-hidden="true" style="font-size: 20px;"></i>
                        <i v-else class="fa fa-times-circle-o text-danger" aria-hidden="true"  style="font-size: 20px;"></i>
                      </template>
                    </Column>
                    <Column header="Carimbo prefeitura" class="text-sm font-weight-bold text-wrap text-uppercase text-center">
                              <template #body="slotProps">
                                <i v-if="slotProps.data.carimbo_prefeitura_pdf != null" class="fa fa-check-circle-o text-success" aria-hidden="true" style="font-size: 20px;"></i>
                                <i v-else class="fa fa-times-circle-o text-danger" aria-hidden="true"  style="font-size: 20px;"></i>
                              </template>
                    </Column>
                    <Column header="Assinado" class="text-sm font-weight-bold text-wrap text-uppercase text-center">
                      <template #body="slotProps">
                        <i v-if="slotProps.data.status_sign === 2 || slotProps.data.status_sign === 1" class="fa fa-check-circle-o text-success" aria-hidden="true" style="font-size: 20px;"></i>
                        <i v-else class="fa fa-times-circle-o text-danger" aria-hidden="true"  style="font-size: 20px;"></i>
                      </template>
                    </Column>
                      <Column  header="Visualizar" class="text-sm font-weight-bold text-wrap text-uppercase TT text-center">
                        <template #body="slotProps">
                          <button style="margin-bottom: 0rem !important;" type="button" class="btn bg-smartlegis btnEdit envio" @click="ViewDoc(slotProps.data)">
                            <i style="color: white !important;" class="fa fa-eye"></i>
                          </button>
                </template>
                        </Column>
                        <Column header="Ir para sessão" class="text-sm font-weight-bold text-wrap text-uppercase TT text-center">
                        <template #body="slotProps">
                          <button  data-toggle="modal" style="margin-bottom: 0rem !important;" type="button" class="btn bg-smartlegis btnEdit envio" @click="Go_History(slotProps.data)">
                            <i style="color: white !important;" class="fa fa-arrow-circle-o-right"></i>
                          </button>
                        </template>
                        </Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <Toast />
</div>
</template>
<style scoped>

.close{
  font-size: 2.3rem !important;
}

.p-multiselect-token {
  padding: 0.175rem 0.75rem !important;
}

.p-multiselect-label {
  padding: 0px !important;
}

.my_inp {
  width: 100%;
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.38);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
}
</style>
