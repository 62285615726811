<script>
import Tv_quorum from '../Two_tv/Tv_quorum.vue';
import Tv_Header_Two from '../Two_tv/Tv_Header_Two.vue';
export default {
	name: 'TvFullQuorum',
	components: { Tv_quorum, Tv_Header_Two },
	data() {
		return {
			tela_tv: false,
			fullScren: false,
			voted: false,
			tela: '',
			isFullScreen: false,
		};
	},

	mounted() {
		this.testetela();
		this.telafull();
		this.testetando();
	},
	methods: {
		telafull(){
			this.isFullScreen = false;
			let elem = document.documentElement;
			localStorage.setItem('full_2', 'ok');
    
			if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
				this.isFullScreen = false;
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
				this.isFullScreen = false;
			}


			if (elem.requestFullscreen) {
				elem.requestFullscreen();
				this.isFullScreen = true;
			} else if (elem.mozRequestFullScreen) {
				elem.mozRequestFullScreen();
				this.isFullScreen = true;
			} else if (elem.webkitRequestFullscreen) {
				elem.webkitRequestFullscreen();
				this.isFullScreen = true;
			} else if (elem.msRequestFullscreen) {
				elem.msRequestFullscreen();
				this.isFullScreen = true;
			}
		},
		testetela() {
			setTimeout(() => {
				this.testetela();
			}, 2000);
			let largura = window.screen.width;
			let altura = window.screen.height;
			if (
				(largura >= 1280 && largura <= 1365) ||
        (altura >= 720 && altura <= 767)
			) {
				this.tela = 'HD';
			}
			if (
				(largura >= 1366 && largura <= 1599) ||
        (altura >= 768 && altura <= 899)
			) {
				this.tela = 'WXGA';
			}
			if (
				(largura >= 1600 && largura <= 1919) ||
        (altura >= 900 && altura <= 1079)
			) {
				this.tela = 'HD+';
			}
			if (
				(largura >= 1920 && largura <= 2559) ||
        (altura >= 1080 && altura <= 1439)
			) {
				this.tela = 'FHD';
			}
			if (
				(largura >= 2560 && largura <= 3839) ||
        (altura >= 1440 && altura <= 2159)
			) {
				this.tela = 'WQHD';
			}
			if (largura >= 3840 && altura >= 2160) {
				this.tela = '4K';
			}
		},
		refresh() {
			location.reload();
		},
		testetando(){
			if(this.$route.name == 'Tela de sessão 2'){
				document.body.style.backgroundImage = 'linear-gradient(to right, #004c85, #00508d, #005fa5)';
			}else{
				document.body.style.backgroundImage = 'white';
			}
		},
	},
};
</script>
<template>
    <div>
      <div
        :class="{
          backHD: tela === 'HD',
          backWXGA: tela == 'WXGA',
          backHDM: tela == 'HD+',
          backFHD: tela == 'FHD',
          backWQHD: tela == 'WQHD',
          backFOURK: tela == '4K',
        }"
        style="height: 100vh; border-radius: 0px; "
      >
        <Tv_Header_Two />
  
        <Tv_quorum />
  
      </div>
    </div>
  </template>
  <style>
  .text-black {
    color: black;
  }
  .backHD {
    background-image: url(../../../assets/img/HD.png);
    background-size: cover;
  }
  .backWXGA {
    background-image: url(../../../assets/img/HD+.png);
    background-size: cover;
  }
  .backHDM {
    background-image: url(../../../assets/img/HD+.png);
    background-size: cover;
  }
  .backFHD {
    background-image: url(../../../assets/img/FHD.png);
    background-size: cover;
  }
  .backWQHD {
    background-image: url(../../../assets/img/WQHD.png);
    background-size: cover;
  }
  .backFOURK {
    background-image: url(../../../assets/img/FK.png);
    background-size: cover;
  }
  </style>
  