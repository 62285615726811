import {AxiosAPI} from '@/axios';

export default {
	async auth(to, from, next) {
		const token = localStorage.getItem('TokenExterno');

		if (!token) {
			AxiosAPI.post('/external/access'
			).then((resp) => {
				localStorage.setItem('TokenExterno', resp.data.token);	
				next();
			});		
		} else {
    	    AxiosAPI.post('/external/access').then((resp) => {
				localStorage.setItem('TokenExterno', resp.data.token);		
			});	

			next();
		}
	}
};
