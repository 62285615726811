<script>
import { AxiosAPI } from '@/axios';
import { useMainStore, useBusStore } from '../../stores/index';
import Loading from '../../components/documents/Modals/Loading.vue';

export default {
	name: 'TribunaPresidente',
	components: { Loading },
	setup() {
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		StoreAll.Token();
		return {
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			user_id: 0,
			convidados_now: [],
			convidado: false,
			convidados_id: [],
			category_user: 0,
			quorum: [],
			quorums: [],
			namer: [],
			tribuna_id: 0,
			tribuna_vereador: null,
			time: false,
			tribune_init: null,
			seconds: 2,
			minutes: 0,
			quorum_tribune_session: [],
			tribune_session: [],
			cronometro: 'Aguarde...',
			voted_on: null,
			tribune_on: null,
			explanation_on: null,
			discut_on: null,
			header_error: '',
			body_error: '',
			header_success: '',
			body_success: '',
			list_users_quorum: [],
			list_users: [],
			Loadings: false,
			socket_ids: [],
		};
	},

	created () {
		let OnSocket = setInterval(() => {
			if (this.StoreAll.websocket_host !== null) {
				clearInterval(OnSocket);
				window.Echo.channel(this.StoreAll.websocket_host).listen('.' + this.StoreAll.websocket_host, (e) => {
					if (e.socket_id === 10) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.tribunaOns();
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 11) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.GetTribunaUsers_websocket();
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 12) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							if (this.StoreAll.host == 'campogrande') {
								this.FimTribunaTv();
							}
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 14) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.GetTribunaUsers_websocket();
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 15) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.GetTribunaUsers_websocket();
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 16) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.GetTribunaUsers_websocket();
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 17) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.GetTribunaUsers_websocket();
							this.socket_ids.push(e.event_id);  
						}
					} 
			
				});
			}}, 500);
	},

	mounted() {
		this.GetUsersAll();
		this.GetUserConvidado();
		this.GetSession();
		this.Get_Session_Tribune();
		this.User();
		this.timerstart();
		this.TimesNow_presidente();
		this.GetTribunaUsers_websocket();

	},
	methods: {

		TimesNow_presidente() {
			let verificarTimer = setInterval(() => {
				if (this.StoreAll.timers !== null) {
					clearInterval(verificarTimer);

					this.seconds = this.StoreAll.timers[0].Subscriber_tribune.seconds;
					this.minutes = this.StoreAll.timers[0].Subscriber_tribune.minutes;

				}
			}, 500);
		},

		async GetSession() {
			/* verificar essa situação */
			await this.StoreAll.Get_Data();
			this.session_id = this.StoreAll.session.id;
		},

		async User() {
			await this.StoreAll.User();
			this.user_id = this.StoreAll.user.id;
			this.category_user = this.StoreAll.user.user_category_id;
		},

		async GetUsersAll() {
			await this.StoreAll.UserAllEnvio();
			this.vereadores = this.StoreAll.user_envio;
		},

		async GetUserConvidado() {
			let Convidado = [];
			await AxiosAPI.get('/users', {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				},
			})
				.then((response) => {
					for (let i = 0; i < response.data.length; i++) {
						if (response.data[i].name == 'Convidado' || response.data[i].user_category_id == 5) {
							Convidado.push(response.data[i]);
						}
						this.convidados_now = Convidado;
					}

				});
		},

		GetQuorumUsers() {
			this.Loadings = true;
			let verificarElemento = setInterval(() => {
				if (this.StoreAll.quorum !== null) {
					clearInterval(verificarElemento);
					AxiosAPI.get('/quorum-users?quorum_id=' + this.StoreAll.quorum[0].id, {
						headers: {
							authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}).then((response) => {
						let list_user_quorum = [];
						list_user_quorum = this.convidados_now;

						for (let i = 0; i < response.data.data.length; i++) {
							list_user_quorum.push(response.data.data[i].user);
						}

						this.list_users_quorum = list_user_quorum;
						this.Loadings = false;
					});
				}
			});
		},

		async Get_Session_Tribune() {
			await this.StoreAll.Get_Data();
			/* await AxiosAPI.get('/sessions', {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((resp) => {
				let size = resp.data.data.length;
				let session = resp.data.data[size - 1]; */

			this.tribune_session = this.StoreAll.session;
			this.Quorum_data_tribune();
			/* verificar se isso tem algum impacto relevante */
			/* }); */
		},

		async Quorum_data_tribune() {
			await AxiosAPI.get('/quorum?session_id=' + this.tribune_session.id, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken,
				},
			})
				.then((response) => {
					let quorum = response.data.data;

					this.quorum_tribune_session = quorum;

					this.tribunaOns();
					this.GetTribunaUsers_websocket();
				});
		},

		async tribunaOns() {

			if (this.quorum_tribune_session[0].id == null) { return; } else {

				await AxiosAPI.get('/tribune?quorum_id=' + this.quorum_tribune_session[0].id, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				}).then((response) => {
					if (response.data.data.length <= 0) {
						this.tribune_init = false;
					} else if (response.data.data[0].status_tribune == 1) {
						this.tribune_init = true;
					} else if (response.data.data[0].status_tribune != 1) {
						this.tribune_init = null;
					}
				});
			}

		},

		Success_CreateT() {
			this.$toast.add({ severity: 'success', summary: 'Tribuna iniciada', detail: 'A tribuna foi iniciada, aguarde inscrições', life: 3000 });
		},
		Starttribune_error_show() {
			this.$toast.add({ severity: 'error', summary: 'Tribuna não inciada', detail: 'A tribuna não foi iniciada!', life: 3000 });
		},
		async Checks(value) {
			this.$refs.Loading.openModal();

			await AxiosAPI.get('/documents?session_id=' + this.StoreAll.session.id + '&document_status_vote_id=4',
				{
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				}
			)
				.then((response) => {
					if (response.data.data.length <= 0) {
						this.voted_on = false;
					} else {
						this.voted_on = true;
					}

				});


			await AxiosAPI.get('/discussion?quorum_id=' + this.StoreAll.quorum[0].id, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken,
				},
			}).then((resp) => {
				for (let i = 0; i < resp.data.data.length; i++) {
					if (resp.data.data[i].status_discussion == 1) {
						this.discut_on = true;
					} else {
						this.discut_on = false;
					}
				}
			});


			const quorum = this.StoreAll.quorum;
			const accessToken = this.StoreAll.accessToken;
			try {
				const response = await AxiosAPI.get('/tribune?quorum_id=' + quorum[0].id, {
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				});

				const tribunaData = response.data.data[0];

				if (tribunaData.status_tribune == 1 || tribunaData.status_tribune == 2) {
					this.tribune_on = true;
				} else {
					this.tribune_on = false;
				}
			}
			catch {
				this.tribune_on = false;
			}


			await AxiosAPI.get('/big-discussion?quorum_id' + this.StoreAll.quorum[0].id, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken,
				},
			}).then((resp) => {
				for (let i = 0; i < resp.data.data.length; i++) {
					if (resp.data.data[i].status_big_discussion == 1) {
						this.explanation_on = true;
					} else {
						this.explanation_on = false;
					}
				}
			});

			await this.CreateTribuna(value);

		},

		async CreateTribuna(id) {
			if (this.voted_on === true) {
				this.$refs.Loading.closeModal();
				this.$swal.fire({
					title: 'Existe uma votação em andamento, encerre para iniciar a tribuna',
					showDenyButton: false,
					showCancelButton: false,
					buttonsStyling: false,
					confirmButtonText: 'OK',
					customClass: {
						confirmButton: 'btn btn-success mr-1 ml-1',
					},
				}).then((result) => {
					if (result.isConfirmed) {
						this.Starttribune_error_show();
					} else {
						this.Starttribune_error_show();
					}
				});
			}
			else if (this.tribune_on === true) {
				this.$refs.Loading.closeModal();
				this.$swal.fire({
					title: 'A tribuna já está em andamento',
					showDenyButton: false,
					showCancelButton: false,
					buttonsStyling: false,
					confirmButtonText: 'OK',
					customClass: {
						confirmButton: 'btn btn-success mr-1 ml-1',
					},
				}).then((result) => {
					if (result.isConfirmed) {
						this.Starttribune_error_show();
					} else {
						this.Starttribune_error_show();
					}
				});
			}
			else if (this.explanation_on === true) {
				this.$refs.Loading.closeModal();
				this.$swal.fire({
					title: 'A explicações pessoais estão em andamento, solicite o encerramento para iniciar a tribuna',
					showDenyButton: false,
					showCancelButton: false,
					buttonsStyling: false,
					confirmButtonText: 'OK',
					customClass: {
						confirmButton: 'btn btn-success mr-1 ml-1',
					},
				}).then((result) => {
					if (result.isConfirmed) {
						this.Starttribune_error_show();
					} else {
						this.Starttribune_error_show();
					}
				});
			}
			else if (this.discut_on === true) {
				this.$refs.Loading.closeModal();
				this.$swal.fire({
					title: 'Existe uma discussão em andamento, solicite o encerramento para iniciar a tribuna',
					showDenyButton: false,
					showCancelButton: false,
					buttonsStyling: false,
					confirmButtonText: 'OK',
					customClass: {
						confirmButton: 'btn btn-success mr-1 ml-1',
					},
				}).then((result) => {
					if (result.isConfirmed) {
						this.Starttribune_error_show();
					} else {
						this.Starttribune_error_show();
					}
				});
			} else {
				if (id == 1) {
					const formData = new FormData();
					formData.append('quorum_id', this.StoreAll.quorum[0].id);
					formData.append('status_tribune', 1);

					await AxiosAPI.post('/tribune', formData, {
						headers: {
							Authorization: 'Bearer ' + this.StoreAll.accessToken
						}
					}).then((response) => {
						if (response.status == 200) {
							this.StoreAll.tribuna_id = response.data.data.id;
							this.time = true;
							this.tribune_init = true;
							this.Success_CreateT();
							setTimeout(() => {

								const convit = {
									tribune_id: response.data.data.id,
									users_id: this.convidados_id,
									status_fala: 1
								};
								AxiosAPI.post('/tribune-users', convit, {
									headers: {
										'Content-Type': 'application/json',
										Authorization: 'Bearer ' + this.StoreAll.accessToken
									}
								}).then((response) => {
									if (response.status == 200) {
										this.StoreAll.in_tribuna = true;
										this.Bus.tribuneEmitModalOne = true;
										this.convidado = true;
										this.$refs.Loading.closeModal();
									}
								});

							}, 1000);
						}
					});
					this.GetTribunaUsers_websocket();
				} else if(id === 3){

					const formData = new FormData();
					formData.append('quorum_id', this.StoreAll.quorum[0].id);
					formData.append('status_tribune', 1);

					await AxiosAPI.post('/tribune', formData, {
						headers: {
							Authorization: 'Bearer ' + this.StoreAll.accessToken
						}
					}).then((response) => {
						if (response.status == 200) {
							this.StoreAll.tribuna_id = response.data.data.id;
							let tribune_id = response.data.data.id;
							let vereadores_id = [];
							for (let i = 0; i < this.list_users.length; i++) {
								vereadores_id.push(this.list_users[i].id);
							}
							const list = {
								tribune_id: response.data.data.id,
								users_id: vereadores_id,
								status_fala: 1
							};


							AxiosAPI.post('/tribune-users', list, {
								headers: {
									'Content-Type': 'application/json',
									Authorization: 'Bearer ' + this.StoreAll.accessToken
								}
							}).then((response) => {
								if (response.status == 200) {
									this.StoreAll.in_tribuna = true;
									this.Bus.tribuneEmitModalOne = true;


									AxiosAPI.put('/tribune/' + tribune_id, {
										status_tribune: 2
									}, {
										headers: {
											Authorization: 'Bearer ' + this.StoreAll.accessToken
										}
									}).then((response) => {
										if (response.status == 200) {
											this.tribune_init = null;
											this.GetTribunaUsers_websocket();
											this.$refs.Loading.closeModal();
										}
									});
								}
							});
						}
					});


				}else {
					const formData = new FormData();
					formData.append('quorum_id', this.StoreAll.quorum[0].id);
					formData.append('status_tribune', 1);

					await AxiosAPI.post('/tribune', formData, {
						headers: {
							Authorization: 'Bearer ' + this.StoreAll.accessToken
						}
					}).then((response) => {
						if (response.status == 200) {
							this.StoreAll.tribuna_id = response.data.data.id;
							this.time = true;
							this.tribune_init = true;
							this.Success_CreateT();
							this.$refs.Loading.closeModal();
						}
					});
					this.GetTribunaUsers_websocket();
				}
			}
		},

		show_error() {
			this.$toast.add({ severity: 'error', summary: this.header_error, detail: this.body_error, group: 'tl', life: 5000 });
		},
		show_success() {
			this.$toast.add({ severity: 'success', summary: this.header_success, detail: this.body_success, group: 'tl', life: 3000 });
		},

		async FimTribuna() {
			this.$refs.Loading.openModal();
			await AxiosAPI.put('/tribune/' + this.StoreAll.tribuna_id, {
				status_tribune: 2
			}, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((response) => {
				if (response.status == 200) {
					this.time = false;
					this.tribune_init = null;
					document.getElementById('load').style.display = 'none';
					document.getElementById('fim_tribune').style.display = 'flex';
					this.header_success = 'Inscrições encerrada';
					this.body_success = 'Você finalizou as inscrições';
					localStorage.removeItem('timer');
					this.$refs.Loading.closeModal();
					this.show_success();
				}
			});
		},

		FimTribunaTv () {
			this.time = false;
			this.tribune_init = null;
			document.getElementById('load').style.display = 'none';
			document.getElementById('fim_tribune').style.display = 'flex';
			localStorage.removeItem('timer');
		},

		closebox() {
			document.getElementById('load').style.display = 'flex';
			document.getElementById('start_tribune').style.display = 'flex';
			document.getElementById('fim_tribune').style.display = 'none';
		},

		GetTribunaUsers_websocket() {
			let verificarTimer = setInterval(() => {
				if (this.StoreAll.tribuna_id !== 0) {
					clearInterval(verificarTimer);
					AxiosAPI.get('/tribune-users?tribune_id=' + this.StoreAll.tribuna_id, {
						headers: {
							Authorization: 'Bearer ' + this.StoreAll.accessToken
						}
					}).then((response) => {
						let tribuna_vereador = [];
						if (response.data.data.length <= 0) {
							this.tribuna_vereador = 'Aguarde...';
						} else {
							for (let i = 0; i < response.data.data.length; i++) {
								if (response.data.data[i].status_fala == 7 || response.data.data[i].status_fala == 8) {
									continue;
								} else {
									tribuna_vereador.push(response.data.data[i]);
									this.tribuna_vereador = tribuna_vereador;
								}
							}
						}
					});
				}
			}, 500);
		},
		/* atualização de quem está falando pela telá do presidente */


		timerstart() {
			if (localStorage.getItem('timer') == 'ok' && this.StoreAll.user.user_category_id == 3) {
				this.timer();
			} else {
				setTimeout(() => {
					this.timerstart();
				}, 1000);
			}
		},
		timer() {
			setTimeout(() => {
				this.timer();
			}, 1000);
			let sec = this.seconds--;
			let min = this.minutes;

			if (min == 0 && sec == 0) {
				this.seconds = 0;
				this.minutes = 0;
				this.cronometro = '';
				if (localStorage.getItem('timer') == 'ok') {
					let text = 'Tempo encerrado, encerre as inscrições!';
					document.getElementById('timerzz').innerText = text;
				}
			} else {

				if (sec <= 9) {
					sec = '0' + sec;
					this.t = sec;
				}
				if (sec == 0) {
					this.seconds = 59;
					let min = this.minutes--;
					this.m = min;
				}
				if (min == 0 && sec <= 59) {
					if (localStorage.getItem('timer') == 'ok') {
						document.getElementById('timerz').style.color = '#FF0000';
						document.getElementById('timerzz').style.color = '#FF0000';
					} else if (localStorage.getItem('timer') == undefined) {
						this.t = 'f';
					}
				}

				this.cronometro = min + ' : ' + sec;
				this.StoreAll.cronometro_tribuna = min + ' : ' + sec;
				this.c = sec;
			}
		}
	},
};
</script>
<template>
  <div style="z-index: 1010 !important;">
    <Loading ref="Loading"></Loading>
  </div>
  <div class="py-4 h-100 container-fluid">
    <div class="row">
      <div class="col-12">
        <h3 class="mt-auto text-white text-center ">Inicie a tribuna e veja seus participantes!</h3>
        <h5 class="text-white font-weight-normal text-center ">
          Clique em iniciar e aguarde o tempo finalizar, após isso clique finalizar, a lista de participantes
          ficará disponível aqui!
        </h5>
        <div class="mb-5 multisteps-form">
          <div class="row">
            <div class="m-auto col-12 col-lg-8">

              <div>
                <div class="
                      p-3
                      bg-white
                      
                      multisteps-form__panel
                      border-radius-xl
                      js-active
                      position-relative
                    " data-animation="FadeIn">
                  <div class="d-flex justify-content-center">
                    <div v-if="tribune_init == false" class="d-flex justify-content-around">
                      <button type="button" class="btn btn-primary bg-success text-lg" style="width: 100%; height: 60px;"
                        data-toggle="modal" data-target="#add_convidado">
                        Iniciar inscrições para tribuna
                      </button>


                      <button type="button" class="btn btn-primary text-lg ml-3"
                        style="background-color: rgb(49 15 255 / 96%); width: 100%; height: 60px;" data-toggle="modal" data-target="#tribune_lits"
                        @click="GetQuorumUsers()">
                        Iniciar tribuna com lista definida
                      </button>
                    </div>

                    <div v-else-if="tribune_init == true">

                      <button type="button" class="btn btn-primary bg-warning text-lg text-center"
                        style="width: 315px; height: 60px;" @click="FimTribuna()">
                        Finalizar inscrições para tribuna
                      </button>
                    </div>

                    <div v-else>

                    </div>


                  </div>

                  <div class="d-flex justify-content-center">

                    <div>
                      <span style="font-size: 20px; display: block;" class="font-weight-bolder text-center text-dark ">
                        Vereadores inscritos</span>

                      <div v-if="tribuna_vereador == null"> AGUARDANDO NOVOS INSCRITOS</div>
                      <div v-else-if="tribuna_vereador == 'Aguarde...'"> AGUARDANDO NOVOS INSCRITOS</div>
                      <div v-else>
                        <ul>
                          <li v-for="users in tribuna_vereador" :key="users" style="font-size: 20px;"
                            class="font-weight-bolder text-align-start text-dark d-flex mb-2"> {{ users.user.nickname }} -


                            <div v-if="users.status_fala == 1" class="d-flex justify-content-center ml-2"
                              style="width: 200px ;border-radius: 10px; color:black;background-image: linear-gradient(#ffdaa2, #ffba5ff6, #fda726);">
                              Aguardando fala</div>
                            <div v-if="users.status_fala == 2" class="d-flex justify-content-center ml-2"
                              style="width: 200px ;border-radius: 10px; color:black;background-color: #0db80d"> Utilizando
                              tribuna </div>
                            <div v-if="users.status_fala == 3" class="d-flex justify-content-center ml-2"
                              style="width: 200px ;border-radius: 10px; color:black;background-image: linear-gradient(#fc2c2c, #e41b1bf6, #bd0808);">
                              Sendo aparteado </div>
                            <div v-if="users.status_fala == 4" class="d-flex justify-content-center ml-2"
                              style="width: 200px ;border-radius: 10px; color:black;background-color: #0db80d"> Utilizando
                              tribuna </div>
                            <div v-if="users.status_fala == 5" class="d-flex justify-content-center ml-2"
                              style="width: 200px ;border-radius: 10px; color:black;background-image: linear-gradient(#b1b0b0, #777775f6, #4b4a4a);">
                              Já utilizado </div>


                          </li>
                        </ul>
                      </div>

                    </div>
                  </div>

                  <div v-if="time == true" class="d-flex justify-content-center">
                    <span class="text-uppercase text-black text-2xl font-weight-bolder mt-5"> Tempo para encerramento das
                      inscrições </span>
                  </div>
                  <div v-if="time == true" class="d-flex justify-content-center">
                    <span v-if="this.StoreAll.cronometro_tribuna != '0 : 01'" id="timerz"
                      class="text-uppercase text-black text-3xl font-weight-bolder mt-2">
                      {{ this.StoreAll.cronometro_tribuna }} </span>
                    <span v-else id="timerz" class="text-uppercase text-black text-3xl font-weight-bolder mt-2"> </span>
                    <span id="timerzz" class="text-uppercase text-black text-3xl font-weight-bolder mt-2"></span>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- box confirmação -->
    <div id="tribune_lits" class="modal fade bd-example-modal-lg-startmodal" tabindex="-1" role="dialog"
      aria-labelledby="myLargeModalLabel" aria-hidden="true" style="z-index: 10000">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">
              Lista de vereadores
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label style="font-size: 18px"><b> Marque quem deseja utilizar a tribuna: </b></label>

            <div v-if="Loadings">
              <div class="d-flex justify-content-center">
                <spinnerprime />
              </div>
              <div class="text-center">
                <span class="text-dark"><strong>Carregando lista de vereadores...</strong></span>
              </div>
            </div>

            <div v-else class="">
              <div v-for="user in list_users_quorum" :key="user.id" class="mt" style="display: flex">
                <input v-model="list_users" :value="user" class="mr" type="checkbox"
                  style="width: 1.7rem; height: 1.7rem" />
                <label style="font-size: 18px">{{ user.nickname }}</label>
              </div>

              <div class="mt-4">
                <span class="text-dark"><strong>Lista de vereadores selecionados e ordem:</strong></span>

                <div v-if="list_users.length > 0">
                  <div v-for="(selectedUser, index) in list_users" :key="selectedUser.id">
                    <span class="text-dark" style="font-size: 18px"><strong>{{ selectedUser.nickname }} ({{ index + 1
                    }})</strong></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button type="button" class="btn btn-danger" data-dismiss="modal">
              voltar
            </button>
            <button type="button" class="btn btn-primary bg-success" data-dismiss="modal" data-toggle="modal"
              data-target=".AddOrRemove" @click="Checks(3);">
              Continuar
            </button>
          </div>
        </div>
      </div>
    </div>



    <div id="add_convidado" class="modal fade bd-example-modal-lg-startmodal" tabindex="-1" role="dialog"
      aria-labelledby="myLargeModalLabel" aria-hidden="true" style="z-index: 10000">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">
              Convidados
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label style="font-size: 18px"><b> Marque quem deseja utilizar a tribuna: </b></label>
            <div v-for="(convidate, index) in convidados_now" :key="convidate.id" class="mt" style="display: flex">
              <input :key="index" v-model="convidados_id" :value="convidate.id" class="mr" type="checkbox"
                style="width: 1.7rem; height: 1.7rem" />
              <label style="font-size: 18px">{{ convidate.nickname }}</label>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              voltar
            </button>
            <button v-if="convidados_id.length <= 0" type="button" class="btn btn-primary bg-success" data-dismiss="modal" data-toggle="modal"
              data-target=".AddOrRemove" @click="Checks(2);">
              Continuar
            </button>
            <button v-else type="button" class="btn btn-primary bg-success" data-dismiss="modal" data-toggle="modal"
              data-target=".AddOrRemove" @click="Checks(1);">
              Continuar e adicionar selecionados.
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-sm TribuneStarts" tabindex="-1" role="dialog"
      aria-labelledby="mySmallModalLabel" aria-hidden="true" style="z-index: 1048;">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h6>STATUS DA TRIBUNA</h6>
            <button id="closeers" type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closebox()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div id="start_tribune" class="justify-content-center" style="display: none">
              <span class="
                            justify-content-center
                            text-uppercase
                            font-weight-bold
                          " style="font-size: 13px; color: green">
                Inscrições para tribuna iniciada! <br />
                Aguarde...<br />
              </span>
            </div>
            <div id="load" class="justify-content-center" style="display: none">
              <div class="spinner-grow text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-secondary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-success" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-danger" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-warning" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-info" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-dark" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div id="fim_tribune" class="justify-content-center" style="display: none">
              <span class="
                            justify-content-center
                            text-uppercase
                            font-weight-bold
                          " style="font-size: 13px; color: green">
                Você encerrou as inscrições para a tribuna!
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<style>.close {
  font-size: 2.3rem !important;
}</style>