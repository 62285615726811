<template>
    <div>
        <div class="d-flex justify-content-center">
            <img src="../assets/img/SmartLogo.png">
        </div>
        <div class="text-center  text-dark">
            <span style="font-size: 45px;"><strong>404 NOT FOUND.</strong></span>
        </div>
        <div class="text-center text-dark">
            <span style="font-size: 45px;"><strong> ESSE LINK NÃO ESTÁ DISPONÍVEL.</strong></span>
        </div>
    </div>
  </template>
  <script>
  export default {
    name: "404",
    components: {},
    data() {
      return {
        
      };
    },
    mounted() {
 
      
    },
  
    methods: {
      
  
    },
  };
  </script>
  <style>
  
  </style>