<script>
import { AxiosAPI } from '@/axios';
import { useMainStore, useBusStore } from '../../stores/index';
import Loading from '../../components/documents/Modals/Loading.vue';

export default {
	name: 'ControleTribuna',
	components: { Loading },
	setup() {
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		StoreAll.Token();
		return {
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			LoadingOptions: true,
			off_button: false,
			aparte_start: false,
			tribuna_add_off: false,
			tribuna_order_invert: false,
			layou_user: true,
			layou_user_aparte: false,
			vereador_tribuna: null,
			vereador_tribuna_aparte: null,
			vereadores: [],
			tribuna_users: [],
			tribunaLess: [],
			tribunaLessList: [],
			tribunaRemove: [],
			LoadingLess: true,
			user_id: 0,
			category_user: 0,
			user_aparte: [],
			discut: null,
			discut_add: [],
			quorum: [],
			quorums: [],
			tribuna_id: null,
			controle_on: null,
			user_pause: null,
			lengthReal: null,
			controle_on_aparte: null,
			minute_convidado: false,
			header_error: '',
			body_error: '',
			header_success: '',
			body_success: '',
			socket_ids: [],
		};
	},

	created () {
		let OnSocket = setInterval(() => {
			if (this.StoreAll.websocket_host !== null) {
				clearInterval(OnSocket);
				window.Echo.channel(this.StoreAll.websocket_host).listen('.' + this.StoreAll.websocket_host, (e) => {
					if (e.socket_id === 10) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							setTimeout(() => { this.GetTribune(); }, 1000);
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 11) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.GetUsersTribunaOnl();
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 12) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							setTimeout(() => { this.GetTribune(); }, 1000);
							this.socket_ids.push(e.event_id);  
						}
					}

					if (e.socket_id === 14) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.GetUsersTribunaOnl();
							this.socket_ids.push(e.event_id);  
						}
					}

					if (e.socket_id === 15) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.GetUsersTribunaOnl();
							this.socket_ids.push(e.event_id);  
						}
					}

					if (e.socket_id === 16) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.GetUsersTribunaOnl();
							this.socket_ids.push(e.event_id);  
						}
					}

					if (e.socket_id === 17) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.off_button = false;
							this.GetUsersTribunaOnl();
							this.socket_ids.push(e.event_id);  
						}
					}


					if (e.socket_id === 20) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.aparte_start = false;
							this.GetUsersTribunaApart();
							this.socket_ids.push(e.event_id);  
						}
					}
			
				});
			}}, 500);
	},

	mounted() {
		this.GetSession();
		this.User();
		this.GetTribune();
	},
	methods: {

		async GetSession() {
			/* verificar essa situação */
			await this.StoreAll.Get_Data();
			this.session_id = this.StoreAll.session.id;
			this.Quorum_data_controle();
			this.Tribuna_Websocket();
		},

		async Quorum_data_controle() {
			await this.StoreAll.Get_Data();
			this.GetQuorumUsers();
		},

		async User() {
			await this.StoreAll.User();
			this.user_id = this.StoreAll.user.id;
			this.category_user = this.StoreAll.user.user_category_id;
		},



		async GetTribune() {
			await this.StoreAll.Get_Data();
			if (this.StoreAll.quorum !== null) {
				await AxiosAPI.get('/tribune?quorum_id=' + this.StoreAll.quorum[0].id, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken
					}
				}).then((response) => {
					console.log(response);
					if (response.data.data.length <= 0) {
						return;
					} else {
						for (let i = 0; i < response.data.data.length; i++) {
							if (response.data.data[i].status_tribune == 1) {
								this.tribuna_add_off = false;
								this.tribuna_order_invert = false;
								this.tribuna_id = response.data.data[i].id;
							} else if (response.data.data[i].status_tribune == 3) {
								this.tribuna_add_off = false;
								this.tribuna_order_invert = true;
								this.tribuna_id = response.data.data[i].id;
							} else {
								this.tribuna_add_off = true;
								this.tribuna_order_invert = false;
								this.tribuna_id = response.data.data[i].id;
							}
						}
						this.GetUsersTribunaOnl();
						this.GetUsersTribunaApart();
					}
				});
			}
		},

		async GetUsersTribuna() {
			await AxiosAPI.get('/tribune-users?tribune_id=' + this.tribuna_id + '&status_fala=1', {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((response) => {
				response.data.data.sort((a, b) => a.position - b.position);
				this.tribuna_users = response.data.data;
			});
		},

		async GetUsersTribunaOptions () {
			this.LoadingOptions = true;
			await AxiosAPI.get('/tribune-users?tribune_id=' + this.tribuna_id + '&status_fala=1', {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((response) => {
				response.data.data.sort((a, b) => a.position - b.position);
				this.tribuna_users = response.data.data;
				this.LoadingOptions = false;
			});
		},

		async TribunaListReorde () {
			this.LoadingOptions = true;
			await AxiosAPI.get('/tribune-users?tribune_id=' + this.tribuna_id, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((response) => {
				response.data.data.sort((a, b) => a.position - b.position);
				this.tribuna_users = response.data.data;
				this.LoadingOptions = false;
			});
		},

		async RemoveToTribune () {
			this.$refs.Loading.openModal();
			this.LoadingOptions = true;
			let data = {
				tribune_id: this.tribuna_id,
				tribune_users_id: this.tribunaRemove
			};
			try {
				await AxiosAPI.delete('/tribune-users',
					{
						data,
						headers: {
							Authorization: 'Bearer ' + this.StoreAll.accessToken,
							'Content-Type': 'application/json'
						}
					}).then(() => {
					this.tribunaRemove = [];
					this.LoadingOptions = false;
					this.$refs.Loading.closeModal();
					this.$toast.add({ severity: 'success', summary: 'Sucesso', detail: 'Vereador(es) removidos com sucesso', group: 'tl', life: 3000 });
				});
			}
			catch {
				this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Um erro aconteceu, contate o administrador do sistema', group: 'tl', life: 5000 });
			}
		},

		renderPosition(rowData, rowIndex) {
			return rowIndex + 1;
		},

		async ReorderTribuneSend () {
			this.$refs.Loading.openModal();
			let RowIds = [];
			this.tribuna_users.forEach(rowIDs => {
				RowIds.push(rowIDs.id);
			});
			this.LoadingOptions = true;
			let data = {
				tribune_id: this.tribuna_id,
				tribune_users_id: RowIds
			};
			try {
				await AxiosAPI.patch('/tribune-users', data,
					{
						headers: {
							Authorization: 'Bearer ' + this.StoreAll.accessToken,
							'Content-Type': 'application/json'
						}
					}).then(() => {
					this.tribuna_users = [];
					this.LoadingOptions = false;
					this.$refs.Loading.closeModal();
					this.$toast.add({ severity: 'success', summary: 'Sucesso', detail: 'Lista atualizada com sucesso', group: 'tl', life: 3000 });
				});
			}
			catch {
				this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Um erro aconteceu, contate o administrador do sistema', group: 'tl', life: 5000 });
			}
		},

		async GetTribunaLess() {
			this.LoadingLess = true; 
			await this.StoreAll.UserAll();
			let Users = setInterval(() => {
				if (this.StoreAll.user_all.length >= 1) {
					clearInterval(Users);
					this.ListAdd();
				}
			}, 500);   
		},

		async ListAdd () {
			const LessUsersList = [];
			const QuorumUsersList = [];

			const usersQuorum = await AxiosAPI
				.get('/quorum-users?quorum_id=' + this.StoreAll.quorum[0].id, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				});

			await AxiosAPI.get('/tribune-users?tribune_id=' + this.tribuna_id, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((response) => {
				response.data.data.forEach(LessUsers => {
					LessUsersList.push(LessUsers.user);
				});

				usersQuorum.data.data.forEach(Quorum => {
					QuorumUsersList.push(Quorum);
				});


				const differenceUsers = QuorumUsersList.filter(user => !LessUsersList.some(LessUsers => LessUsers.id === user.user_id));

				this.tribunaLess = differenceUsers;

				this.LoadingLess = false;
			});
		},

		async AddUser () {
			let vereadores_id = [];
			for (let i = 0; i < this.tribunaLessList.length; i++) {
				vereadores_id.push(this.tribunaLessList[i]);
			}

			const list = {
				tribune_id: this.tribuna_id,
				users_id: vereadores_id,
				status_fala: 1
			};

			try{
				await AxiosAPI.post('/tribune-users', list, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + this.StoreAll.accessToken
					}
				}).then((response) => {
					if (response.status == 200) {
						this.tribunaLessList = [];
						this.$toast.add({ severity: 'success', summary: 'Sucesso', detail: 'Vereador adicionado com sucesso', group: 'tl', life: 3000 });
					}
				});
			}
			catch (error) {
				this.$toast.add({ severity: 'error', summary: 'Falhou', detail: 'Vereador não foi adicionado, informe o erro ao administrador', group: 'tl', life: 3000 });
			}
		},

		async GetUsersTribunaOnl() {
			await AxiosAPI.get('/tribune-users?tribune_id=' + this.tribuna_id, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((response) => {
				let user = [];

				for (let i = 0; i < response.data.data.length; i++) {
					if (response.data.data[i].status_fala == 1 && response.data.data[i].status_fala == 5) {
						this.layou_user = true;
					}
					else if (response.data.data[i].status_fala == 2 || response.data.data[i].status_fala == 3 || response.data.data[i].status_fala == 4) {
						user.push(response.data.data[i]);
						this.layou_user = false;
						if (response.data.data[i].user.name == 'Convidado') {
							this.minute_convidado = true;
						} else {
							this.minute_convidado = false;
						}
						this.off_button = true;
					}
					else if (response.data.data[i].status_fala == 2 && response.data.data[i].status_fala == 5) {
						this.layou_user = false;
					}
					else if (response.data.data[i].status_fala == 3 && response.data.data[i].status_fala == 5) {
						this.layou_user = false;
					}
					else if (response.data.data[i].status_fala == 4 && response.data.data[i].status_fala == 5) {
						this.layou_user = false;
					}
				}

				this.vereador_tribuna = user[0];
				if (this.vereador_tribuna == undefined) {
					console.log('note prenc');
				} else {
					localStorage.setItem('id_timer_extra', this.vereador_tribuna.id);
				}

				if (user.length >= 1) {
					this.layou_user = false;
					this.lengthReal = true;
					if (user[0].status_fala == 2) {
						this.controle_on = true;
						this.user_pause = false;
						this.controle_on_aparte = false;
					} else if (user[0].status_fala == 3) {
						this.controle_on = true;
						this.user_pause = true;
						this.aparte = true;
						this.controle_on_aparte = true;
					} else if (user[0].status_fala == 4) {
						this.controle_on = true;
						this.user_pause = false;
						this.aparte = false;
						this.controle_on_aparte = false;
					}
				} else {
					this.layou_user = true;
					this.controle_on = false;
					this.aparte = false;
					this.user_pause = false;
					this.lengthReal = false;
				}
			});
		},

		async Checks_vote() {
			this.$refs.Loading.openModal();
			await AxiosAPI
				.get(
					'/documents?session_id=' +
          this.StoreAll.session.id +
          '&document_status_vote_id=4',
					{
						headers: {
							Authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}
				)
				.then((response) => {
					if (response.data.data.length <= 0) {
						this.Checks_discussion();
						setTimeout(() => { this.StartTribuna(); }, 500);
					} else {
						this.header_error = 'Tribuna não iniciada';
						this.body_error = 'Existe um documento em votação, solicite o encerramento para continuar';
						this.$refs.Loading.closeModal();
						this.show_error();
					}
				});

		},

		async Checks_discussion() {
			await AxiosAPI.get('/discussion?quorum_id=' + this.StoreAll.quorum[0].id, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken,
				},
			}).then((resp) => {
				for (let i = 0; i < resp.data.data.length; i++) {
					if (resp.data.data[i].status_discussion == 1) {
						this.discut = true;
					} else {
						this.discut = false;
					}
				}
			});

		},

		show_error() {
			this.$toast.add({ severity: 'error', summary: this.header_error, detail: this.body_error, group: 'tl', life: 5000 });
		},
		show_success() {
			this.$toast.add({ severity: 'success', summary: this.header_success, detail: this.body_success, group: 'tl', life: 3000 });
		},

		StartTribuna() {

			if (this.discut == true) {
				this.header_error = 'Tribuna não iniciada';
				this.body_error = 'Existe um documento em discussão, encerre a discussão para continuar';
				this.$refs.Loading.closeModal();
				this.show_error();
			} else {
				const formData = new FormData();
				localStorage.setItem('user_start', this.quorums[0]);
				formData.append('tribune_id', this.tribuna_id);
				formData.append('user_id', this.quorums[0]);
				formData.append('type', true);
				formData.append('status_fala', 2);

				AxiosAPI.post('/tribune-users-upp', formData, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken
					}
				}).then((response) => {
					this.t = response;
					this.quorums = [];
					this.GetUsersTribunaOnl();
					this.GetQuorumUsers(1);
				});
			}
		},

		PauseTribuna() {
			this.$refs.Loading.openModal();
			const formData = new FormData();
			formData.append('tribune_id', this.tribuna_id);
			formData.append('user_id', localStorage.getItem('user_start'));
			formData.append('status_fala', 3);


			AxiosAPI.post('/tribune-users-upp', formData, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((response) => {
				if (response.status == 200) {
					this.GetQuorumUsers(2);
				}
			});
		},

		BackPauseTribuna() {
			this.$refs.Loading.openModal();
			const formData = new FormData();
			formData.append('tribune_id', this.tribuna_id);
			formData.append('user_id', localStorage.getItem('user_start'));
			formData.append('status_fala', 4);


			AxiosAPI.post('/tribune-users-upp', formData, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((response) => {
				if (response.status == 200) {
					this.GetQuorumUsers(3);
				}
			});
		},

		AddTimer() {
			this.$refs.Loading.openModal();
			let id_timer_extra = localStorage.getItem('id_timer_extra');

			AxiosAPI.patch('/tribune-users/' + id_timer_extra + '/update-time-extra?time_extra=2', {}, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((response) => {
				if (response.status == 200) {
					this.GetQuorumUsers(4);
				}
			});
		},

		ForceStopTribuna() {
			this.$refs.Loading.openModal();
			const formData = new FormData();
			formData.append('tribune_id', this.tribuna_id);
			formData.append('user_id', localStorage.getItem('user_start'));
			formData.append('status_fala', 5);


			AxiosAPI.post('/tribune-users-upp', formData, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((response) => {
				if (response.status == 200) {
					this.header_success = 'Fala finalizada!';
					this.body_success = 'Fala do vereador finalizada!';
					this.show_success();
					this.$refs.Loading.closeModal();
				}
				localStorage.removeItem('user_start');
			});
		},

		async FinishTribuna() {
			this.$refs.Loading.openModal();

			let message = '';
			let buttonStyle = false;
			let confirmText = '';

			try {
				const dataCheck = await this.StoreAll.VerifySession();

				const hasTribuneUser = dataCheck.some(Check =>
					Check.tribunes.some(TribuneCheck => 
						TribuneCheck.tribune_users.some(TribuneUserCheck =>
							TribuneUserCheck.status_fala === '2' || TribuneUserCheck.status_fala === '3' || TribuneUserCheck.status_fala === '4'))
				);


				if (hasTribuneUser) {
					message = 'A tribuna está em andamento, encerre a fala atual para encerrar a tribuna';
					buttonStyle = false;
					confirmText = 'OK';
				} else {
					message = 'Deseja realmente encerrar a tribuna ?';
					buttonStyle = true;
					confirmText = 'SIM';
				}

				this.$refs.Loading.closeModal();

				this.$swal.fire({
					title: message,
					showDenyButton: false,
					showCancelButton: buttonStyle,
					buttonsStyling: false,
					confirmButtonText: confirmText,
					cancelButtonText: 'VOLTAR',
					customClass: {
						confirmButton: 'btn btn-success mr-5 ml-1 text-lg',
						cancelButton: 'btn btn-secondary mr-1 ml-1 text-lg'
					},
				}).then((result) => {
					if (confirmText === 'OK') {
						this.error_show();
					} else {
						if (result.isConfirmed) {
							this.FinishTribune();
						} else {
							this.error_show();
						}
					}
                  
				});
			}
			catch {
				console.log('erro');
			}

		},

		error_show() {
			this.$toast.add({ severity: 'error', summary: 'Tribuna não encerrada', detail: 'Tribuna não encerrada!', life: 3000 });
		},

		async FinishTribune () {
			await AxiosAPI.put('/tribune/' + this.StoreAll.tribuna_id, {
				status_tribune: 3
			}, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((response) => {
				if (response.status == 200) {
					this.tribuna_add_off = false;
					this.GetUsersTribunaOnl();
					this.GetTribune();
					this.header_success = 'Tribuna finalizada!';
					this.body_success = 'Tribuna finalizada!';
					this.show_success();
				}
			});
		},

		async BackTribune() {
			this.$refs.Loading.openModal();
			await AxiosAPI.put('/tribune/' + this.StoreAll.tribuna_id, {
				status_tribune: 2
			}, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((response) => {
				if (response.status == 200) {
					this.tribuna_add_off = false;
					this.GetUsersTribunaOnl();
					this.GetTribune();
					this.header_success = 'Tribuna reaberta!';
					this.body_success = 'Tribuna reaberta!';
					this.show_success();
					this.$refs.Loading.closeModal();
				}
			});
		},

		GetQuorumUsers(value) {
			let verificarElemento = setInterval(() => {
				if (this.StoreAll.quorum !== null) {
					clearInterval(verificarElemento);
					AxiosAPI.get('/quorum-users?quorum_id=' + this.StoreAll.quorum[0].id, {
						headers: {
							authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}).then((response) => {
						this.vereadores = response.data.data;
						if (value === 1) {
							this.header_success = 'Fala iniciada!';
							this.body_success = 'Fala do vereador iniciada!';
							this.show_success();
							this.$refs.Loading.closeModal();
						} else if (value === 2) {
							this.header_success = 'Pausado!';
							this.body_success = 'Fala do vereador pausada!';
							this.show_success();
							this.$refs.Loading.closeModal();
						} else if (value === 3) {
							this.header_success = 'Despausado!';
							this.body_success = 'Fala do vereador despausada!';
							this.show_success();
							this.$refs.Loading.closeModal();
						} else if (value === 4) {
							this.header_success = 'Tempo adicionado!';
							this.body_success = 'Tempo adicionado ao vereador!';
							this.show_success();
							this.$refs.Loading.closeModal();
						}
					});
				}
			}, 500);
		},


		async AparteTribuna(value) {
			this.$refs.Loading.openModal();
			if (value === 1) {
				await AxiosAPI.post('/apartiamento-users', {
					tribune_id: this.tribuna_id,
					user_id: this.user_aparte[0],
					type: false
				}, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken
					}
				}).then((response) => {
					console.log(response);
					localStorage.setItem('user_aparte', this.user_aparte[0]);
					this.user_aparte = [];
					this.GetUsersTribunaApart();
					this.header_success = 'Apartiador adicionado!';
					this.body_success = 'Vereador está fazendo aparte!';
					this.show_success();
					this.$refs.Loading.closeModal();
					this.aparte_start = true;
				});
			} else {
				await AxiosAPI.post('/apartiamento-users', {
					tribune_id: this.tribuna_id,
					user_id: this.user_aparte[0],
					type: true
				}, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken
					}
				}).then((response) => {
					console.log(response);
					localStorage.setItem('user_aparte', this.user_aparte[0]);
					this.user_aparte = [];
					this.GetUsersTribunaApart();
					this.header_success = 'Apartiador adicionado!';
					this.body_success = 'Vereador está fazendo aparte!';
					this.show_success();
					this.$refs.Loading.closeModal();
					this.aparte_start = true;
				});
			}
		},

		async GetUsersTribunaApart() {
			await AxiosAPI.get('/apartiamento-users?tribune_id=' + this.tribuna_id, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((response) => {
				let user = [];

				if (response.data.data.length <= 0) {
					this.layou_user_aparte = false;
				}

				if (response.data.data.length >= 1) {
					for (let i = 0; i < response.data.data.length; i++) {
						if (response.data.data[i].status_fala !== '1') {
							this.layou_user_aparte = false;
						}
						if (response.data.data[i].status_fala === '1') {
							user.push(response.data.data[i]);
							this.layou_user_aparte = true;
						}
						else {
							this.layou_user_aparte = false;
						}
					}
					this.vereador_tribuna_aparte = user[0];
				}
			});
		},

		RemoveAparte() {
			this.$refs.Loading.openModal();
			const formData = new FormData();
			formData.append('tribune_id', this.tribuna_id);
			formData.append('user_id', localStorage.getItem('user_aparte'));
			formData.append('status_fala', 2);


			AxiosAPI.post('/apartiamento-users-upp', formData, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((response) => {
				if (response.status == 200) {
					this.GetUsersTribunaApart();
					this.GetQuorumUsers();
					this.header_success = 'Apartiador removido!';
					this.body_success = 'Vereador foi removido do aparte!';
					this.show_success();
					this.$refs.Loading.closeModal();
					this.aparte_start = false;
				}
			});
		},

		onColReorder(event) {
			console.log('Coluna reordenada:', event);
			// Aqui você pode adicionar lógica para manipular a reordenação das colunas, se necessário
		},
		onRowReorder(event) {
			console.log('Linha reordenada:', event);
			const draggedItem = this.tribuna_users.splice(event.dragIndex, 1)[0]; // Remove o item arrastado da sua posição anterior
      		this.tribuna_users.splice(event.dropIndex, 0, draggedItem); // Insere o item arrastado na nova posição
    
			// Aqui você pode adicionar lógica para manipular a reordenação das linhas, se necessário
		},

		async Tribuna_Websocket() {
			await AxiosAPI.get('/tribune?quorum_id=' + this.StoreAll.quorum[0].id, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken,
				},
			}).then((resp) => {
				if (resp.data.data.length == undefined) {
					return;
				} else if (resp.data.data.length <= 0) {
					return;
				} else if (resp.data.data[0].status_tribune == undefined) {
					return;
				} else {
					this.StoreAll.status_tribune = resp.data.data[0].status_tribune;
					this.StoreAll.tribuna_id = resp.data.data[0].id;

				}
			});
		},

	},
};
</script>
<template>
  <div style="z-index: 1010 !important;">
    <Loading ref="Loading"></Loading>
  </div>
  <div class="py-4 h-100 container-fluid">
    <div class="row">
      <div class="text-center col-12">
        <h3 class="mt-auto text-white">Controle da tribuna</h3>
        <h5 class="text-white font-weight-normal">
          Comece a fala de um vereador na tribuna, pause e finalize por aqui!
        </h5>
        <div class="mb-5 multisteps-form">

          <div v-if="tribuna_add_off == false" class="row">
            <div class="m-auto col-12">
              <form class="multisteps-form__form">
                <div>
                  <div class="p-3 bg-white card multisteps-form__panel border-radius-xl js-active position-relative"
                    data-animation="FadeIn">
                    <div class="text-center row">
                      <div class="mx-auto col-10">
                        <div>
                          <h2 style="font-family: 'Bebas Neue', cursive;">Tribuna ainda não iniciada, inscrições não
                            finalizadas ou tribuna encerrada, aguarde...</h2>
                        </div>
                        <div v-if="tribuna_order_invert == true">
                          <div class="d-flex justify-content-center">
                            <button type="button" class="btn btn-primary bg-warning" style="width: 350px; height: 50px;"
                              @click="BackTribune()">
                              Voltar para a tribuna
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div v-else class="row">
            <div class="m-auto col-12">
              <form class="multisteps-form__form">
                <div>
                  <div class="p-3 bg-white card multisteps-form__panel border-radius-xl js-active position-relative"
                    data-animation="FadeIn">

                    <div class="text-center row">
                      <div class="mx-auto col-10">
                        <h5 class="font-weight-normal">
                          Abaixo selecione a opção desejada!
                        </h5>
                      </div>

                      <div class="container">
                      <div class="row d-block">
                        <div class="col">


                        <div>
                          <div v-if="layou_user">
                            <div class="d-flex justify-content-center">
                              <span class="font-weight-bolder my_fonts"> Usuário atual: &nbsp; Aguarde...
                              </span>
                            </div>
                          </div>

                          <div v-else-if="!layou_user && !aparte_start" class="mb-2" style="border-style: double;">
                            
                            <div class="d-flex justify-content-center mb-3">
                              <span class="font-weight-bolder my_fonts">TRIBUNA
                              </span>
                            </div>
                            <div class="d-flex justify-content-center">
                              <img :src="this.StoreAll.Dochost + vereador_tribuna.user.path_image"
                                class="rounded-circle justify-content-start"
                                style="object-fit: cover; width: 150px" alt="user photo" />
                            </div>

                            <div class="d-flex justify-content-center">
                              <span class="font-weight-bolder my_fonts"> Usuário atual: &nbsp;
                                {{ vereador_tribuna.user.nickname }}
                              </span>
                            </div>
                            <div v-if="lengthReal == true" class="d-flex justify-content-center">
                              <span v-if="vereador_tribuna.status_fala == 2"
                                class="font-weight-bolder my_fonts"> Status: &nbsp; Falando </span>
                              <span v-if="vereador_tribuna.status_fala == 3"
                                class="font-weight-bolder my_fonts"> Status: &nbsp; Pausado </span>
                              <span v-if="vereador_tribuna.status_fala == 4"
                                class="font-weight-bolder my_fonts"> Status: &nbsp; Falando </span>
                            </div>

                            <div v-else>
                              <span class="font-weight-bolder my_fonts"> Status: &nbsp; Aguarde... </span>
                            </div>

                            <div v-if="controle_on == true">

                              <div class="d-flex justify-content-center mt-3">
                                <p class="text-black text-uppercase font-weight-bold my_fonts"> CONTROLE
                                  DA TRIBUNA </p>
                              </div>

                              <div class="container">
                              <div class="row d-flex justify-content-center">

                                <button v-if="user_pause == false" id="pause"
                                  style=" width: 100%; height: 50px;" type="button"
                                  class="justify-content-center btn btn-primary bg-dangerlegis my_fonts"
                                  @click="PauseTribuna()">
                                  Pausar
                                </button>

                                <button v-if="user_pause == true" id="pauseback"
                                  style=" width: 100%; height: 50px;" type="button"
                                  class="justify-content-center btn btn-primary bg-success my_fonts"
                                  @click="BackPauseTribuna()">
                                  Continuar
                                </button>
                                <button v-if="user_pause == true" id="aparte"
                                  style=" width: 100%; height: 50px;" type="button"
                                  class="justify-content-center btn btn-primary bg-dangerlegis my_fonts" data-toggle="modal"
                                  data-target=".aparte">
                                  Apartiar
                                </button>

                                <button id="fim" 
                                  style=" width: 100%; height: 50px; background-color: #454545;" type="button"
                                  class="justify-content-center btn btn-primary my_fonts" @click="ForceStopTribuna()">
                                  Encerrar fala do vereador
                                </button>

                                <button v-if="minute_convidado == false" id="add"
                                  style=" width: 100%; height: 50px;" type="button"
                                  class="justify-content-center btn btn-primary bg-smartlegis my_fonts" @click="AddTimer()">
                                  + {{ this.StoreAll.timers[0].Extra_vereador.minutes }} minutos
                                </button>

                                <button v-if="minute_convidado == true" id="add"
                                  style=" width: 100%; height: 50px;" type="button"
                                  class="justify-content-center btn btn-primary bg-smartlegis my_fonts" @click="AddTimer()">
                                  + {{ this.StoreAll.timers[0].Extra_convidado.minutes }} minutos
                                </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else>

                          </div>
                        </div>
                          <div v-if="!layou_user_aparte">

                          </div>
                          <div v-if="layou_user_aparte" class="mb-2" style="border-style: double;">


                            <div class="d-flex justify-content-center mt-3 mb-3">
                              <span class="font-weight-bolder my_fonts"> APARTEAMENTO
                              </span>
                            </div>


                            <div class="d-flex justify-content-center">
                              <img :src="this.StoreAll.Dochost + vereador_tribuna_aparte.user.path_image"
                                class="rounded-circle justify-content-start"
                                style="object-fit: cover; width: 150px" alt="user photo" />
                            </div>



                            <div class="d-flex justify-content-center">
                              <span class="font-weight-bolder my_fonts"> Usuário atual: &nbsp;
                                {{ vereador_tribuna_aparte.user.nickname }}
                              </span>
                            </div>



                            <div class="d-flex justify-content-center">
                              <span v-if="vereador_tribuna_aparte.status_fala == 1"
                                class="font-weight-bolder my_fonts"> Status: &nbsp; Falando </span>
                            </div>

                            <div v-if="controle_on_aparte == true">
                              <div class="d-flex justify-content-center  mt-3">
                                <p class="text-black text-uppercase font-weight-bold my_fonts"> CONTROLE
                                  DO APARTIAMENTO </p>
                              </div>

                              <div class="d-flex justify-content-center">

                                <button id="removeaparte"
                                  style="width: 100%; height: 50px; background-color: #d56200 !important;"
                                  type="button" class="justify-content-center btn btn-primary ml-2 mr-2 my_fonts"
                                  @click="RemoveAparte()">
                                  Finalizar aparteamento
                                </button>

                              </div>


                            </div>
                            <div v-else>
                              <div class="d-flex justify-content-center mt-3">
                                <h3> AGUARDANDO VEREADOR INICIAR FALA </h3>
                              </div>

                            </div>


                          </div>
                          <div v-if="!off_button" class="d-flex justify-content-center">
                            <button type="button" class="btn btn-primary bg-success text-center" style="width: 100%; height: 50px;"
                              data-dismiss="modal" data-toggle="modal" data-target="#SelectVereador"
                              @click="GetUsersTribuna()">
                              Selecionar vereador para falar
                            </button>
                          </div>
                        </div>

						<div v-if="layou_user && !aparte_start" class="col">
                          <div class="d-flex justify-content-center">
                            <span class="font-weight-bolder my_fonts">
                              Trocar ordem da tribuna!</span>
                          </div>

                          <div class="d-flex justify-content-center">
                            <button type="button" class="btn btn-primary"
                              style="width: 100%; height: 50px; background-color: rgb(76 57 101)" 
                              data-dismiss="modal" data-toggle="modal" data-target="#SelectOrdem"
                              @click="TribunaListReorde()">
                              Selecionar
                            </button>
                          </div>
                        </div>

                        <div v-if="layou_user && !aparte_start" class="col">
                          <div class="d-flex justify-content-center">
                            <span class="font-weight-bolder my_fonts">
                              Inscrever vereador para tribuna!</span>
                          </div>

                          <div class="d-flex justify-content-center">
                            <button type="button" class="btn btn-primary bg-smartlegis"
                              style="width: 100%; height: 50px;" 
                              data-dismiss="modal" data-toggle="modal" data-target="#SelectSubscriber"
                              @click="GetTribunaLess()">
                              Selecionar
                            </button>
                          </div>
                        </div>

						<div v-if="layou_user && !aparte_start" class="col">
                          <div class="d-flex justify-content-center">
                            <span class="font-weight-bolder my_fonts">
                              Remover vereador da lista da tribuna!</span>
                          </div>

                          <div class="d-flex justify-content-center">
                            <button type="button" class="btn btn-primary"
                              style="width: 100%; height: 50px; background-color: rgb(87 0 0)" 
                              data-dismiss="modal" data-toggle="modal" data-target="#SelectRemove"
                              @click="GetUsersTribunaOptions()">
                              Selecionar
                            </button>
                          </div>
                        </div>

                        <div v-if="layou_user && !aparte_start" class="col">
                          <div class="d-flex justify-content-center">
                            <span class="font-weight-bolder my_fonts">
                              Encerre a tela da tribuna!</span>
                          </div>

                          <div class="d-flex justify-content-center">
                            <button type="button" class="btn btn-primary bg-dangerlegis"
                              style="width: 100%; height: 50px;" @click="FinishTribuna()">
                              Encerrar tribuna
                            </button>
                          </div>
                        </div>


                      </div>


                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="modal apartiamento_timer" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Apartiamento</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="d-flex justify-content-center text-black">
              <p>Qual o tempo desse apartiador ?</p>
            </div>
          </div>
		  <div v-if="this.StoreAll.timers === null"></div>
          <div v-if="this.StoreAll.timers !== null" class="modal-footer d-flex justify-content-center">
            <button type="button" class="btn btn-success" data-dismiss="modal" @click="AparteTribuna(2);">
              {{ this.StoreAll.timers[0].Apartiamento_reduzido_tribune.minutes }} minutos
            </button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" @click="AparteTribuna(1);">
              {{ this.StoreAll.timers[0].Apartiamento_tribune.minutes }} minutos
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="SelectVereador" class="modal fade bd-example-modal-lg-startmodal" tabindex="-1" role="dialog"
      aria-labelledby="myLargeModalLabel" aria-hidden="true" style="z-index: 10000">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">
              Tribuna
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label style="font-size: 18px"><b> Marque qual vereador irá falar agora! </b></label>
            <div v-for="(vereadorr, index) in tribuna_users" :key="vereadorr.user.id" class="mt" style="display: flex">
              <input :key="index" v-model="quorums" :value="vereadorr.user.id" class="mr" type="checkbox"
                style="width: 1.7rem; height: 1.7rem" />
              <label style="font-size: 18px">{{ vereadorr.user.nickname }}</label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Voltar
            </button>
            <button type="button" class="btn btn-primary bg-success" data-dismiss="modal" @click="Checks_vote();">
              Enviar
            </button>
          </div>
        </div>
      </div>
    </div>

	<div id="SelectOrdem" class="modal fade bd-example-modal-lg-startmodal" tabindex="-1" role="dialog"
      aria-labelledby="myLargeModalLabel" aria-hidden="true" style="z-index: 10000">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">
              TROCAR ORDEM DE FALA
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label style="font-size: 18px"><b> Selecione a ordem que deseja na lista da tribuna! </b></label>

            <div v-if="LoadingOptions">
              <div class="d-flex justify-content-center">
                <spinnerprime/>
              </div>
              <div class="text-center">
                <span class="text-dark"><strong>Aguarde...</strong></span>
              </div>
            </div>

            <div v-else>

				<DataTable :value="tribuna_users" :reorderableColumns="true" @columnReorder="onColReorder" @rowReorder="onRowReorder"  responsive-layout="scroll">
					<Column rowReorder headerStyle="width: 3rem" :reorderableColumn="false" />
					<Column header="Vereador" :reorderableColumn="false" class="text-sm font-weight-bold text-wrap text-uppercase">
						<template #body="{ data, index }">
                          <span>{{data.user.nickname}} - {{ index + 1 }} </span>
                        </template>
					</Column>
					<Column header="Status" :reorderableColumn="false" class="text-sm font-weight-bold text-wrap text-uppercase">
						<template #body="{ data }">
							<Tag v v-if="data.status_fala == 1"
                            style="border: 1px solid rgb(213 171 16);background-color: rgb(255 212 0 / 10%);color: rgb(85 87 0);">
                            <div class="flex align-items-center gap-2">
                              <span class="text-base text-uppercase">Aguardando inicio</span>
                            </div>
                          </Tag>

						  <Tag v v-if="data.status_fala == 5"
                            style="border: 1px solid rgb(30 30 30);background-color: rgb(75 75 75 / 10%);color: rgb(16 16 16);">
                            <div class="flex align-items-center gap-2">
                              <span class="text-base text-uppercase">Já utilizado</span>
                            </div>
                          </Tag>
                        </template>
					</Column>
				</DataTable>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Voltar
            </button>
            <button type="button" class="btn btn-primary bg-success" data-dismiss="modal" @click="ReorderTribuneSend();">
              Trocar
            </button>
          </div>
        </div>
      </div>
    </div>

	<div id="SelectRemove" class="modal fade bd-example-modal-lg-startmodal" tabindex="-1" role="dialog"
      aria-labelledby="myLargeModalLabel" aria-hidden="true" style="z-index: 10000">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">
              REMOVER VEREADOR DA TRIBUNA
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label style="font-size: 18px"><b> Marque qual vereador(es) você deseja remover da lista da tribuna! </b></label>

            <div v-if="LoadingOptions">
              <div class="d-flex justify-content-center">
                <spinnerprime/>
              </div>
              <div class="text-center">
                <span class="text-dark"><strong>Aguarde...</strong></span>
              </div>
            </div>

            <div v-else>
              <div v-for="(Remove, index) in tribuna_users" :key="Remove.id" class="mt" style="display: flex">
                <input :key="index" v-model="tribunaRemove" :value="Remove.id" class="mr" type="checkbox"
                  style="width: 1.7rem; height: 1.7rem" />
                <label style="font-size: 18px">{{ Remove.user.nickname }}</label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Voltar
            </button>
            <button type="button" class="btn btn-primary bg-success" data-dismiss="modal" @click="RemoveToTribune();">
              Enviar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="SelectSubscriber" class="modal fade bd-example-modal-lg-startmodal" tabindex="-1" role="dialog"
      aria-labelledby="myLargeModalLabel" aria-hidden="true" style="z-index: 10000">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">
              INSCRIÇÃO
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label style="font-size: 18px"><b> Marque qual vereador(es) você deseja adicionar a lista da tribuna! </b></label>

            <div v-if="LoadingLess">
              <div class="d-flex justify-content-center">
                <spinnerprime/>
              </div>
              <div class="text-center">
                <span class="text-dark"><strong>Aguarde...</strong></span>
              </div>
            </div>

            <div v-else>
              <div v-for="(UserLess, index) in tribunaLess" :key="UserLess.user.id" class="mt" style="display: flex">
                <input :key="index" v-model="tribunaLessList" :value="UserLess.user.id" class="mr" type="checkbox"
                  style="width: 1.7rem; height: 1.7rem" />
                <label style="font-size: 18px">{{ UserLess.user.nickname }}</label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Voltar
            </button>
            <button type="button" class="btn btn-primary bg-success" data-dismiss="modal" @click="AddUser();">
              Enviar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-lg-startmodal aparte" tabindex="-1" role="dialog"
      aria-labelledby="myLargeModalLabel" aria-hidden="true" style="z-index: 10000">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">
              Tribuna
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label style="font-size: 18px"><b> Marque vereador que solicitou apartemento. </b></label>
            <div v-for="(vereadorr, index) in vereadores" :key="vereadorr.id" class="mt" style="display: flex">
              <input :key="index" v-model="user_aparte" :value="vereadorr.user_id" class="mr" type="checkbox"
                style="width: 1.7rem; height: 1.7rem" />
              <label style="font-size: 18px">{{ vereadorr.user.nickname }}</label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Voltar
            </button>
            <button type="button" class="btn btn-primary bg-success" data-dismiss="modal" data-toggle="modal"
              data-target=".apartiamento_timer">
              Enviar
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<style>
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.close {
  font-size: 2.3rem !important;
}

.my_fonts{
  font-size: 20px;
}
</style>
