<script>
import { AxiosAPI } from '@/axios';
import sound from '@/assets/sound/fim.mp3';
import { useMainStore, useBusStore } from '../../stores/index';

export default {
	name: 'TvTribuna',
	components: {},
	setup(){
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		StoreAll.Token();
		StoreAll.User();
		return{
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			Subscribe_seconds: 0,
			Subscribe_minutes: 0,
			seconds: 0,
			minutes: 0,
			SubscribeTimer: true,
			timerSubscribeEnd: true,
			Aparte_seconds: 2,
			Aparte_minutes: 0,
			Aparte_minutes_reduze: 0,
			Aparte_seconds_reduze: 2,
			Timer_Extra_Vereador_minutes: 0,
			Timer_Extra_Vereador_seconds: 2,
			Timer_Extra_Convidado_minutes: 0,
			minutes_lider: 0,
			seconds_lider: 0,
			Timer_Extra_Convidado_seconds: 2,
			minutes_convidado:0,
			seconds_convidado:2,
			minutes_prefeitura: 0,
			seconds_prefeitura: 2,
			tribuna_id: 0,
			fala: true,
			tribuna: true,
			voted: false,
			legendavote: false,
			tribruna_vereadores: [],
			tribruna_vereadores_aparte: [],
			Apartes: '',
			timer_start: false,
			cronometro: 'Aguarde',
			cronometros: '2: 00',
			Aparte: '3:00',    
			convidado: null,
			dia: 0,
			mes: '',
			time: '',
			timer_session: 'Aguarde o inicio',
			fmenor: false,
			TimersConf: false,
			SoundGo: false,
			Loading: true,
			timerOn: false,
			timerOnAparte: false,
			TimerOver: false,
			TimerOverAparte: false,
			socket_ids: [],
		};
	},

	watch: {
		SoundGo: function (newValue, oldPath) {
			if (newValue === true) {
				this.Sound();
				this.SoundGo = false;
			}   
		},
		TimerOver: function (newValue, oldPath) {
			if (newValue === true) {
				this.TimerOver = false;
				this.Sound();
				this.FinishUserTalk();
			} 
		},
		TimerOverAparte: function (newValue, oldPath) {
			if (newValue === true) {
				this.TimerOverAparte = false;
				this.Sound();
				this.FinishTalkAparte();
			} 
		},
    
	},

	beforeCreate() {
		let verificarTimer = setInterval(() => {
			if (this.StoreAll.timers !== null) {
				clearInterval(verificarTimer);

				/* Inscrição */
				/* Timer vereador */
				this.minutes = this.StoreAll.timers[0].Vereador_tribune.minutes;
				this.seconds = this.StoreAll.timers[0].Vereador_tribune.seconds;

				/* Timer vereador lider */
				this.minutes_lider = this.StoreAll.timers[0].Vereador_lider_tribune.minutes;
				this.seconds_lider = this.StoreAll.timers[0].Vereador_lider_tribune.seconds;

				/* Timer convidado */
				this.minutes_convidado = this.StoreAll.timers[0].Convidado_tribune.minutes;
				this.seconds_convidado = this.StoreAll.timers[0].Convidado_tribune.seconds;

				/* Timer prefeitura */
				this.minutes_prefeitura = this.StoreAll.timers[0].Prefeitura_tribune.minutes;
				this.seconds_prefeitura = this.StoreAll.timers[0].Prefeitura_tribune.seconds;

				/* Timer apartiamento */
				this.Aparte_minutes = this.StoreAll.timers[0].Apartiamento_tribune.minutes;
				this.Aparte_seconds = this.StoreAll.timers[0].Apartiamento_tribune.seconds;

				/* Time apartiamento reduzido */
				this.Aparte_minutes_reduze = this.StoreAll.timers[0].Apartiamento_reduzido_tribune.minutes;
				this.Aparte_seconds_reduze = this.StoreAll.timers[0].Apartiamento_reduzido_tribune.seconds;

				/* Timer extra */

				this.Timer_Extra_Vereador_minutes = this.StoreAll.timers[0].Extra_vereador.minutes;
				this.Timer_Extra_Vereador_seconds = this.StoreAll.timers[0].Extra_vereador.seconds;

				/* Timer extra Convidado */

				this.Timer_Extra_Convidado_minutes = this.StoreAll.timers[0].Extra_convidado.minutes;
				this.Timer_Extra_Convidado_seconds = this.StoreAll.timers[0].Extra_convidado.seconds;
            
				this.Subscribe_seconds =  this.StoreAll.timers[0].Subscriber_tribune.seconds + 1;
				this.Subscribe_minutes = this.StoreAll.timers[0].Subscriber_tribune.minutes;

				this.TimersConf = true;
			}
		}, 500);
	},

	created () {
		let OnSocket = setInterval(() => {
			if (this.StoreAll.websocket_host !== null) {
				clearInterval(OnSocket);
				window.Echo.channel(this.StoreAll.websocket_host).listen('.' + this.StoreAll.websocket_host, (e) => {
					if (e.socket_id === 10) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.tribunaOn();
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 11) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.tribunaOn();
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 12) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.tribunaOn();
							this.force_stop_time_inscrito();
							this.socket_ids.push(e.event_id);  
						}
					}

					if (e.socket_id === 13) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.tribuna_of();
							this.socket_ids.push(e.event_id);  
						}
					}

					if (e.socket_id === 14) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} 
						else {
							this.Users_Tribuna();
							this.socket_ids.push(e.event_id);  
						}
					}

					if (e.socket_id === 15) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Users_Tribuna();
							this.socket_ids.push(e.event_id);  
						}
					}

					if (e.socket_id === 16) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Users_Tribuna();
							this.socket_ids.push(e.event_id);  
						}
					}

					if (e.socket_id === 17) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Users_Tribuna();
							this.socket_ids.push(e.event_id);  
						}
					}

					if (e.socket_id === 18) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Addtimer();
							this.socket_ids.push(e.event_id);  
						}
					}

					if (e.socket_id === 19) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.AparteOn();
							this.socket_ids.push(e.event_id);  
						}
					}

					if (e.socket_id === 20) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.AparteOn();
							this.socket_ids.push(e.event_id);  
						}
					}

					if (e.socket_id === 35) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.tribunaOn();
							this.socket_ids.push(e.event_id);  
						}
					}

					if (e.socket_id === 36) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.tribunaOn();
							this.socket_ids.push(e.event_id);  
						}
					}
				});
			}}, 500);
	},
	computed: {
		orderedTribunaVereadores() {
			return this.tribruna_vereadores.slice().sort((a, b) => a.position - b.position);
		}
	},

	mounted() {
		this.GetSession(); // OK
	},
	methods: {

		async GetSession() {
			await this.StoreAll.Get_Data();
      
			this.session = this.StoreAll.session; this.session_status = this.StoreAll.session.session_status.id;
			this.session_id = this.StoreAll.session.id; this.session_status_name = this.StoreAll.session.session_status.name;       
			this.tribunaOn(); 
			this.status_timer(); // caso a tribuna ainda esteja sendo iniciada.
			this.Session_timer();
			this.Hora();
			setInterval(this.Hora, 1000);
		},

		Session_timer() {
			setTimeout(() => {
				this.Session_timer();
			}, 300);

			if(this.StoreAll.quorum.length <= 0){
				return;
			}else{
				let inicio = new Date(this.StoreAll.quorum[0].created_at).getTime();;
				let fim = new Date().getTime();;
				let diferenca = fim - inicio;
				let hours = Math.floor((diferenca % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				let minutes = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60));
				let seconds = Math.floor((diferenca % (1000 * 60)) / 1000);
				let timer_session = hours + 'h: ' + minutes + 'm: ' + seconds + 's';
				this.timer_session = timer_session;
			}},

		Hora() {
			let date_time = new Date();
			let dia = date_time.getDate();
			let mesesEmPortugues = [
				'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
				'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
			];
			let mes = mesesEmPortugues[date_time.getMonth()];

			let minutes = date_time.getMinutes().toString().padStart(2, '0');
			let hours = date_time.getHours().toString().padStart(2, '0');
			let seconds = date_time.getSeconds().toString().padStart(2, '0');

			this.time = `${hours}: ${minutes}: ${seconds}`;
			this.dia = dia;
			this.mes = mes;
		},

		async tribunaOn() {
			if (this.voted == false) {
				if(this.StoreAll.tribuna == false){
					return;
				}else{
					await AxiosAPI.get('/tribune?quorum_id=' + this.StoreAll.quorum[0].id, {
						headers: {
							Authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}).then((response) => {
						this.tribuna_id = response.data.data[0].id;
						this.Users_Tribuna();
						if (response.data.data[0].status_tribune == 1 || response.data.data[0].status_tribune == 2) {
							this.tribuna = true;
							this.StoreAll.tribuna = true;
						} else {
							this.tribuna = false;
							this.StoreAll.tribuna = false;
							return;
						}
					});
				}}
		},

		tribuna_of(){
			this.StoreAll.tribuna = false;
		},

		Sound () {
			let audio = new Audio(sound);
			audio.play();
		},

		async status_timer(){
			await AxiosAPI.get('/tribune?quorum_id=' + this.StoreAll.quorum[0].id, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken,
				},
			}).then((response) => {
				if (response.data.data[0].status_tribune === '1') {
					let verificarTimer = setInterval(() => {
						if (this.TimersConf === true) {
							clearInterval(verificarTimer);
							this.timer_start = true;
							this.tempoinscrito();
						}
					}, 500);
				}else{
					this.timer_start = false;
				}
			});
		},

		force_stop_time_inscrito(){
			this.timer_start = false;
		},


		tempoinscrito() {
			if (!this.StoreAll.tribuna) {
				return;
			}

			setTimeout(() => {
				this.tempoinscrito();
			}, 1000);
          

			if (!this.timer_start) {
				this.hideTimerElements();
				return;
			}

			this.showTimerElements();

			if (this.Subscribe_seconds <= 0 && this.Subscribe_minutes <= 0) {
				this.handleTimerEnd();
			} else {
				this.updateTimer();         
			}
		},

		hideTimerElements() {					
			document.getElementById('textinit').style.display = 'none';
			document.getElementById('timerinit').style.display = 'none';
		},

		showTimerElements() {					
			document.getElementById('textinit').style.display = 'flex';
			document.getElementById('timerinit').style.display = 'flex';
			document.getElementById('textinit').style.color = 'white';
			document.getElementById('timerinit').style.color = 'white';
			document.getElementById('textinit').innerText = 'Tempo para inscrições serem encerradas';
		},

		handleTimerEnd() {
			this.Subscribe_seconds = 0;
			this.Subscribe_minutes = 0;
			this.cronometros = '';
			document.getElementById('textinit').innerText = 'Tempo para inscrições encerrado';
			document.getElementById('timerinit').style.display = 'none';
			document.getElementById('textinit').style.color = '#FF0000';
			if (this.timerSubscribeEnd === true) {
				this.timerSubscribeEnd = false;
				this.SoundGo = true;
				this.StopSubscribre(); 
			}        

		},

		updateTimer() {
			let sec = this.Subscribe_seconds;
			let min = this.Subscribe_minutes;

			sec--;

			if (sec < 0) {
				sec = 59;
				min--;

				if (min < 0) {
					min = 0;
				}
			}

			this.Subscribe_seconds = sec;
			this.Subscribe_minutes = min;

			if (sec <= 9) {
				sec = '0' + sec;
			}

			if (min <= 9) {
				min = '0' + min;
			}

			if (min == 0 && sec <= 59) {
				document.getElementById('timerinit').style.color = '#FF0000';
			}

			this.cronometros = min + ' : ' + sec;
		},


		async StopSubscribre () {
			/* CAMARA DE CAMPO GRANDE */
			if (this.StoreAll.host == 'campogrande' && !this.timerSubscribeEnd) {
				await AxiosAPI.put('/tribune/' + this.tribuna_id, {
					status_tribune: 2
				}, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken
					}
				}).then((response) => {
					if (response.status == 200) {
						this.tribunaOn();
						this.force_stop_time_inscrito();
					}
				});
			}
		},

		async Users_Tribuna() {
			const response = await AxiosAPI.get(`/tribune-users?tribune_id=${this.tribuna_id}`, {
				headers: {
					Authorization: `Bearer ${this.StoreAll.accessToken}`,
				},
			});
			const responseData = response.data.data;

			const tribruna_vereadores = responseData.slice();
			const ArrayLessEach = [];

			let verificarTimer = setInterval(() => {
				if (this.StoreAll.timers !== null) {
					clearInterval(verificarTimer);

					tribruna_vereadores.forEach(vereador => {
						if (vereador.status_fala == 1) {
							vereador.state = 'A';
						}
						if (vereador.status_fala == 2) {
							vereador.state = 'F';
							if (vereador.user.user_category_id === 2) {
								if (vereador.user.status_lider === true) {
									console.log(this.StoreAll.timers[0].Vereador_lider_tribune.minutes);
									this.minutes = this.StoreAll.timers[0].Vereador_lider_tribune.minutes;
									this.seconds = this.StoreAll.timers[0].Vereador_lider_tribune.seconds;
								} else {
									this.seconds = this.StoreAll.timers[0].Vereador_tribune.seconds;
									this.minutes = this.StoreAll.timers[0].Vereador_tribune.minutes;
								}
							}
							if (vereador.user.user_category_id === 3) {
								if (vereador.user.status_lider === true) {
									console.log(this.StoreAll.timers[0].Vereador_lider_tribune.minutes);
									this.minutes = this.StoreAll.timers[0].Vereador_lider_tribune.minutes;
									this.seconds = this.StoreAll.timers[0].Vereador_lider_tribune.seconds;
								} else {
									this.seconds = this.StoreAll.timers[0].Vereador_tribune.seconds;
									this.minutes = this.StoreAll.timers[0].Vereador_tribune.minutes;
								}
							}
							if(vereador.user.user_category_id === 8){
								console.log('4');
								this.minutes = this.StoreAll.timers[0].Convidado_tribune.minutes;
								this.seconds = this.StoreAll.timers[0].Convidado_tribune.seconds;
							}
							if(vereador.user.user_category_id === 5){
								console.log('5');
								this.minutes = this.StoreAll.timers[0].Prefeitura_tribune.minutes;
								this.seconds = this.StoreAll.timers[0].Prefeitura_tribune.seconds;
							}
							if(vereador.user.user_category_id !== 1 && vereador.user.user_category_id !== 2 && vereador.user.user_category_id !== 3 && vereador.user.user_category_id !== 5 && vereador.user.user_category_id !== 8){
								console.log('6');
								this.minutes = this.StoreAll.timers[0].Vereador_lider_tribune.minutes;
								this.seconds = this.StoreAll.timers[0].Vereador_lider_tribune.seconds;
							}
							this.fala = true;
							localStorage.setItem('id_user_tv', vereador.user.id);
							localStorage.setItem('id_timer_extra', vereador.id);
						} 
						if (vereador.status_fala == 3) {
							vereador.state = 'P';
							this.fala = false;
						}
						if (vereador.status_fala == 4) {
							vereador.state = 'VP';
							localStorage.setItem('id_user_tv', vereador.user.id);
						}
						if (vereador.status_fala == 5) {
							vereador.state = 'FS';
						}
						ArrayLessEach.push(vereador);
					});

					this.tribruna_vereadores = ArrayLessEach;
					this.Loading = false;
					this.cronometroconf(ArrayLessEach);
				}}, 500);
		},

		cronometroconf(value) {
			if (value.length <= 0) {
				return;
			}

			const TalkUser = [];
			const PauseUser = [];
			const FimUsers = [];

			if (value.length >= 1) {

				value.forEach(Talk => {
					if (Talk.state === 'F') {
						TalkUser.push(Talk);
					}
					if (Talk.state === 'P') {
						PauseUser.push(Talk);
					}
					if (Talk.state === 'VP') {
						TalkUser.push(Talk);
					}

					if (Talk.state === 'FS') {
						FimUsers.push(Talk);
					}
				});
			}  

			if (TalkUser.length >= 1) {
				this.timerOn = true;
				this.timer();
			}

			if (PauseUser.length >= 1) {
				this.timerOn = false;
				this.VerificaFala();
			}

			if (TalkUser.length <= 0 && PauseUser.length <= 0) {
				this.timerOn = false;
				this.ResetTimerFS(FimUsers);
			}
		},

		VerificaFala() {
			this.timerpauseseconds = this.seconds;
			this.timerpauseminutes = this.minutes;

			this.seconds = this.timerpauseseconds;
			this.minutes = this.timerpauseminutes;
		},

		ResetTimerFS(value) {
			for (let i = 0; i < value.length; i++) {
				if (value[i].user.id == localStorage.getItem('id_user_tv')) {
					localStorage.removeItem('id_user_tv');
				}
			}
		},

		timer() {
			if (!this.timerOn) { 
				return;
			}

			setTimeout(() => {
				this.timer();
			}, 1000);

			let sec = this.seconds--;
			let min = this.minutes;

			if (min == 0 && sec == 0) {
				this.cronometro = '';
				let text = 'Acabou seu tempo!';
				document.getElementById('timerzz').innerText = text;
				this.timerOn = false;
				this.TimerOver = true;

			} else {

				if (sec <= 9) {
					sec = '0' + sec;
				}
				if (sec == 0) {
					this.seconds = 59;
					let min = this.minutes--;
				}
				if (min == 0 && sec <= 59) {
					let CheckId = setInterval(() => {
						if (document.getElementById('timerz') !== null && document.getElementById('timerzz') !== null) {
							clearInterval(CheckId);
							document.getElementById('timerz').style.color = '#FF0000';
							document.getElementById('timerzz').style.color = '#FF0000';
						}
					}, 500);
				}

				this.cronometro = min + ' : ' + sec;
			}
		},

		FinishUserTalk () {
			try {
				const formData = new FormData();
				formData.append('tribune_id', this.tribuna_id);
				formData.append('user_id', localStorage.getItem('id_user_tv'));
				formData.append('status_fala', 5);


				AxiosAPI.post('/tribune-users-upp', formData, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken
					}
				}).then((resp) => {
					console.log(resp);
				});
			} catch {
				console.log('error');
			}
		},

		async AparteOn() {
			if(this.StoreAll.tribuna == false){
				return;
			}
			if (this.voted) {
				return;
			}
			if (this.fala) {
				return;
			} 

			try {
				await AxiosAPI.get('/apartiamento-users?tribune_id=' + this.tribuna_id, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				}).then((response) => {
					const ArrayLessAparte = [];

					response.data.data.forEach(Apartiador => {
						if (Apartiador.status_fala === '1') {
							Apartiador.state = 'APT';
							localStorage.setItem('id_user_tv_aparte', Apartiador.user.id);
								
							if (Apartiador.type) {
								this.Aparte_seconds = this.StoreAll.timers[0].Apartiamento_reduzido_tribune.seconds;
								this.Aparte_minutes = this.StoreAll.timers[0].Apartiamento_reduzido_tribune.minutes;
							}

							if (!Apartiador.type) {
								this.Aparte_minutes = this.StoreAll.timers[0].Apartiamento_tribune.minutes;
								this.Aparte_seconds = this.StoreAll.timers[0].Apartiamento_tribune.seconds;
							}
						} 
							
						if (Apartiador.status_fala === '2') {
							Apartiador.state = 'APTF';
						}
						ArrayLessAparte.push(Apartiador);
					});
	
					this.tribruna_vereadores_aparte = ArrayLessAparte;
					this.CronometroAparte(ArrayLessAparte);
				});
			} catch (error) {
				console.log(error);
			}
		}, 

		CronometroAparte(value) {
			if (value.length <= 0) {
				return;
			}
			const TalkAparte = [];
			const FimAparte = [];

			value.forEach(Talk => {
				if (Talk.state === 'APT') {
					TalkAparte.push(Talk);
				}

				if (Talk.state === 'APTF') {
					FimAparte.push(Talk);
				}
			});

			if (TalkAparte.length >= 1) {
				this.timerOnAparte = true;
				this.timerparte();
			}

			if (TalkAparte.length <= 0) {
				this.timerOnAparte = false;
				this.ResetTimerAPTF(FimAparte);
			}

		},
		timerparte() {
			if (!this.timerOnAparte) {
				return;
			}

			setTimeout(() => {
				this.timerparte();
			}, 1000);


			let sec = this.Aparte_seconds--;
			let min = this.Aparte_minutes;

			if (min == 0 && sec == 0) {
				this.Aparte = '';
				this.Apartes = 'Acabou seu tempo!';
				this.timerOnAparte = false;
				this.TimerOverAparte = true;
			} else {
				this.Apartes = '';
				if (sec <= 9) {
					sec = '0' + sec;

				}
				if (sec == 0) {
					this.Aparte_seconds = 59;
					let min = this.Aparte_minutes--;

				}
				if (min == 0 && sec <= 59) {
					let CheckId = setInterval(() => {
						if (document.getElementById('timerz') !== null) {
							clearInterval(CheckId);
							document.getElementById('timerz').style.color = '#FF0000';
						}
					}, 500);
				}

				this.Aparte = min + ' : ' + sec;
			}},

   
		async ResetTimerAPTF(value) {
			for (let i = 0; i < value.length; i++) {
				if (value[i].user.id == localStorage.getItem('id_user_tv_aparte')) {

					this.Aparte_seconds = this.StoreAll.timers[0].Apartiamento_tribune.seconds;
					this.Aparte_minutes = this.StoreAll.timers[0].Apartiamento_tribune.minutes;
						
					try {
						await AxiosAPI.delete('/att-aparte?tribune_id=' + this.tribuna_id + '&user_id=' + localStorage.getItem('id_user_tv_aparte'), {
							headers: {
								Authorization: 'Bearer ' + this.StoreAll.accessToken
							}
						}).then((resp) => {
							localStorage.removeItem('id_user_tv_aparte');
							this.Apartes = '';
							this.t = resp;
						});
					} catch {
						console.log('error');
					}
				}
			}
		}, 

		FinishTalkAparte () {
			try {
				const formData = new FormData();
				formData.append('tribune_id', this.tribuna_id);
				formData.append('user_id', localStorage.getItem('id_user_tv_aparte'));
				formData.append('status_fala', 2);


				AxiosAPI.post('/apartiamento-users-upp', formData, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken
					}
				}).then((resp) => {
					console.log(resp);
				});
			} catch {
				console.log('error');
			}
		},

		async Addtimer() {
			try{
				await AxiosAPI.get('/tribune-users?tribune_id=' + this.tribuna_id, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				}).then((response) => {

					let id_timer_extra = localStorage.getItem('id_timer_extra');

					for (let i = 0; i < response.data.data.length; i++) {

						if (response.data.data[i].user.id == localStorage.getItem('id_user_tv')) {

							if (response.data.data[i].time_extra == 2) {
								if(response.data.data[i].user.user_category_id === 8){
									this.minutes = this.minutes + this.Timer_Extra_Convidado_minutes;
                
									AxiosAPI.patch('/tribune-users/'+id_timer_extra+'/update-time-extra?time_extra=1',{},{
										headers: {
											Authorization: 'Bearer ' + this.StoreAll.accessToken,
										},
									});
								}
						
								if(response.data.data[i].user.user_category_id !== 8){
									this.minutes = this.minutes + this.Timer_Extra_Vereador_minutes;

									AxiosAPI.patch('/tribune-users/'+id_timer_extra+'/update-time-extra?time_extra=1',{}, {
										headers: {
											Authorization: 'Bearer ' + this.StoreAll.accessToken,
										},
									});}
							}
						}

					}
				});
			} 
			catch {
				console.log('error');
			}
		}
	}, 
};
</script>
<template>
<div v-if="Loading" class="d-flex justify-content-center">
	<span style="transform: translate(-50%, 80%);margin-right: -50%;top: 39%;left: 50%;position: absolute;font-size: clamp(0.1vw, 2.5vw, 40px);font-weight: 700 !important;color: white;">
          Carregando tribuna ...</span>
</div>
  <div v-if="!Loading">
    <div>
      <div class="display_vh">
        <div>
          <div class="d-flex" style="margin-top: 5px; border-top: 2px solid #005c9f;">
          </div>
          <div class="ml-3 mt-2">
            <div style="border-radius: 2%; background-color: white;" class="display_sign float-left">
              <span class="ml-3 text-uppercase text-dark font-weight-bold"
                style="font-size: clamp(0.5rem, 0.5em + 1vw, 1.5em); font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
                Lista de Inscritos:
              </span>
              <div v-for="tribunaxs in orderedTribunaVereadores" :key="tribunaxs.id">
                <div class="display_sign" style="display:block">
                  <div>
                    <ul>
                      <div v-if="tribunaxs.state == 'A'" class="d-flex text-uppercase text-dark font-weight-bold"
                        style="font-size: clamp(0.5rem, 0.5em + 1vw, 1.5em); font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
                        <div class="mr-2 status_circle"
                          style="border: 2px solid rgb(124, 91, 0); background-image: linear-gradient(#ffdaa2, #ffba5ff6, #fda726);">
                        </div>
                        {{
                            tribunaxs.user.nickname
                        }}
                      </div>
                      <div v-if="tribunaxs.state == 'F'" class="d-flex text-uppercase text-dark font-weight-bold"
                        style="font-size: clamp(0.5rem, 0.5em + 1vw, 1.5em); font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
                        <div class="mr-2 status_circle_active" style=""></div>
                        {{
                            tribunaxs.user.nickname
                        }}
                      </div>
                      <div v-if="tribunaxs.state == 'P'" class="d-flex text-uppercase text-dark font-weight-bold"
                        style="font-size: clamp(0.5rem, 0.5em + 1vw, 1.5em); font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
                        <div class="mr-2 status_circle"
                          style="border: 2px solid rgb(236, 40, 40); background-image: linear-gradient(#fc2c2c, #e41b1bf6, #bd0808);">
                        </div>
                        {{
                            tribunaxs.user.nickname
                        }}
                      </div>
                      <div v-if="tribunaxs.state == 'VP'" class="d-flex text-uppercase text-dark font-weight-bold"
                        style="font-size: clamp(0.5rem, 0.5em + 1vw, 1.5em); font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
                        <div class="mr-2 status_circle_active" style=""></div>
                        {{
                            tribunaxs.user.nickname
                        }}
                      </div>
                      <div v-if="tribunaxs.state == 'FS'" class="d-flex text-uppercase text-dark font-weight-bold"
                        style="font-size: clamp(0.5rem, 0.5em + 1vw, 1.5em); font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
                        <div class="mr-2 status_circle"
                          style="border: 2px solid rgb(109, 108, 108); background-image: linear-gradient(#b1b0b0, #777775f6, #4b4a4a);">
                        </div>
                        <span>
                          {{ tribunaxs.user.nickname }}
                        </span>
                      </div>
                      <!-- AMARELO - AGUARDANDO // VERDE - FALANDO // VERMELHO - PAUSADO // CINZA - FINALIZADO -->
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <template v-for="tribunas in tribruna_vereadores" :key="tribunas">
              <div v-if="tribunas.state == 'F' || tribunas.state == 'VP'" class="float-right display_margin"
                style="width: 65%;">
                <div class="d-flex justify-content-center text-white" style="margin-top: 10%">
                  <div class="d-flex align-items-center flex-column display_cronometro">
                    <img :src=this.StoreAll.Dochost+tribunas.user.path_image class="text-center rounded-circle display_img"
                      style="object-fit: cover;" alt="user photo" />
                      <span v-if="tribunas.user.user_category_id === 5 || tribunas.user.user_category_id === 8" class="text-center p-2"
                      style="font-size: clamp(0vw, 2.8vw, 3vw); font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">
                      {{ tribunas.user.nickname }}</span>
                      <span v-else class="text-center p-2"
                      style="font-size: clamp(0vw, 2.8vw, 3vw); font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">
                      {{ tribunas.user.nickname }}  - {{ tribunas.user.category_party.name_party }}</span>
                  </div>
                  <div class="d-flex align-items-center">
                    <span id="timerz" style="font-size: clamp(0vw, 10vw, 10vw);"> {{ cronometro }} </span>
                    <span id="timerzz" class="text-uppercase font-weight-bold mt-7"
                      style="font-size: clamp(0vw, 3vw, 3vw); color: red"> </span>
                  </div>
                </div>
              </div>
            </template>

            <template v-for="tribunax in tribruna_vereadores_aparte" :key="tribunax">
              <div v-if="tribunax.state == 'APT'" class="float-right display_margin" style="width: 65%;">
                <div class="d-flex justify-content-center text-white" style="margin-top: 7%">
                  <div class="d-flex align-items-center flex-column display_cronometro">
                    <img :src=this.StoreAll.Dochost+tribunax.user.path_image class="text-center rounded-circle display_img"
                      style="object-fit: cover;" alt="user photo" />
                      <span v-if="tribunax.user.user_category_id === 5 || tribunax.user.user_category_id === 8" class="text-center p-2"
                      style="font-size: clamp(0vw, 2.8vw, 3vw); font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">
                      {{ tribunax.user.nickname }}</span>
                    <span v-else class="text-center p-2"
                      style="font-size: clamp(0vw, 2.8vw, 3vw); font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">
                      {{ tribunax.user.nickname }} - {{ tribunax.user.category_party.name_party }}</span>
                  </div>
                  <div class="d-flex align-items-center">
                    <span id="timerz" style="font-size: clamp(0vw, 10vw, 10vw);"> {{ Aparte }} </span>
                    <span id="timerzzz" class="text-uppercase font-weight-bold mt-7"
                      style="font-size: clamp(0vw, 3vw, 3vw); color: red"> {{ Apartes }}</span>
                </div>
                </div>
                  <span class="d-flex justify-content-center text-uppercase font-weight-bold mt-2"
                    style="font-size: clamp(0vw, 3vw, 3vw); color: red"> APARTEAMENTO </span>
                </div>
            </template>
            </div>
          </div>

            <div class="d-flex align-items-center flex-column" style="margin-left: 25rem; margin-top: 5rem;">
              <span id="timerinit" style="font-size: clamp(0.1vw, 10vw, 10vw); display: none;"> {{ cronometros }} </span>
              <span id="textinit" class="text-uppercase font-weight-bold mt-7" style="font-size: clamp(0.1vw, 2.5vw, 3vw); color: red; display: none;"> </span>
            </div>

          </div>
        <div>
      </div>
    </div>

    <div v-if="this.StoreAll.tribuna == true" class="d-flex justify-content-between">
      <div style="display: flex;">
        <span class="text-white text-center"
          style="font-size: clamp(1em, 1em + 1vw, 1.5em); width: 8.5rem; border-right: 1px solid">
          {{ dia }} <br />
          {{ mes }}
        </span>
        <span class="text-white d-flex justify-content-center"
          style="font-size:clamp(2em, 1em + 1vw, 1.5em); width: 12rem; margin-top: 1rem;">
          {{ time }}
        </span>
      </div>
      <div class="d-flex justify-content-center mt-3" style="width: 50%;">
        <div class="mr-2"
          style="width: 40px; height: 40px; border-radius: 50%;  border: 2px solid rgb(124, 91, 0); background-image: linear-gradient(#ffdaa2, #ffba5ff6, #fda726);">
        </div>
        <span style="font-size:clamp(0.5rem, 0.5em + 1vw, 1.5em)" class="mr-2 mt-1 text-white"> Aguardando </span>

        <div
          style="width: 40px; height: 40px; border-radius: 50%;  border: 2px solid rgb(37 231 70); background-image: linear-gradient(rgb(29 247 35), rgb(51 255 0 / 97%), rgb(69 237 0));">
        </div>
        <span style="font-size:clamp(0.5rem, 0.5em + 1vw, 1.5em)" class="mr-2 ml-1 mt-1 text-white"> Falando </span>

        <div class="mr-2"
          style="width: 40px; height: 40px; border-radius: 50%; border: 2px solid rgb(236, 40, 40); background-image: linear-gradient(#fc2c2c, #e41b1bf6, #bd0808);">
        </div>
        <span style="font-size:clamp(0.5rem, 0.5em + 1vw, 1.5em)" class="mr-2 mt-1 text-white"> Apartiado </span>

        <div class="mr-2"
          style="width: 40px; height: 40px; border-radius: 50%; border-radius: 50%; border: 2px solid rgb(109, 108, 108); background-image: linear-gradient(#b1b0b0, #777775f6, #4b4a4a);">
        </div>
        <span style="font-size:clamp(0.5rem, 0.5em + 1vw, 1.5em)" class="mr-2 mt-1 text-white"> Finalizado </span>
      </div>
      <div class="text-white text-center d-flex justify-content-center"
        style="border-left: 1px solid white; width: 15%; font-family: monospace; font-size: clamp(0.5em, 0.5em + 1vw, 1.5em);">
        Tempo de sessão <br />
        {{ timer_session }}
      </div>
    </div>
  
 </div>
</template>
<style>
.text-black {
  color: black;
}
.status_circle {
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.status_circle_active {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  border: 2px solid #0db80d;
  background-color: #0db80d;
}
.display_vh {
  height: clamp(0.1vw, 44.6vw, 855px);
}
.display_sign {
  width: clamp(0.1vw, 22.6vw, 410px);
}
.display_cronometro {
  width: clamp(0.1vw, 50vw, 600px);
}
.display_img {
  width: clamp(0.1vw, 20vw, 310px);
  height: clamp(0.1vw, 20vw, 310px);
}

.display_margin {
  margin-right: 12%;
}
@media only screen and (max-width: 1600px) {
  .display_vh {
    height: clamp(0.1vw, 43vw, 690px);
  }
  .display_sign {
    width: clamp(0.1vw, 25.6vw, 410px);
  }
  .display_cronometro {
    width: clamp(0.1vw, 30vw, 500px);
  }
  .display_margin {
    margin-right: 7%;
  }
}
@media only screen and (max-width: 1380px) {
  .display_vh {
    height: clamp(0.1vw, 43vw, 570px);
  }
  .display_sign {
    width: clamp(0.1vw, 28.6vw, 400px);
  }
  .display_margin {
    margin-right: 4%;
  }
}
@media only screen and (max-width: 1280px) {
  .display_vh {
    height: clamp(0.1vw, 43vw, 530px);
  }
  .display_sign {
    width: clamp(0.1vw, 29.6vw, 400px);
  }
  .display_margin {
    margin-right: 2%;
  }
}
</style>
