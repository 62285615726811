<script>
import {AxiosAPI} from '@/axios';
import { useMainStore, useBusStore } from '../../stores/index';
import Loading from '../../components/documents/Modals/Loading.vue';
export default {
	name: 'ControleD',
	components: {Loading},
	setup(){
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		StoreAll.Token();
		return{
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			discussion_off: true,
			talk_now: false,
			timer_on: null,
			Discut_document: [],
			Document_discut_name_tv: 'Aguarde...',
			vereador_user_talk: [],
			discut_vereador: [],
			layout_user_discussion: false,
			discut_name_user: 'Aguarde...',
			vereadores: [],
			tribuna_users:[],
			user_id: 0,
			Discut_id: 0,
			category_user:0,
			aparte: [],
			discut_add: [],
			quorum: [],
			quorums: [],
			namer: [],
			tribuna_id:0,
			tribuna: null,
			vereador_user_talk_id: null,
			orador: null,
			header_error:'',
			body_error: '',
			header_success: '',
			body_success: '',
			socket_ids: [],
		};
	},

	created () {
		let OnSocket = setInterval(() => {
			if (this.StoreAll.websocket_host !== null) {
				clearInterval(OnSocket);
				window.Echo.channel(this.StoreAll.websocket_host).listen('.' + this.StoreAll.websocket_host, (e) => {
					if (e.socket_id === 25) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Discut_ID();
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 26) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Discut_ID();
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 27) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.second_timer();
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 29) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Discut_ID();
							this.socket_ids.push(e.event_id);  
						}
					} 
				});
			}}, 500);

	},

	mounted() {
		this.GetSession();
		this.GetUsersAll();
		this.User();
	},
    
	methods: {

		async GetUsersAll() {
			await this.StoreAll.UserAllEnvio();
			this.vereadores = this.StoreAll.user_envio;
		},

		async GetSession() {
			/* verificar essa situação */
			await this.StoreAll.Get_Data(); 
			this.session_id = this.StoreAll.session.id; 
			this.Quorum_data_controle();
		},

		async User(){
			await this.StoreAll.User(); 
			this.user_id = this.StoreAll.user.id;
			this.category_user = this.StoreAll.user.user_category_id; 
		},

		async Quorum_data_controle(){
			await this.StoreAll.Get_Data(); 
			this.Discut_ID();
		},

		Orador(value){
			if(value == 1){
				this.orador = 1;
			}else{
				this.orador = 2;
			}
		},

		async Discut_ID() {       
			let Discut_id = [];
			let Discut_document = [];
			let discut_doc_name = [];
			if(this.StoreAll.quorum == null){return;}else if(this.StoreAll.quorum.length <= 0){return;}else{

				await AxiosAPI.get('/discussion?quorum_id='+ this.StoreAll.quorum[0].id,{
					headers: {
						Authorization: 'Bearer '+this.StoreAll.accessToken,
					},
				}).then((resp) => {   
					if(resp.data.data.length <= 0){
						this.error = 'Array menor ou igual a 0';
						return;
					}
					
					resp.data.data.forEach(DataDiscussion => {
						if(DataDiscussion.status_discussion === '1'){
							this.discussion_off = false;
							Discut_id.push(DataDiscussion.id);
							Discut_document.push(DataDiscussion.document_id);
							discut_doc_name.push(DataDiscussion.document.name);
						}
						
					});

					this.Discut_id = Discut_id;
					this.Discut_document = Discut_document;
					this.Document_discut_name_tv = discut_doc_name;

					let elementos = discut_doc_name;
					let LIMIT = 100;
					let names_doc = [];

					for (let i = 0; i < elementos.length; i++) {
						if (elementos[i].length > LIMIT) {
							names_doc.push(elementos[i].substring(0, 100) + '...');
						} else {
							names_doc.push(elementos[i]);
						}
					}

					this.Document_discut_name_tv = names_doc;
					this.functions_of_discut();
				});
			} 
		},


		async Checks_vote(){
			this.$refs.Loading.openModal();
			await AxiosAPI
				.get(
					'/documents?session_id=' +
          this.StoreAll.session.id  +
            '&document_status_vote_id=4',
					{
						headers: {
							Authorization: 'Bearer '+ this.StoreAll.accessToken,
						},
					}
				)
				.then((response) => {
					if(response.data.data.length <= 0){
						this.Checks_tribune();
					}else{
						this.header_error = 'Falha ao iniciar fala',
						this.body_error = 'Existe um documento em votação, solicite o encerramento para continuar.';
						this.$refs.Loading.closeModal();
						this.show_error();
					}
				});
		},

		async Checks_tribune(){  
			await AxiosAPI.get('/tribune?quorum_id='+this.StoreAll.quorum[0].id,{
				headers: {
					Authorization: 'Bearer '+this.StoreAll.accessToken,
				},
			}).then((response) => {
				if(response.data.data.length <= 0){
					this.tribuna = false;
					setTimeout(()=>{ this.Discut_Add();}, 200);
				}else{
                   
					this.tribuna_id = response.data.data[0].id;
					if(response.data.data[0].status_tribune == 1 || response.data.data[0].status_tribune == 2){
						this.tribuna = true;
					}else{
						this.tribuna = false;
					}
					setTimeout(()=>{ this.Discut_Add();}, 200);
				}  
			});
		},

		show_error() {
			this.$toast.add({ severity: 'error', summary: this.header_error, detail: this.body_error, group: 'tl', life: 5000 });
		},
		show_success() {
			this.$toast.add({ severity: 'success', summary: this.header_success, detail: this.body_success , life: 3000 });
		},
		async Discut_Add(){
			if(this.tribuna == true){
				this.header_error = 'Falha ao iniciar fala',
				this.body_error = 'A tribuna ainda está ativa, encerre a tribuna para continuar.';
				this.$refs.Loading.closeModal();
				this.show_error();
			}else{
				await AxiosAPI.post('/discussion-users',{
					discussion_id: this.Discut_id[0],
					user_id: this.discut_add[0],
					orador: this.orador 
				},{
					headers: {
						Authorization: 'Bearer '+ this.StoreAll.accessToken
					} 
				}).then((response)=>{
					localStorage.setItem('user_discut', this.discut_add[0]);
					this.user_discut_store = this.discut_add[0];
					this.discut_add = [];
					this.functions_of_discut();
					if(response.status == 200){
						this.body_success = 'Fala iniciada!';
						this.header_success = 'Iniciado';
						this.$refs.Loading.closeModal();
						this.show_success();
					}
				});}
		}, 

		async functions_of_discut(){ 
			let discut_vereador = [];
			let vereador_user_talk = [];
			let vereador_user_talk_id = [];
			let discutIds = JSON.stringify(this.Discut_id[0]);

			await AxiosAPI.get('/discussion-users?discussion_id=' + discutIds, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken,
				},
			}).then((response) => {
				for (let i = 0; i < response.data.data.length; i++) {
					discut_vereador.push(response.data.data[i]);
				}
				this.discut_vereador = discut_vereador;
				for (let i = 0; i < discut_vereador.length; i++) {
					if (response.data.data[i].status_fala_discussion == 1) {
                
						vereador_user_talk.push(response.data.data[i]);
						vereador_user_talk_id.push({id: response.data.data[i].user.id});
						this.discut_name_user = response.data.data[i].user.name;
						this.talk_now = true;
						this.timer_on = true;
						this.layout_user_discussion = true;
					} else {
						this.t = 'a';
					}
					this.vereador_user_talk = vereador_user_talk;
					this.vereador_user_talk_id = vereador_user_talk_id;
                  
					let valueV = [];
					for (let i = 0; i < this.vereador_user_talk_id.length; i++) {
						valueV.push(this.vereador_user_talk_id[i].id);
					}

					this.vereador_user_talk_id = valueV;
					localStorage.setItem('user_discut', this.vereador_user_talk_id[0]);
				}
			});
		},

		Modal_Second_timer_on(){
			this.timer_on = false;
		},

		async ForceStopDiscut(){
			this.$refs.Loading.openModal();
			const formData = new FormData();
			formData.append('discussion_id', this.Discut_id[0],);
			formData.append('user_id', localStorage.getItem('user_discut'));
			formData.append('status_fala_discussion', 3);


			await AxiosAPI.post('/discussion-users-upp',formData,{
				headers: {
					Authorization: 'Bearer '+ this.StoreAll.accessToken
				} 
			}).then((response)=>{
				if(response.status == 200){
					this.talk_now = false;
					this.timer_on = false;
					this.layout_user_discussion = false;
					this.body_success = 'Você encerrou a discussão de um vereador!';
					this.header_success = 'Fala encerrada!';
					this.$refs.Loading.closeModal();    
					this.show_success();  
				}
				localStorage.removeItem('user_discut');
        
			});
		},


		async FinishDiscut() {
			this.$refs.Loading.openModal();

			let message = '';
			let buttonStyle = false;
			let confirmText = '';

			try {
				const dataCheck = await this.StoreAll.VerifySession();

				const hasDiscussion = dataCheck.some(Check =>
					Check.discussions.some(DiscussionCheck => 
						DiscussionCheck.discussion_users.some(DiscussionUserCheck => 
							DiscussionUserCheck.status_fala_discussion === '1'))
				);


				if (hasDiscussion) {
					message = 'A dicussão está em andamento, encerre a fala atual para encerrar a dicussão';
					buttonStyle = false;
					confirmText = 'OK';
				} else {
					message = 'Deseja realmente encerrar a dicussão ?';
					buttonStyle = true;
					confirmText = 'SIM';
				}

				this.$refs.Loading.closeModal();

				this.$swal.fire({
					title: message,
					showDenyButton: false,
					showCancelButton: buttonStyle,
					buttonsStyling: false,
					confirmButtonText: confirmText,
					cancelButtonText: 'VOLTAR',
					customClass: {
						confirmButton: 'btn btn-success mr-5 ml-1 text-lg',
						cancelButton: 'btn btn-secondary mr-1 ml-1 text-lg'
					},
				}).then((result) => {
					if (confirmText === 'OK') {
						this.error_show();
					} else {
						if (result.isConfirmed) {
							this.FinishDiscuts();
						} else {
							this.error_show();
						}
					}
                  
				});
			}
			catch {
				console.log('erro');
			}
		},

		error_show() {
			this.$toast.add({ severity: 'error', summary: 'Discussão não encerrada', detail: 'Discussão não encerrada!', life: 3000 });
		},


		async FinishDiscuts(){
			if(this.Discut_id.length >= 2){
				let array_discut = JSON.stringify({
					'status_discussion': '2',
					'discussion_id': this.Discut_id,
				});
				await AxiosAPI.put('/discussion',array_discut,{
					headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer '+ this.StoreAll.accessToken
					}
				}).then((response)=>{
					if(response.status == 200){
						this.discussion_off = true;
						this.body_success = 'A discussão está encerrada!';
						this.header_success = 'Discussão encerrada!';
						this.show_success();
					}
				});
			}else{
				let array_discut = JSON.stringify({
					'status_discussion': '2',
					'discussion_id': [this.Discut_id[0]],
				});

				AxiosAPI.put('/discussion',array_discut,{
					headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer '+ this.StoreAll.accessToken
					}
				}).then((response)=>{
					if(response.status == 200){
						this.discussion_off = true;
						this.body_success = 'A discussão está encerrada!';
						this.header_success = 'Discussão encerrada!';
						this.$refs.Loading.closeModal();
						this.show_success();
					}
				});
			}

			const formData = new FormData();

			formData.append('discussion_id', this.Discut_id[0]);
			formData.append('status_discussion', 2);


		},


		second_timer(){
			if(localStorage.getItem('user_discut') == null){
				return;
			}

			let discutIds = JSON.stringify(this.Discut_id[0]);
			AxiosAPI.get('/discussion-users?discussion_id=' + discutIds + '&user_id=' + localStorage.getItem('user_discut'),{
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken,
				},
			}).then((response) => {
				if(response.data.data[0].status_fala_discussion === '2'){
					this.Modal_Second_timer_on();
				}   
			}
			);
		}, 

		second_timer_resp(id){
			this.$refs.Loading.openModal();
			if(id == 1){
				const formData = new FormData();
				formData.append('discussion_id', [this.Discut_id[0]]);
				formData.append('user_id', localStorage.getItem('user_discut'));
				formData.append('status_fala_discussion', 1);

				AxiosAPI.post('/discussion-users-upp', formData, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken
					}
				}).then((resp) => {
					this.functions_of_discut();
					this.timer_on = null;
					this.body_success = 'Segundo tempo do vereador iniciado!';
					this.header_success = 'Segundo tempo iniciado!';
					this.$refs.Loading.closeModal();
					this.show_success();
				});
			}else if(id == 2){
				const formData = new FormData();
				formData.append('discussion_id', [this.Discut_id[0]]);
				formData.append('user_id', localStorage.getItem('user_discut'));
				formData.append('status_fala_discussion', 3);

				AxiosAPI.post('/discussion-users-upp', formData, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken
					}
				}).then((resp) => {
					this.talk_now = false;
					this.timer_on = null;
					this.layout_user_discussion = false;
					this.functions_of_discut();
					this.body_success = 'Tempo do vereador encerrado!';
					this.header_success = 'Tempo encerrado!';
					this.$refs.Loading.closeModal();
					this.show_success();
				});}
		},   
 
	},
};
</script>
<template>
  <div style="z-index: 1010 !important;">
    <Loading ref="Loading"></Loading>
  </div>
    <div class="py-4 h-100 container-fluid">
        <div class="row">
            <div class="text-center col-12">
                <h3 class="mt-auto text-white">Controle da discussão</h3>
                <h5 class="text-white font-weight-normal">
                  Adicione, pause e finalize as falas dos vereadores em discussão por aqui!
                </h5>
                <div class="mb-5 multisteps-form">
                   
                  <div v-if="discussion_off == true" class="row">
                    <div class="m-auto col-12">
                      <form class="multisteps-form__form">
                        <div>
                          <div class="p-3 bg-white card multisteps-form__panel border-radius-xl js-active position-relative" data-animation="FadeIn">
                            <div class="text-center row">
                              <div class="mx-auto col-10">
                                <div>
                                  <h2 style="font-family: 'Bebas Neue', cursive;">Nenhum documento se encontra em discussão, aguarde...</h2></div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </form>
                    </div>
                  </div>         

                    <div v-else class="row">
                        <div class="m-auto col-12">
                            <form class="multisteps-form__form">
                                <div>
                                    <div class="p-3 bg-white card multisteps-form__panel border-radius-xl js-active position-relative" data-animation="FadeIn">
                                        <div class="text-center row">
                                            <div class="mx-auto col-10 mb-5">
                                                <h5 class="font-weight-normal">
                                                    Documento(s) em discussão
                                                </h5>
                                                <h5 v-for="name in Document_discut_name_tv" :key="name" style="color: red;"><strong> {{ name }} </strong></h5>
                                            </div>

											<div v-if="!talk_now">
												<div style="width: 100%;">
														<button type="button" class="btn btn-primary bg-success" style="width: 100%; height: 50px;"
														data-toggle="modal" data-target="#discuts"
														@click="Discut_ID()">
														Selecionar veriador para discutir documento(s)!
														</button>
												</div>

												<div style="width: 100%;">
													<button type="button" class="btn btn-primary bg-dangerlegis" 
													style="width: 100%; height: 50px;" @click="FinishDiscut()">
													Encerrar a discussão do(s) documento(s) presente(s)
													</button>
												</div>
											</div>

                                            <div class="d-flex justify-content-center">

                                              <div class="mb-2" style="border-style: double; width: 100%;">
                                                <div v-if="talk_now">
												<div class="d-flex justify-content-center mb-3">
                                                  <span style="font-size: 20px;" class="font-weight-bolder"> Discussão
                                                  </span>
                                                  </div>

													<div class="d-flex justify-content-center">
															<img :src="this.StoreAll.Dochost + vereador_user_talk[0].user.path_image"
															class="rounded-circle justify-content-start"
															style="object-fit: cover; width: 150px" alt="user photo" />
													</div>

                                                  	<div class="d-flex justify-content-center">
														<span style="font-size: 20px;" class="font-weight-bolder"> Usuário atual: &nbsp;
														{{ vereador_user_talk[0].user.nickname }}
														</span> 
                                                	</div>

                                                <div v-if="talk_now" class="d-flex justify-content-center">
                                                  	<span v-if="vereador_user_talk[0].status_fala_discussion == 1" style="font-size: 20px;" class="font-weight-bolder"> Status: &nbsp; Falando 
													</span> 
                                                </div>

                                                <div v-if="timer_on">
                                                  <button id="fim_discut" style="width: 100%; height: 50px; font-size: 20px;" type="button" class="justify-content-center btn btn-primary bg-gray-600"
                                                  	@click="ForceStopDiscut()">
                                                	Encerrar fala do vereador
                                        			</button>
                                                </div>
												</div>
												<div v-if="talk_now && !timer_on">
                                                  	<button id="fim_discut" style="width: 100%; height: 50px; font-size: 20px;" type="button" class="justify-content-center btn btn-primary bg-gray-600 mr-3"
                                                	@click="ForceStopDiscut()">
                                                	Encerrar fala do vereador
                                            		</button>
    
                                                  	<button style="width: 100%; height: 50px; font-size: 20px;" type="button" class="justify-content-center btn btn-primary bg-success"
                                                	data-target=".discut_timer" data-toggle="modal">
                                                     Adicionar mais um tempo!
                                                	</button>
                                                </div>  
                                              </div>                                        
                                        	</div>

                                        </div>                                    
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div
      class="modal fade bd-example-modal-lg-startmodal discut"
      id="discuts"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
      style="z-index: 10000"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">
              Discussão
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label style="font-size: 18px"
              ><b> Marque vereador que deseja discutir o documento. </b></label
            >
            <div 
              v-for="(vereadorr, index) in vereadores"
              :key="vereadorr.id"
              class="mt"
              style="display: flex"
            >
              <input 
                :key="index"
                v-model="discut_add"
                :value="vereadorr.id"
                class="mr"
                type="checkbox"
                style="width: 1.7rem; height: 1.7rem"
              />
              <label style="font-size: 18px">{{ vereadorr.name }}</label>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Voltar
            </button>
            <button
              type="button"
              class="btn btn-primary bg-success"
              data-dismiss="modal"
              data-toggle="modal" data-target=".orador"
            >
              próximo
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal orador" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Orador</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex justify-content-center text-black"> <p>O usuário é o primeiro orador ?</p>  </div>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button type="button" class="btn btn-success" data-dismiss="modal" @click="Checks_vote(); Orador(1);">Sim</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal" @click="Checks_vote(); Orador(2);">Não</button>
      </div>
    </div>
  </div>
</div>
       
  <div class="modal discut_timer" tabindex="-1" role="dialog" style="z-index: 999">
  <div class="modal-dialog discut_timer" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Discussão</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span class="text-dark font-weight-bold ">Deseja Adicionar mais um tempo ao vereador: </span> <span style="color: red;" class="font-weight-bold "><br/>{{discut_name_user}}</span>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-dismiss="modal" @click="second_timer_resp(1);">Sim</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal" @click="second_timer_resp(2);">Não</button>
      </div>
    </div>
  </div>
</div>
</div>
</template>
<style>
  .close{
  font-size: 2.3rem !important;
}
</style>
