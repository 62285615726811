<script>
import { AxiosAPI } from '@/axios';
import { useMainStore, useBusStore } from '../../stores/index';
import Loading from '../../components/documents/Modals/Loading.vue';

export default {
	name: 'ControleE',
	components: {Loading},
	setup(){
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		StoreAll.Token();
		return{
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			QuestionOrdem: false,
			vereadores: [],
			QuestionOrdem_buttom: 0,
			QuestionOrdem_user: false,
			QuestionOrdem_users: [],
			Vereador_QuestionOrdem_now: [],
			QuestionOrdem_on: false,
			id_QuestionOrdem: null,
			user_id: 0,
			voted_on: null,
			tribune_on: null,
			discut_on: null,
			header_error:'',
			body_error: '',
			header_success: '',
			body_success: '',
			socket_ids: [],
		};
	},

	created () {
		let OnSocket = setInterval(() => {
			if (this.StoreAll.websocket_host !== null) {
				clearInterval(OnSocket);
				window.Echo.channel(this.StoreAll.websocket_host).listen('.' + this.StoreAll.websocket_host, (e) => {
					if (e.socket_id === 31) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.CheckOrdem();
							this.header_success = 'Ordem iniciada!';
							this.body_success = 'Ordem iniciada!';
							this.show_success();
							this.$refs.Loading.closeModal();
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 32) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.CheckOrdem();
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 33) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.CheckOrdem();
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 34) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.CheckOrdem();
							this.header_success = 'Ordem encerrada!';
							this.body_success = 'Ordem encerrada!';
							this.show_success();
							this.$refs.Loading.closeModal();
							this.socket_ids.push(e.event_id);  
						}
					} 
				});
			}}, 500);
	},

	mounted() {
		this.GetSession();
		this.GetUsersAll();
		this.User();
	},
	methods: {

		async GetUsersAll() {
			await this.StoreAll.UserAllEnvio();
			this.vereadores = this.StoreAll.user_envio;
		},

		async GetSession() {
			await this.StoreAll.Get_Data(); 
			this.session_id = this.StoreAll.session.id; 
			this.CheckOrdem();
		},

		async User(){
			await this.StoreAll.User(); 
			this.user_id = this.StoreAll.user.id;
			this.category_user = this.StoreAll.user.user_category_id; 
		},

		async CheckOrdem() {  
			await AxiosAPI
				.get(
					'/question-orders?quorum_id=' + this.StoreAll.quorum[0].id,
					{
						headers: {
							authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}
				).then((resp) => {
					let OrdemNow = [];

					resp.data.data.forEach(ordem => {
						if (resp.data.data) {
							if (ordem.status === '1') {
								OrdemNow.push(ordem);
								localStorage.setItem('QuestionOrdem', ordem.id);
							}
						}
					});

					if (OrdemNow.length <= 0) {
						this.QuestionOrdem_buttom = 0;
						this.QuestionOrdem_on = false;
						this.QuestionOrdem_user = false;
					} else {
						this.QuestionOrdem_on = true;
						this.QuestionOrdem_user = true;
						this.QuestionOrdem_buttom = 1;
					}

					this.QuestionOrdem_user_now();
				});
		},

		async check() {
			let message = '';
			let buttonStyle = false;
			let confirmText = '';

			try {
				const dataCheck = await this.StoreAll.VerifySession();

				const hasTribuneUser = dataCheck.some(Check =>
					Check.tribunes.some(TribuneCheck => 
						TribuneCheck.tribune_users.some(TribuneUserCheck =>
							TribuneUserCheck.status_fala === '2' || TribuneUserCheck.status_fala === '3' || TribuneUserCheck.status_fala === '4'))
				);

				const hasApartUser = dataCheck.some(Check =>
					Check.tribunes.some(TribuneCheck => 
						TribuneCheck.apartiamento_users.some(ApartUserCheck =>
							ApartUserCheck.status_fala === '1'))
				);


				const hasDiscussion = dataCheck.some(Check =>
					Check.discussions.some(DiscussionCheck => 
						DiscussionCheck.discussion_users.some(DiscussionUserCheck => 
							DiscussionUserCheck.status_fala_discussion === '1'))
				);

                

				const hasExplanation = dataCheck.some(Check =>
					Check.big_discussions.some(ExplanationCheck =>
						ExplanationCheck.big_discussion_users.some(ExplanationUserCheck => 
							ExplanationUserCheck.status_fala_big_discussion === '1'))
				);


				if (hasTribuneUser) {
					message = 'A tribuna está em andamento, solicite o encerramento da fala atual para iniciar a questão de ordem';
					buttonStyle = false;
					confirmText = 'OK';
				} else if (hasApartUser) {
					message = 'A tribuna está em andamento, solicite o encerramento do apartiamento atual para iniciar a questão de ordem';
					buttonStyle = false;
					confirmText = 'OK';
				} else if (hasExplanation) {
					message = 'Existe alguém falando nas explanações pessoais, solicite o encerramento para iniciar a questão de ordem';
					buttonStyle = false;
					confirmText = 'OK';
				} else if (hasDiscussion) {
					message = 'Existe uma discussão em andamento, solicite o encerramento da fala para iniciar a questão de ordem';
					buttonStyle = false;
					confirmText = 'OK';
				} else {
					message = 'Deseja realmente iniciar uma questão de ordem ?';
					buttonStyle = true;
					confirmText = 'SIM';
				}

				this.$swal.fire({
					title: message,
					showDenyButton: false,
					showCancelButton: buttonStyle,
					buttonsStyling: false,
					confirmButtonText: confirmText,
					cancelButtonText: 'VOLTAR',
					customClass: {
						confirmButton: 'btn btn-success mr-5 ml-1 text-lg',
						cancelButton: 'btn btn-secondary mr-1 ml-1 text-lg'
					},
				}).then((result) => {
					if (confirmText === 'OK') {
						this.Startvote_error_show();
					} else {
						if (result.isConfirmed) {
							this.Start();
						} else {
							this.Startvote_error_show();
						}
					}
                  
				});
			}
			catch {
				console.log('erro');
			}
		},


		Startvote_error_show() {
			this.$toast.add({ severity: 'error', summary: 'Ordem não inciada', detail: 'A ordem não foi iniciada!', life: 3000 });
		},
		StartQuestionOrdem_error_show() {
			this.$toast.add({ severity: 'error', summary: 'Questão de ordem não inciada', detail: 'A Questão de ordem não foi iniciada!', life: 3000 });
		},

		Success_QuestionOrdem() {
			this.$toast.add({severity:'success', summary: 'Questão de ordem iniciada', detail:'A Questão de ordem foi iniciada.', life: 3000});
		},

		show_error() {
			this.$toast.add({ severity: 'error', summary: this.header_error, detail: this.body_error, group: 'tl', life: 5000 });
		},
		show_success() {
			this.$toast.add({ severity: 'success', summary: this.header_success, detail: this.body_success, group: 'tl', life: 3000 });
		},

		async Start() {
			this.$refs.Loading.openModal();
			await AxiosAPI
				.post(
					'/question-orders',
					{
						quorum_id: this.StoreAll.quorum[0].id,
					},
					{
						headers: {
							authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}
				);
		},

		async Finish() {
			this.$refs.Loading.openModal();

			let message = '';
			let buttonStyle = false;
			let confirmText = '';

			try {
				let OrdemId = localStorage.getItem('QuestionOrdem');
				await AxiosAPI.get('/question-order-users?question_order_id=' + OrdemId, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken
					}
				}).then((response) => {
					const dataCheck = response.data.data;

					const hasOrdem = dataCheck.some(Check => Check.status_fala === '1');


					if (hasOrdem) {
						message = 'A questão de ordem está em andamento, encerre a fala atual para encerrar a questão de ordem';
						buttonStyle = false;
						confirmText = 'OK';
					} else {
						message = 'Deseja realmente encerrar a questão de ordem ?';
						buttonStyle = true;
						confirmText = 'SIM';
					}

					this.$refs.Loading.closeModal();

					this.$swal.fire({
						title: message,
						showDenyButton: false,
						showCancelButton: buttonStyle,
						buttonsStyling: false,
						confirmButtonText: confirmText,
						cancelButtonText: 'VOLTAR',
						customClass: {
							confirmButton: 'btn btn-success mr-5 ml-1 text-lg',
							cancelButton: 'btn btn-secondary mr-1 ml-1 text-lg'
						},
					}).then((result) => {
						if (confirmText === 'OK') {
							this.error_show();
						} else {
							if (result.isConfirmed) {
								this.Finishs();
							} else {
								this.error_show();
							}
						}
                  
					});
				}
				);
			}
			catch {
				console.log('erro');
			}
		},

		error_show() {
			this.$toast.add({ severity: 'error', summary: 'Questão de ordem não encerrada', detail: 'Questão de ordem não encerrada!', life: 3000 });
		},

		Finishs() {
			this.$refs.Loading.openModal();
			AxiosAPI
				.put(
					'/question-orders/'+localStorage.getItem('QuestionOrdem')+'?status=2',{},
					{
						headers: {
							authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}
				);
		},

		async send_users() {
			this.$refs.Loading.openModal();
			let OrdemId = localStorage.getItem('QuestionOrdem');
			localStorage.setItem('QuestionOrdem_user', this.QuestionOrdem_users);

			const formData = new FormData();
			formData.append('question_order_id', OrdemId);
			formData.append('user_id', this.QuestionOrdem_users);

			await AxiosAPI
				.post(
					'/question-order-users', formData,
					{
						headers: {
							authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}
				).then((resp) => {
					if (resp.status === 200) {
						this.QuestionOrdem_users = [];
						/* this.QuestionOrdem_user = false */
						this.QuestionOrdem_user_now();
						this.header_success = 'Fala iniciada!';
						this.body_success = 'Fala do vereador iniciada!';
						this.show_success();
						this.$refs.Loading.closeModal();
					}
				});

		},

		async QuestionOrdem_user_now() {
			let OrdemId = localStorage.getItem('QuestionOrdem');
			await AxiosAPI.get('/question-order-users?question_order_id=' + OrdemId, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((response) => {
				let user = [];

				for (let i = 0; i < response.data.data.length; i++) {
					if (response.data.data[i].status_fala != 1) {
						this.QuestionOrdem_user = true;
						this.talk_now = false;
					}
					else if (response.data.data[i].status_fala == 1) {
						user.push(response.data.data[i]);
						this.QuestionOrdem_user = false;
						this.talk_now = true;
					}
				}

				this.Vereador_QuestionOrdem_now = user;
			}
			);
		},

		async Stoptalk() {
			this.$refs.Loading.openModal();
			let OrdemId = localStorage.getItem('QuestionOrdem');
			let user_id = localStorage.getItem('QuestionOrdem_user');
			const formData = new FormData();
			formData.append('question_order_id', OrdemId);
			formData.append('user_id', user_id);
			formData.append('status_fala', 2);

			await AxiosAPI.post('/question-order-users-upp', formData, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((response) => {
				if (response.status === 200) {
					localStorage.removeItem('QuestionOrdem_user');
					this.header_success = 'Fala encerrada!';
					this.body_success = 'Fala do vereador encerrada!';
					this.show_success();
					this.QuestionOrdem_user_now();
					this.$refs.Loading.closeModal();
				}
			});
		},


	},
};
</script>
<template>
  <div style="z-index: 1010 !important;">
    <Loading ref="Loading"></Loading>
  </div>
  <div class="py-4 h-100 container-fluid">
    <div class="row">
      <div class="text-center col-12">
        <h3 class="mt-auto text-white">Controle da questão de ordem</h3>
        <h5 class="text-white font-weight-normal">
          Para solicitações de questão de ordem
        </h5>
        <div class="mb-5 multisteps-form">

          <div v-if="QuestionOrdem == false" class="row">
            <div class="m-auto col-12">
              <form class="multisteps-form__form">
                <div>
                  <div class="p-3 bg-white card multisteps-form__panel border-radius-xl js-active position-relative"
                    data-animation="FadeIn">
                    <div class="text-center row">
                      <div class="mx-auto col-10">
                        <div>
                          <button v-if="QuestionOrdem_buttom == 0" type="button" class="btn btn-primary bg-success"
                            style="width: 100%; height: 50px;" @click="check()"> INICIAR UMA QUESTÃO DE ORDEM </button> <!-- @click="Start()" -->
                          <button v-if="QuestionOrdem_buttom === 1 && QuestionOrdem_user" type="button" class="btn btn-primary bg-danger"
                            style="width: 100%; height: 50px;" @click="Finish()"> ENCERRAR QUESTÃO DE ORDEM </button>

                          <div v-if="QuestionOrdem_on">
                            <div v-if="QuestionOrdem_user" class="mt-5">
                              <div class="d-flex justify-content-center">
                                <span style="font-size: 20px;" class="font-weight-bolder"> Usuário atual: &nbsp;
                                  Aguarde...
                                </span>
                              </div>

                              <div class="d-flex justify-content-center">
                                <button type="button" class="btn btn-primary bg-success"
                                  style="width: 100%; height: 50px;" data-dismiss="modal" data-toggle="modal"
                                  data-target=".QuestionOrdem">
                                  SELECIONAR VEREADOR
                                </button>
                              </div>
                            </div>

                            <div v-if="!QuestionOrdem_user" class="mb-2" style="border-style: double;">
                              <div class="d-flex justify-content-center mb-3">
                                <span style="font-size: 20px;" class="font-weight-bolder"> Questão de ordem
                                </span>
                              </div>

                              <div class="d-flex justify-content-center">
                                <img :src="this.StoreAll.Dochost+Vereador_QuestionOrdem_now[0].user.path_image"
								class="rounded-circle justify-content-start" style="object-fit: cover; width: 150px" alt="user photo" />
                              </div>

                              <div class="d-flex justify-content-center">
                                <span style="font-size: 20px;" class="font-weight-bolder"> Usuário atual: &nbsp;
                                  {{ Vereador_QuestionOrdem_now[0].user.nickname }}
                                </span>
                              </div>

                              <div v-if="talk_now">
                                <div class="d-flex justify-content-center">
                                  <span v-if="Vereador_QuestionOrdem_now[0].status_fala_big_discussion == 1"
                                    style="font-size: 20px;" class="font-weight-bolder"> Status: &nbsp; Falando
                                  </span>
                                </div>
                                <div class="d-flex justify-content-center">
                                  <button id="fim_discut" style="width: 100%; height: 50px; font-size: 20px;"
                                    type="button" class="justify-content-center btn btn-primary bg-gray-600"
                                    @click="Stoptalk()">
                                    Encerrar fala do vereador
                                  </button>
                                </div>

                              </div>

                              <div v-if="!talk_now">
                                <span style="font-size: 20px;" class="font-weight-bolder"> Status: &nbsp; Aguarde...
                                </span>
                              </div>

                            </div>
                          </div>
                          <div v-if="!QuestionOrdem_on">

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="modal fade bd-example-modal-lg-startmodal QuestionOrdem" tabindex="-1" role="dialog"
      aria-labelledby="myLargeModalLabel" aria-hidden="true" style="z-index: 10000">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">
              Questão de ordem
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label style="font-size: 18px">
              <b> Marque vereador que deseja discutir o documento. </b>
            </label>
            <div v-for="(vereadorr, index) in vereadores" :key="vereadorr.id" class="mt" style="display: flex">
              <input :key="index" v-model="QuestionOrdem_users" :value="vereadorr.id" class="mr" type="checkbox" style="width: 1.7rem; height: 1.7rem"
                />
              <label style="font-size: 18px">{{ vereadorr.name }}</label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Voltar
            </button>
            <button type="button" class="btn btn-primary bg-success" data-dismiss="modal" @click="send_users()">
              Enviar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal orador" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Orador</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="d-flex justify-content-center text-black">
              <p>O usuário é o primeiro orador ?</p>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button type="button" class="btn btn-success" data-dismiss="modal"
              data-toggle="modal" data-target=".Discut_conf" @click="Checks_vote(); Orador(1);">Sim</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal"
              data-toggle="modal" data-target=".Discut_conf" @click="Checks_vote(); Orador(2);">Não</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.close {
  font-size: 2.3rem !important;
}
</style>
