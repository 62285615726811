<template>
    <div>
        <ModalBigger ref="viewDocument"  style="z-index: 1000;">
            <template #header>
              <div class="d-flex justify-content-between">
                <strong><span> Assinatura de documento </span> </strong>

                <div class="flex-start">
                    <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
              </div>
            </template>

            <template #body>

                <div v-if="signer_status == 1" class="d-flex justify-content-center">
                    <div id="signer" style='width: 100%;height: 700px'></div>
                </div>

               <div v-if="signer_status == 2" class="d-flex justify-content-center">
                        <div id="signer_lote" style='width: 100%;height: 700px'></div>
                      </div> 

            </template>

            <template #footer>
            
            </template>
        </ModalBigger>

    </div>
</template>
    
<script>
import { AxiosAPI } from "@/axios";
import ModalBigger from "./ModalBig.vue";
export default {
    components: {
        ModalBigger,
    },

    data() {
        return {
        signer_status: null,
        prefix_c: '',
        SignerDocs: [],
        key: '',
        documento_src: '',
        storage_name: '',
        category_id: '',
        document_id: 0,
        session_id: 'realizadass',
        selected_products: null,
        key: null
        }
    },

    methods: {
        openModal() {
            this.signer_status = 1
            this.pref(1);
            this.$refs.viewDocument.openModal();
        },

        openModal_lote() {
            this.signer_status = 2
            this.pref(2);
            this.$refs.viewDocument.openModal();
        },

    async pref(value){
        let prefix = ''
    if(this.$pinia.state.value.main.host == 'dev'){
        prefix = "dev"
      }else if(this.$pinia.state.value.main.host == 'teste'){
        prefix = "teste"
      }else if(this.$pinia.state.value.main.host == 'jc'){
        prefix = "jc"
      }else if(this.$pinia.state.value.main.host == 'bodo'){
        prefix = "bd"
      }else if(this.$pinia.state.value.main.host == 'pedroavelino'){
        prefix = "pa"
      }else if(this.$pinia.state.value.main.host == 'pedrapreta'){
        prefix = "pp"
      }else if(this.$pinia.state.value.main.host == 'campogrande'){
        prefix = "cg"
      }else if(this.$pinia.state.value.main.host == 'parazinho'){
        prefix = "parazinho"
      }else if(this.$pinia.state.value.main.host == 'local'){
        prefix = "dev"
      }else if(this.$pinia.state.value.main.host == 'jandaira'){
        prefix = "jd"
      }else if(this.$pinia.state.value.main.host == 'triunfo'){
        prefix = "tp"
      }else if(this.$pinia.state.value.main.host == 'upanema'){
        prefix = "upanema"
      }else if(this.$pinia.state.value.main.host == 'ja'){
        prefix = "ja"
      }else if(this.$pinia.state.value.main.host == 'angicos'){
        prefix = "angicos"
      }else if(this.$pinia.state.value.main.host == 'goianinha'){
        prefix = "goianinha"
      }else if(this.$pinia.state.value.main.host == 'crv'){
        prefix = "crv"
      }else if(this.$pinia.state.value.main.host == 'jucurutu'){
				prefix = "jucurutu"
			}else if(this.$pinia.state.value.main.host == 'odb'){
				prefix = "odb"
			}else if(this.$pinia.state.value.main.host == 'sm'){
				prefix = "sm"
			}else if(this.$pinia.state.value.main.host == 'lp'){
				prefix = "lp"
			}else if(this.$pinia.state.value.main.host == 'sr'){
				prefix = "sr"
			}else if(this.$pinia.state.value.main.host == 'bf'){
				prefix = "bf"
			}else if(this.$pinia.state.value.main.host == 'sbn'){
				prefix = "sbn"
			}else if(this.$pinia.state.value.main.host == 'pureza'){
				prefix = "pureza"
			}else if(this.$pinia.state.value.main.host == 'abapp'){
				prefix = "abapp"
			}

      this.prefix_c = prefix
       if(value == 1){
    this.key = this.$pinia.state.value.main.document_details.request_signature_key
    var verificarElemento = setInterval(() => {
        if (document.getElementById('signer') !== null) {
            clearInterval(verificarElemento);
            this.signer();
            this.Document_att();
            }
       }, 500); //Nessa função, enquanto signer for null a função não é executada.
      }else{
        var verificarElemento = setInterval(() => {
        if (document.getElementById('signer_lote') !== null) {
            clearInterval(verificarElemento);
            this.signer_lote();
            this.Document_att();
            }
       }, 500);
      }; 
      
      },

        Document_att(){
        setTimeout(()=>{ this.Document_att()}, 4000);
        if(localStorage.getItem('ClickSing') == 'Ok'){
          this.emitter.emit("Assinado_comission", 1);
          this.showCenter();
          localStorage.removeItem('ClickSing')
        }else{
          return
        }
    },

    async signer(){
      let camara = this.prefix_c
      let ClickSign_token = this.$pinia.state.value.main.accessToken
      let Document_id = this.$pinia.state.value.main.document_details.id
      let Document_name = this.$pinia.state.value.main.document_details.name
      let Document_category = this.$pinia.state.value.main.document_details.document_category_id
      function Clicksign(u){"use strict";var n,i,c="https://app.clicksign.com",s=window.location.protocol+"//"+window.location.host,r={},l=function(t){var e="/sign/"+u,f="?embedded=true&origin="+this.origin,h=this.endpoint+e+f;return i=document.getElementById(t),n=document.createElement("iframe"),n.setAttribute("src",h),n.setAttribute("style","width: 100%; height: 100%;"),n.setAttribute("allow","camera;geolocation;fullscreen;gyroscope;accelerometer;magnetometer"),window.addEventListener("message",a),i.appendChild(n)},m=function(t){return t.name||t},d=function(t,e){return r[t]||(r[t]=[]),r[t].push(e)},o=function(t){(r[m(t)]||[]).forEach(function(e){e(t.data)})},a=function(t){o(t.data)},g=function(){return n&&(i.removeChild(n),i=null,n=null,window.removeEventListener("message",a)),!0};return{endpoint:c,origin:s,mount:l,unmount:g,on:d,trigger:o}}globalThis.Clicksign=Clicksign;
  					 
            var widget = new Clicksign(this.key);
            widget.endpoint = "https://app.clicksign.com";
            widget.origin =  'https://'+camara+'.smartlegis.net.br/'

            widget.mount("signer");
            widget.on('loaded', function(ev) {})
            widget.on('signed', function(ev) { 
              AxiosAPI
                .get("/documents-notification", {
                  headers: {
                    Authorization: `Bearer `+ ClickSign_token,
                  },
                })
                 .then(() => {

              AxiosAPI.post('/update_document?document_id=' + Document_id + '&name=' + Document_name + '&document_category_id=' + Document_category + '&status_sign= 2',{},
                  {
                    headers: {
                      authorization: `Bearer `+ ClickSign_token,
                    },
                  }
                ).then(()=>{
                  setTimeout(()=>{ localStorage.setItem('ClickSing', 'Ok')}, 500); //Aqui eu coloco em 1 segundo e quando atualizar o documento vai de forma alto;
                })
                                   
                });
                
            });

            widget.on("resized", function (height) {
              document.getElementById("signer").style.height = height + "px";
            });
            this.DownloadSigner()
            },  

    signer_lote(){
      let camara_lote = this.prefix_c
      let ClickSign_token = this.$pinia.state.value.main.accessToken

      const documents_id = []
      for (let i = 0; i < this.$pinia.state.value.main.MultiSelectDocs.length; i++) {
      documents_id.push(this.$pinia.state.value.main.MultiSelectDocs[i].id)
    
    }

      const dados = {
      user_id: this.$pinia.state.value.main.user.id,
      comission_document_id: documents_id

    }

      AxiosAPI.post('/parecer-batch', dados,
            {
              headers: {
                authorization: `Bearer `+ this.$pinia.state.value.main.accessToken,
              },
            }
          ).then((resp)=>{
            const key_doc = resp.data.data.batch.request_signature_key

            function Clicksign(u){"use strict";var n,i,c="https://app.clicksign.com",s=window.location.protocol+"//"+window.location.host,r={},l=function(t){var e="/sign/"+u,f="?embedded=true&origin="+this.origin,h=this.endpoint+e+f;return i=document.getElementById(t),n=document.createElement("iframe"),n.setAttribute("src",h),n.setAttribute("style","width: 100%; height: 100%;"),n.setAttribute("allow","camera;geolocation;fullscreen;gyroscope;accelerometer;magnetometer"),window.addEventListener("message",a),i.appendChild(n)},m=function(t){return t.name||t},d=function(t,e){return r[t]||(r[t]=[]),r[t].push(e)},o=function(t){(r[m(t)]||[]).forEach(function(e){e(t.data)})},a=function(t){o(t.data)},g=function(){return n&&(i.removeChild(n),i=null,n=null,window.removeEventListener("message",a)),!0};return{endpoint:c,origin:s,mount:l,unmount:g,on:d,trigger:o}}globalThis.Clicksign=Clicksign;
  					
      var widget = new Clicksign(key_doc);
      widget.endpoint = "https://app.clicksign.com";
      widget.origin = 'https://'+camara_lote+'.smartlegis.net.br/';

      widget.mount("signer_lote");
      widget.on('loaded', function(ev) {})
      widget.on('signed', function(ev) { 

        const status_change = {
          document_id: documents_id,
          status_sign: 2,
    }

        AxiosAPI.post('/update_document_signer',status_change,
            {
              headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer `+ ClickSign_token,
              },
            }
          ).then(()=>{
            setTimeout(()=>{ localStorage.setItem('ClickSing', 'Ok')}, 500); //Aqui eu coloco em 1 segundo e quando atualizar o documento vai de forma alto;
          })
                             
          });
          
      widget.on("resized", function (height) {
        document.getElementById("signer_doc_lote").style.height = height + "px";
      });

    });
          
},  

removes(){ localStorage.removeItem('view'); localStorage.removeItem('documento_session_rec');localStorage.removeItem('documento_state_rec');localStorage.removeItem('documento_id_rec');localStorage.removeItem('documento_name_rec'); localStorage.removeItem('documento_src_rec'); this.documento_name_recs = 'Carregando...', this.documento_src_recs = ''},
      

      showCenter() {
              this.$toast.add({severity: 'info', summary: 'Documento assinado', detail: "Agora aguarde alguns segundo e seu documento será atualizado, você pode acompanhar em Meus Documentos enviados", group: 'tl', life: 8000});
          },
    }
}


</script>
    
<style></style>