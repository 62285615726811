import { createApp } from "vue";
import { createPinia } from 'pinia'
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import ArgonDashboard from "./argon-dashboard";
import mitt from "mitt";
import PrimeVue from 'primevue/config';
import Tag from 'primevue/tag';
import Image from 'primevue/image';
import ProgressSpinner from 'primevue/progressspinner';
import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/mdc-light-indigo/theme.css'
import DataTable from 'primevue/datatable';
import Button from 'primevue/button';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row'; 
import InputText from 'primevue/inputtext';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import MultiSelect from 'primevue/multiselect';
import TreeTable from 'primevue/treetable';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import 'primeicons/primeicons.css'
import 'primevue/resources/primevue.min.css';
library.add(faUserSecret)

 import Echo from 'laravel-echo';

 import Pusher from 'pusher-js';



const url = window.location.href;
const startIndex = url.indexOf('https://') + 8;
const pathIndex = url.indexOf('/', startIndex);
const result = url.substring(startIndex, pathIndex);

/* let base = '' */
let base_host = 0

if(result == 'dev.smartlegis.net.br'){
  base_host = 3030 //3030 bicause port 6000 don´t work...
}else if(result == 'localhost:8080'){
  base_host = 3030 //3030 bicause port 6000 don´t work...
}else if(result == '192.168.0.100:8080'){
  base_host = 3030 //3030 bicause port 6000 don´t work...
}else{
  base_host = 6001
}


 window.Pusher = Pusher;

 window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'local',
    wsHost: 'sandbox.smartlegis.net.br',
    wssHost: 'sandbox.smartlegis.net.br',
    wssPort: base_host,
    wsPort: base_host,
    authEndpoint: 'https://sandbox.smartlegis.net.br/broadcasting/auth/',
    encrypted:true,
    forceTLS: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
 });


const appInstance = createApp(App);
const pinia = createPinia()
appInstance.use(pinia);
appInstance.use(PrimeVue);
appInstance.use(ToastService);
appInstance.component('FontAwesomeIcon', FontAwesomeIcon);
appInstance.component('DataTable', DataTable);
appInstance.component('Tag', Tag);
appInstance.component('spinnerprime', ProgressSpinner);
appInstance.component('MultiSelectPrime', MultiSelect);
appInstance.component('Column', Column);
appInstance.component('ColumnGroup', ColumnGroup);
appInstance.component('InputText', InputText);
appInstance.component('Row', Row);
appInstance.component('Buttonprime', Button);
appInstance.component('Toast', Toast);
appInstance.component('TreeTable', TreeTable);
appInstance.component('ImagePrime', Image);
const emitter = mitt();
appInstance.config.globalProperties.emitter = emitter;
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(ArgonDashboard);
appInstance.mount("#app");
