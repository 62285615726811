<script>
import ModalBig from './ModalBig.vue';
import { AxiosAPI, SocketAPI } from '@/axios';
import { useMainStore } from '../../../stores/index';
import { useBusStore } from '../../../stores/index';
import Modal_view_Parecer from '../Modals/Modal_View_Parecer.vue';
import Modal_view_Stamp from '../Modals/Modal_View_Stamp.vue';
import Modal_Second_Vote from '../Modals/Modal_Second_vote.vue';
import Modal_View_Send_VoteNominal from '../Modals/Modal_View_VoteNominal.vue';
import Modal_Result_vote from '../Modals/Modal_Result_vote.vue';
import Modal_alert from '../Modals/Modal_alert.vue';
import Modal_view_Discut from '../Modals/Modal_View_Discut.vue';
import Anexo_Attach from '../Modals/Modal_anexo_attachment.vue';
import Anexo from '../Modals/Modal_Anexo_Parecer.vue';
import Viwerjs from './Viwerjs.vue';
import Loading from '../Modals/Loading.vue';
import { saveAs } from 'file-saver';
export default {
	components: {
		ModalBig,
		Modal_view_Parecer,
		Modal_view_Stamp,
		Modal_Second_Vote,
		Modal_View_Send_VoteNominal,
		Viwerjs,
		Modal_Result_vote,
		Modal_alert,
		Loading,
		Modal_view_Discut,
		Anexo,
		Anexo_Attach
	},

	setup() {
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		return {
			StoreAll: StoreAll,
			Bus
		};
	},

	data() {
		return {
			usersq: {},
			status_vote_doc: 'Aguarde ...',
			status_doc_vote: 1,
			vereados_off_quorum: {},
			user_vote_teste: {},
			chartData: [],
			chartOptions: {
				legend: { position: 'none' },
				height: 350,
			},
			onlypdf: '',
			unique_vote: null,
			voted_on: null,
			tribune_on: null,
			explanation_on: null,
			discut_on: null,
			Docs_recp: []
		};
	},

	watch: {
		'Bus.AxexoSuccess': function (newValue, oldPath) {
			if (newValue === true) {
				this.Bus.AxexoSuccess = null;
				this.Bus.AxexoSecretario = true;
				this.$refs.viewDocument.closeModal();
			}
		},

		'Bus.RemoveParecerModalView': function (newValue, oldPath) {
			if (newValue === true) {
				this.Bus.RemoveParecerModalView = null;
				this.$refs.viewDocument.closeModal();
			}
		},
	},

	methods: {
		openModal2(event) {
			this.StoreAll.parecershow = false;
			this.getVotes();
			this.getParecer();
			this.$refs.viewDocument.openModal();
			this.Docs_recp = event;
		},

		openModal_secretario() {
			this.$refs.Anexo.openModal_secretario(this.Docs_recp);
		},

		openModal_attach() {
			this.$refs.Anexo_Attach.openModal(this.Docs_recp);
		},

		DiscutStatus(value) {
			this.$refs.Modal_view_Discut.StatusDisc(value);
		},
		Discut() {
			this.$refs.Modal_view_Discut.openModal(this.StoreAll.document_details);
		},

		async CheckVote(value) {

			this.$refs.Loading.openModal();
			let message = '';
			let buttonStyle = false;
			let confirmText = '';

			try {
				const dataCheck = await this.StoreAll.VerifySession();

				const hasVote = dataCheck.some(Check =>
					Check.session.documents.some(VoteCheck => VoteCheck)
				);

				const hasTribune = dataCheck.some(Check =>
					Check.tribunes.some(TribuneCheck => TribuneCheck.status_tribune === '1' || TribuneCheck.status_tribune === '2')
				);

				const hasDiscussion = dataCheck.some(Check =>
					Check.discussions.some(DiscussionCheck => DiscussionCheck.status_discussion === '1')
				);

				const hasExplanation = dataCheck.some(Check =>
					Check.big_discussions.some(ExplanationCheck => ExplanationCheck.status_big_discussion === '1')
				);

				if (hasTribune) {
					message = 'A tribuna está em andamento, solicite o encerramento para iniciar a votação';
					buttonStyle = false;
					confirmText = 'OK';
				} else if (hasExplanation) {
					message = 'As explicações pessoais estão em andamento, solicite o encerramento para iniciar a votação';
					buttonStyle = false;
					confirmText = 'OK';
				} else if (hasDiscussion) {
					message = 'Existe uma discussão em andamento, solicite o encerramento para iniciar a votação';
					buttonStyle = false;
					confirmText = 'OK';
				} else if (hasVote) {
					message = 'Existe um document em votação, finalize para iniciar a nova votação';
					buttonStyle = false;
					confirmText = 'OK';
				} else {
					this.$refs.Loading.closeModal();
					message = 'Deseja iniciar a votação desse documento?';
					buttonStyle = true;
					confirmText = 'SIM';
				}

				this.$refs.Loading.closeModal();

				this.$swal.fire({
					title: message,
					showDenyButton: false,
					showCancelButton: buttonStyle,
					buttonsStyling: false,
					confirmButtonText: confirmText,
					cancelButtonText: 'VOLTAR',
					customClass: {
						confirmButton: 'btn btn-success mr-5 ml-1 text-lg',
						cancelButton: 'btn btn-secondary mr-1 ml-1 text-lg'
					},
				}).then((result) => {
					if (confirmText === 'OK') {
						this.Startvote_error_show();
					} else {
						this.StoreAll.UserAll();
						if (result.isConfirmed) {
							const start_vote = {
								document_id: [this.StoreAll.document_details.id],
								document_status_vote_id: 4,
								user_id: this.StoreAll.user.id
							};

							if (value === 2) {
								AxiosAPI.patch('/session/' + this.StoreAll.session.id + '/document/' + this.StoreAll.document_details.id,
									{
										secret_vote: true
									}, {
										headers: {
											'Content-Type': 'application/json',
											authorization: 'Bearer ' + this.StoreAll.accessToken,
										}
									}).then((response) => {
									console.log(response);

									SocketAPI.post('/document/change-status', start_vote, {
										headers: {
											'x-tenant': this.StoreAll.voteSocket,
											'Content-Type': 'application/json',
											authorization: 'Bearer ' + this.StoreAll.socketToken,
										}
									}).then((response) => {
										this.document = response.data.data;
										this.document_status_vote_id = 4,
										this.startvote_show();
										this.$refs.viewDocument.closeModal();
									});
								});
							} else {
								SocketAPI.post('/document/change-status', start_vote, {
									headers: {
										'x-tenant': this.StoreAll.voteSocket,
										'Content-Type': 'application/json',
										authorization: 'Bearer ' + this.StoreAll.socketToken,
									}
								}).then((response) => {
									this.document = response.data.data;
									this.document_status_vote_id = 4,
									this.startvote_show();
									this.$refs.viewDocument.closeModal();
								});
							}
						} else {
							this.Startvote_error_show();
						}
					}
				});

			} catch (erro) {
				console.error('Erro ao despachar ação:', erro);
			}
		},

		startvote_show() {
			this.$toast.add({ severity: 'success', summary: 'Documento em votação', detail: 'O documento está em votação agora!', life: 3000 });
		},
		showSuccess_send() {
			this.$toast.add({ severity: 'success', summary: 'Documento enviado ao secretário', detail: 'O documento foi enviado com sucesso', life: 3000 });
		},
		showError_send() {
			this.$toast.add({ severity: 'error', summary: 'Erro ao enviado ao secretário', detail: 'O documento selecionado não foi enviado ao secretário', life: 3000 });
		},
		Startvote_error_show() {
			this.$toast.add({ severity: 'error', summary: 'Votação não inciada', detail: 'A votação não foi iniciada!', life: 3000 });
		},
		Show_getParecer() {
			this.$refs.Modal_view_Parecer.openModal();
		},
		Show_votes() {
			this.$refs.Modal_Result_vote.openModal();
		},
		not_move() {
			this.$toast.add({ severity: 'error', summary: 'Ação não realizada', detail: 'Nada foi feito!', life: 3000 });
		},
		ShowCarimbo(value) {
			if (this.StoreAll.document_details.status_sign === 0) {
				this.$swal.fire({
					title: 'Documento não pode ser carimbado!',
					text: 'Assinaturas pendentes',
					icon: 'info',
					confirmButtonText: 'Ok'
				});
			} else {
				if (value == 1) {
					this.$refs.Modal_view_Stamp.openModal();
					this.$refs.viewDocument.closeModal();
				} else if (value == 3) {
					this.$refs.Modal_alert.openModal();
					this.$refs.viewDocument.closeModal();
				} else {
					this.$refs.Modal_view_Stamp.openModal2();
					this.$refs.viewDocument.closeModal();
				}
			}

		},
		ShowSecondVote() {
			this.$refs.Modal_Second_Vote.openModal();
			this.$refs.viewDocument.closeModal();
		},
		Show_NominalVote() {
			this.$refs.Modal_View_Send_VoteNominal.openModal();
			this.$refs.viewDocument.closeModal();
		},
		async send_secret() {
			this.$swal.fire({
				title: 'Deseja remover o documento da sessão atual também ?',
				showDenyButton: true,
				showCancelButton: true,
				buttonsStyling: false,
				confirmButtonText: 'Sim, remover da sessão e enviar para secretário',
				denyButtonText: 'Não, apenas enviar para secretário',
				cancelButtonText: 'Cancelar ação',
				customClass: {
					confirmButton: 'btn btn-success mr-2 ml-1 text-lg',
					denyButton: 'btn btn-danger mr-2 ml-1 text-lg',
					cancelButton: 'btn btn-primary mr-1 ml-1 text-lg'
				},
			}).then((result) => {
				if (result.isConfirmed) {

					AxiosAPI.delete('/detach-document?document_id=' + this.StoreAll.document_details.id + '&session_id=' + this.StoreAll.session.id + '&document_status_movement_id=1', {
						headers: {
							Authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					})
						.then((resp) => {
							if (resp.status === 200) {
								this.Bus.docSendSession = true;
								this.showSuccess_send();
								this.$refs.viewDocument.closeModal();
							} else {
								this.Bus.docSendSession = true;
								this.showError_send();
								this.$refs.viewDocument.closeModal();
							}
						});


				} else if (result.isDenied) {

					AxiosAPI.post('/detach-and-send-document?document_id=' + this.StoreAll.document_details.id + '&document_status_movement_id=1', {}, {
						headers: {
							Authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					})
						.then((resp) => {
							if (resp.status === 200) {
								this.Bus.docSendSession = true;
								this.showSuccess_send();
								this.$refs.viewDocument.closeModal();
							} else {
								this.Bus.docSendSession = true;
								this.showError_send();
								this.$refs.viewDocument.closeModal();
							}
						});

				} else if (result.dismiss == 'cancel') {

					this.not_move();

				} else {
					this.not_move();
				}
			});

		},

		send_secret_voted() {
			AxiosAPI.post('/detach-and-send-document?document_id=' + this.StoreAll.document_details.id + '&document_status_movement_id=1', {}, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken,
				},
			})
				.then((resp) => {
					if (resp.status === 200) {
						this.Bus.docSendSession = true;
						this.showSuccess_send();
						this.$refs.viewDocument.closeModal();
					} else {
						this.Bus.docSendSession = true;
						this.showError_send();
						this.$refs.viewDocument.closeModal();
					}
				});
		},

		async FindUsers() {
			if (this.StoreAll.quorum == null) {
				return;
			} else if (this.StoreAll.quorum.length <= 0) {
				return;
			} else {
				let NewUser = [];
				let Users_Quorum_Now = [];

				for (let i = 0; i < this.StoreAll.user_all.length; i++) {
					NewUser.push(this.StoreAll.user_all[i]);
				}

				await AxiosAPI.get('/quorum-users?quorum_id=' + this.StoreAll.quorum[0].id, {
					headers: {
						authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				}).then((resp) => {

					this.usersq = resp.data.data;

					this.array_popule();
					for (let i = 0; i < resp.data.data.length; i++) {

						Users_Quorum_Now.push(resp.data.data[i].user_id);
					}
					this.NewInQuorum();
				});

			}
		},

		array_popule() {
			let date = [];
			for (let i = 0; i < this.usersq.length; i++) {
				date.push({ user_id: this.usersq[i].user.id, user_name: this.usersq[i].user.nickname, user_photo: this.StoreAll.Dochost + this.usersq[i].user.path_image, vote_category_id: null, voto_nominal: 1 });
			}
			this.user_vote_teste = date;
		},

		NewInQuorum() {
			let array1 = this.StoreAll.vereadores;
			let array2 = this.usersq;

			const array3 = array1.filter(item1 => {
				const foundItem2 = array2.find(item2 => item1.id === item2.user_id);
				return !foundItem2;
			}).map(item => ({
				id: item.id,
				name: item.name,
				nickname: item.nickname
			}));

			this.vereados_off_quorum = array3;
		},



		graphic() {
			let favor = this.StoreAll.favorable;
			let contra = this.StoreAll.against;
			let absten = this.StoreAll.vista;
			this.chartData = [
				['Voto', '', { role: 'style' }],
				['Favoraveis', favor, '#20875b'],
				['Contras', contra, 'red'],
				['Abstenções', absten, 'silver'],
			];
		},

		async getVotes() {
			this.unique_vote = null;
			await AxiosAPI.get('/votes?document_id=' + this.StoreAll.document_details.id, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((response) => {
				let arraydata = response.data.data;
				this.StoreAll.votes = arraydata;
				let data_array = [];
				let favorable = 0, against = 0, vista = 0;
				for (let i = 0; i < arraydata.length; i++) {
					let obj = {};
					obj.vote_category_id = arraydata[i].vote_category_id;
					obj.user_id = arraydata[i].user_id;
					data_array.push(obj);
					switch (obj.vote_category_id) {
					case 1:
						favorable++;
						break;
					case 2:
						against++;
						break;
					case 3:
						vista++;
						break;
					default:
						console.log('ERRO! TIPO DE VOTO NÃO RECONHECIDO' + favorable + against + vista);
					}
				}
				/* Logica para caso não possua segunda votação ainda*/

				for (let i = 0; i < arraydata.length; i++) {
					if (arraydata[i].order === 2) {
						this.unique_vote = true;
					}
				}

				this.StoreAll.favorable = favorable;
				this.StoreAll.against = against;
				this.StoreAll.vista = vista;


				if (this.StoreAll.favorable > this.StoreAll.against) {
					this.status_vote_doc = 'Aprovado';
				} else if (this.StoreAll.against > this.StoreAll.favorable) {
					this.status_vote_doc = 'Reprovado';
				} else if (this.StoreAll.favorable == 0 && this.StoreAll.against == 0) {
					this.status_vote_doc = 'Aguarde ...';
				} else {
					this.status_vote_doc = 'Aguarde ...';
				}

				if (response.data.data.length >= 1) {
					if (response.data.data[0].document_session !== null) {
						this.StoreAll.result_secret = response.data.data[0].document_session.secret_vote;
					}
				}
				this.graphic();

			});/* } */
		},

		/* async Document_download(){
            const pdf = await fetch(this.StoreAll.document_src)
            const blob = await pdf.blob();
            saveAs(blob, 'Documento' + ".pdf")
        }, */

		async Document_64() {
			await AxiosAPI.get('/document/' + this.StoreAll.document_details.id + '/votes', {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken,
				},
			})
				.then((response) => {
					this.onlypdf = 'data:application/pdf;base64,' + response.data.data;
					this.Pdf_Download();
				});
		},

		async Pdf_Download() {
			const pdf = await fetch(this.onlypdf);
			const blob = await pdf.blob();
			saveAs(blob, 'Documento_Completo' + '.pdf');
		},

		async getParecer() {
			let parecer = {
				1: [], // education
				2: [], // farm
				3: [], // infra
				4: [], // health
				5: [], // human
				6: []  // justice
			};

			if (this.StoreAll.parecer_procurador_pdf == null) {
				this.StoreAll.parecershow = false;
			} else {
				this.StoreAll.parecershow = true;
			}

			await AxiosAPI
				.get('/parecer-comissao?document_id=' + this.StoreAll.document_details.id, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				})
				.then((response) => {
					for (let i = 0; i < response.data.parecer.length; i++) {
						const comission_id = response.data.parecer[i].comission_id;
						if (parecer[comission_id]) {
							parecer[comission_id].push(response.data.parecer[i]);
						}
					}

					let parecer_education = parecer[1][0]?.parecer_pdf || null;
					let parecer_farm = parecer[2][0]?.parecer_pdf || null;
					let parecer_infra = parecer[3][0]?.parecer_pdf || null;
					let parecer_health = parecer[4][0]?.parecer_pdf || null;
					let parecer_human = parecer[5][0]?.parecer_pdf || null;
					let parecer_justice = parecer[6][0]?.parecer_pdf || null;

					if (parecer_education != null) { this.StoreAll.parecershow = true; }
					if (parecer_farm != null) { this.StoreAll.parecershow = true; }
					if (parecer_infra != null) { this.StoreAll.parecershow = true; }
					if (parecer_health != null) { this.StoreAll.parecershow = true; }
					if (parecer_human != null) { this.StoreAll.parecershow = true; }
					if (parecer_justice != null) { this.StoreAll.parecershow = true; }


				})
				.catch((error) => {
					console.error(error);
				});
		},

	}
};
</script>
<template>
    <div>
        <Modal_view_Parecer ref="Modal_view_Parecer"></Modal_view_Parecer>
        <Modal_view_Stamp ref="Modal_view_Stamp"></Modal_view_Stamp>
        <Modal_View_Send_VoteNominal ref="Modal_View_Send_VoteNominal"></Modal_View_Send_VoteNominal>
        <Modal_Result_vote ref="Modal_Result_vote"></Modal_Result_vote>
        <Modal_alert ref="Modal_alert"></Modal_alert>
        <Modal_Second_Vote ref="Modal_Second_Vote"></Modal_Second_Vote>
        <Loading ref="Loading"></Loading>
        <Modal_view_Discut ref="Modal_view_Discut"></Modal_view_Discut>
        <Anexo ref="Anexo"></Anexo>
        <Anexo_Attach ref="Anexo_Attach"></Anexo_Attach>

        <ModalBig ref="viewDocument">
          <!--  eslint-disable-next-line vue/no-useless-template-attributes -->
            <template #header style="display: block !important;">
                <div class="start">
                    <div class="d-flex justify-content-between">
                        <div>
                            <strong>
                                <span>{{ this.StoreAll.document_details.name }}: </span>
                                <span v-if="this.StoreAll.document_details.document_status_vote_id == 1"
                                    style="color:red">Aguardando movimentação</span>
                                <span v-if="this.StoreAll.document_details.document_status_vote_id == 2"
                                    style="color:red">Aguardando votação</span>
                                <span v-if="this.StoreAll.document_details.document_status_vote_id == 4"
                                    style="color:red">Em votação</span>
                                <span v-if="this.StoreAll.document_details.document_status_vote_id == 5"
                                    style="color:red">Votação concluída</span>
                                <span v-if="this.StoreAll.document_details.document_status_vote_id == 6"
                                    style="color:red">Leitura no expediente</span>
                            </strong>
                        </div>
                        <div class="">
                            <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="this.StoreAll.document_details.document_status_vote_id == 5">
                    <div class="end d-flex">
                        <div class="d-flex justify-content-start" style="float: right; width: 50%;">
                            <button v-if="this.StoreAll.document_details.document_status_vote_id == 5"
                                type="button" class="btn btn-success" @click="Show_votes()">
                                Ver resultado <i class="ml-2 fa fa-check-circle"></i>
                            </button>
                            <span v-if="this.StoreAll.document_details.document_status_vote_id == 5"
                                class="mt-3 ml-2"> <strong> Situação: </strong></span>
                            <span v-if="this.StoreAll.document_details.document_status_vote_id == 5"
                                class="mt-3 ml-2"
                                :class="{ 'text-success': status_vote_doc == 'Aprovado', 'text-danger': status_vote_doc == 'Reprovado', 'text-dark': status_vote_doc == 'Aguarde ...' }">
                                <strong>{{ status_vote_doc }}</strong></span>
                        </div>
                        <div class="d-flex justify-content-end" style="float: left; width: 50%;">
                            <button v-if="this.StoreAll.parecershow === true" type="button"
                                class="btn btn-success " @click="Show_getParecer()">
                                Ver parecer <i class="ml-2 fa fa-file-import"></i>
                            </button>

                        </div>

                    </div>
                    <button
                        v-if="this.StoreAll.document_details.document_status_vote_id >= 5 && this.StoreAll.document_details.document_status_vote_id != 6"
                        type="button" class="btn text-white" @click="Document_64();"
                        style="background-color: #49a571;">Baixar documento completo</button>
                    <a v-if="this.StoreAll.document_details.document_status_vote_id < 5 || this.StoreAll.document_details.document_status_vote_id == 6"
                        target="_blank"
                        :href="this.StoreAll.Dochost + this.StoreAll.document_src"
                        download="proposed_file_name">
                        <button type="button" class="btn text-white" style="background-color: #49a571;">Baixar
                            documento</button>
                    </a>
                </div>
                <div v-else class="end d-flex">
                    <div class="d-flex justify-content-start" style="float: right; width: 50%;">
                        <button
                            v-if="this.StoreAll.document_details.document_status_vote_id >= 5 && this.StoreAll.document_details.document_status_vote_id != 6"
                            type="button" class="btn text-white" @click="Document_64();"
                            style="background-color: #49a571;">Baixar documento completo</button>
                        <a v-if="this.StoreAll.document_details.document_status_vote_id <= 5 || this.StoreAll.document_details.document_status_vote_id == 6"
                            target="_blank"
                            :href="this.StoreAll.Dochost + this.StoreAll.document_src"
                            download="proposed_file_name">
                            <button type="button" class="btn text-white" style="background-color: #49a571;">Baixar
                                documento</button>
                        </a>
                    </div>
                    <div class="d-flex justify-content-end" style="float: left; width: 50%;">
                        <!-- <button v-if="this.StoreAll.document_details.document_category_id == 1 || this.StoreAll.document_details.document_category_id == 2 || this.StoreAll.document_details.document_category_id == 4 || this.StoreAll.document_details.document_category_id == 6 || this.StoreAll.document_details.document_category_id == 12"
            type="button" class="btn btn-success " @click="Show_getParecer()">
            Ver parecer <i class="ml-2 fa fa-file-import"></i>
        </button> -->

                        <button v-if="this.StoreAll.parecershow === true" type="button"
                            class="btn btn-success " @click="Show_getParecer()">
                            Ver parecer <i class="ml-2 fa fa-file-import"></i>
                        </button>

                    </div>

                </div>

            </template>

            <template #body>
                <div v-if="this.StoreAll.document_details.document_status_vote_id == 2"
                    class="d-flex justify-content-center">

                    <button
                        v-if="this.StoreAll.user.user_category_id == 3 && this.StoreAll.document_details.document_status_movement_id != 1 || this.StoreAll.user.user_category_id == 1"
                        type="button" class="justify-content-center btn mb-2 mr-3 text-white"
                        style="background-color: #978b11;" data-dismiss="modal" @click="send_secret()">
                        <i class="fa fa-file-import pr-1"></i> Enviar para secretário </button>

                    <button
                        v-if="this.StoreAll.user.user_category_id == 3 && this.StoreAll.document_details.document_status_vote_id == 2 && this.StoreAll.session.session_status_id == 2"
                        type="button" class="btn mb-2 mr-3 text-white" style="background-color: #203387;"
                        @click="CheckVote(2)"><i class="fa fa-user-secret text-white pr-1"></i>
                        Iniciar Votação secreta</button>

                    <button
                        v-if="this.StoreAll.user.user_category_id == 3 && this.StoreAll.document_details.document_status_vote_id == 2 && this.StoreAll.session.session_status_id == 2"
                        type="button" class="btn btn-success mb-2 mr-2" @click="CheckVote(1)"><i
                            class="fa fa-check text-white pr-1"></i>
                        Iniciar Votação</button>


                    <button
                        v-if="this.StoreAll.user.user_category_id == 3 && this.StoreAll.document_details.document_status_vote_id == 2 && this.StoreAll.session.session_status_id == 2"
                        type="button" class="btn mb-2 ml-2 mr-2 text-white" @click="Show_NominalVote();"
                        style="background-color: #207087;"><i class="fa fa-hand-paper-o text-white pr-1"></i>
                        Votação nominal</button>

                    <button
                        v-if="this.StoreAll.user.user_category_id == 3 && this.StoreAll.session.session_status_id == 2"
                        type="button" class="btn mb-2 ml-2 text-white" @click="Discut(); DiscutStatus(1);"
                        style="background-color: #872020;"><i style="color: white !important;"
                            class="fa fa-microphone pr-1">
                        </i>
                        Discutir documento</button>
                </div>

                <div v-else class="d-flex justify-content-center">

                    <button
                        v-if="this.StoreAll.user.user_category_id === 1 && this.StoreAll.document_details.document_status_movement_id === 1 && this.StoreAll.document_details.document_category.name === 'Parecer'"
                        type="button" class="justify-content-center btn mb-2 mr-2 text-white"
                        style="background-color: rgb(17 114 151);" data-dismiss="modal" @click="openModal_secretario()">
                        <i class="fa fa-plus-circle mr-1" aria-hidden="true"></i>
                        Anexar esse parecer a um documento</button>

                    <!--  <button
                        v-if="this.StoreAll.user.user_category_id === 1 && this.StoreAll.document_details.document_status_movement_id === 1"
                        type="button" class="justify-content-center btn mb-2 mr-2 text-white" style="background-color: rgb(46 147 146);" data-dismiss="modal"
                        @click="openModal_attach()">
                         <i class="fa fa-plus-circle mr-1" aria-hidden="true"></i>
                         Anexar esse documento a outro documento</button> -->

                    <button
                        v-if="this.StoreAll.user.user_category_id == 3 && this.StoreAll.document_details.document_status_movement_id != 1 && this.StoreAll.document_details.document_status_vote_id !== 6 || this.StoreAll.user.user_category_id == 1 && this.StoreAll.document_details.document_status_movement_id != 1 && this.StoreAll.document_details.document_status_vote_id !== 6"
                        type="button" class="justify-content-center btn mb-2 mr-2 text-white"
                        style="background-color: #978b11;" data-dismiss="modal" @click="send_secret_voted()">
                        <i class="fa fa-file-import pr-1"></i> Enviar para secretário</button>

                    <button
                        v-if="this.StoreAll.user.user_category_id == 3 && this.StoreAll.document_details.document_status_movement_id != 1 && this.StoreAll.document_details.document_status_vote_id === 6 || this.StoreAll.user.user_category_id == 1 && this.StoreAll.document_details.document_status_movement_id != 1 && this.StoreAll.document_details.document_status_vote_id === 6"
                        type="button" class="justify-content-center btn mb-2 mr-2 text-white"
                        style="background-color: #978b11;" data-dismiss="modal" @click="send_secret()">
                        <i class="fa fa-file-import pr-1"></i> Enviar para secretário</button>

                    <button
                        v-if="this.StoreAll.user.user_category_id == 3 && this.StoreAll.document_details.document_status_vote_id == 2 && this.StoreAll.session.session_status_id == 2"
                        type="button" class="btn mb-2 mr-3 text-white" style="background-color: #203387;"
                        @click="CheckVote(2)"><i class="fa fa-user-secret text-white pr-1"></i>
                        Iniciar Votação secreta</button>

                    <button
                        v-if="this.StoreAll.user.user_category_id == 3 && this.StoreAll.session.session_status_id == 2 && this.StoreAll.document_details.document_category_id == 7 && this.StoreAll.document_details.document_status_vote_id != 5"
                        type="button" class="btn btn-success mb-2 mr-2" @click="CheckVote(1)"><i
                            class="fa fa-check text-white pr-1"></i>
                        Iniciar Votação</button>

                    <button
                        v-if="this.StoreAll.user.user_category_id == 3 && this.StoreAll.session.session_status_id == 2 && this.StoreAll.document_details.document_category_id == 7 && this.StoreAll.document_details.document_status_vote_id != 5"
                        type="button" class="btn btn-success mb-2 ml-2" @click="Show_NominalVote();"><i
                            class="fa fa-hand-paper-o text-white pr-1"></i>
                        Votação nominal</button>

                    <button
                        v-if="this.StoreAll.user.user_category_id == 1 && this.StoreAll.document_details.document_status_vote_id == 5 && this.StoreAll.Leitura == false"
                        type="button" class="btn btn-success mb-2 mr-2" @click="ShowCarimbo(1)"><i
                            class="fa fa-check text-white pr-1"></i>
                        Carimbar documento</button>

                    <button
                        v-if="this.StoreAll.user.user_category_id == 3 && this.StoreAll.session.session_status_id == 2"
                        type="button" class="btn mb-2 ml-2 text-white" @click="Discut(); DiscutStatus(1);"
                        style="background-color: #872020;"><i style="color: white !important;"
                            class="fa fa-microphone pr-1">
                        </i>
                        Discutir documento</button>


                    <button
                        v-if="this.StoreAll.user.user_category_id == 5 && this.StoreAll.document_details.document_status_vote_id == 5 && this.StoreAll.Leitura_prefeitura == false && this.$route.name == 'Documentos recebidos prefeitura'"
                        type="button" class="btn btn-success mb-2 mr-2" @click="ShowCarimbo(2)"><i
                            class="fa fa-check text-white pr-1"></i>
                        Carimbar documento</button>

                    <button
                        v-if="this.StoreAll.user.user_category_id == 1 && this.StoreAll.carimbo_camara === true && this.StoreAll.document_details.document_status_movement_id != 6"
                        type="button" class="btn btn-danger mb-2 mr-2" @click="ShowCarimbo(3)"><i
                            class="fa fa-trash text-white pr-1"></i>
                        Remover carimbo de aprovação</button>

                </div>

                <div
                    v-if="this.StoreAll.document_details.document_status_vote_id == 4 || this.StoreAll.document_details.document_status_vote_id == 5">


                    <div>
                        <Viwerjs />
                    </div>
                </div>

                <div v-else>
                    <div>
                        <Viwerjs />
                    </div>
                </div>

            </template>

            <template #footer>
                <div>
                    <a v-if="this.StoreAll.document_details.document_status_vote_id >= 5 && this.StoreAll.document_details.document_status_vote_id != 6"
                        download="proposed_file_name">
                        <div class="d-flex justify-content-center">
                            <h3 style="cursor: pointer;" @click="Document_64();">Baixar o Documento completo</h3>
                        </div>
                    </a>
                    <a v-if="this.StoreAll.document_details.document_status_vote_id < 5 || this.StoreAll.document_details.document_status_vote_id == 6"
                        target="_blank"
                        :href="this.StoreAll.Dochost + this.StoreAll.document_src"
                        download="proposed_file_name">
                        <div class="d-flex justify-content-center">
                            <h3>Baixar o Documento</h3>
                        </div>
                    </a>
                </div>
            </template>
        </ModalBig>

    </div>
</template>
<style>.swal2-container {
    z-index: 10001 !important;
}

.close {
    font-size: 2.3rem !important;
}</style>