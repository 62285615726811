<script>
import { AxiosAPI } from '@/axios';
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import moment from 'moment';
import { useMainStore } from '../../stores/index';
import Modal_view from '../../components/documents/Modals/Modal_View.vue';
    
export default {
	name: 'DocSearchParecer',
	components: {
		Modal_view,
	},
	setup(){
		const StoreAll = useMainStore();
		StoreAll.Token();
		return{
			StoreAll: StoreAll,
		};
	},
	data() {
		return {
			comission:false,
			document_timeline_leitura: null,
			Timeline: 1,
			expandedKeys: {},
			messages: [],
			filters: {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
				'name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
				'document_category.name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
				'user.nickname': {value: null, matchMode: FilterMatchMode.IN},
				'created_at': {value: null, matchMode: FilterMatchMode.EQUALS},
				'id': {value: null, matchMode: FilterMatchMode.EQUALS}
			},
			Search_all_parecer: [],
			docs_pareceres: [],
			session_id: 'realizadas',
			documento_session_id: 0,
			document_status_vote_id: '...',
			documento_name: 'Carregando...',
			documento_src: '',
			documento_statusid: 0,
			documento_id: 0,
		};
	},
	mounted() {
		this.GetUser();
		this.GetUsersAll();
		this.Get_Search_all();
		this.GetSession();
		this.initFilters();
	},
	methods: {  
    
		async GetUsersAll() {
			await this.StoreAll.UserAllEnvio();
			this.vereadores = this.StoreAll.user_envio;  
		},
    
		async GetUser(){
			await this.StoreAll.User(); 
			this.category_user = this.StoreAll.user.user_category_id;
		},
    
		async GetSession() {
			await this.StoreAll.Get_Data();
		},
    
		clearFilter1() {
			this.initFilters();
		},
		initFilters() {
			this.filters = {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
				'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
				'document_category.name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
				'user.nickname': {value: null, matchMode: FilterMatchMode.IN},
				'created_at': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
				'id': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
			};
		},
		async Get_Search_all(){
			await AxiosAPI
				.get('/documents', {
					headers: {
						Authorization: 'Bearer '+ this.StoreAll.accessToken,
					},
				})
				.then((resp) => {
    
					/* this.Search_all_parecer = resp.data.data */
					let data_docs = resp.data.data;
					let filter = [];

					/* Localizamos documentos com parecer do procurador primeiramente */

					for (let i = 0; i < data_docs.length; i++) {
						if(data_docs[i].parecer_procurador_pdf != null)
							filter.push(data_docs[i]);
					}

					/* Após isso, filtramos os documentos que possuem parecer nas comissões */

					AxiosAPI
						.get('/documents-comission', {
							headers: {
								Authorization: 'Bearer '+ this.StoreAll.accessToken,
							},
						})
						.then((resp) => {
							let data_docs_comission = resp.data.data;
							let filter_docs = [];

							for (let i = 0; i < data_docs_comission.length; i++) {
								filter_docs.push(data_docs_comission[i]);
							}
                    
							const idSet = new Set(filter.map(item => item.id));

							for (const item of filter_docs) {
								if (!idSet.has(item.document_id)) {
									filter.push(item);
									idSet.add(item.id);
								}
							}
							this.docs_pareceres = filter;
						});
                
                    

        
				});
		},
		showError() {
			this.$toast.add({severity:'error', summary: 'Sessão Não Encotrada', detail:'Documento não possui sessão', life: 3000});
		},

		formatDate(value) {
			moment.locale('pt-br');
			return moment(value).format('DD/MMMM/YYYY');
		},
            
		ViewDoc(event){
			this.StoreAll.document_details = event;

			if (event.carimbo_camara_pdf == null) {
				this.StoreAll.Leitura = false;
				if (event.attachment_partial != null) {
					this.StoreAll.document_src = event.attachment_partial;
				} else {
					this.StoreAll.document_src = event.attachment;
				}
			} else if (event.carimbo_camara_pdf != null && event.carimbo_prefeitura_pdf != null) {
				this.StoreAll.Leitura = true;
				this.StoreAll.document_src = event.carimbo_prefeitura_pdf;
			} else if (event.carimbo_camara_pdf != null && event.carimbo_prefeitura_pdf == null) {
				this.StoreAll.Leitura = true;
				this.StoreAll.document_src = event.carimbo_camara_pdf;
			} else {
				this.StoreAll.Leitura = false;
				if (event.attachment_partial != null) {
					this.StoreAll.document_src = event.attachment_partial;
				} else {
					this.StoreAll.document_src = event.attachment;
				}
			}

			/* Logica para documento atual */
			if(event.parecer_procurador_pdf != null){
				this.StoreAll.parecer_procurador_pdf = event.parecer_procurador_pdf;
			}else{
				this.StoreAll.parecer_procurador_pdf = null;
			}
    
			this.$refs.Modal_view.openModal2();
		},
    
		Author(value){
			let Co_user = [];
			for (let i = 0; i < value.length; i++) {
				Co_user.push(value[i].nickname);
			}
			return Co_user.join(', ');
		},
    
    
	},
};
</script>
<template>
    <div>
        <Modal_view ref="Modal_view"></Modal_view>
      </div>
      <div class="container-fluid">
        <div class="mt-4 row">
          <div class="col-12">
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <h5 class="mb-0">Pesquise todos documentos enviados no sistema!</h5>
                <p class="mb-0 text-sm">
                  Aquivo você pode visualizar todos os documentos enviados no sistema.
                </p>
              </div>
    
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="table-responsive">                  
                      <DataTable v-model:filters="filters" :value="docs_pareceres" show-gridlines responsive-layout="scroll" :paginator="true" :rows="10"  filter-display="menu"
                      :global-filterfields="['id','name','document_category.name','user.nickname','created_at']">
                      <template #header>
                        <div class="d-flex justify-content-between">
                            <Buttonprime type="button" icon="pi pi-filter-slash" label="Limpar Filtro" class="p-button-outlined" @click="clearFilter1()"/>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Pesquisa de documento" />
                            </span>
                        </div>
                    </template>
                          <Column field="id" header="Protocolo" class="text-sm font-weight-bold text-wrap text-uppercase" :sortable="true">
                            <template #body="{ data }">
                              <span v-if="data.id == null"> {{ data.document_id }} </span>
                              <span v-else> {{data.id}} </span>
                            </template>
                        </Column>
                          <Column field="name" header="Documento" class="text-sm font-weight-bold text-wrap text-uppercase">
                            <template #body="{ data }">
                              <span v-if="data.name == null"> {{ data.document_name }} </span>
                              <span v-else> {{data.name}} </span>
                            </template>
                        </Column>
                          <Column field="category.name" header="Tipo" class="text-sm font-weight-bold text-wrap text-uppercase">
                          <template #body="{ data }"> 
                            <div v-if="data.document_category == undefined">
                              {{ data.category.name }}
                          </div>
                          <div v-else>
                            {{ data.document_category.name }}
                          </div>
                        </template>
                          </Column>
                          <Column field="authors" header="Autor(es)"
                            class="text-sm font-weight-bold text-wrap text-uppercase" :sortable="true">
                            <template #body="{ data }">
                              <span>{{Author(data.authors)}} </span>
                            </template>
                          </Column>
                          <Column field="created_at" header="Data" class="text-sm font-weight-bold text-wrap text-uppercase">
                           <template #body="{data}">
                            {{formatDate(data.created_at)}}
                        </template> 
                          </Column>
                          <Column  header="Visualizar" class="text-sm font-weight-bold text-wrap text-uppercase TT justify-content-center text-center">
                            <template #body="slotProps">
                              <button style="margin-bottom: 0rem !important;" type="button" class="btn bg-smartlegis btnEdit envio" @click="ViewDoc(slotProps.data)"><i style="color: white !important;" class="fa fa-eye"></i></button>
                    </template>
                            </Column>
                      </DataTable>
    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    
    
          <div id="mymodals" class="modal fade bd-example-modal-xl Timeline" tabindex="-1" role="dialog"
            aria-labelledby="myExtraLargeModalLabel" aria-hidden="true" style="z-index: 10000; display: none;"
            >
            <div class="modal-dialog modal-xl Search_all">
              <div class="modal-content">
                <div class="modal-header">
    
                  <strong><span> {{documento_name}} </span> </strong>
    
                  <button id="closess" type="button" class="close" data-dismiss="modal" aria-label="Fechar">
                    <span aria-hidden="true">&times;</span>
                  </button>
    
                </div>
      <div v-if="comission == false" class="modal-body">
        
        <div v-if="document_timeline_leitura == false" class="d-flex justify-content-center">       
          <div style="display: flex; flex-direction: column; align-items: center;">
            
            <div class="ml-2 justify-content-center" :class="{ 'bg-success': Timeline == 1 || Timeline == 2 || Timeline == 3 || Timeline == 4 || Timeline == 5 || Timeline == 6 }"
              style="display: flex; align-items: center; justify-content: center; border: 1px #222020;width: clamp(0.1vw, 3vw, 302px);border-style: double;height:clamp(0.1vw, 3vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                <span class="text-black font-weight-bold" style="font-size: clamp(1vw, 1.5vw, 4vw); text-align: center;">1</span>
            </div>
                <span> Documento criado </span>
    
          </div>
          
          <hr style="border: none; border-top: 2px solid black; margin: 20px 0; width: 5%;">
    
    
          <div style="display: flex; flex-direction: column; align-items: center; ">
            <div class="ml-2 justify-content-center" :class="{ 'bg-success': Timeline == 2 || Timeline == 3 || Timeline == 4 || Timeline == 5 || Timeline == 6}"
              style="display: flex; align-items: center; justify-content: center; border: 1px #222020;width: clamp(0.1vw, 3vw, 302px);border-style: double;height:clamp(0.1vw, 3vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                <span class="text-black font-weight-bold" style="font-size: clamp(1vw, 1.5vw, 4vw); text-align: center;">2</span>
            </div>
                <span> Enviado para sessão </span>
          </div>
    
          <hr style="border: none; border-top: 2px solid black; margin: 20px 0; width: 5%;">
    
          <div style="display: flex; flex-direction: column; align-items: center;">
            <div class="ml-2 justify-content-center" :class="{ 'bg-success': Timeline == 3 || Timeline == 4}"
              style="display: flex; align-items: center; justify-content: center; border: 1px #222020;width: clamp(0.1vw, 3vw, 302px);border-style: double;height:clamp(0.1vw, 3vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                <span class="text-black font-weight-bold" style="font-size: clamp(1vw, 1.5vw, 4vw); text-align: center;">3</span>
            </div>
                <span> Documento votado </span>
          </div>
                    
          <hr style="border: none; border-top: 2px solid black; margin: 20px 0; width: 5%;">
    
          <div style="display: flex; flex-direction: column; align-items: center;">
            <div class="ml-2 justify-content-center" :class="{ 'bg-success': Timeline == 4}"
              style="display: flex; align-items: center; justify-content: center; border: 1px #222020;width: clamp(0.1vw, 3vw, 302px);border-style: double;height:clamp(0.1vw, 3vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                <span class="text-black font-weight-bold" style="font-size: clamp(1vw, 1.5vw, 4vw); text-align: center;">4</span>
            </div>
                <span> Encaminhado a Prefeitura </span>
            </div>
            </div>
    
            <div v-else class="d-flex justify-content-center">       
          <div style="display: flex; flex-direction: column; align-items: center;">
            
            <div class="ml-2 justify-content-center" :class="{ 'bg-success': Timeline == 1 || Timeline == 2 || Timeline == 3 || Timeline == 4 || Timeline == 5 || Timeline == 6 }"
              style="display: flex; align-items: center; justify-content: center; border: 1px #222020;width: clamp(0.1vw, 3vw, 302px);border-style: double;height:clamp(0.1vw, 3vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                <span class="text-black font-weight-bold" style="font-size: clamp(1vw, 1.5vw, 4vw); text-align: center;">1</span>
            </div>
                <span> Documento criado </span>
          </div>
          
          <hr style="border: none; border-top: 2px solid black; margin: 20px 0; width: 5%;">
    
    
          <div style="display: flex; flex-direction: column; align-items: center; ">
            <div class="ml-2 justify-content-center" :class="{ 'bg-success': Timeline == 2 || Timeline == 3 || Timeline == 4 || Timeline == 5 || Timeline == 6}"
              style="display: flex; align-items: center; justify-content: center; border: 1px #222020;width: clamp(0.1vw, 3vw, 302px);border-style: double;height:clamp(0.1vw, 3vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                <span class="text-black font-weight-bold" style="font-size: clamp(1vw, 1.5vw, 4vw); text-align: center;">2</span>
            </div>
                <span> Enviado para sessão </span>
          </div>
    
          <hr style="border: none; border-top: 2px solid black; margin: 20px 0; width: 5%;">
    
          <div style="display: flex; flex-direction: column; align-items: center;">
            <div class="ml-2 justify-content-center" :class="{ 'bg-success': Timeline == 6}"
              style="display: flex; align-items: center; justify-content: center; border: 1px #222020;width: clamp(0.1vw, 3vw, 302px);border-style: double;height:clamp(0.1vw, 3vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                <span class="text-black font-weight-bold" style="font-size: clamp(1vw, 1.5vw, 4vw); text-align: center;">3</span>
            </div>
                <span> Documento em leitura na sessão </span>
          </div>
                    
          <hr style="border: none; border-top: 2px solid black; margin: 20px 0; width: 5%;">
    
          </div>
    
    </div>       
            
    
                <div v-else class="modal-body">
    
                  <div class="justify-content-center">     
                    
                    
                  <div class="d-flex justify-content-center">
                    <div style="display: flex; flex-direction: column; align-items: center;">
                    <div class="ml-2 justify-content-center" :class="{ 'bg-success': Timeline == 1 || Timeline == 2 || Timeline == 3 || Timeline == 4 || Timeline == 5 || Timeline == 6}"
                      style="display: flex; align-items: center; justify-content: center; border: 1px #222020;width: clamp(0.1vw, 3vw, 302px);border-style: double;height:clamp(0.1vw, 3vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                        <span class="text-black font-weight-bold" style="font-size: clamp(1vw, 1.5vw, 4vw); text-align: center;">1</span>
                    </div>
                      <span> Documento criado </span>
                    </div>
                    <hr style="border: none; border-top: 2px solid black; margin: 20px 0; width: 5%;">
    
    
    
    
                    <div style="display: flex; flex-direction: column; align-items: center; ">
                      <div class="ml-2 justify-content-center" :class="{ 'bg-success': Timeline == 2 || Timeline == 3 || Timeline == 4 || Timeline == 5 || Timeline == 6}"
                      style="display: flex; align-items: center; justify-content: center; border: 1px #222020;width: clamp(0.1vw, 3vw, 302px);border-style: double;height:clamp(0.1vw, 3vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                            <span class="text-black font-weight-bold" style="font-size: clamp(1vw, 1.5vw, 4vw); text-align: center;">2</span>
                          </div>
                          <span> Enviado para sessão </span>
                    </div>
                    <hr style="border: none; border-top: 2px solid black; margin: 20px 0; width: 5%;">
    
    
    
                    <div style="display: flex; flex-direction: column; align-items: center; ">
                      <div class="ml-2 justify-content-center" :class="{ 'bg-success': Timeline == 5 }"
                      style="display: flex; align-items: center; justify-content: center; border: 1px #222020;width: clamp(0.1vw, 3vw, 302px);border-style: double;height:clamp(0.1vw, 3vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                            <span class="text-black font-weight-bold" style="font-size: clamp(1vw, 1.5vw, 4vw); text-align: center;">3</span>
                          </div>
                          <span> Documento retornado para secretário </span>
                    </div>
                    <hr style="border: none; border-top: 2px solid black; margin: 20px 0; width: 5%;">
    
    
    
                    <div class="d-flex justify-content-center">
                      <div style="display: flex; flex-direction: column; align-items: center;">
                      <div class="ml-2 justify-content-center" :class="{ 'bg-success': Timeline == 3 || Timeline == 4}"
                      style="display: flex; align-items: center; justify-content: center; border: 1px #222020;width: clamp(0.1vw, 3vw, 302px);border-style: double;height:clamp(0.1vw, 3vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                            <span class="text-black font-weight-bold" style="font-size: clamp(1vw, 1.5vw, 4vw); text-align: center;">5</span>
                          </div>
                    <span> Votação concluída </span>
                    </div>
                    <hr style="border: none; border-top: 2px solid black; margin: 20px 0; width: 5%;">
    
    
    
                    <div style="display: flex; flex-direction: column; align-items: center;">
                      <div class="ml-2 justify-content-center" :class="{ 'bg-success': Timeline == 4}"
                      style="display: flex; align-items: center; justify-content: center; border: 1px #222020;width: clamp(0.1vw, 3vw, 302px);border-style: double;height:clamp(0.1vw, 3vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                            <span class="text-black font-weight-bold" style="font-size: clamp(1vw, 1.5vw, 4vw); text-align: center;">6</span>
                          </div>
                    <span> Encaminhado a Prefeitura </span>
                    </div>
                  </div>
                  </div>
                </div>
    
                
                <div class="modal-footer d-flex justify-content-center">
                  <div>
                    <a target="_blank" :href="documento_src" download="proposed_file_name">
                      <div>
                        <h3>Baixar o Documento</h3>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>      
        </div>
      <Toast />
    </div>
  </template>
    <style>
    .close{
      font-size: 2.3rem !important;
    }
    
    </style>
    