<script>
import { AxiosAPI } from '@/axios';
import moment from 'moment';
import Modal_view from '../../../components/documents/Modals/Modal_View.vue';
import { useMainStore } from '../../../stores/index';
import {FilterMatchMode,FilterOperator} from 'primevue/api';
export default {
	name: 'DataTables',
	components: {
		Modal_view,
	},
	setup(){
		const StoreAll = useMainStore();
		StoreAll.Token();
		return{
			StoreAll: StoreAll,
		};
	},
	data() {
		return {
			start_url: 'https://docs.google.com/gview?url=',
			end_url: '&embedded=true',
			session_id: 0,
			documento_status: '...',
			documento_name: 'Carregando...',
			documento_src: '',
			documento_id: 0,
			document_status_vote_id: 0,
			category_user: 0,
			session: {},
			session_status_name: '',
			votes: {},
			favorable: 0,
			against: 0,
			vista: 0,
			ordem: 1,
			DayOrder_History: [],
			pdfPresentes: '',
			WorkDay_History: [],
			filters_Expediente: {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
				'name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
				'document_category.name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
				'created_at': {value: null, matchMode: FilterMatchMode.EQUALS},
				'id': {value: null, matchMode: FilterMatchMode.EQUALS}
			},
			filters_ordem: {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
				'name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
				'document_category.name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
				'created_at': {value: null, matchMode: FilterMatchMode.EQUALS},
				'id': {value: null, matchMode: FilterMatchMode.EQUALS}
			},
		};
	},
	mounted() {
		this.GetSession();
		this.GetSessionother();
		this.User();
		this.GetUsersAll();
	},
	created() {

	},
	methods: {

		async GetSession() {
			await this.StoreAll.GetSessionStory();

			this.session = this.StoreAll.sessionstory;
			this.session_status = this.StoreAll.sessionstory.session_status.id;
			this.session_id = this.StoreAll.sessionstory.id;
			this.session_status_name = this.StoreAll.sessionstory.session_status.name;
			this.GetWorkDay_History(); this.GetDayOrder_History();
		},

		async User(){
			await this.StoreAll.User();
			this.category_user = this.StoreAll.user.user_category_id;
		},

		async GetUsersAll() {
			await this.StoreAll.UserAllEnvio();
			this.vereadores = this.StoreAll.user_envio;
		},

		async GetSessionother() { await this.StoreAll.Get_Data();},

		GetWorkDay_History() {
			AxiosAPI
				.get('/documents', {
					params: {
						session_id: this.session.id,
						ordem: 0,
					},
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				})
				.then((resp) => {
					this.WorkDay_History = resp.data.data;
				});
		},
		GetDayOrder_History() {
			AxiosAPI
				.get('/documents', {
					params: {
						session_id: this.session.id,
						ordem: 1,
					},
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				})
				.then((resp) => {
					this.DayOrder_History = resp.data.data;
				});
		},

		clearFilter_ordem() {
			this.initFilters_ordem();
		},

		initFilters_ordem() {
			this.filters_ordem = {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
				'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
				'document_category.name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
				'created_at': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
				'id': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
			};
		},

		clearFilter_Expediente() {
			this.initFilters_Expediente();
		},

		initFilters_Expediente() {
			this.filters_Expediente = {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
				'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
				'document_category.name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
				'created_at': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
				'id': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
			};
		},

		Author(value){
			let Co_user = [];
			for (let i = 0; i < value.length; i++) {
				Co_user.push(value[i].nickname);
			}
			return Co_user.join(', ');
		},

		formatDate(value) {
			moment.locale('pt-br');
			return moment(value).format('DD/MMMM/YYYY');
		},

		editProduct(event) {
			this.StoreAll.history_doc = true;
			/* apenas por enquanto */
			this.StoreAll.document_details = [];
			this.StoreAll.document_src = [];
			this.StoreAll.document_details = event;
			if (event.carimbo_camara_pdf == null) {
				this.StoreAll.Leitura = false;
				if (event.attachment_partial != null) {
					this.StoreAll.document_src = event.attachment_partial;
				} else {
					this.StoreAll.document_src = event.attachment;
				}
			} else if (event.carimbo_camara_pdf != null && event.carimbo_prefeitura_pdf != null) {
				this.StoreAll.Leitura = true;
				this.StoreAll.document_src = event.carimbo_prefeitura_pdf;
			} else if (event.carimbo_camara_pdf != null && event.carimbo_prefeitura_pdf == null) {
				this.StoreAll.Leitura = true;
				this.StoreAll.document_src = event.carimbo_camara_pdf;
			} else {
				this.StoreAll.Leitura = false;
				if (event.attachment_partial != null) {
					this.StoreAll.document_src = event.attachment_partial;
				} else {
					this.StoreAll.document_src = event.attachment;
				}
			}
			/* Logica para documento atual */

			if(event.parecer_procurador_pdf != null){
				this.StoreAll.parecer_procurador_pdf = event.parecer_procurador_pdf;
			}else{
				this.StoreAll.parecer_procurador_pdf = null;
			}
			/* verificar impletação */

			this.$refs.Modal_view.openModal2();
			this.$refs.Modal_view.getVotes();
		},

		async listDownload () {
			await AxiosAPI.get('session/'+this.session.id+'/attendance', {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((resp) => {
				const byteCharacters = atob(resp.data.data);
				const byteNumbers = new Array(byteCharacters.length);
				for (let i = 0; i < byteCharacters.length; i++) {
					byteNumbers[i] = byteCharacters.charCodeAt(i);
				}
				const byteArray = new Uint8Array(byteNumbers);
				const blob = new Blob([byteArray], { type: 'application/pdf' });
				const url = URL.createObjectURL(blob);
				window.open(url, '_blank');
			});
		},

	},
};
</script>
<style>
.previous {
  padding-right: 11px;
  padding-bottom: 0.5rem;
}

.close {
  font-size: 2.3rem !important;
}
</style>
<template>
  <div>
    <Modal_view ref="Modal_view"></Modal_view>

    <div class="py-1 h-100 container-fluid">
      <div class="row">
        <div class="d-flex justify-content-around col-12  mt-5">
          <h3 class="mt-auto text-white">{{ session.name }}</h3>
          <h3 v-if="session_status_name == 'Aguardando votação'" style="color: #dd002c">
            {{ session_status_name }}
          </h3>
          <h3 v-if="session_status_name == 'Em votação'" style="color: rgb(253 192 19)">
            {{ session_status_name }}
          </h3>
          <h3 v-if="session_status_name == 'Concluída'" style="color: rgb(76 255 54)">
            {{ session_status_name }}
          </h3>
        </div>
      </div>
    </div>

    <div v-if="this.StoreAll.user.user_category_id == 1 && this.StoreAll.session.session_status_id == 3" class="d-flex justify-content-center">
			<button type="button" class="btn btn-success btn-lg" @click="listDownload()">
                      Baixar lista de presença desta sessão
            </button>
		</div>

    <div class="container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <h5 class="text-start mb-0" style="font-size: 25px;">Expediente do dia</h5>
                <div v-if="this.$route.fullPath == '/historico_sessao/'">
                  <a href="javascript:history.back()">
                    <button v-if="this.$route.fullPath == '/historico_sessao/'" type="button" class="btn btn-primary">
                      Voltar para página anterior
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="table-responsive">
                    <DataTable :filters="filters_Expediente" :value="WorkDay_History" show-gridlines responsive-layout="scroll" :paginator="true"
                      :rows="10" filter-display="menu"
                  :globalfilterfields="['id','name','document_category.name','created_at']">
                  <template #header>
                          <div class="d-flex justify-content-between">
                              <Buttonprime type="button" icon="pi pi-filter-slash" label="Limpar Filtro" class="p-button-outlined" @click="clearFilter_Expediente()"/>
                              <span class="p-input-icon-left">
                                  <i class="pi pi-search" />
                                  <InputText v-model="filters_Expediente['global'].value" placeholder="Pesquise o documento" />
                              </span>
                          </div>
                      </template>
                      <Column field="id" header="Protocolo" :sortable="true"></Column>
                      <Column field="name" header="Documento"></Column>
                      <Column field="document_category.name" header="Tipo"></Column>
                      <Column field="authors" header="Autor(es)"
                        class="text-sm font-weight-bold text-wrap text-uppercase" :sortable="true">
                        <template #body="{ data }">
                          <span>{{Author(data.authors)}} </span>
                        </template>
                      </Column>
                      <Column field="created_at" header="Data">
                        <template #body="{ data }">
                          {{ formatDate(data.created_at) }}
                        </template>
                      </Column>
                      <Column header="Assinado" class="text-sm font-weight-bold text-wrap text-uppercase text-center">
                      <template #body="slotProps">
                        <i v-if="slotProps.data.status_sign === 2 || slotProps.data.status_sign === 1" class="fa fa-check-circle-o text-success" aria-hidden="true" style="font-size: 20px;"></i>
                        <i v-else class="fa fa-times-circle-o text-danger" aria-hidden="true"  style="font-size: 20px;"></i>
                      </template>
                    </Column>
                      <Column header="Visualizar" class="d-flex justify-content-center">
                        <template #body="slotProps">
                          <div class="text-center"><button data-toggle="modal" style="margin-bottom: 0rem !important;"
                              data-target=".xp" type="button" class="btn bg-smartlegis btnEdit envio" 
                              @click="editProduct(slotProps.data)"><i style="color: white !important;"
                                class="fa fa-eye"></i></button></div>
                        </template>
                      </Column>
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h5 class="text-start mb-0" style="font-size: 25px;">Ordem do dia</h5>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="table-responsive">
                    <DataTable :filters="filters_ordem" :value="DayOrder_History" show-gridlines responsive-layout="scroll" :paginator="true"
                      :rows="10" filter-display="menu"
                  :globalfilterfields="['id','name','document_category.name','created_at']">
                  <template #header>
                          <div class="d-flex justify-content-between">
                              <Buttonprime type="button" icon="pi pi-filter-slash" label="Limpar Filtro" class="p-button-outlined" @click="clearFilter_ordem()"/>
                              <span class="p-input-icon-left">
                                  <i class="pi pi-search" />
                                  <InputText v-model="filters_ordem['global'].value" placeholder="Pesquise o documento" />
                              </span>
                          </div>
                      </template>
                      <Column field="id" header="Protocolo" class="text-sm font-weight-bold text-wrap text-uppercase"
                        :sortable="true"></Column>
                      <Column field="name" header="Documento" class="text-sm font-weight-bold text-wrap text-uppercase">
                      </Column>
                      <Column field="document_category.name" header="Tipo"
                        class="text-sm font-weight-bold text-wrap text-uppercase"></Column>
                        <Column field="authors" header="Autor(es)"
                        class="text-sm font-weight-bold text-wrap text-uppercase" :sortable="true">
                        <template #body="{ data }">
                          <span>{{Author(data.authors)}} </span>
                        </template>
                      </Column>
                      <Column field="created_at" header="Data"
                        class="text-sm font-weight-bold text-wrap text-uppercase">
                        <template #body="{ data }">
                          {{ formatDate(data.created_at) }}
                        </template>
                      </Column>
                      <Column field="document_status_vote_id" header="Status" class="text-sm font-weight-bold text-wrap text-uppercase" :sortable="true">
                        <template #body="slotProps">
                          <p v-if="slotProps.data.document_status_vote_id == 2" class="bg-danger text-center font-weight-bolder text-white" style="border-radius: 10px;"> Aguardando votação </p>
                          <p v-if="slotProps.data.document_status_vote_id == 3" class="bg-danger text-center font-weight-bolder text-white" style="border-radius: 10px;"> Em vista </p>
                          <p v-if="slotProps.data.document_status_vote_id == 4" class="bg-warning text-center font-weight-bolder text-white" style="border-radius: 10px;"> Em votação </p>
                          <p v-if="slotProps.data.document_status_vote_id == 5" class="bg-success text-center font-weight-bolder text-white" style="border-radius: 10px;"> Votação concluída</p>
                          <p v-if="slotProps.data.document_status_vote_id == 6" class="bg-danger text-center font-weight-bolder text-white" style="border-radius: 10px;"> Leitura </p>
                        </template>
                      </Column>
                      <Column v-if="this.StoreAll.user.user_category_id == 1" header="Carimbo" class="text-sm font-weight-bold text-wrap text-uppercase text-center">
                      <template #body="slotProps">
                        <i v-if="slotProps.data.carimbo_camara_pdf != null" class="fa fa-check-circle-o text-success" aria-hidden="true" style="font-size: 20px;"></i>
                        <i v-else class="fa fa-times-circle-o text-danger" aria-hidden="true"  style="font-size: 20px;"></i>
                      </template>
                    </Column>
                    <Column header="Assinado" class="text-sm font-weight-bold text-wrap text-uppercase text-center">
                      <template #body="slotProps">
                        <i v-if="slotProps.data.status_sign === 2 || slotProps.data.status_sign === 1" class="fa fa-check-circle-o text-success" aria-hidden="true" style="font-size: 20px;"></i>
                        <i v-else class="fa fa-times-circle-o text-danger" aria-hidden="true"  style="font-size: 20px;"></i>
                      </template>
                    </Column>
                      <Column header="Visualizar"
                        class="text-sm font-weight-bold text-wrap text-uppercase TT text-center">
                        <template #body="slotProps">
                          <button data-toggle="modal" style="margin-bottom: 0rem !important;" data-target=".xps"
                            type="button" class="btn bg-smartlegis btnEdit envio" @click="editProduct(slotProps.data)"><i style="color: white !important;"
                              class="fa fa-eye"></i></button>
                        </template>
                      </Column>
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
