<script>
import AlertVote from './AlertVote.vue';
import { useMainStore, useBusStore } from '../../../stores/index';

export default {
	components: {
		AlertVote,
	},
	setup() {
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		return {
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {

		};
	},

	methods: {
		openModal() {
			this.$refs.viewDocument.openModal();
		},

		voteSelect (value) {
			this.Bus.votePresidente = value;
			this.$refs.viewDocument.closeModal();
		}
	}
};


</script>
<template>
  <div>
    <AlertVote ref="viewDocument" style="z-index: 1001 !important">
      <template #body>
        <div class="d-flex justify-content-center mb-3">
            <h3>Como vota ?</h3>
        </div>
        <div class="d-flex justify-content-center mb-2">
            <div>
                <button id="favor" type="button" class="btn btn-success mr-4 ml-1 text-lg" @click="voteSelect(1)">A favor</button>
                <button id="contra" type="button" class="btn btn-danger mr-4 ml-1 text-lg" @click="voteSelect(2)">Contra</button>
                <button id="absten" type="button" class="btn btn-secondary mr-1 ml-1 text-lg" @click="voteSelect(3)">Abster</button>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <div>
                <button id="notForm" type="button" class="btn mr-1 ml-1 text-lg text-white" style="background-color:#c7841f" @click="voteSelect(4)">Não informar o voto</button>
            </div>
        </div>
      </template>
    </AlertVote>
  </div>
</template>
<style>
</style>
