<script>
import ModalBig from './ModalBig.vue';
import { AxiosAPI, SocketAPI } from '@/axios';
import { useMainStore } from '../../../stores/index';
import { useBusStore } from '../../../stores/index';
import Viwerjs from './Viwerjs.vue';
import Loading from '../Modals/Loading.vue';
import { saveAs } from 'file-saver';
export default {
	components: {
		ModalBig,
		Viwerjs,
		Loading,
	},

	setup() {
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		return {
			StoreAll: StoreAll,
			Bus
		};
	},

	data() {
		return {
			onlypdf: '',
			Docs_recp: [],
			text_reprove: '',
			reprove_detail: ''
		};
	},

	watch: {
	},

	methods: {
		openModal2(event) {
			this.$refs.viewDocument.openModal();
		},

		async Aprrove () {
			await AxiosAPI
				.post('/document/change-status-move', {
					document_id: this.StoreAll.document_details.id,
					document_status_movement_id:1,
					user_id: this.StoreAll.user.id,
				}, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken
					}
				})
				.then(resp => {
					if (resp.status === 200) {
						this.$toast.add({ severity: 'success', summary: 'Documento analisado', detail: 'O documento já está em recepicionados!', life: 3000 });
						this.emitter.emit('ApproveDocument', 1);
						this.$refs.viewDocument.closeModal();
					}
				});
		},

		async Reprove () {
			await AxiosAPI
				.post('/document/change-status-move', {
					document_id: this.StoreAll.document_details.id,
					document_status_movement_id:8,
					user_id: this.StoreAll.user.id,
					decision_message: this.text_reprove
				},{
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken
					}
				})
				.then(resp => {
					if (resp.status === 200) {
						this.$toast.add({ severity: 'success', summary: 'Documento analisado', detail: 'O documento foi reprovado!', life: 3000 });
						this.emitter.emit('ReproveDocument', 1);
						this.$refs.viewDocument.closeModal();
					}
				});
		}

	}
};
</script>
<template>
    <div>
        <Loading ref="Loading"></Loading>
        <ModalBig ref="viewDocument">
          <!--  eslint-disable-next-line vue/no-useless-template-attributes -->
            <template #header style="display: block !important;">
                <div>
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <strong>
                                <span>{{ this.StoreAll.document_details.name }}:
                                    <span v-if="this.StoreAll.document_details.document_status_movement_id === 7" style="color: #717108"> Aguardando analise</span>
                                    <span v-if="this.StoreAll.document_details.document_status_movement_id === 8" style="color: red"> Reprovado</span>
                                    </span>
                            </strong>
                        </div>
                        <div class="">
                            <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                </div>
                    <a target="_blank"
                        :href="this.StoreAll.Dochost + this.StoreAll.document_src"
                        download="proposed_file_name">
                        <button
                        type="button" class="btn text-white"
                        style="background-color: #49a571;">Baixar documento</button>
                    </a>
                    <div v-if="this.StoreAll.document_details.document_status_movement_id === 7 && this.StoreAll.user.user_category_id === 1" class="d-flex justify-content-center">
                        <button type="button" class="bg-success btn mb-2 ml-2 mr-2 text-white" @click="Aprrove()"><i class="fa fa-check" aria-hidden="true"></i>
                            Aprovar</button>

                        <button type="button" class="bg-danger btn mb-2 ml-2 mr-2 text-white" data-toggle="modal" data-target="#reprove"><i class="fa fa-times" aria-hidden="true"></i>
                            Reprovar</button>
                    </div>
                    <div v-if="this.StoreAll.document_details.document_status_movement_id === 8" class="d-flex justify-content-center">
                        <button type="button" class="btn-danger btn mb-2 ml-2 mr-2 text-white" data-toggle="modal" data-target="#reprove_detail">
                            Motivo da reprovação</button>
                    </div>
                </div>

            </template>

            <template #body>
                <div>
                    <div>
                        <Viwerjs />
                    </div>

                <div class="modal" tabindex="-1" id="reprove" role="dialog">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Motivo da reprovação</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <label for="exampleFormControlTextarea1">Descreva o motivo da reprovação</label>
                            <div style="width: 100%;">
                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="text_reprove"></textarea>
                            </div>
                            <label v-if="!text_reprove" style="color:red">Informar motivo é obrigatório</label>
                        </div>
                        <div class="d-flex justify-content-center modal-footer">
                            <div>
                                <button class="btn btn-danger" @click="Reprove()" :disabled="!text_reprove" data-dismiss="modal" aria-label="Close">Reprovar</button>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

                    <div class="modal" tabindex="-1" id="reprove_detail" role="dialog">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Motivo da reprovação</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div style="width: 100%;">
                                <div class="d-flex justify-content-center">
                                    <span class="text-center">Motivo</span>
                                </div>
                                <div class="d-flex justify-content-center mt-2">
                                    <span><b>{{ this.StoreAll.document_details.decision_message }}</b></span>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>


            </template>

            <template #footer>
                <div>
                    <a target="_blank"
                        :href="this.StoreAll.Dochost + this.StoreAll.document_src"
                        download="proposed_file_name">
                        <div class="d-flex justify-content-center">
                            <h3>Baixar o Documento</h3>
                        </div>
                    </a>
                </div>
            </template>
        </ModalBig>

    </div>
</template>
<style>.swal2-container {
    z-index: 10001 !important;
}

.close {
    font-size: 2.3rem !important;
}</style>