<script>
import VuePdfEmbed from 'vue-pdf-embed';
import { AxiosAPI } from '@/axios';
import { useMainStore } from '../../../stores/index';
export default {
	name: 'ViewPdf',
	components: {
		VuePdfEmbed,
	},
	setup() {
		const StoreAll = useMainStore();
		return {
			StoreAll: StoreAll,
		};
	},
	data() {
		return {
			pdfonly: null,
		};
	},
	mounted() {
		this.convertToBase64();
	},
	methods: {
		async convertToBase64() {
			let ur = JSON.stringify({hiperlink: this.StoreAll.Dochost + this.StoreAll.document_src});
			await AxiosAPI.post('/get-base64', ur, {
				headers: {
					'Content-Type': 'application/json',
					authorization: 'Bearer ' + this.StoreAll.accessToken,
				}
			}).then((response) => {
				this.pdfonly = response.data.base64;
			});
		}
	}
};
</script>
<template class="teste">
<div id="app">
  <div class="app-content" style="height: 580px">
    <vue-pdf-embed
      ref="pdfRef"
      :source="`data:application/pdf;base64,`+pdfonly"
    />
  </div>
</div>

</template>
<style>
.vue-pdf-embed > div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}
.teste{
    width: 500px !important;
    height: 500px !important;
}
.app-header {
  padding: 16px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
  background-color: #555;
  color: #ddd;
}
</style>