<script>
import { AxiosAPI } from '@/axios';
import moment from 'moment';
import { useMainStore } from '../../stores/index';
import Modal_View_signer_comission from './Modals/Modal_View.vue';
import Modal_View_signer_user_comission from './Modals/Modal_View_Signer_comission.vue';
export default {
	name: 'SignerDocComission',
	components: { Modal_View_signer_comission, Modal_View_signer_user_comission },
	setup(){
		const StoreAll = useMainStore();
		StoreAll.Token();
		return{
			StoreAll: StoreAll,
		};
	},
	data() {
		return {
			SignerDocs: [],
			session_id: 'realizadass',
			selected_products: null,
			select: false
		};
	},
	watch: {
		selected_products: function (novoValor, valorAntigo) {
			if (novoValor !== null) {
				if(this.selected_products.length != 0 && this.selected_products.length >= 2){
					this.select = true;
				}else{
					this.select = false;
				}
			}
		}
	},
	mounted() {
		this.GetUser();
		this.GetUsersAll();
		this.GetSession();

		this.emitter.on('Assinado_comission', () => {
			this.DocSearch();
		});
	},
	methods: {

		async GetUsersAll() {
			await this.StoreAll.UserAllEnvio();
			this.vereadores = this.StoreAll.user_envio;  
		},

		async GetUser(){
			await this.StoreAll.User(); 
			this.DocSearch();
		},

		async GetSession() {
			await this.StoreAll.Get_Data();
		},
  

		onRowSelect(event) {     
			if(this.selected_products.length != 0 && this.selected_products.length >= 2){
				this.select = true;
			}else{
				this.select = false;
			}
		},

		pref(){
			let prefix = '';
			if(this.StoreAll.host == 'dev'){
				prefix = 'dev';
			}else if(this.StoreAll.host == 'teste'){
				prefix = 'teste';
			}else if(this.StoreAll.host == 'jc'){
				prefix = 'jc';
			}else if(this.StoreAll.host == 'bodo'){
				prefix = 'bd';
			}else if(this.StoreAll.host == 'pedroavelino'){
				prefix = 'pa';
			}else if(this.StoreAll.host == 'pedrapreta'){
				prefix = 'pp';
			}else if(this.StoreAll.host == 'campogrande'){
				prefix = 'cg';
			}else if(this.StoreAll.host == 'parazinho'){
				prefix = 'parazinho';
			}else if(this.StoreAll.host == 'local'){
				prefix = 'dev';
			}else if(this.StoreAll.host == 'jandaira'){
				prefix = 'jd';
			}else if(this.StoreAll.host == 'ja'){
				prefix = 'ja';
			}else if(this.StoreAll.host == 'lp'){
				prefix = 'lp';
			}else if(this.StoreAll.host == 'sr'){
				prefix = 'sr';
			}else if(this.StoreAll.host == 'sm'){
				prefix = 'sm';
			}else if(this.StoreAll.host == 'bf'){
				prefix = 'bf';
			}else if(this.StoreAll.host == 'sbn'){
				prefix = 'sbn';
			}else if(this.StoreAll.host == 'pureza'){
				prefix = 'pureza';
			}

			this.prefix_c = prefix;
      
		},

		onRowUnselect(event) {
			if(this.selected_products.length != 0 && this.selected_products.length >= 2){
				this.select = true;
			}else{
				this.select = false;
			}
      
		},
      
		DocSearch() { 
			AxiosAPI
				.get('/documents-notification-comission', {
					headers: {
						Authorization: 'Bearer '+ this.StoreAll.accessToken,
					},
				})
				.then((response) => {
					this.SignerDocs = response.data.data;
                                                   
				});
		},

		ViewDoc(event) {
			this.StoreAll.document_details = {
				name: event.document_name,
				request_signature_key: event.signature_key_comission,
				document_status_vote_id: 1,
			};
			this.StoreAll.document_src = event.parecer_pdf;
			this.$refs.Modal_View_signer_comission.openModal2();

		},
		ViewDoc_signer(event) {
			this.StoreAll.document_details = {
				name: event.document_name,
				request_signature_key: event.signature_key_comission,
				document_status_vote_id: 1,
			};

			this.$refs.Modal_View_signer_user_comission.openModal();
		},

		ViewDoc_signer_lote_comission(event) {
			this.StoreAll.document_details = event;
			this.StoreAll.MultiSelectDocs = this.selected_products;
			this.$refs.Modal_View_signer_user_comission.openModal_lote();
		},


		formatDate(value) {
			moment.locale('pt-br');
			return moment(value).format('DD/MMMM/YYYY');
		}, 
              
	},
};
</script>
<template>
   <div>
    <Modal_View_signer_comission ref="Modal_View_signer_comission"></Modal_View_signer_comission>
  </div>
  <div>
    <Modal_View_signer_user_comission ref="Modal_View_signer_user_comission"></Modal_View_signer_user_comission>
  </div>
    <div class="container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <h5 class="mb-0">Pareceres que precisam de assinatura!</h5>
              <p class="mb-0 text-sm">
                Aquivo você pode assinar seus Pareceres anexados.
              </p>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="card mt-4">
                  <div class="table-responsive">
                    <DataTable v-model:selection="selected_products" :value="SignerDocs" responsiveLayout="scroll" :paginator="true" :rows="10" 
                    data-key="id" @rowSelect="onRowSelect" @rowUnselect="onRowUnselect">
                    <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
                    <template #header>

                      <button v-if="select" type="button" class="btn btn-success mb-2 mr-2" @click="ViewDoc_signer_lote_comission();" data-toggle="modal"
                        data-target=".signer_doc_lote" data-dismiss="modal"><i class="fa fa-pen text-white pr-1"></i>
                        Assine documentos selecionados juntos</button>

                    </template>
                      <Column field="document_id" header="Protocolo" :sortable="true"></Column>
                      <Column field="document_name" header="Documento">
                        <template #body="{data}">
                          Protocolo: {{data.document_name}}
                        </template>
                      </Column>
                      <Column field="created_at" header="Data">
                        <template #body="{data}">
                        {{formatDate(data.created_at)}}
                    </template>
                      </Column>
                      <Column class="d-flex justify-content-center"  header="Visualizar parecer"> <!-- verificar se esses d-flex ficam ruins -->
                        <template #body="slotProps">
                            <div class="d-flex justify-content-center">
                              <button  data-toggle="modal" style="margin-bottom: 0rem !important;" data-target=".signer_x" type="button" @click="ViewDoc(slotProps.data)" class="btn bg-smartlegis btnEdit envio"><i style="color: white !important;" class="fa fa-eye"></i></button>
                  
                            </div>
                          </template>
                        </Column>
                        <Column  header="Assinar">
                        <template #body="slotProps">
                          <button  data-toggle="modal" style="margin-bottom: 0rem !important;" data-target=".signer_doc" type="button" @click="ViewDoc_signer(slotProps.data)" class="btn bg-smartlegis btnEdit envio"><i style="color: white !important;" class="fa fa-pen"></i></button>
                </template>
                        </Column>
                  </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
              <Toast position="center" group="tl" />
    </div>
  </template>
  <style>
  
  .close{
    font-size: 2.3rem !important;
  }
  </style>
  
  