<script>
import ModalBigger from '../../components/documents/Modals/ModalBig.vue';
import { AxiosAPI } from '@/axios';
import VuePdfEmbed from 'vue-pdf-embed';
import Loading from '../../components/documents/Modals/Loading.vue';

export default {
	components: {
		ModalBigger,
		VuePdfEmbed,
		Loading
	},
	data() {
		return {
			pdfonly: null,
			parecer_src: '',
			HostDoc: '',
			token: ''
		};
	},

	methods: {
		openModal(event) {
			this.parecer_src = event[0];
			this.HostDoc = event[2];
			this.token = event[3];
			this.convertToBase64();
			this.$refs.viewDocument.openModal();

		},
		async convertToBase64() {
      
			let ur = JSON.stringify({hiperlink: this.HostDoc + this.parecer_src});

			await AxiosAPI.post('/external/get-base64', ur, {
				headers: {
					'Content-Type': 'application/json',
					authorization: 'Bearer ' + this.token,
				}
			}).then((response) => {
				this.pdfonly = response.data.base64;
			});
		},

		CloseModal(){
			this.$refs.viewDocument.closeModal();        
		},
	}};


</script>
<template>
    <div>
        <Loading ref="Loading" style="z-index: 1002 !important;"></Loading>
    </div>
    <div>
        <ModalBigger ref="viewDocument"  style="z-index: 1001;">
            <template #header>
                <div class="d-flex justify-content-between">
                <strong><span> Parecer </span> </strong>

                <div class="flex-start">
                    <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <a target="_blank" :href="HostDoc+parecer_src"
                        download="proposed_file_name">
                            <button type="button" class="btn btn-success">Baixar documento</button>
                    </a>   
            </template>

            <template #body>
                <div id="app">
                <div class="app-content" style="height: 580px">
                    <vue-pdf-embed
                    ref="pdfRef"
                    :source="`data:application/pdf;base64,`+pdfonly"
                    class="justify-content-center"
                    />
                </div>
                </div>

            </template>

            <template #footer>
                <div>
                    <a target="_blank" :href="HostDoc+parecer_src"
                        download="proposed_file_name">
                        <div class="d-flex justify-content-center">
                            <h3>Baixar o Documento</h3>
                        </div>
                    </a>
                </div>
            </template>
        </ModalBigger>

    </div>
</template>
<style></style>