<script>
import { AxiosAPI } from '@/axios';
import { useMainStore, useBusStore } from '../../stores/index';

export default {
	name: 'TvBody',
	setup() {
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		StoreAll.Token();
		StoreAll.User();
		return {
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			session_id: 0,
			tela: '',
			Loading: true,
		};
	},

	watch: {
		
	},


	mounted() {
		this.GetSession();
	},
  
	methods: {
		async GetSession() {
			this.Loading = true;
			await this.StoreAll.Get_Data();
			const { session } = this.StoreAll;
			if (session == null) {
				this.GetSession();
				return;
			}
			const { session_status, id } = session;
			this.session = session;
			this.session_status = session_status.id;
			this.session_id = id;
			this.session_status_name = session_status.name;

		},

	},
};
</script>
<style>
.text-black {
  color: black;
}

.display_fast_body {
  width: clamp(0.1vw, 8.5vw, 300px);
  height: clamp(0.1vw, 8.5vw, 300px);
}

.display_body_nine {
  width: clamp(0.1vw, 25vw, 475px);
}

.font_element_vote {
  font-size: 27px;
}
@media only screen and (max-width: 1600px) {
  .display_fast_body {
    width: clamp(0.1vw, 8vw, 300px);
    height: clamp(0.1vw, 8vw, 300px);
  }

  .font_element_vote {
  font-size: 21px;
}
}

@media only screen and (max-width: 1380px) {
  .display_fast_body {
    width: clamp(0.1vw, 7.5vw, 300px);
    height: clamp(0.1vw, 7.5vw, 300px);
  }

  .display_body_nine {
    width: clamp(0.1vw, 25vw, 330px);
  }

  .font_element_vote {
  font-size: 19px;
}
}

@media only screen and (max-width: 1280px) {
  .display_fast_body {
    width: clamp(0.1vw, 7.1vw, 300px);
    height: clamp(0.1vw, 7.1vw, 300px);
  }

  .font_element_vote {
  font-size: 19px;
}
}

.QuorumBox{
	width: clamp(0.1vw, 22.5vw, 450px); 
	height: clamp(0.1vw, 9vw, 160px);
}

.TextQuorum{
	font-size: clamp(1vw, 4vw, 4vw);
	font-family: 'Old Standard TT', serif; 
	position: absolute; 
	transform: translate(-50%, -10%);
}

.TextMargin{
	margin-top: 5%; 
	font-size: clamp(1vw, 2vw, 3vw);
	font-family: 'Old Standard TT', serif;
}

.col-1-5-users-14 {
  width: 14% !important;
}

.col-1-5-users-11 {
  width: 20% !important;
}

.rounded-border {
  border-radius: 10px;
}

.text-white {
  color: white;
}

.font-weight-bold {
  font-weight: bold;
}
</style>
<template>
  <div>
    <div class="d-flex justify-content-center">
        <h1 style="transform: translate(-50%, 80%);margin-right: -50%;top: 39%;left: 50%;position: absolute;font-size: clamp(0.1vw, 2.5vw, 40px);font-weight: 700 !important;color: white;">
            SESSÃO FINALIZADA, OBRIGADO POR ACOMPANHAR!
        </h1>
    </div>
  </div>
</template>