import { createRouter, createWebHistory } from 'vue-router';
//import Cookie from "js-cookie"
import Guard from '../Services/service';
import GuardPublic from '../Services/authPublic';
import Home from '../views/dashboard/Home_Page.vue';
import Not_Found from '../components/Error_404.vue';
import TV from '../views/secretario/Tv.vue';
import Tv_extend from '../views/secretario/Two_tv/Tv_extend.vue';
import Controle_Tribuna from '../views/secretario/Controle_Tribuna.vue';
import Ata_Record from '../components/documents/Ata_Live.vue';
import Controle_discussion from '../views/secretario/Controle_Discussion.vue';
import Controle_explanation from '../views/secretario/Controle_Explanation.vue';
import Controle_ordem from '../views/secretario/Controle_Ordem.vue';
import Tribuna from '../views/secretario/Tribuna_Presidente.vue';
import Sessao_semana from '../views/users/Session';
import Historico_sessao from '../views/users/Historicosessao.vue';
import Commission_education from '../components/commissions/Education.vue';
import Commission_health from '../components/commissions/Health.vue';
import Commission_infraestrutura from '../components/commissions/infraestrutura.vue';
import Commission_farm from '../components/commissions/Farm.vue';
import Commission_humans from '../components/commissions/Human_rights.vue';
import Doc_recepcionado from '../components/documents/Doc_Recepcionado.vue';
import Doc_recepcionado_prefeitura from '../components/documents/Doc_Recepcionado_prefeitura.vue';
import Doc_proc from '../components/commissions/Procurador/Doc_procurador.vue';
import Doc_Model from '../components/documents/Doc_Model.vue';
import Doc_Legislativo from '../components/documents/Doc_Legislatio.vue';
import Doc_Relatorio from '../components/documents/Doc_Relatorio.vue';
import Doc_pesquisa from '../components/documents/Doc_Pesquisa.vue';
import Doc_signer from '../components/documents/Documents_signer.vue';
import Doc_pesquisa_all from '../components/documents/Doc_Pesquisa_All.vue';
import Doc_envio from '../components/documents/Doc_Envio.vue';
import Login from '../views/auth/signin/Login.vue';
import Register from '../views/auth/SingUp/Register.vue';
import Historico from '../views/users/HistoricoView/HistoricoView.vue';
import Commission_justice from '../components/commissions/Justice.vue';
import Indicacao from '../components/documents/Sub_Components/Model_Indicacao.vue';
import Mocao from '../components/documents/Sub_Components/Model_Mocao.vue';
import Mocao_Aplausos from '../components/documents/Sub_Components/Modal_Mocao_Aplausos.vue';
import Mocao_Parabeniza from '../components/documents/Sub_Components/Modal_Mocao_Parabeniza.vue';
import Mocao_Pesar from '../components/documents/Sub_Components/Modal_Mocao_Pesar.vue';
import PDL from '../components/documents/Sub_Components/Model_PDL.vue';
import PLE from '../components/documents/Sub_Components/Model_PLE.vue';
import PLL from '../components/documents/Sub_Components/Model_PLL.vue';
import RV from '../components/documents/Sub_Components/Model_RV.vue';
import Parecer_signer from '../components/documents/Documents_signer_comission.vue';
import profile from '../components/Profile/User_Profile.vue';
import doc_parecer_search from '../components/documents/Doc_pesquisa_parecer.vue';
import userExternal from '../views/userExternal/public.vue';
import ViewApprove from '../views/secretario/ViewApprove.vue';

const routes = [
	{
		path: '/',
		name: 'login',
		redirect: '/Home'
	},
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '/404',
		name: '404',
		component: Not_Found
	},
	{
		path: '/profile',
		name: 'profile',
		component: profile,
		beforeEnter: Guard.auth
	},
	{
		path: '/register',
		name: 'Register',
		component: Register
	},
	{
		path: '/Home',
		name: 'Página inicial',
		component: Home,
		beforeEnter: Guard.auth
	},
	{
		path: '/Ata',
		name: 'Ata virtual',
		component: Ata_Record,
		beforeEnter: Guard.auth
	},
	{
		path: '/sessao_semana', //testar se isso tem algum efeito negativo
		name: 'Sessão da semana',
		component: Sessao_semana,
		beforeEnter: Guard.auth
	},
	{
		path: '/historico_sessao/',
		name: 'Sessão da semana realizada',
		component: Historico,
		beforeEnter: Guard.auth
	},
	{
		path: '/analise_documentos/',
		name: 'Analise de documentos',
		component: ViewApprove,
		beforeEnter: Guard.auth
	},
	{
		path: '/tribuna',
		name: 'Tribuna',
		component: Tribuna,
		beforeEnter: Guard.auth
	},
	{
		path: '/commission_education',
		name: 'Comissão da educação',
		component: Commission_education,
		beforeEnter: Guard.auth
	},
	{
		path: '/commission_health',
		name: 'Comissão da saúde',
		component: Commission_health,
		beforeEnter: Guard.auth
	},
	{
		path: '/commission_infraestrutura',
		name: 'Comissão de infraestrutura',
		component: Commission_infraestrutura,
		beforeEnter: Guard.auth
	},
	{
		path: '/commission_justice',
		name: 'Comissão de justiça',
		component: Commission_justice,
		beforeEnter: Guard.auth
	},
	{
		path: '/commission_farm',
		name: 'Comissão da fazenda',
		component: Commission_farm,
		beforeEnter: Guard.auth
	},
	{
		path: '/commission_humans',
		name: 'Comissão de direitos humanos',
		component: Commission_humans,
		beforeEnter: Guard.auth
	},
	{
		path: '/historico',
		name: 'Histórico de sessões',
		component: Historico_sessao,
		beforeEnter: Guard.auth
	},
	{
		path: '/doc_envio',
		name: 'Envio de documentos',
		component: Doc_envio,
		beforeEnter: Guard.auth
	},
	{
		path: '/doc_legislativo',
		name: 'Documentos do legislativo',
		component: Doc_Legislativo,
		beforeEnter: Guard.auth
	},
	{
		path: '/doc_relatorio',
		name: 'Relatório de documentos',
		component: Doc_Relatorio,
		beforeEnter: Guard.auth
	},
	{
		path: '/doc_recepcionado',
		name: 'Documentos recebidos',
		component: Doc_recepcionado,
		beforeEnter: Guard.auth
	},
	{
		path: '/doc_recepcionado_prefeitura',
		name: 'Documentos recebidos prefeitura',
		component: Doc_recepcionado_prefeitura,
		beforeEnter: Guard.auth
	},
	{
		path: '/doc_procurador',
		name: 'Documentos procurador',
		component: Doc_proc,
		beforeEnter: Guard.auth
	},
	{
		path: '/doc_parecer_all',
		name: 'Documentos com parecer',
		component: doc_parecer_search,
		beforeEnter: Guard.auth
	},
	{
		path: '/doc_pesquisa',
		name: 'Meus documentos enviados',
		component: Doc_pesquisa,
		beforeEnter: Guard.auth
	},
	{
		path: '/doc_signer',
		name: 'Documentos para assinatura',
		component: Doc_signer,
		beforeEnter: Guard.auth
	},
	{
		path: '/parecer_assinatura',
		name: 'Parecer signer',
		component: Parecer_signer,
		beforeEnter: Guard.auth
	},
	{
		path: '/doc_pesquisa_geral',
		name: 'Pesquisa de documentos gerais',
		component: Doc_pesquisa_all,
		beforeEnter: Guard.auth
	},
	{
		path: '/doc_modelos',
		name: 'Modelo de documentos',
		component: Doc_Model,
		beforeEnter: Guard.auth
	},
	{
		path: '/doc_Indicacao',
		name: 'Modelo de Indicação',
		component: Indicacao,
		beforeEnter: Guard.auth
	},
	{
		path: '/doc_Mocao',
		name: 'Modelo de Moção',
		component: Mocao,
		beforeEnter: Guard.auth
	},
	{
		path: '/doc_Mocao_Aplauso',
		name: 'Modelo de Moção de aplausos',
		component: Mocao_Aplausos,
		beforeEnter: Guard.auth
	},
	{
		path: '/doc_Mocao_parabeniza',
		name: 'Modelo de Moção de parabenização',
		component: Mocao_Parabeniza,
		beforeEnter: Guard.auth
	},
	{
		path: '/doc_Mocao_pesar',
		name: 'Modelo de Moção de pesar',
		component: Mocao_Pesar,
		beforeEnter: Guard.auth
	},
	{
		path: '/doc_PDL',
		name: 'Modelo PDL',
		component: PDL,
		beforeEnter: Guard.auth
	},
	{
		path: '/doc_PLE',
		name: 'Modelo PLE',
		component: PLE,
		beforeEnter: Guard.auth
	},
	{
		path: '/doc_PLL',
		name: 'Modelo PLL',
		component: PLL,
		beforeEnter: Guard.auth
	},
	{
		path: '/doc_RV',
		name: 'Modelo RV',
		component: RV,
		beforeEnter: Guard.auth
	},
	{
		path: '/tv',
		name: 'Tela de sessão',
		component: TV,
		beforeEnter: Guard.auth
	},
	{
		path: '/tv_two',
		name: 'Tela de sessão up',
		component: Tv_extend,
		beforeEnter: Guard.auth
	},
	{
		path: '/controle_tribuna',
		name: 'Controle da Tribuna',
		component: Controle_Tribuna,
		beforeEnter: Guard.auth
	},
	{
		path: '/controle_explanation',
		name: 'Controle da explanation',
		component: Controle_explanation,
		beforeEnter: Guard.auth
	},
	{
		path: '/Controle_ordem',
		name: 'Controle da questão de ordem',
		component: Controle_ordem,
		beforeEnter: Guard.auth
	},
	{
		path: '/controle_discussion',
		name: 'Controle da discussão',
		component: Controle_discussion,
		beforeEnter: Guard.auth
	},
	{
		path: '/externo',
		name: 'Documentos do legislativo',
		component: userExternal,
		beforeEnter: GuardPublic.auth
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	linkActiveClass: 'active'
});

export default router;
