<script>
import ModalBigger from "./ModalBigger.vue";
import {AxiosAPI} from "@/axios";
export default {
	components : {
		ModalBigger
	},
	data() {
		return {};
	},
	mounted(){
	},
	methods: {
		openModal() {
			this.$refs.viewDocument.openModal();
		},
		async subscription_quorum(value){
			console.log(this.$pinia.state.value.main.quorum[0].id)
			console.log(this.$pinia.state.value.main.user.id)
			if(value === 1){
				await AxiosAPI.post("/quorum-users",
					{
						quorum_id: this.$pinia.state.value.main.quorum[0].id,
						users: [this.$pinia.state.value.main.user.id],
					},
					{
						headers: {
							authorization: `Bearer ` + this.$pinia.state.value.main.accessToken,
						},
					}
				).then((resp) => {
					console.log(resp);
					this.$refs.viewDocument.closeModal();
					this.emitter.emit("subscribe_user", 1);
				});
			}else {
				this.$pinia.state.value.main.in_quorum = false;
			}
		}
	}
};
</script>
    <style>
    .swal2-container {
      z-index: 10001 !important;
    }
    .close{
      font-size: 2.3rem !important;
    }
    </style>
<template>
    <div>
     <ModalBigger ref="viewDocument">
          <template #header>
            <div class="status_label">
                  <strong><span class="ml mr title">Presença em quorum</span></strong>
              </div>
            <div class="flex-start">
                            <button
                            id="close"
                            type="button"
                            class="close"
                            @click="$refs.viewDocument.closeModal()"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
            </div>
          </template>
          <template #body>
            <div class="d-flex justify-content-center">
                <span class="text-black mb-3" style="font-size: 20px;"> <b>DESEJA TER A PRESENÇA NO QUORUM ATUAL ? </b></span>
            </div>
             <div class="d-flex justify-content-center"> 
            <button type="button" class="btn btn-success mb-1  mr-3" 
            style="width: 150px; height: 60px;" @click="subscription_quorum(1); $refs.viewDocument.closeModal()">Sim</button>
            <button type="button" class="btn btn-danger mb-1" 
            style="width: 150px; height: 60px;" @click="subscription_quorum(2); $refs.viewDocument.closeModal()">Não</button>
            </div>
          </template>
          <template #footer>
          </template>
      </ModalBigger>
      </div>
    </template>