<template>
    <div>
        <Loading ref="Loading" style="z-index: 1002 !important;"></Loading>
    </div>
    <div>
        <ModalBigger ref="viewDocument" style="z-index: 1001;">
            <template #header>
                
                <span style="color: black"><strong> Anexar documento a outro documento </strong></span>

                <div class="flex-start">
                    <button type="button" class="close" @click="$refs.viewDocument.closeModal();">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </template>

            <template #body>
        
            <div>
                <label>Nome do anexo</label>
                <input v-model="anexo_name" class="mb-3 multisteps-form__input form-control" type="text" placeholder="LEI SANSIONADA"/>

                <div class="mt-3 mb-3">
                    <label class="form-label">Selecione o documento principal</label>
                    <select id="choices-category" v-model="document_id_chose" class="form-control" name="choices-category"
                    @change="change">
                    <option v-for="docs in documents_in_secretario" :key="docs.id" :value="docs.id">
                        {{ docs.name }}
                    </option>
                </select>
                </div>
            </div>

            <div class="mb-3">
                    <label for="formFile" class="form-label">Selecione o arquivo</label>
                    <input id="formFile" class="form-control" type="file" accept=".pdf" @change="ValidatInputFile" />
                </div>
            </template>

            <template #footer>
            
            <div>
                <div class="d-flex justify-content-center">
                    <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">
                        Fechar
                    </button>
                    <button type="button" class="btn btn-success" data-dismiss="modal" @click="send_attach();">
                        anexar documento!
                    </button>
                </div>
            </div>
            </template>
        </ModalBigger>
    </div>
</template>
    
<script>
import ModalBigger from "./ModalBigger.vue";
import { AxiosAPI } from "@/axios";
import Loading from '../Modals/Loading.vue';
export default {
    components: {
        ModalBigger,
        Loading
    },

    data() {
        return {
            anexo_name: '',
            documento_src: null,
            document_status_move: null,
            documento_statusid: null,
            documento_id: null,
            documento_src_proc: null,
            document_id_chose: [],
            documents_in_secretario: [],
        }
    },

    methods: {
        openModal(event) {
            this.documents_in_secretario = event
            this.$refs.viewDocument.openModal();
        },

        send_attach(){
            let file = document.getElementById("formFile").files[0];
            const formData = new FormData();

                formData.append("document_id", this.document_id_chose);
                formData.append("name", this.anexo_name);
                formData.append("attachments[]", file);

                console.log(formData)
                AxiosAPI.post("/documents/86/attachment", formData, {
                    headers: {
                        Authorization: `Bearer ` + this.$pinia.state.value.main.accessToken,
                    },
                })
                    .then((resp) => {
                        console.log(resp)
                        this.emitter.emit("send_parecer", 1);
                        this.Doc_ok_send();
                        this.$refs.Loading.closeModal();
                        this.$refs.viewDocument.closeModal();
                    })
        },

        ValidatInputFile() {
            const selectedFile = document.getElementById("formFile").files[0];
            this.header_error = ''
            this.body_error = ''
            if (selectedFile) {
                if (selectedFile.type !== "application/pdf") {
                    this.header_error = 'Documento inválido'
                    this.body_error = 'Apenas documentos PDF são permitidos para envio.'
                    this.show_error();
                    document.getElementById('formFile').value = '';
                    console.log(document.getElementById("formFile").files[0])
                    return;
                }
            } else {
                console.log("Nenhum arquivo selecionado.");
            } 

        },

        Doc_ok_send() {
            this.$toast.add({ severity: 'success', summary: 'Documento', detail: 'Documento anexado com sucesso', life: 3000 });
        },

    }
}


</script>
    
<style></style>