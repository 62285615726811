<script>
import { AxiosAPI } from '@/axios';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import moment from 'moment';
import { useMainStore } from '../../stores/index';
import Modal_view from '../../components/documents/Modals/Modal_View.vue';

export default {
	name: 'DocSearchAll',
	components: {
		Modal_view,
	},
	setup() {
		const StoreAll = useMainStore();
		StoreAll.Token();
		return {
			StoreAll: StoreAll,
		};
	},
	data() {
		return {
			comission: false,
			document_timeline_leitura: null,
			Timeline: 1,
			filter: {
				protocol: null,
				name: null,
				type_doc: 0,
				status_doc: 0,
				date_creat: null,
				authors: [],
			},
			vereadores: [],
			expandedKeys: {},
			messages: [],
			result_vote: [
				{ key: '0', result: 'Selecione o status do documento' },
				{ key: '1', result: 'Aprovado' },
				{ key: '2', result: 'Reprovado' },
				{ key: '3', result: 'Em vista' },
			],
			categories: [{id: 0, name: 'Selecione o tipo do documento'}],
			Search_all: [],
			session_id: 'realizadas',
			documento_session_id: 0,
			document_status_vote_id: '...',
			documento_name: 'Carregando...',
			documento_src: '',
			documento_statusid: 0,
			documento_id: 0,
			logs: []
		};
	},
	mounted() {
		this.GetUser();
		this.GetUsersAll();
		this.Get_Search_all();
		this.GetSession();
	},
	methods: {
		resultvote(value) {

			let inner = 'Documento' + ' ';
			let result_one = '';
			let result_two = '';

			if (value.voting_result_1 === 1) {
				result_one = 'aprovado em 1° votação';
			} else if (value.voting_result_1 === 2) {
				result_one = 'reprovado em 1° votação';
			} else {
				result_one = 'em vista em 1° votação';
			}

			if (value.voting_result_2 === 1) {
				result_two = ' ' + 'e aprovado em 2° votação';
			} else if (value.voting_result_2 === 2) {
				result_two = ' ' + 'e reprovado em 2° votação';
			} else {
				result_two = ' ' + 'e em vista em 2° votação';
			}

			let result = inner + result_one + result_two;
			return result;
		},

		async GetUsersAll() {
			await this.StoreAll.UserAllEnvio();
			this.vereadores = this.StoreAll.user_envio;
		},

		async GetUser() {
			await this.StoreAll.User();
			this.category_user = this.StoreAll.user.user_category_id;
		},

		async GetSession() {
			await this.StoreAll.Get_Data();
			await this.StoreAll.DocCategory();

			let OnSystem = setInterval(() => {
				if (this.StoreAll.document_category != null) {
					clearInterval(OnSystem);
					this.categories = this.StoreAll.document_category;
				}
			}, 500);
		},


		FilterDoc() {
			function getValueOrEmpty(value) {
				return value != null && value !== '' ? value : '';
			}

			// Função para extrair IDs de autores
			function extractAuthorIds(authors) {
				return authors.map(author => author.id);
			}

			// Filtrar os valores e criar o objeto de filtro
			const filteredValues = {
				protocolo: getValueOrEmpty(this.filter.protocol),
				name: getValueOrEmpty(this.filter.name),
				type_doc: this.filter.type_doc !== 0 ? this.filter.type_doc : '',
				status_doc: this.filter.status_doc !== 0 ? this.filter.status_doc : '',
				date_creat: getValueOrEmpty(this.filter.date_creat),
				authors: this.filter.authors.length > 0 ? extractAuthorIds(this.filter.authors) : '',
			};

			// Verificar se todos os campos estão vazios
			const allFieldsEmpty = Object.values(filteredValues).every(value => value === '');

			let filt_no_id = '?document_category_id=' + filteredValues.type_doc + '&name=' + filteredValues.name + '&id=' + filteredValues.protocolo + '&voting_result=' + filteredValues.status_doc + '&created_at=' + filteredValues.date_creat;

			if (filteredValues.authors) {
				filt_no_id += '&authors=' + JSON.stringify(filteredValues.authors);
			}

			if (allFieldsEmpty) {
				AxiosAPI
					.get('/documents', {
						headers: {
							Authorization: `Bearer ${this.StoreAll.accessToken}`,
						},
					})
					.then((resp) => {
						this.Search_all = resp.data.data;
					});
			} else {
				AxiosAPI
					.get('/documents' + filt_no_id, {
						headers: {
							Authorization: `Bearer ${this.StoreAll.accessToken}`,
						},
					})
					.then((resp) => {
						this.Search_all = resp.data.data;
					});
			}

		},


		Get_Search_all() {
			AxiosAPI
				.get('/documents', {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				})
				.then((resp) => {

					this.Search_all = resp.data.data;


				});
		},
		showError() {
			this.$toast.add({ severity: 'error', summary: 'Sessão Não Encotrada', detail: 'Documento não possui sessão', life: 3000 });
		},
		Go_History(event) {
			if (event.sessions.length <= 0) {
				this.showError();

				return;
			} else {
				localStorage.setItem('Session_id', event.sessions[0].id);
				window.location.href = '/historico_sessao/';
			}
		},
		formatDate(value) {
			moment.locale('pt-br');
			return moment(value).format('DD/MMMM/YYYY');
		},

		ViewDoc(event) {
			this.StoreAll.document_details = event;
			/* Populando a variavel global */

			if (event.carimbo_camara_pdf == null) {
				this.StoreAll.Leitura = false;
				if (event.attachment_partial != null) {
					this.StoreAll.document_src = event.attachment_partial;
				} else {
					this.StoreAll.document_src = event.attachment;
				}
			} else if (event.carimbo_camara_pdf != null && event.carimbo_prefeitura_pdf != null) {
				this.StoreAll.Leitura = true;
				this.StoreAll.document_src = event.carimbo_prefeitura_pdf;
			} else if (event.carimbo_camara_pdf != null && event.carimbo_prefeitura_pdf == null) {
				this.StoreAll.Leitura = true;
				this.StoreAll.document_src = event.carimbo_camara_pdf;
			} else {
				this.StoreAll.Leitura = false;
				if (event.attachment_partial != null) {
					this.StoreAll.document_src = event.attachment_partial;
				} else {
					this.StoreAll.document_src = event.attachment;
				}
			}

			this.$refs.Modal_view.openModal2();
		},

		Author(value) {
			let Co_user = [];
			for (let i = 0; i < value.length; i++) {
				Co_user.push(value[i].nickname);
			}
			return Co_user.join(', ');
		},

		Timeline_status(event) {
			this.logs = [];
			const options = { year: 'numeric', month: 'long', day: 'numeric' };

			/* 
      this.Timeline = 1 // Documento criado
      this.Timeline = 2 // Enviado para sessão
      this.Timeline = 3 // Documento Votado
      this.Timeline = 4 // Documento encaminhado para prefeitura
      this.Timeline = 5 // Retornado para o secretário para encaminhamento a comissões
      this.Timeline = 6 // Documento enviado para leitura
      
      */
			/* Documento que foi para leitura */

			if (event.document_status_movement_id == 1 && event.document_status_vote_id == 1) {
				this.Timeline = 1;
				this.document_timeline_leitura = false;
				/* Documento foi criado e ainda não movimentado */
			} else if (event.document_status_movement_id == 1 && event.document_status_vote_id != 1) {
				this.Timeline = 5;
				this.comission = true;
				this.document_timeline_leitura = false;
				/* Documento foi para secretário, porém já esteve em sessão */
			} else if (event.document_status_movement_id == 2 && event.document_status_vote_id == 6) {
				this.Timeline = 6;
				this.comission = false;
				this.document_timeline_leitura = true;
				/* Documento foi para sessão, porém em leitura */
			} else if (event.document_status_movement_id == 2 && event.document_status_vote_id == 2) {
				this.Timeline = 2;
				this.comission = false;
				this.document_timeline_leitura = false;
				/* Documento foi para sessão, porém em leitura */
			} else if (event.document_status_movement_id == 2 && event.document_status_vote_id == 5) {
				this.Timeline = 3;
				this.comission = false;
				this.document_timeline_leitura = false;
				/* Documento foi para sessão, porém em leitura */
			} else if (event.document_status_movement_id == 6 && event.document_status_vote_id == 5) {
				this.Timeline = 4;
				this.comission = false;
				this.document_timeline_leitura = false;
				/* Documento foi para sessão, porém em leitura */
			}

			this.documento_name = event.name;
			this.documento_src = event.attachment;
			this.document_status_vote_id = event.document_status_vote_id;
			this.documento_id = event.id;

			this.data_timeline_secretario = new Date(event.created_at).toLocaleString('pt-BR', options).replace(/ de /g, '/');

			let log = [];

			for (let i = 0; i < event.logs.length; i++) {
				if(event.logs[i].status_log_id == 1){
					log.push({'creat':event.logs[i].document_log_datetime});
				}
				if(event.logs[i].status_log_id == 2){
					log.push({'session':event.logs[i].document_log_datetime});
				}
				if(event.logs[i].status_log_id == 4){
					log.push({'send_vote':event.logs[i].document_log_datetime});
				}
				if(event.logs[i].status_log_id == 5){
					log.push({'vote_finish':event.logs[i].document_log_datetime});
				}
				if(event.logs[i].status_log_id == 6){
					log.push({'send_pref':event.logs[i].document_log_datetime});
				}
			}
    
      
			const mergedObject = {};

			log.forEach(item => {
				for (const key in item) {
					mergedObject[key] = item[key];
				}
			});

			this.logs = mergedObject;
		},

	},
};
</script>
<template>
  <div>
    <Modal_view ref="Modal_view"></Modal_view>
  </div>
  <div class="container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">Pesquise todos documentos enviados no sistema!</h5>
            <p class="mb-0 text-sm">
              Aquivo você pode visualizar todos os documentos enviados no sistema.
            </p>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="table-responsive">
                  <DataTable :value="Search_all" show-gridlines responsive-layout="scroll"
                    :paginator="true" :rows="10">
                    <template #header>
                      <div class="d-flex row">
                        <div class="col-md-4 col-md-auto col-sm">
                          <div class="card flex justify-content-center">
                            <label for="username">Protocolo</label>
                          </div>
                          <div>
                            <input placeholder="Protocolo do documento" v-model="filter.protocol" class="my_inp" />
                          </div>
                          <div class="card flex justify-content-center">
                            <small id="username-help">Informe o protocolo do documento.</small>
                          </div>
                        </div>


                        <div class="col-md-4 col-md-auto col-sm">
                          <div class="card flex justify-content-center">
                            <label for="username">Objeto do documento</label>
                          </div>
                          <div>
                            <input class="my_inp" placeholder="Objeto do documento" v-model="filter.name" />
                          </div>
                          <div class="card flex justify-content-center">
                            <small id="username-help">Informe o objeto do documento.</small>
                          </div>
                        </div>

                        <div class="col-md-4 col-md-auto col-sm">
                          <div class="card flex justify-content-center">
                            <label for="username">Tipo do documento</label>
                          </div>
                          <select class="my_inp" id="choices-category" v-model="filter.type_doc" name="choices-category">
                            <option v-for="option in categories" :key="option.id" :value="option.id">
                              {{ option.name }}
                            </option>
                          </select>
                          <div class="card flex justify-content-center">
                            <small id="username-help">Informe o tipo do documento.</small>
                          </div>
                        </div>

                        <div class="col-md-4 col-md-auto col-sm">
                          <div class="card flex justify-content-center">
                            <label for="username">Status do documento</label>
                          </div>
                          <select class="my_inp" id="choices-category" v-model="filter.status_doc"
                            name="choices-category">
                            <option v-for="option in result_vote" :key="option.key" :value="option.key">
                              {{ option.result }}
                            </option>
                          </select>
                          <div class="card flex justify-content-center">
                            <small id="username-help">Informe o status do documento</small>
                          </div>
                        </div>

                        <div class="col-md-4 col-md-auto col">
                          <div class="card flex justify-content-center">
                            <label for="username">Autor(es)</label>
                          </div>
                          <MultiSelectPrime id="choices-category" v-model="filter.authors" display="chip"
                            :options="vereadores" option-label="name" placeholder="Selecione o(s) autor(es)"
                            class="my_inp p-multiselect-label p-multiselect-token"
                            style="height: calc(3.5rem + 2px) !important" />
                          <div class="card flex justify-content-center">
                            <small id="username-help">Informe os autor(es) do(s) documento(s)</small>
                          </div>
                        </div>


                        <div class="col-md-4 col-md-auto col-sm">
                          <div class="card flex justify-content-center">
                            <label for="username">Data de envio</label>
                          </div>
                          <input type="date" class="mb-3 multisteps-form__input form-control my_inp"
                            v-model="filter.date_creat">
                          <div class="card flex justify-content-center">
                            <small id="username-help">Informe a data de envio</small>
                          </div>
                        </div>

                        <div class="d-flex justify-content-center">
                          <button class="btn bg-smartlegis text-white" style="width: 100%;"
                            @click="FilterDoc()">Filtrar</button>
                        </div>
                      </div>
                    </template>

                    <Column field="id" header="Protocolo" class="text-sm font-weight-bold text-wrap text-uppercase"
                      :sortable="true"></Column>

                    <Column field="name" header="Documento" class="text-sm font-weight-bold text-wrap text-uppercase">
                    </Column>

                    <Column field="document_category.name" header="Tipo"
                      class="text-sm font-weight-bold text-wrap text-uppercase"></Column>

                    <Column field="authors" header="Autor(es)" class="text-sm font-weight-bold text-wrap text-uppercase"
                      :sortable="true">
                      <template #body="{ data }">
                        <span>{{ Author(data.authors) }} </span>
                      </template>
                    </Column>

                    <Column field="created_at" header="Data" class="text-sm font-weight-bold text-wrap text-uppercase">
                      <template #body="{ data }">
                        {{ formatDate(data.created_at) }}
                      </template>
                    </Column>

                    <Column field="voting_result_1" header="Resultado" class="text-sm font-weight-bold text-uppercase">
                      <template #body="{ data }">
                        <span v-if="data.voting_result_1 === null" class="text-center font-weight-bolder text-white"
                          style="background-color: #261e1e; border-radius: 6px;font-size: 15px;padding: 4px;"> Não votado
                        </span>

                        <span v-else-if="data.voting_result_1 === 1 && data.voting_result_2 === null"
                          class="bg-success text-center font-weight-bolder text-white"
                          style="border-radius: 6px;font-size: 15px;padding: 4px;"> Documento aprovado
                        </span>
                        <span v-else-if="data.voting_result_1 === 2 && data.voting_result_2 === null"
                          class="bg-danger text-center font-weight-bolder text-white"
                          style="border-radius: 6px;font-size: 15px;padding: 4px;"> Documento reprovado
                        </span>
                        <span v-else-if="data.voting_result_1 === 3 && data.voting_result_2 === null"
                          class="bg-secondary text-center font-weight-bolder text-white"
                          style="border-radius: 6px;font-size: 15px;padding: 4px;"> Documento em vista
                        </span>
                        <span v-else-if="data.voting_result_1 != null && data.voting_result_2 != null"
                          class="text-center font-weight-bolder text-white"
                          style="background-color: #2a8b89; border-radius: 6px;font-size: 15px;padding: 4px;">
                          {{ resultvote(data) }} </span>

                        <span v-else class="text-center font-weight-bolder text-white"
                          style="background-color: #261e1e; border-radius: 6px;font-size: 15px;padding: 4px;"> Não
                          votado</span>
                      </template>
                    </Column>

                    <Column v-if="this.StoreAll.user.user_category_id == 1" header="Carimbo" class="text-sm font-weight-bold text-wrap text-uppercase text-center">
                      <template #body="slotProps">
                        <i v-if="slotProps.data.carimbo_camara_pdf != null" class="fa fa-check-circle-o text-success" aria-hidden="true" style="font-size: 20px;"></i>
                        <i v-else class="fa fa-times-circle-o text-danger" aria-hidden="true"  style="font-size: 20px;"></i>
                      </template>
                    </Column>
                    <Column header="Carimbo prefeitura" class="text-sm font-weight-bold text-wrap text-uppercase text-center">
                              <template #body="slotProps">
                                <i v-if="slotProps.data.carimbo_prefeitura_pdf != null" class="fa fa-check-circle-o text-success" aria-hidden="true" style="font-size: 20px;"></i>
                                <i v-else class="fa fa-times-circle-o text-danger" aria-hidden="true"  style="font-size: 20px;"></i>
                              </template>
                    </Column>
                    <Column header="Assinado" class="text-sm font-weight-bold text-wrap text-uppercase text-center">
                      <template #body="slotProps">
                        <i v-if="slotProps.data.status_sign === 2 || slotProps.data.status_sign === 1" class="fa fa-check-circle-o text-success" aria-hidden="true" style="font-size: 20px;"></i>
                        <i v-else class="fa fa-times-circle-o text-danger" aria-hidden="true"  style="font-size: 20px;"></i>
                      </template>
                    </Column> 
                    <Column header="Movimentação"
                      class="text-sm font-weight-bold text-wrap text-uppercase TT text-center">
                      <template #body="slotProps">
                        <button data-toggle="modal" style="margin-bottom: 0rem !important;" data-target=".Timeline"
                          type="button" class="btn bg-smartlegis btnEdit envio" @click="Timeline_status(slotProps.data)">
                          <i style="color: white !important;" class='fa fa-list'></i> </button>
                      </template>
                    </Column>
                    <Column header="Visualizar"
                      class="text-sm font-weight-bold text-wrap text-uppercase TT justify-content-center text-center">
                      <template #body="slotProps">
                        <button style="margin-bottom: 0rem !important;" type="button"
                          class="btn bg-smartlegis btnEdit envio" @click="ViewDoc(slotProps.data)"><i
                            style="color: white !important;" class="fa fa-eye"></i></button>
                      </template>
                    </Column>
                    <Column header="Ir para sessão"
                      class="text-sm font-weight-bold text-wrap text-uppercase TT text-center">
                      <template #body="slotProps">
                        <button data-toggle="modal" style="margin-bottom: 0rem !important;" type="button"
                          class="btn bg-smartlegis btnEdit envio" @click="Go_History(slotProps.data)"><i
                            style="color: white !important;" class="fa fa-arrow-circle-o-right"></i></button>
                      </template>
                    </Column>
                  </DataTable>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div id="mymodals" class="modal fade bd-example-modal-xl Timeline" tabindex="-1" role="dialog"
      aria-labelledby="myExtraLargeModalLabel" aria-hidden="true" style="z-index: 10000; display: none;">
      <div class="modal-dialog modal-xl Search_all">
        <div class="modal-content">
          <div class="modal-header">

            <strong><span> {{ documento_name }} </span> </strong>

            <button id="closess" type="button" class="close" data-dismiss="modal" aria-label="Fechar">
              <span aria-hidden="true">&times;</span>
            </button>

          </div>
          <div v-if="comission == false" class="modal-body">

            <div v-if="document_timeline_leitura == false" class="d-flex justify-content-center">
              <div style="display: flex; flex-direction: column; align-items: center;">

                <div class="ml-2 justify-content-center"
                  :class="{ 'bg-success': Timeline == 1 || Timeline == 2 || Timeline == 3 || Timeline == 4 || Timeline == 5 || Timeline == 6 }"
                  style="display: flex; align-items: center; justify-content: center; border: 1px #222020;width: clamp(0.1vw, 3vw, 302px);border-style: double;height:clamp(0.1vw, 3vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                  <span class="text-black font-weight-bold"
                    style="font-size: clamp(1vw, 1.5vw, 4vw); text-align: center;">1</span>
                </div>
                <span> Documento criado </span>
                <span v-if="logs.creat === undefined" class="text-center"> - </span>
                <span v-else class="text-center"> {{ logs.creat }} </span>
              </div>

              <hr style="border: none; border-top: 2px solid black; margin: 20px 0; width: 5%;">


              <div style="display: flex; flex-direction: column; align-items: center; ">
                <div class="ml-2 justify-content-center"
                  :class="{ 'bg-success': Timeline == 2 || Timeline == 3 || Timeline == 4 || Timeline == 5 || Timeline == 6 }"
                  style="display: flex; align-items: center; justify-content: center; border: 1px #222020;width: clamp(0.1vw, 3vw, 302px);border-style: double;height:clamp(0.1vw, 3vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                  <span class="text-black font-weight-bold"
                    style="font-size: clamp(1vw, 1.5vw, 4vw); text-align: center;">2</span>
                </div>
                <span> Enviado para sessão </span>

                <span v-if="logs.session === undefined" class="text-center"> - </span>
                <span v-else class="text-center"> {{ logs.session }} </span>
              </div>

              <hr style="border: none; border-top: 2px solid black; margin: 20px 0; width: 5%;">

              <div style="display: flex; flex-direction: column; align-items: center;">
                <div class="ml-2 justify-content-center" :class="{ 'bg-success': Timeline == 3 || Timeline == 4 }"
                  style="display: flex; align-items: center; justify-content: center; border: 1px #222020;width: clamp(0.1vw, 3vw, 302px);border-style: double;height:clamp(0.1vw, 3vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                  <span class="text-black font-weight-bold"
                    style="font-size: clamp(1vw, 1.5vw, 4vw); text-align: center;">3</span>
                </div>
                <span> Documento votado </span>
                <span v-if="logs.vote_finish === undefined" class="text-center"> - </span>
                <span v-else class="text-center"> {{ logs.vote_finish }} </span>
              </div>

              <hr style="border: none; border-top: 2px solid black; margin: 20px 0; width: 5%;">

              <div style="display: flex; flex-direction: column; align-items: center;">
                <div class="ml-2 justify-content-center" :class="{ 'bg-success': Timeline == 4 }"
                  style="display: flex; align-items: center; justify-content: center; border: 1px #222020;width: clamp(0.1vw, 3vw, 302px);border-style: double;height:clamp(0.1vw, 3vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                  <span class="text-black font-weight-bold"
                    style="font-size: clamp(1vw, 1.5vw, 4vw); text-align: center;">4</span>
                </div>
                <span> Encaminhado a Prefeitura </span>
                <span v-if="logs.send_pref === undefined" class="text-center"> - </span>
                <span v-else class="text-center"> {{ logs.send_pref }} </span>
              </div>
            </div>

            <div v-else class="d-flex justify-content-center">
              <div style="display: flex; flex-direction: column; align-items: center;">

                <div class="ml-2 justify-content-center"
                  :class="{ 'bg-success': Timeline == 1 || Timeline == 2 || Timeline == 3 || Timeline == 4 || Timeline == 5 || Timeline == 6 }"
                  style="display: flex; align-items: center; justify-content: center; border: 1px #222020;width: clamp(0.1vw, 3vw, 302px);border-style: double;height:clamp(0.1vw, 3vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                  <span class="text-black font-weight-bold"
                    style="font-size: clamp(1vw, 1.5vw, 4vw); text-align: center;">1</span>
                </div>
                <span> Documento criado </span>
                <span v-if="logs.creat === undefined" class="text-center"> - </span>
                <span v-else class="text-center"> {{ logs.creat }} </span>
              </div>

              <hr style="border: none; border-top: 2px solid black; margin: 20px 0; width: 5%;">


              <div style="display: flex; flex-direction: column; align-items: center; ">
                <div class="ml-2 justify-content-center"
                  :class="{ 'bg-success': Timeline == 2 || Timeline == 3 || Timeline == 4 || Timeline == 5 || Timeline == 6 }"
                  style="display: flex; align-items: center; justify-content: center; border: 1px #222020;width: clamp(0.1vw, 3vw, 302px);border-style: double;height:clamp(0.1vw, 3vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                  <span class="text-black font-weight-bold"
                    style="font-size: clamp(1vw, 1.5vw, 4vw); text-align: center;">2</span>
                </div>
                <span> Enviado para sessão </span>
                <span v-if="logs.session === undefined" class="text-center"> - </span>
                <span v-else class="text-center"> {{ logs.session }} </span>
              </div>

              <hr style="border: none; border-top: 2px solid black; margin: 20px 0; width: 5%;">

              <div style="display: flex; flex-direction: column; align-items: center;">
                <div class="ml-2 justify-content-center" :class="{ 'bg-success': Timeline == 6 }"
                  style="display: flex; align-items: center; justify-content: center; border: 1px #222020;width: clamp(0.1vw, 3vw, 302px);border-style: double;height:clamp(0.1vw, 3vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                  <span class="text-black font-weight-bold"
                    style="font-size: clamp(1vw, 1.5vw, 4vw); text-align: center;">3</span>
                </div>
                <span> Documento em leitura na sessão </span>
                <span v-if="logs.session === undefined" class="text-center"> - </span>
                <span v-else class="text-center"> {{ logs.session }} </span>
              </div>

              <hr style="border: none; border-top: 2px solid black; margin: 20px 0; width: 5%;">

            </div>

          </div>


          <div v-else class="modal-body">

            <div class="justify-content-center">


              <div class="d-flex justify-content-center">
                <div style="display: flex; flex-direction: column; align-items: center;">
                  <div class="ml-2 justify-content-center"
                    :class="{ 'bg-success': Timeline == 1 || Timeline == 2 || Timeline == 3 || Timeline == 4 || Timeline == 5 || Timeline == 6 }"
                    style="display: flex; align-items: center; justify-content: center; border: 1px #222020;width: clamp(0.1vw, 3vw, 302px);border-style: double;height:clamp(0.1vw, 3vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                    <span class="text-black font-weight-bold"
                      style="font-size: clamp(1vw, 1.5vw, 4vw); text-align: center;">1</span>
                  </div>
                  <span> Documento criado </span>
                  <span v-if="logs === false" class="text-center"> - </span>
                </div>
                <hr style="border: none; border-top: 2px solid black; margin: 20px 0; width: 5%;">




                <div style="display: flex; flex-direction: column; align-items: center; ">
                  <div class="ml-2 justify-content-center"
                    :class="{ 'bg-success': Timeline == 2 || Timeline == 3 || Timeline == 4 || Timeline == 5 || Timeline == 6 }"
                    style="display: flex; align-items: center; justify-content: center; border: 1px #222020;width: clamp(0.1vw, 3vw, 302px);border-style: double;height:clamp(0.1vw, 3vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                    <span class="text-black font-weight-bold"
                      style="font-size: clamp(1vw, 1.5vw, 4vw); text-align: center;">2</span>
                  </div>
                  <span> Enviado para sessão </span>
                  <span v-if="logs === false" class="text-center"> - </span>
                </div>
                <hr style="border: none; border-top: 2px solid black; margin: 20px 0; width: 5%;">



                <div style="display: flex; flex-direction: column; align-items: center; ">
                  <div class="ml-2 justify-content-center" :class="{ 'bg-success': Timeline == 5 }"
                    style="display: flex; align-items: center; justify-content: center; border: 1px #222020;width: clamp(0.1vw, 3vw, 302px);border-style: double;height:clamp(0.1vw, 3vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                    <span class="text-black font-weight-bold"
                      style="font-size: clamp(1vw, 1.5vw, 4vw); text-align: center;">3</span>
                  </div>
                  <span> Documento retornado para secretário </span>
                  <span v-if="logs === false" class="text-center"> - </span>
                </div>
                <hr style="border: none; border-top: 2px solid black; margin: 20px 0; width: 5%;">



                <div class="d-flex justify-content-center">
                  <div style="display: flex; flex-direction: column; align-items: center;">
                    <div class="ml-2 justify-content-center" :class="{ 'bg-success': Timeline == 3 || Timeline == 4 }"
                      style="display: flex; align-items: center; justify-content: center; border: 1px #222020;width: clamp(0.1vw, 3vw, 302px);border-style: double;height:clamp(0.1vw, 3vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                      <span class="text-black font-weight-bold"
                        style="font-size: clamp(1vw, 1.5vw, 4vw); text-align: center;">5</span>
                    </div>
                    <span> Votação concluída </span>
                    <span v-if="logs === false" class="text-center"> - </span>
                  </div>
                  <hr style="border: none; border-top: 2px solid black; margin: 20px 0; width: 5%;">



                  <div style="display: flex; flex-direction: column; align-items: center;">
                    <div class="ml-2 justify-content-center" :class="{ 'bg-success': Timeline == 4 }"
                      style="display: flex; align-items: center; justify-content: center; border: 1px #222020;width: clamp(0.1vw, 3vw, 302px);border-style: double;height:clamp(0.1vw, 3vw, 302px);color: black;border-radius: 50%;box-shadow: 3px 2px black;">
                      <span class="text-black font-weight-bold"
                        style="font-size: clamp(1vw, 1.5vw, 4vw); text-align: center;">6</span>
                    </div>
                    <span> Encaminhado a Prefeitura </span>
                    <span v-if="logs === false" class="text-center"> - </span>
                  </div>
                </div>
              </div>
            </div>


            <div class="modal-footer d-flex justify-content-center">
              <div>
                <a target="_blank" :href="documento_src" download="proposed_file_name">
                  <div>
                    <h3>Baixar o Documento</h3>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Toast />

  </div>
</template>
<style scoped>
.close {
  font-size: 2.3rem !important;
}

.p-multiselect-token {
  padding: 0.175rem 0.75rem !important;
}

.p-multiselect-label {
  padding: 0px !important;
}

.my_inp {
  width: 100%;
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.38);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
}</style>
