<template>
    <div>
        <ModalBigger ref="viewDocument" style="z-index: 1051;">
            <template #header>
                <span class="text-dark"> <strong>Alerta</strong> </span>

                <div class="flex-start">
                    <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </template>

            <template #body>

                <div v-if="type_alert == 1" class="d-flex justify-content-center">
                    <span class="text-dark"><strong>Você realmente deseja remover esse carimbo ?</strong></span>
                </div>

            </template>

            <template #footer>

                <div v-if="type_alert == 1" class="d-flex justify-content-center">
                    <button type="button" class="btn btn-primary bg-danger mr-3" @click="$refs.viewDocument.closeModal()">
                        Voltar
                    </button>
                    <button type="button" class="btn btn-primary bg-success" @click="Remove_Stamp_approved()">
                        Confirmar
                    </button>
                </div>
            </template>
        </ModalBigger>

    </div>
</template>
    
<script>
import ModalBigger from "./ModalBigger.vue";
import { AxiosAPI } from "@/axios";
import { useMainStore } from "../../../stores/index";
import { useBusStore } from "../../../stores/index";
export default {
    components: {
        ModalBigger,
    },

    setup() {
        const StoreAll = useMainStore();
        const Bus = useBusStore();
        return {
          StoreAll: StoreAll,
          Bus
        }
      },
      
    data() {
        return {
            carimbo_locale: 0,
            type_alert: 1
        }
    },

    methods: {
        openModal() {
            this.type_alert = 1
            this.$refs.viewDocument.openModal();
        },


        Remove_Stamp_approved() {
            let carimbo = JSON.stringify({
            document_id: [this.StoreAll.document_details.id],   
            stamp: 'camara'
            })

            AxiosAPI.patch("/documents/stamp-remove", carimbo, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.StoreAll.accessToken}`,
            },
            }).then(() => {
                this.$refs.viewDocument.closeModal();
                this.showSuccess_carimbo();
                this.Bus.docSendSession = true
            })
        },

        showSuccess_carimbo() {
            this.$toast.add({ severity: 'success', summary: 'Carimbo removido', detail: 'Carimbo removido com sucesso', life: 3000 });
        },
    }
}


</script>
    
<style></style>