<script>
import { AxiosAPI } from '@/axios';
import { useMainStore, useBusStore } from '../../../stores/index';

export default {
	name: 'TvStandby',
	setup() {
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		StoreAll.Token();
		StoreAll.User();
		return {
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			brasao: '',
			status_tv: 1,
			votedlegenda: false,
			session_id: 0,
			DocVoted: '',
			us: [],
			User_Tot: [],
			users: [],
			document: [],
			user_presidente: [],
			users_quorums: [],
			tela: '',
			presentess: 0,
			ausentess: 0,
			totall: 0,
			tribuna_id: 0,
			dia: 0,
			mes: '',
			time: '',
			timer_session: 'Aguarde o inicio',
			Session_start: false,
			QuorumRemoveUser: false,
			QuorumAddUser: false,
			DocumentVoteStart: false,
			EndVote: false,
			socket_ids: [],
		};
	},

	watch: {
		Session_start: function (novoValor, valorAntigo) {
			if (novoValor !== false) {
				this.Get_Quorum_Socket_Tv_full();
				this.Session_start = false;
			}
		},

		QuorumRemoveUser: function (novoValor, valorAntigo) {
			if (novoValor !== false) {
				this.Get_Quorum_Socket_Tv_full();
				this.QuorumRemoveUser = false;
			}
		},

		QuorumAddUser: function (novoValor, valorAntigo) {
			if (novoValor !== false) {
				this.Get_Quorum_Socket_Tv_full();
				this.QuorumAddUser = false;
			}
		},

		DocumentVoteStart: function (novoValor, valorAntigo) {
			if (novoValor !== false) {
				this.Get_Quorum_Socket_Tv_full();
				this.DocumentVoteStart = false;
			}
		},

		EndVote: function (novoValor, valorAntigo) {
			if (novoValor !== false) {
				this.DocumentVoted();
				this.EndVote = false;
			}
		},
	},
  
	created () {
		let OnSocket = setInterval(() => {
			if (this.StoreAll.websocket_host !== null) {
				clearInterval(OnSocket);
				window.Echo.channel(this.StoreAll.websocket_host).listen('.' + this.StoreAll.websocket_host, (e) => {
					if (e.socket_id === 1) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Session_start = true;
							this.socket_ids.push(e.event_id);  
						}
					}
			
					if (e.socket_id === 4) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.QuorumAddUser = true;
							this.socket_ids.push(e.event_id);  
						}
					}
			
					if (e.socket_id === 5) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.QuorumRemoveUser = true;
							this.socket_ids.push(e.event_id);  
						}
					}

					if (e.socket_id === 10) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							setTimeout(() => { this.tribunaOn(); }, 2000);
							this.socket_ids.push(e.event_id);  
						}
					}

					if (e.socket_id === 12) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							setTimeout(() => {  this.tribunaOn(); }, 2000);
							this.socket_ids.push(e.event_id);  
						}
					}

					if (e.socket_id === 13) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.tribuna_of();
							this.socket_ids.push(e.event_id);  
						}
					}

					if (e.socket_id === 21) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Explanation_on();
							this.socket_ids.push(e.event_id);  
						}
					} 
        
					if (e.socket_id === 24) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Explanation_on();
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 25) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Discut();
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 30) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Discut_off();
							this.socket_ids.push(e.event_id);  
						}
					} 
				});
			}}, 500);


		let OnSystem = setInterval(() => {
			if (this.StoreAll.socket !== null) {
				clearInterval(OnSystem);
				this.StoreAll.socket.on('6', (e) => {
					if (this.DocumentVoteStart === false) {			
						this.DocumentVoteStart = true;
					}
				});

				this.StoreAll.socket.on('8', (e) => {
					if (this.EndVote === true) {
						this.EndVote = true;
					}	
				});
			}}, 500);
	},

	mounted() {
		this.GetSession();
		this.Session_timer();
		this.Hora();
		this.BrasaoAndName();
	},
    
	methods: {
		async GetSession() {
			await this.StoreAll.Get_Data();
			const { session } = this.StoreAll;
			if (session == null) {
				this.GetSession();
				return;
			}
			const { session_status, id } = session;
			this.session = session;
			this.session_status = session_status.id;
			this.session_id = id;
			this.session_status_name = session_status.name;
  
			this.get_expediente();
			this.DocumentVoted();
			this.tribunaOn();
			this.Discut();
			this.Explanation_on();
			/* remove a necessidade de adicionar no mounted já que todos precisa do get_data */
  
			this.Get_Quorum_Socket_Tv_full();
		},


		BrasaoAndName(){
			if(this.StoreAll.host == 'dev'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_bd.png');
        
			}else if(this.StoreAll.host == 'teste'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_bd.png');
        
			}else if(this.StoreAll.host == 'jc'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_jc.png');
        
			}else if(this.StoreAll.host == 'bodo'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_bd.png');
        
			}else if(this.StoreAll.host == 'pedroavelino'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_pa.png');
        
			}else if(this.StoreAll.host == 'pedrapreta'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_pp.png');
        
			}else if(this.StoreAll.host == 'campogrande'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_cg.png');
        
			}else if(this.StoreAll.host == 'parazinho'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_parazinho.png');
        
			}else if(this.StoreAll.host == 'local'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_jc.png');
        
			}else if(this.StoreAll.host == 'jandaira'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_jd.png');
        
			}else if(this.StoreAll.host == 'triunfo'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_tp.png');      
      
			}else if(this.StoreAll.host == 'upanema'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_upanema.png');
			
			}else if(this.StoreAll.host == 'ja'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_ja.png');
        
			}else if(this.StoreAll.host == 'angicos'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_angicos.png');
        
			}else if(this.StoreAll.host == 'crv'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_crv.png');
        
			}else if(this.StoreAll.host == 'goianinha'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_goianinha.png');
			}
			else if(this.StoreAll.host == 'jucurutu'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_jucurutu.png');
			}
			else if(this.StoreAll.host == 'odb'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_odb.png');
			}
			else if(this.StoreAll.host == 'sm'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_sm.png');
			}
			else if(this.StoreAll.host == 'lp'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_lagoapedra.png');
			}
			else if(this.StoreAll.host == 'sr'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_sr.png');
			}
			else if(this.StoreAll.host == 'bf'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_bf.png');
			}
		},
  
		async Get_Quorum_Socket_Tv_full() {
			await this.StoreAll.Get_Data();
			/* Realy ? */
			this.Quorum_atual_full();
		},
  
		async get_expediente() {
			try {
				const response = await AxiosAPI.get('/users', {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				});
  
				this.users = response.data.filter(user => {
					const excludedStatusUser = [2, 3];
					return !excludedStatusUser.includes(user.status_user) && user.user_category_id !== 3;
				});
  
				this.users2 = response.data.filter(user => user.user_category_id === 3);
				this.user_presidente = this.users2;
  
			} catch (error) {
				console.log(error);
			}
		},
  
		Session_timer() {
			setTimeout(() => {
				this.Session_timer();
			}, 300);
  
			if (this.StoreAll.quorum === null) {
				return;
			} 
			if (this.StoreAll.quorum.length <= 0) {
				return;
			}
			if (this.StoreAll.quorum !== null && this.StoreAll.quorum.length >= 1) {
				let inicio = new Date(this.StoreAll.quorum[0].created_at).getTime();;
				let fim = new Date().getTime();;
				let diferenca = fim - inicio;
				let hours = Math.floor((diferenca % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				let minutes = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60));
				let seconds = Math.floor((diferenca % (1000 * 60)) / 1000);
				let timer_session = hours + 'h: ' + minutes + 'm: ' + seconds + 's';
				this.timer_session = timer_session;
			}
		},
  
		Hora() {
			let date_time = new Date();
			let dia = date_time.getDate();
			let mes = date_time.getMonth();
  
			if (mes == 0) {
				this.mesEmPortugues = 'Janeiro';
			}
			if (mes == 1) {
				this.mesEmPortugues = 'Fevereiro';
			}
			if (mes == 2) {
				this.mesEmPortugues = 'Março';
			}
			if (mes == 3) {
				this.mesEmPortugues = 'Abril';
			}
			if (mes == 4) {
				this.mesEmPortugues = 'Maio';
			}
			if (mes == 5) {
				this.mesEmPortugues = 'Junho';
			}
			if (mes == 6) {
				this.mesEmPortugues = 'Julho';
			}
			if (mes == 7) {
				this.mesEmPortugues = 'Agosto';
			}
			if (mes == 8) {
				this.mesEmPortugues = 'Setembro';
			}
			if (mes == 9) {
				this.mesEmPortugues = 'Outubro';
			}
			if (mes == 10) {
				this.mesEmPortugues = 'Novembro';
			}
			if (mes == 11) {
				this.mesEmPortugues = 'Dezembro';
			}
  
			let ano = date_time.getFullYear();
			this.dia = dia;
			this.mes = this.mesEmPortugues;
			this.ano = ano;
  
			this.datenow = this.dia + '/' + this.mes + '/' + this.ano;
			let minutes = date_time.getMinutes();
			if (minutes.toString().length == 1) {
				minutes = '0' + minutes;
			}
			let hours = date_time.getHours();
			if (hours.toString().length == 1) {
				hours = '0' + hours;
			}
			let seconds = date_time.getSeconds();
			if (seconds.toString().length == 1) {
				seconds = '0' + seconds;
			}
			this.time = hours + ' : ' + minutes + ' : ' + seconds;
			setTimeout(() => {
				this.Hora();
			}, 300);
		},
  
		async Discut() {
			if (this.StoreAll.quorum == null) {
				return;
			} else if (this.StoreAll.quorum.length <= 0) {
				return;
			} else {
				if (this.StoreAll.disct == true) {
					return;
				} else {
					await AxiosAPI.get('/discussion?quorum_id=' + this.StoreAll.quorum[0].id, {
						headers: {
							Authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}).then((resp) => {
						for (let i = 0; i < resp.data.data.length; i++) {
							if (resp.data.data[i].status_discussion == 1) {
								this.StoreAll.discut = true;
							} else {
								this.StoreAll.discut = false;
							}
						}
					});
				}
			}
		},
  
		async Explanation_on() {
			if (this.StoreAll.quorum == null) {
				return;
			} else if (this.StoreAll.quorum.length <= 0) {
				return;
			} else {
				if (this.StoreAll.disct == true) {
					return;
				} else {
					await AxiosAPI.get('/big-discussion?quorum_id' + this.StoreAll.quorum[0].id, {
						headers: {
							Authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}).then((resp) => {
						for (let i = 0; i < resp.data.data.length; i++) {
							if (resp.data.data[i].status_big_discussion == 1) {
								this.StoreAll.explanation = true;
							} else {
								this.StoreAll.explanation = false;
							}
						}
					});
				}
			}
		},
  
  
		async Quorum_atual_full() {
			if (this.StoreAll.quorum == null) {
				return;
			} else if (this.StoreAll.quorum.length <= 0) {
				return;
			} else {
				await AxiosAPI.get('/quorum-users?quorum_id=' + this.StoreAll.quorum[0].id, {
					headers: {
						authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				}).then((response) => {
					this.users_quorums = response.data.data;
					this.StoreAll.quorum_moment = true;
					setTimeout(() => {
						this.ContagemUsers();
					}, 300);
				});
			};
		},
  
  
		ContagemUsers() {
			let users_presentes = 0;
			let user_P = [];
			let user_A = [];
			let user_T = [];
			this.users.forEach(user => {
				user[1] = 'A';
			});
  
			this.users_quorums.forEach(quorum => {
				const foundUser = this.users.find(user => user.name === quorum.user.name);
				if (foundUser) {
					foundUser[1] = 'P';
					users_presentes++;
				}
			});
  
			user_P = this.users.filter(user => user[1] === 'P');
			user_A = this.users.filter(user => user[1] === 'A');
			user_T = [...user_P, ...user_A];
			this.User_Tot = user_T;
  
			this.totall = this.users.length;
			this.presentess = users_presentes;
			this.ausentess = this.totall - this.presentess;
  
			// Não é necessário copiar o array "users" para "new_data"
			this.us = this.users;
			this.isQuorumLoaded = true;
  
			this.ContagemPresidente();
		},
		ContagemPresidente() {
			let presidente_presente = 0;
  
			this.user_presidente.forEach(user => {
				user[1] = 'A';
			});
  
			this.users_quorums.forEach(quorum => {
				const foundPresidente = this.user_presidente.find(user => user.name === quorum.user.name);
				if (foundPresidente) {
					foundPresidente[1] = 'P';
					presidente_presente++;
				}
			});
  
			this.totall += presidente_presente;
			this.presentess += presidente_presente;
			this.ausentess = this.totall - this.presentess;
		},
  
		async tribunaOn() {
			const quorum = this.StoreAll.quorum;
			const vote = this.StoreAll.vote;
			const tribuna = this.StoreAll.tribuna;
			const accessToken = this.StoreAll.accessToken;
  
			if (quorum == null || quorum.length <= 0 || vote === true) {
				return;
			}
  
			if (tribuna === true) {
				return;
			}
  
			try {
				const response = await AxiosAPI.get('/tribune?quorum_id=' + quorum[0].id, {
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				});
  
				const tribunaData = response.data.data[0];
  
				if (tribunaData.status_tribune == 1 || tribunaData.status_tribune == 2) {
					this.tribuna_id = tribunaData.id;
					this.StoreAll.tribuna = true;
				} else {
					this.StoreAll.tribuna = false;
					this.status_tv = 2;
					return;
				}
			} catch (error) {
				console.log(error);
				// Tratar erros da API aqui, se necessário
			}
		},
  
		DocumentVoted() {
			if (this.StoreAll.tribuna == false) {
				if (this.StoreAll.document == null) {
					this.StoreAll.vote = false;
					this.StoreAll.votedlegenda = false;
				} else if (
					this.StoreAll.document.document_status_vote_id == 4
				) {
					this.StoreAll.vote = true; //trocar para true
					this.StoreAll.votedlegenda = true;
					this.DocVoted = this.StoreAll.document.name;
				} else {
					this.StoreAll.vote = false;
					this.StoreAll.votedlegenda = false;
				}
			} else { return; }

		},

		Discut_off() {
			this.StoreAll.discut = false;
		},
  
		tribuna_of() {
			this.StoreAll.tribuna = false;
			this.status_tv = 2;
		},
  
	},
};
</script>
<template>
    <div>
      <div>
        <div v-if="this.StoreAll.quorum_moment == false" class="d-flex justify-content-center">
          <span
            style="transform: translate(-50%, 80%);margin-right: -50%;top: 39%;left: 50%;position: absolute;font-size: clamp(0.1vw, 2.5vw, 40px);font-weight: 700 !important;color: white;">
            AGUARDANDO O INICIO DO QUORUM</span>
        </div>
        <div v-else>
  
          <div
            v-if="(this.StoreAll.vote == false && this.StoreAll.tribuna == false && this.StoreAll.discut == false && this.StoreAll.explanation == false)">
          
            <div style="position: absolute; top:40%; left: 50%; transform: translate(-50%, 0%);">
            <div class="text-center mt-5">
              <h1 v-if="status_tv == 1" class="text-white">LEITURA DA ATA E MATÉRIAS</h1>
              <h1 v-if="status_tv == 2" class="text-white">AGUARDANDO INICIO DA VOTAÇÃO</h1>
                </div>  
            </div>
         
          </div>
        </div>
      </div>
    </div>
  </template>
  <style>
  .text-black {
    color: black;
  }
  
  .display_fast_body {
    width: clamp(0.1vw, 8.5vw, 300px);
    height: clamp(0.1vw, 8.5vw, 300px);
  }
  
  .display_body_nine {
    width: clamp(0.1vw, 25vw, 475px);
  }
  
  .font_element_vote {
    font-size: 27px;
  }
  @media only screen and (max-width: 1600px) {
    .display_fast_body {
      width: clamp(0.1vw, 8vw, 300px);
      height: clamp(0.1vw, 8vw, 300px);
    }
  
    .font_element_vote {
    font-size: 21px;
  }
  }
  
  @media only screen and (max-width: 1380px) {
    .display_fast_body {
      width: clamp(0.1vw, 7.5vw, 300px);
      height: clamp(0.1vw, 7.5vw, 300px);
    }
  
    .display_body_nine {
      width: clamp(0.1vw, 25vw, 330px);
    }
  
    .font_element_vote {
    font-size: 19px;
  }
  }
  
  @media only screen and (max-width: 1280px) {
    .display_fast_body {
      width: clamp(0.1vw, 7.1vw, 300px);
      height: clamp(0.1vw, 7.1vw, 300px);
    }
  
    .font_element_vote {
    font-size: 19px;
  }
  }
  
  .col-1-5-users-14 {
    width: 14% !important;
  }
  
  .col-1-5-users-11 {
    width: 20% !important;
  }
  </style>
  