<script>
import ModalBig from './ModalBig.vue';
import { SocketAPI } from '@/axios';
import Modal_view_Quorum from './Modal_View_Quorum.vue';
import optionsVote from './optionsVote.vue';
import { useMainStore, useBusStore } from '../../../stores/index';

export default {
	components: {
		ModalBig,
		Modal_view_Quorum,
		optionsVote
	},
	setup() {
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		return {
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			pdfonly: null,
			vote_hidden: true,
			autor: '',
			document: {
				document_status: ''
			},
			documento_vote_src: '',
			user_for_remove: [],
			ready2vote: true,
			voted: false,
			voteFunction: '',
			votes: {},
			favorable: 0,
			against: 0,
			vista: 0,
			ordem: 1,
			sessao_iniciada: false,
			total_votes: 0,
			chartData: [],
			chartOptions: {
				legend: { position: 'none' },
				height: 350,
			}
		};
	},

	computed: {
		TagVote (value) {
			let Quorum = [];
			let Votos = [];
			let MargedTrue = [];
			let Marge =  [];

			this.StoreAll.quorum_users.forEach(users => {
				Quorum.push(users.user);
			});

			if (this.StoreAll.allvotes === null) {
				Votos = [];
			}
			if (this.StoreAll.allvotes !== null) {
				this.StoreAll.allvotes.forEach(votes => {
					Votos.push(votes);
				});
			}

			Votos.forEach(VotosID => {
				Quorum.forEach(QuorumID => {
					if (VotosID.user_id == QuorumID.id) {
						let mergedData = {
							QuorumID: QuorumID,
							VotosID: VotosID,
							Status: true
						};
						MargedTrue.push(mergedData);
					}
				});
			});

			let filtered = Quorum.filter((item) => {
				return !MargedTrue.some((item2) => {
					return item.id === item2.QuorumID.id;
				});
			}).map((item) => {
				return { ...item, Status: false };
			});

			Marge.push(...MargedTrue, ...filtered);
			return Marge;
		}
	},

	watch: {
		'Bus.votePresidente': function (novoValor, valorAntigo) {
			if (novoValor !== null) {
				this.Bus.votePresidente = null;
				this.vote(novoValor);
			}
		},
	},

	methods: {
		Quorum_modal(value) {
			this.$refs.Modal_view_Quorum.openModal(value);
			this.$refs.Modal_view_Quorum.FindUsers();
		},

		async vote(id) {
			await SocketAPI.post('/register-vote',
				{
					document_id: [this.StoreAll.document.id],
					session_id: this.StoreAll.session.id,
					user_id:  this.StoreAll.user.id,
					vote_category_id: id
				},
				{
					headers: {
						'x-tenant': this.StoreAll.voteSocket,
						authorization: 'Bearer ' + this.StoreAll.socketToken,
					}
				}).then(() => {
				if (id == 1) {
					this.StoreAll.VoteConf = 1;
				} else if (id == 2) {
					this.StoreAll.VoteConf = 2;
				} else {
					this.StoreAll.VoteConf = 3;
				}
				this.StoreAll.voted = true;
				this.voted = this.StoreAll.voted;
				this.endVoting();
				this.$refs.viewDocument.closeModal();
			});
		},

		Votehidden(value) {
			this.vote_hidden = value;
		},

		startvote(value) {
			if (value[2] === 1){
				this.Bus.ModalVoteOn = true;
			}

			if (value[2] === 2) {
				this.Bus.ModalVoteOn = false;
			}
			this.favorable = 0;
			this.against = 0;
			this.vista = 0;
			this.document = this.StoreAll.document;
			this.Dat_doc();
		},

		Dat_doc() {
			let Co_user = [];

			for (let i = 0; i < this.StoreAll.document.authors.length; i++) {
				Co_user.push(this.StoreAll.document.authors[i].nickname);
			}

			let result = Co_user.join(', ');

			this.category_doc = this.StoreAll.document.document_category.name;
			this.autor = result;
			this.doc_name = this.StoreAll.document.name;
			this.$refs.viewDocument.openModal();
		},

		nameUser (value) {
			let userName = [];
			this.StoreAll.user_all.forEach(users => {
				if (users.id == value.user_id) {
					userName= users;
				}
			});
			return userName.nickname;
		},

		teste () {
			console.log('teste');
			this.$swal.close();
		},

		stop_voting() {
			let quorum_size = this.StoreAll.quorum_size;
			if (quorum_size - 1 == this.StoreAll.total_votes) {
				this.$refs.optionsVote.openModal();
			} else {
				let vereadores = quorum_size - this.StoreAll.total_votes - 1;
				this.$swal.fire({
					title: 'Ainda falta(m) ' + vereadores + ' vereador(es) votar(em)!',
					showDenyButton: false,
					showCancelButton: false,
				});

			}
		},

		async endVoting() {
			const end_vote = {
				document_id: [this.StoreAll.document.id],
				document_status_vote_id: 5,
				user_id: this.StoreAll.user.id
			};

			await SocketAPI.post('/document/change-status', end_vote, {
				headers: {
					'x-tenant': this.StoreAll.voteSocket,
					'Content-Type': 'application/json',
					authorization: 'Bearer ' + this.StoreAll.socketToken,
				}
			}).then(() => {
				this.$refs.viewDocument.closeModal();
			});
		},
	}
};


</script>
<template>
  <div>
    <Modal_view_Quorum ref="Modal_view_Quorum"></Modal_view_Quorum>
    <optionsVote ref="optionsVote"></optionsVote>
    <ModalBig ref="viewDocument" style="z-index: 999 !important">
      <template #header>
        <div class="d-flex justify-content-between">
          <div class="status_label">
            <strong><span class="ml mr title">VOTAÇÃO -</span>
              <span v-if="document.document_status_vote_id == 4" class="text-danger ml title"> EM
                ANDAMENTO</span>
              <span v-if="document.document_status_vote_id == 5" class="text-success ml title">
                CONCLUÍDA</span>
            </strong>
          </div>
          <div class="flex-start">

            <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </template>

      <template #body>
        <div v-if="this.StoreAll.vote_secret">
          <div v-if="document.document_status_vote_id == 5">
            <div class="mt" style="display:flex">

              <div class="d-grid justify-content-center text-center w-50 text-bold" style="align-items: center; border: 1px solid black;">
                <div>
                  <span style="font-size: 21px;font-family: 'Dosis', sans-serif;">
                    DOCUMENTO EM VOTAÇÃO
                  </span>
                </div>
                <div>
                  <span style="font-size: 21px;font-family: 'Dosis', sans-serif;">
                    {{ document.name }}
                  </span>
                </div>
                <div>
                  <span style="font-size: 21px;font-family: 'Dosis', sans-serif;">
                    Autor(es): {{ autor }}
                  </span>
                </div>
              </div>


              <div style="width: 40%; margin-left: 2rem;text-align: center;color: rgb(0, 0, 0);display: flex;flex-direction: column;align-content: flex-end !important;">

                  <h2 style="font-size: 26px;color: black;font-family: 'Dosis', sans-serif;">RESULTADO DA VOTAÇÃO
                            </h2>

                            <div v-if="this.StoreAll.voteResult === 1" class="mt-2 mb-2">
                                <img src="../../../assets/img/check.png" style="width: 40%;">
                                <h2 style="font-size: 26px;color: green;font-family: 'Dosis', sans-serif;">APROVADO</h2>
                            </div>

                            <div v-else-if="this.StoreAll.voteResult === 2" class="mt-2 mb-2">
                                <img src="../../../assets/img/CheckX.png" style="width: 40%;">
                                <h2 style="font-size: 26px;color: red;font-family: 'Dosis', sans-serif;">REPROVADO</h2>
                            </div>

                            <div v-else class="mt-2 mb-2">
                                <img src="../../../assets/img/AbstenCheck.png" style="width: 40%;">
                                <h2 style="font-size: 26px;color: BLACK;font-family: 'Dosis', sans-serif;">ABSTENÇÃO</h2>
                            </div>

                            <div class="d-flex justify-content-center mt-3">
                                            <h2 style="font-size: 26px;color: black;font-family: 'Dosis', sans-serif;">SEU
                                                VOTO:</h2>

                                                <div class="mt-1 ml-1 mr-1">
                                                <div
                                                    class="d-flex text-uppercase font-weight-bold rounded ml-2 pl-2 pr-2 text-black">

                                                    <Tag v-if="vote_hidden"
                                                        style="border: 1px solid rgb(6 122 167);background-color: rgb(6 93 167 / 10%);color: rgb(6 99 167);">
                                                        <div class="flex align-items-center gap-2">
                                                            <i class="fa fa-user-secret mr-2" aria-hidden="true"></i>
                                                            <span class="text-base text-uppercase"
                                                                style="font-size: 14px;">SECRETO</span>
                                                        </div>
                                                    </Tag>

                                                    <Tag v-else-if="this.StoreAll.userVote.length <= 0"
                                                        style="border: 1px solid rgb(8 5 5);background-color: rgba(9,167, 6, 0.1);color: rgb(0 0 0);">
                                                        <div class="flex align-items-center gap-2">
                                                            <i class="fa fa-hand-paper-o mr-1" aria-hidden="true"></i>
                                                            <span class="text-base text-uppercase"
                                                                style="font-size: 14px;">AGUARDE...</span>
                                                        </div>
                                                    </Tag>

                                                    <Tag v-else-if="this.StoreAll.userVote.vote_category_id === '1'"
                                                        style="border: 1px solid rgb(9 167 6);background-color: rgba(9,167, 6, 0.1);color: rgb(9 167 6);">
                                                        <div class="flex align-items-center gap-2">
                                                            <i class="fa fa-check mr-2" aria-hidden="true"></i>
                                                            <span class="text-base text-uppercase"
                                                                style="font-size: 14px;">A FAVOR</span>
                                                        </div>
                                                    </Tag>

                                                    <Tag v-else-if="this.StoreAll.userVote.vote_category_id === '2'"
                                                        style="border: 1px solid rgb(231, 0, 0);background-color: rgba(9,167, 6, 0.1);color: rgb(231, 0, 0);">
                                                        <div class="flex align-items-center gap-2">
                                                            <i class="fa fa-times-circle-o mr-1" aria-hidden="true"></i>
                                                            <span class="text-base text-uppercase"
                                                                style="font-size: 14px;">CONTRA</span>
                                                        </div>
                                                    </Tag>

                                                    <Tag v-else-if="this.StoreAll.userVote.vote_category_id === '3'"
                                                        style="border: 1px solid rgb(8 5 5);background-color: rgba(9,167, 6, 0.1);color: rgb(0 0 0);">
                                                        <div class="flex align-items-center gap-2">
                                                            <i class="fa fa-hand-paper-o mr-1" aria-hidden="true"></i>
                                                            <span class="text-base text-uppercase"
                                                                style="font-size: 14px;">ABSTENÇÃO</span>
                                                        </div>
                                                    </Tag>

                                                    <div class="ml-3 pt-1">
                                                        <a v-if="vote_hidden" style="cursor: pointer;"
                                                            @click="Votehidden(false)"><i class="fa fa-eye-slash"
                                                                aria-hidden="true"></i></a>
                                                        <a v-else style="cursor: pointer;" @click="Votehidden(true)"><i
                                                                class="fas fa-eye" aria-hidden="true"></i></a>

                                                    </div>

                                                </div>
                                            </div>
                              </div>
                <div class="d-flex justify-content-center text-center">
                  <h2 style="font-size: 26px;color: black;font-family: 'Dosis', sans-serif;">Votos Registrados</h2> 
                </div>
                <div class="myrow mt text-center">
                  <div class="flex-wrap">

                    <div class="mt-1 ml-1 mr-1 mb-2" style="background-color: darkred;">
                      <div class="text-white text-uppercase font-weight-bold rounded pl-2 pr-2 text-black text-center">
                        VOTAÇÃO SECRETA
                      </div>
                    </div>

                    <div v-if="this.StoreAll.allvotes === null"></div>

                    <div v-else-if="this.StoreAll.allvotes.length <= 0">
                      <Tag
                        style="border: 1px solid rgb(255 153 0);background-color: rgb(255 189 4 / 10%);color: rgb(54 41 9);">
                        <div class="flex align-items-center gap-2">
                          <i class="fa fa-circle-o-notch fa-spin fa-1x fa-fw mr-1"></i>
                          <span class="text-base text-uppercase" style="font-size: 14px;">AGUARDANDO PRIMEIRO
                            VOTO...</span>
                        </div>
                      </Tag>
                    </div>

                    <div v-else class="d-flex justify-content-center flex-wrap">
                      <div v-for="(votex, index) in this.StoreAll.allvotes" :key="`vote-${index}`"
                        class="mt-1 ml-1 mr-1">

                        <Tag
                          style="border: 1px solid #890101;background-color: rgb(143 0 0 / 10%);color: rgb(129 2 2);">
                          <div class="flex align-items-center gap-2">
                            <i class="fa fa-user-secret mr-2" aria-hidden="true"></i>
                            <span class="text-base text-uppercase" style="font-size: 14px;">SECRETO - {{ index + 1 }}</span>
                          </div>
                        </Tag>

                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>

            <div class="d-flex justify-content-around">

              <div v-if="document.document_status_vote_id == 4">
                <div style="width: 300px;margin: 0px 0 20px 50px;">
                  <div class="d-flex justify-content-around">

                    <button type="button" class="btn btn-warning mr-3 mb-0" @click="stop_voting()"><i
                        class="fa fa-times-circle"></i> Finalizar Votação</button>
                    <button type="button" class="btn btn-danger ml-3 mb-0" @click="Quorum_modal(3)"><i
                        class="fa fa-times-circle"></i> Remover vereador não presente</button>

                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-around">
              <div class="d-grid justify-content-center text-center w-50 text-bold" style="align-items: center; border: 1px solid black;">
                <span class="mb-2" style="font-size: 21px;font-family: 'Dosis', sans-serif; margin-bottom: 2rem;">
                  DOCUMENTO EM VOTAÇÃO
                </span>
                <span style="font-size: 21px;font-family: 'Dosis', sans-serif; margin-bottom: 2rem;">
                  {{ document.name }}
                </span>
                <span style="font-size: 21px;font-family: 'Dosis', sans-serif;">
                  Autor(es): {{ autor }}
                </span>
              </div>


              <div v-if="document.document_status_vote_id == 4" class="mt"
                style="width: 40%; margin-left: 2rem;text-align: center;color: rgb(0, 0, 0);display: flex;flex-direction: column;align-content: flex-end !important;">

                <div class="myrow mt flex-start text-center">
                  <h2 style="font-size: 26px;color: black;font-family: 'Dosis', sans-serif;">Votos Registrados</h2>
                </div>

                <div class="myrow mt text-center">
                  <div class="flex-wrap">

                    <div class="mt-1 ml-1 mr-1 mb-2" style="background-color: darkred;">
                      <div class="text-white text-uppercase font-weight-bold rounded pl-2 pr-2 text-black text-center">
                        VOTAÇÃO SECRETA
                      </div>
                    </div>

                    <div v-if="this.StoreAll.allvotes === null"></div>

                    <div v-else-if="this.StoreAll.allvotes.length <= 0">
                      <Tag
                        style="border: 1px solid rgb(255 153 0);background-color: rgb(255 189 4 / 10%);color: rgb(54 41 9);">
                        <div class="flex align-items-center gap-2">
                          <i class="fa fa-circle-o-notch fa-spin fa-1x fa-fw mr-1"></i>
                          <span class="text-base text-uppercase" style="font-size: 14px;">AGUARDANDO PRIMEIRO
                            VOTO...</span>
                        </div>
                      </Tag>
                    </div>

                    <div v-else class="d-flex justify-content-center flex-wrap">
                      <div v-for="(votex, index) in this.StoreAll.allvotes" :key="`vote-${index}`"
                        class="mt-1 ml-1 mr-1">

                        <Tag
                          style="border: 1px solid #890101;background-color: rgb(143 0 0 / 10%);color: rgb(129 2 2);">
                          <div class="flex align-items-center gap-2">
                            <i class="fa fa-user-secret mr-2" aria-hidden="true"></i>
                            <span class="text-base text-uppercase" style="font-size: 14px;">SECRETO - {{ index + 1 }}</span>
                          </div>
                        </Tag>

                      </div>
                    </div>

                    <div class="mt-2">
                      <Tag
                        style="border: 1px solid rgb(255 153 0);background-color: rgb(255 189 4 / 10%);color: rgb(54 41 9);">
                        <div class="flex align-items-center gap-2">
                          <i class="fa fa-circle-o-notch fa-spin fa-1x fa-fw mr-1"></i>
                          <span class="text-base text-uppercase" style="font-size: 14px;">FALTAM VOTAR: {{ this.StoreAll.quorum_size - this.StoreAll.total_votes - 1 }}</span> <!-- CONTAR PRESIDENTE OU NÃO -->
                        </div>
                      </Tag>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div v-if="document.document_status_vote_id == 5">
            <div class="mt" style="display:flex">

              <div class="d-grid justify-content-center text-center w-50 text-bold" style="align-items: center; border: 1px solid black;">
                <span style="font-size: 21px;font-family: 'Dosis', sans-serif;">
                  DOCUMENTO EM VOTAÇÃO
                </span>
                <span style="font-size: 21px;font-family: 'Dosis', sans-serif;">
                  {{ document.name }}
                </span>
                <span style="font-size: 21px;font-family: 'Dosis', sans-serif;">
                  Autor(es): {{ autor }}
                </span>
              </div>


              <div
                            style="width: 40%; margin-left: 2rem;text-align: center;color: rgb(0, 0, 0);display: flex;flex-direction: column;align-content: flex-end !important;">

                            <h2 style="font-size: 26px;color: black;font-family: 'Dosis', sans-serif;">RESULTADO DA VOTAÇÃO
                            </h2>

                            <div v-if="this.StoreAll.voteResult === 1" class="mt-2 mb-2">
                                <img src="../../../assets/img/check.png" style="width: 40%;">
                                <h2 style="font-size: 26px;color: green;font-family: 'Dosis', sans-serif;">APROVADO</h2>
                            </div>

                            <div v-else-if="this.StoreAll.voteResult === 2" class="mt-2 mb-2">
                                <img src="../../../assets/img/CheckX.png" style="width: 40%;">
                                <h2 style="font-size: 26px;color: red;font-family: 'Dosis', sans-serif;">REPROVADO</h2>
                            </div>

                            <div v-else class="mt-2 mb-2">
                                <img src="../../../assets/img/AbstenCheck.png" style="width: 40%;">
                                <h2 style="font-size: 26px;color: BLACK;font-family: 'Dosis', sans-serif;">ABSTENÇÃO</h2>
                            </div>

                            <div class="d-flex justify-content-center">
                                <h2 style="font-size: 26px;color: black;font-family: 'Dosis', sans-serif;">SEU VOTO:</h2>

                                <div class="mt-1 mb-3 ml-1 mr-1">
                                    <div class="text-uppercase font-weight-bold rounded ml-2 pl-2 pr-2 text-black">

                                        <Tag v-if="this.StoreAll.userVote.length <= 0"
                                            style="border: 1px solid rgb(8 5 5);background-color: rgba(9,167, 6, 0.1);color: rgb(0 0 0);">
                                            <div class="flex align-items-center gap-2">
                                                <i class="fa fa-hand-paper-o mr-1" aria-hidden="true"></i>
                                                <span class="text-base text-uppercase"
                                                    style="font-size: 14px;">AGUARDE...</span>
                                            </div>
                                        </Tag>

                                        <Tag v-else-if="this.StoreAll.userVote.vote_category_id === '1'"
                                            style="border: 1px solid rgb(9 167 6);background-color: rgba(9,167, 6, 0.1);color: rgb(9 167 6);">
                                            <div class="flex align-items-center gap-2">
                                                <i class="fa fa-check mr-2" aria-hidden="true"></i>
                                                <span class="text-base text-uppercase" style="font-size: 14px;">A
                                                    FAVOR</span>
                                            </div>
                                        </Tag>

                                        <Tag v-else-if="this.StoreAll.userVote.vote_category_id === '2'"
                                            style="border: 1px solid rgb(231, 0, 0);background-color: rgba(9,167, 6, 0.1);color: rgb(231, 0, 0);">
                                            <div class="flex align-items-center gap-2">
                                                <i class="fa fa-times-circle-o mr-1" aria-hidden="true"></i>
                                                <span class="text-base text-uppercase"
                                                    style="font-size: 14px;">CONTRA</span>
                                            </div>
                                        </Tag>

                                        <Tag v-else-if="this.StoreAll.userVote.vote_category_id === '3'"
                                            style="border: 1px solid rgb(8 5 5);background-color: rgba(9,167, 6, 0.1);color: rgb(0 0 0);">
                                            <div class="flex align-items-center gap-2">
                                                <i class="fa fa-hand-paper-o mr-1" aria-hidden="true"></i>
                                                <span class="text-base text-uppercase"
                                                    style="font-size: 14px;">ABSTENÇÃO</span>
                                            </div>
                                        </Tag>

                                    </div>
                                </div>
                            </div>
                <div class="d-flex justify-content-center text-center">
                  <h2 style="font-size: 26px;color: BLACK;font-family: 'Dosis', sans-serif;">VOTOS REGISTRADOS</h2>
                </div>
                <div class="d-flex justify-content-center flex-wrap">
                <div v-for="(voter, index) in this.StoreAll.allvotes" :key="`vote-${index}`"
                        class="mt-1 ml-1 mr-1">

                        <Tag v-if="voter.vote_category_id === '1'"
                          style="border: 1px solid rgb(9 167 6);background-color: rgba(9,167, 6, 0.1);color: rgb(9 167 6);">
                          <div class="flex align-items-center gap-2">
                            <i class="fa fa-check mr-2" aria-hidden="true"></i>
                            <span class="text-base text-uppercase" style="font-size: 14px;">{{ nameUser(voter) }}</span>
                          </div>
                        </Tag>

                        <Tag v-else-if="voter.vote_category_id === '2'"
                          style="border: 1px solid rgb(231, 0, 0);background-color: rgba(9,167, 6, 0.1);color: rgb(231, 0, 0);">
                          <div class="flex align-items-center gap-2">
                            <i class="fa fa-times-circle-o mr-1" aria-hidden="true"></i>
                            <span class="text-base text-uppercase" style="font-size: 14px;">{{ nameUser(voter) }}</span>
                          </div>
                        </Tag>

                        <Tag v-else-if="voter.vote_category_id === '3'"
                          style="border: 1px solid rgb(8 5 5);background-color: rgba(9,167, 6, 0.1);color: rgb(0 0 0);">
                          <div class="flex align-items-center gap-2">
                            <i class="fa fa-hand-paper-o mr-1" aria-hidden="true"></i>
                            <span class="text-base text-uppercase" style="font-size: 14px;">{{ nameUser(voter) }}</span>
                          </div>
                        </Tag>

                      </div>
                    </div>
              </div>
            </div>
          </div>
          <div v-else>

            <div class="d-flex justify-content-around">

              <div v-if="document.document_status_vote_id == 4">
                <div style="width: 300px;margin: 0px 0 20px 50px;">
                  <div class="d-flex justify-content-around">

                    <button type="button" class="btn btn-warning mr-3 mb-0" @click="stop_voting()"><i
                        class="fa fa-times-circle"></i> Finalizar Votação</button>
                    <button type="button" class="btn btn-danger ml-3 mb-0" @click="Quorum_modal(3)"><i
                        class="fa fa-times-circle"></i> Remover vereador não presente</button>

                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-around">
              <div class="d-grid justify-content-center text-center w-50 text-bold" style="align-items: center; border: 1px solid black;">
                  <span style="font-size: 21px;font-family: 'Dosis', sans-serif; margin-bottom: 2rem;">
                    DOCUMENTO EM VOTAÇÃO
                  </span>
                  <span style="font-size: 21px;font-family: 'Dosis', sans-serif; margin-bottom: 2rem;">
                    {{ document.name }}
                  </span>
                  <span style="font-size: 21px;font-family: 'Dosis', sans-serif;">
                    Autor(es): {{ autor }}
                  </span>
              </div>

              <div v-if="document.document_status_vote_id == 4" class="mt"
                style="width: 40%; margin-left: 2rem;text-align: center;color: rgb(0, 0, 0);display: flex;flex-direction: column;align-content: flex-end !important;">


                <div class="myrow mt flex-start text-center">
                  <h2 style="font-size: 26px;color: black;font-family: 'Dosis', sans-serif;">Votos Registrados</h2>
                </div>
                <div class="myrow mt text-center">
                  <div class="flex-wrap">
                    <div class="d-flex justify-content-center flex-wrap">

                      <div v-for="Quorum in TagVote" :key="Quorum.id"
                        class="mt-1 ml-1 mr-1">

                        <div v-if="Quorum.Status === true">
                        <Tag v-if="Quorum.VotosID.vote_category_id === '1'"
                          style="border: 1px solid rgb(9 167 6);background-color: rgba(9,167, 6, 0.1);color: rgb(9 167 6);">
                          <div class="flex align-items-center gap-2">
                            <i class="fa fa-check mr-2" aria-hidden="true"></i>
                            <span class="text-base text-uppercase" style="font-size: 14px;">{{ Quorum.QuorumID.nickname }}</span>
                          </div>
                        </Tag>
                        <Tag v-if="Quorum.VotosID.vote_category_id === '2'"
                          style="border: 1px solid rgb(231, 0, 0); background-color: rgba(9, 167, 6, 0.1); color: rgb(231, 0, 0);">
                          <div class="flex align-items-center gap-2">
                            <i class="fa fa-times-circle-o mr-2" aria-hidden="true"></i>
                            <span class="text-base text-uppercase" style="font-size: 14px;">{{ Quorum.QuorumID.nickname }}</span>
                          </div>
                        </Tag>
                        <Tag v-if="Quorum.VotosID.vote_category_id === '3'"
                          style="    border: 1px solid rgb(8, 5, 5); background-color: rgba(9, 167, 6, 0.1); color: rgb(0, 0, 0);">
                          <div class="flex align-items-center gap-2">
                            <i class="fa fa-hand-paper-o mr-2" aria-hidden="true"></i>
                            <span class="text-base text-uppercase" style="font-size: 14px;">{{ Quorum.QuorumID.nickname }}</span>
                          </div>
                        </Tag>
                      </div>
                      <div v-else>
                        <Tag
                          style="border: 1px solid rgb(255 153 0);background-color: rgb(255 189 4 / 10%);color: rgb(54 41 9);">
                          <div class="flex align-items-center gap-2">
                            <i class="fa fa-circle-o-notch fa-spin fa-1x fa-fw mr-1"></i>
                            <span class="text-base text-uppercase" style="font-size: 14px;">{{ Quorum.nickname }}</span> 
                          </div>
                        </Tag>
                        <div>
                      </div>
                     </div>
                  </div>
                </div>



                    <div class="mt-2">
                      <Tag
                        style="border: 1px solid rgb(255 153 0);background-color: rgb(255 189 4 / 10%);color: rgb(54 41 9);">
                        <div class="flex align-items-center gap-2">
                          <i class="fa fa-circle-o-notch fa-spin fa-1x fa-fw mr-1"></i>
                          <span class="text-base text-uppercase" style="font-size: 14px;">FALTAM VOTAR: {{ this.StoreAll.quorum_size - this.StoreAll.total_votes }}</span> <!-- CONTAR PRESIDENTE OU NÃO -->
                        </div>
                      </Tag>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>


        </div>

      </template>
      <template #footer>
        <div>
                <table>
                  <tr class="text-center">
                  <th style="font-size: 21px;font-family: 'Dosis', sans-serif;" rowspan="2">Resultado</th>

                  <th style="background-color: rgba(9,167, 6, 0.1);color: rgb(9, 167, 6); font-size: 21px;font-family: 'Dosis', sans-serif;">A favor</th>

                  <th style="background-color: rgb(167 6 6 / 10%);color: rgb(231, 0, 0); font-size: 21px;font-family: 'Dosis', sans-serif;">Contra</th>

                  <th style="background-color: rgb(0 0 0 / 10%);color: rgb(0, 0, 0); font-size: 21px;font-family: 'Dosis', sans-serif;">Abstenção</th>

                  <th style="background-color: rgb(0 0 0 / 10%);color: rgb(0, 0, 0); font-size: 21px;font-family: 'Dosis', sans-serif;">Total</th>
                  </tr>

                  <tr class="text-center">
                    <th style="font-size: 21px;font-family: 'Dosis', sans-serif;">{{ this.StoreAll.favorable }}</th>

                    <th style="font-size: 21px;font-family: 'Dosis', sans-serif;">{{ this.StoreAll.against }}</th>

                    <th style="font-size: 21px;font-family: 'Dosis', sans-serif;">{{ this.StoreAll.vista }}</th>

                    <th style="font-size: 21px;font-family: 'Dosis', sans-serif;">{{ this.StoreAll.favorable + this.StoreAll.against + this.StoreAll.vista }}</th>

                  </tr>
                </table>
                
        </div>
      </template>
    </ModalBig>
  </div>
</template>
<style>
.swal2-container {
  z-index: 10001 !important;
}

.close {
  font-size: 2.3rem !important;
}

.fixed-buttons {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}</style>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid #000;
  padding: 10px;
  text-align: center;
}
</style>