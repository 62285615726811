<script>
import ModalBig from './ModalBig.vue';
import { SocketAPI } from '@/axios';
import { useMainStore, useBusStore } from '../../../stores/index';

export default {
	components: { ModalBig },
	setup() {
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		return {
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			pdfonly: null,
			loading: false,
			vote_hidden: true,
			autor: '',
			document: {
				document_status: ''
			},
			documento_vote_src: '',
			user_for_remove: [],
			ready2vote: true,
			voted: false,
			voteFunction: '',
			votes: {},
			favorable: 0,
			against: 0,
			vista: 0,
			ordem: 1,
			sessao_iniciada: false,
			total_votes: 0,
			chartData: [],
			chartOptions: {
				legend: { position: 'none' },
				height: 350,
			}
		};
	},

	created () {
		let OnSystem = setInterval(() => {
			if (this.StoreAll.socket !== null) {
				clearInterval(OnSystem);
				this.StoreAll.socket.on('8', (e) => {
					this.endVoting_not_president();
				});
			}}, 500);

	},

	methods: {
		Dat_doc() {
			let Co_user = [];

			for (let i = 0; i < this.StoreAll.document.authors.length; i++) {
				Co_user.push(this.StoreAll.document.authors[i].nickname);
			}

			let result = Co_user.join(', ');

			this.category_doc = this.StoreAll.document.document_category.name;
			this.autor = result;
			this.doc_name = this.StoreAll.document.name;
			this.$refs.viewDocument.openModal();
		},

		Votehidden(value) {
			this.vote_hidden = value;
		},

		async vote(id) {
			this.loading = true;

			try {
				await SocketAPI.post('/register-vote',
					{
						document_id: [this.StoreAll.document.id],
						session_id: this.StoreAll.session.id,
						user_id:  this.StoreAll.user.id,
						vote_category_id: id
					},
					{
						headers: {
							'x-tenant': this.StoreAll.voteSocket,
							authorization: 'Bearer ' + this.StoreAll.socketToken,
						}
					}).then((response) => {
					if (response.status === 200) {
						if (id == 1) {
							this.StoreAll.VoteConf = 1;
							this.loading = false;
						} else if (id == 2) {
							this.StoreAll.VoteConf = 2;
							this.loading = false;
						} else {
							this.StoreAll.VoteConf = 3;
							this.loading = false;
						}
					}
					this.StoreAll.voted = true;
					this.voted = this.StoreAll.voted;
					this.$refs.viewDocument.closeModal();

				});
			} catch (error) {
				await SocketAPI
					.get('/votes?document_id=' + this.StoreAll.document.id + '&session_id=' + this.StoreAll.session.id,
						{
							headers: {
								'x-tenant': this.StoreAll.voteSocket,
								Authorization: 'Bearer ' + this.StoreAll.socketToken,
							},
						}
					)
					.then((response) => {
						if (response.data.data.length >= 1 ) {
							response.data.data.forEach(votes => {
								if (votes.user_id == this.StoreAll.user.id) {
									if (votes.vote_category_id === '1'){
										this.StoreAll.VoteConf = 1;
										this.StoreAll.userVote = votes;
										this.loading = false;
									}
									if (votes.vote_category_id === '2'){
										this.StoreAll.VoteConf = 2;
										this.StoreAll.userVote = votes;
										this.loading = false;
									}
									if (votes.vote_category_id === '3'){
										this.StoreAll.VoteConf = 3;
										this.StoreAll.userVote = votes;
										this.loading = false;
									}
									this.StoreAll.voted = true;
									this.voted = this.StoreAll.voted;
									this.$refs.viewDocument.closeModal();
								}
							});
						} else {
							setTimeout(() => {                  
								SocketAPI.post('/register-vote',
									{	
										document_id: [this.StoreAll.document.id],
										session_id: this.StoreAll.session.id,
										user_id:  this.StoreAll.user.id,
										vote_category_id: id
									},
									{
										headers: {
											'x-tenant': this.StoreAll.voteSocket,
											authorization: 'Bearer ' + this.StoreAll.socketToken,
										}
									}).then((response) => {
									if (response.status === 200) {
										if (id == 1) {
											this.StoreAll.VoteConf = 1;
											this.loading = false;
										} else if (id == 2) {
											this.StoreAll.VoteConf = 2;
											this.loading = false;
										} else {
											this.StoreAll.VoteConf = 3;
											this.loading = false;
										}
									}
									this.StoreAll.voted = true;
									this.voted = this.StoreAll.voted;
									this.$refs.viewDocument.closeModal();

								});
				        }, 1000);
				    }
			    });
			}
		},

		startvote(value) {
			let OnSystem = setInterval(() => {
				if (this.StoreAll.in_quorum !== null) {
					clearInterval(OnSystem);
					if (value === 1){
						this.Bus.ModalVoteOn = true;
					}

					if (value === 2) {
						this.Bus.ModalVoteOn = false;
					}
					this.document = [];
					this.favorable = 0;
					this.against = 0;
					this.vista = 0;
					this.document = this.StoreAll.document;
					this.Dat_doc();
				}}, 300);
		},


		endVoting_not_president() {
			if (this.StoreAll.user.user_category_id == 1) {
				return;
			} else {
				this.$refs.viewDocument.closeModal();
				try {
					this.viewDocument(this.StoreAll.document.id);
				} catch {
					return;
				}
			}
		},

	}
};


</script>
<template>
    <div>
        <ModalBig ref="viewDocument" style="z-index: 999 !important">
            <template #header>
                <div class="d-flex justify-content-between">
                    <div class="status_label">
                        <strong><span class="ml mr title">VOTAÇÃO -</span>
                            <span v-if="document.document_status_vote_id == 4" class="text-danger ml title"> EM
                                ANDAMENTO</span>
                            <span v-if="document.document_status_vote_id == 5" class="text-success ml title">
                                CONCLUÍDA</span>
                        </strong>
                    </div>
                    <div class="flex-start">

                        <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </template>

            <template #body>

                <div v-if="this.StoreAll.vote_secret">
                    <div v-if="document.document_status_vote_id == 5" class="d-flex justify-content-around">

                        <div class="d-grid justify-content-center text-center w-50 text-bold" style="align-items: center; border: 1px solid black;">
                            <span style="font-size: 21px;color: black;font-family: 'Dosis', sans-serif;">
                                DOCUMENTO EM VOTAÇÃO
                            </span>
                            <span style="font-size: 21px;color: black;font-family: 'Dosis', sans-serif;">
                                {{ document.name }}
                            </span>
                            <span style="font-size: 21px;color: black;font-family: 'Dosis', sans-serif;">
                                Autor(es): {{ autor }}
                            </span>
                        </div>


                        <div class="mt"
                            style="width: 40%; margin-left: 2rem;text-align: center;color: rgb(0, 0, 0);display: flex;flex-direction: column;align-content: flex-end !important;">
                            <h2 style="font-size: 26px;color: black;font-family: 'Dosis', sans-serif;">RESULTADO DA VOTAÇÃO
                            </h2>

                            <div v-if="this.StoreAll.voteResult === 1" class="mt-2 mb-2">
                                <img src="../../../assets/img/check.png" style="width: 40%;">
                                <h2 style="font-size: 26px;color: green;font-family: 'Dosis', sans-serif;">APROVADO</h2>
                            </div>

                            <div v-else-if="this.StoreAll.voteResult === 2" class="mt-2 mb-2">
                                <img src="../../../assets/img/CheckX.png" style="width: 40%;">
                                <h2 style="font-size: 26px;color: red;font-family: 'Dosis', sans-serif;">REPROVADO</h2>
                            </div>

                            <div v-else class="mt-2 mb-2">
                                <img src="../../../assets/img/AbstenCheck.png" style="width: 40%;">
                                <h2 style="font-size: 26px;color: BLACK;font-family: 'Dosis', sans-serif;">ABSTENÇÃO</h2>
                            </div>

                            <div class="myrow mt text-center">
                                <div class="flex-wrap">
                                    <div class="mt-1 ml-1 mr-1" style="background-color: darkred;">
                                        <div
                                            class="text-white text-uppercase font-weight-bold rounded pl-2 pr-2 text-black text-center">
                                            VOTAÇÃO SECRETA
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex justify-content-center mt-3">
                                <h2 style="font-size: 26px;color: black;font-family: 'Dosis', sans-serif;">SEU
                                    VOTO:</h2>

                                <div class="mt-1 ml-1 mr-1">
                                    <div class="d-flex text-uppercase font-weight-bold rounded ml-2 pl-2 pr-2 text-black">

                                        <Tag v-if="vote_hidden"
                                            style="border: 1px solid rgb(6 122 167);background-color: rgb(6 93 167 / 10%);color: rgb(6 99 167);">
                                            <div class="flex align-items-center gap-2">
                                                <i class="fa fa-user-secret mr-2" aria-hidden="true"></i>
                                                <span class="text-base text-uppercase"
                                                    style="font-size: 14px;">SECRETO</span>
                                            </div>
                                        </Tag>

                                        <Tag v-else-if="this.StoreAll.userVote.length <= 0"
                                            style="border: 1px solid rgb(8 5 5);background-color: rgba(9,167, 6, 0.1);color: rgb(0 0 0);">
                                            <div class="flex align-items-center gap-2">
                                                <i class="fa fa-hand-paper-o mr-1" aria-hidden="true"></i>
                                                <span class="text-base text-uppercase"
                                                    style="font-size: 14px;">AGUARDE...</span>
                                            </div>
                                        </Tag>

                                        <Tag v-else-if="this.StoreAll.userVote.vote_category_id === '1'"
                                            style="border: 1px solid rgb(9 167 6);background-color: rgba(9,167, 6, 0.1);color: rgb(9 167 6);">
                                            <div class="flex align-items-center gap-2">
                                                <i class="fa fa-check mr-2" aria-hidden="true"></i>
                                                <span class="text-base text-uppercase" style="font-size: 14px;">A
                                                    FAVOR</span>
                                            </div>
                                        </Tag>

                                        <Tag v-else-if="this.StoreAll.userVote.vote_category_id === '2'"
                                            style="border: 1px solid rgb(231, 0, 0);background-color: rgba(9,167, 6, 0.1);color: rgb(231, 0, 0);">
                                            <div class="flex align-items-center gap-2">
                                                <i class="fa fa-times-circle-o mr-1" aria-hidden="true"></i>
                                                <span class="text-base text-uppercase"
                                                    style="font-size: 14px;">CONTRA</span>
                                            </div>
                                        </Tag>

                                        <Tag v-else-if="this.StoreAll.userVote.vote_category_id === '3'"
                                            style="border: 1px solid rgb(8 5 5);background-color: rgba(9,167, 6, 0.1);color: rgb(0 0 0);">
                                            <div class="flex align-items-center gap-2">
                                                <i class="fa fa-hand-paper-o mr-1" aria-hidden="true"></i>
                                                <span class="text-base text-uppercase"
                                                    style="font-size: 14px;">ABSTENÇÃO</span>
                                            </div>
                                        </Tag>

                                        <div class="ml-3 pt-1">
                                            <a v-if="vote_hidden" style="cursor: pointer;" @click="Votehidden(false)"><i
                                                    class="fa fa-eye-slash" aria-hidden="true"></i></a>
                                            <a v-else style="cursor: pointer;" @click="Votehidden(true)"><i
                                                    class="fas fa-eye" aria-hidden="true"></i></a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div>

                            <div class="d-flex justify-content-around"
                                v-if="document.document_status_vote_id == 4 && !this.StoreAll.voted && this.StoreAll.in_quorum">
                                <div class="d-grid justify-content-center text-center w-50 text-bold"
                                    style="align-items: center; border: 1px solid black;">
                                    <span style="font-size: 21px;color: black;font-family: 'Dosis', sans-serif;">
                                        DOCUMENTO EM VOTAÇÃO
                                    </span>
                                    <span style="font-size: 21px;color: black;font-family: 'Dosis', sans-serif;">
                                        {{ document.name }}
                                    </span>
                                    <span style="font-size: 21px;color: black;font-family: 'Dosis', sans-serif;">
                                        Autor(es): {{ autor }}
                                    </span>
                                </div>

                                <div v-if="loading">
                                    <div class="d-flex justify-content-center">
                                        <spinnerprime/>
                                    </div>
                                    <div class="text-center">
                                        <span class="text-dark"><strong>Aguarde, estamos computando seu voto...</strong></span>
                                    </div>
                                </div>

                                <div v-else class="mt-5">
                                    <div>
                                        <h2>Como vota?</h2>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <button type="button" class="btn btn-success mb-4 text-lg" @click="vote(1)"
                                            style="width: 140px; height: 60px;">A Favor</button>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <button type="button" class="btn btn-danger mb-4 text-lg" @click="vote(2)"
                                            style="width: 140px; height: 60px;">Contra</button>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <button type="button" class="btn btn-secondary mb-1 text-lg" @click="vote(3)"
                                            style="width: 140px; height: 60px;">Se Abster</button>
                                    </div>
                                </div>
                            </div>
                            <div v-if="this.StoreAll.voted == true">

                                <div class="d-flex justify-content-around">
                                    <div class="d-grid justify-content-center text-center w-50 text-bold"
                                        style="align-items: center; border: 1px solid black;">
                                        <span style="font-size: 21px;color: black;font-family: 'Dosis', sans-serif;">
                                            DOCUMENTO EM VOTAÇÃO
                                        </span>
                                        <span style="font-size: 21px;color: black;font-family: 'Dosis', sans-serif;">
                                            {{ document.name }}
                                        </span>
                                        <span style="font-size: 21px;color: black;font-family: 'Dosis', sans-serif;">
                                            Autor(es): {{ autor }}
                                        </span>
                                    </div>
                                    <div class="mt"
                                        style="width: 40%; margin-left: 2rem;text-align: center;color: rgb(0, 0, 0);display: flex;flex-direction: column;align-content: flex-end !important;"
                                        v-if="document.document_status_vote_id == 4">
                                        <h2 style="font-size: 26px;color: black;font-family: 'Dosis', sans-serif;">
                                            VOTO CONFIRMADO</h2>

                                        <div class="myrow mt text-center">
                                            <div class="flex-wrap">
                                                <div class="mt-1 ml-1 mr-1" style="background-color: darkred;">
                                                    <div
                                                        class="text-white text-uppercase font-weight-bold rounded pl-2 pr-2 text-black text-center">
                                                        VOTAÇÃO SECRETA
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="d-flex justify-content-center mt-3">
                                            <h2 style="font-size: 26px;color: black;font-family: 'Dosis', sans-serif;">SEU
                                                VOTO:</h2>

                                            <div class="mt-1 ml-1 mr-1">
                                                <div
                                                    class="d-flex text-uppercase font-weight-bold rounded ml-2 pl-2 pr-2 text-black">

                                                    <Tag v-if="vote_hidden"
                                                        style="border: 1px solid rgb(6 122 167);background-color: rgb(6 93 167 / 10%);color: rgb(6 99 167);">
                                                        <div class="flex align-items-center gap-2">
                                                            <i class="fa fa-user-secret mr-2" aria-hidden="true"></i>
                                                            <span class="text-base text-uppercase"
                                                                style="font-size: 14px;">SECRETO</span>
                                                        </div>
                                                    </Tag>

                                                    <Tag v-else-if="this.StoreAll.userVote.length <= 0"
                                                        style="border: 1px solid rgb(8 5 5);background-color: rgba(9,167, 6, 0.1);color: rgb(0 0 0);">
                                                        <div class="flex align-items-center gap-2">
                                                            <i class="fa fa-hand-paper-o mr-1" aria-hidden="true"></i>
                                                            <span class="text-base text-uppercase"
                                                                style="font-size: 14px;">AGUARDE...</span>
                                                        </div>
                                                    </Tag>

                                                    <Tag v-else-if="this.StoreAll.userVote.vote_category_id === '1'"
                                                        style="border: 1px solid rgb(9 167 6);background-color: rgba(9,167, 6, 0.1);color: rgb(9 167 6);">
                                                        <div class="flex align-items-center gap-2">
                                                            <i class="fa fa-check mr-2" aria-hidden="true"></i>
                                                            <span class="text-base text-uppercase"
                                                                style="font-size: 14px;">A FAVOR</span>
                                                        </div>
                                                    </Tag>

                                                    <Tag v-else-if="this.StoreAll.userVote.vote_category_id === '2'"
                                                        style="border: 1px solid rgb(231, 0, 0);background-color: rgba(9,167, 6, 0.1);color: rgb(231, 0, 0);">
                                                        <div class="flex align-items-center gap-2">
                                                            <i class="fa fa-times-circle-o mr-1" aria-hidden="true"></i>
                                                            <span class="text-base text-uppercase"
                                                                style="font-size: 14px;">CONTRA</span>
                                                        </div>
                                                    </Tag>

                                                    <Tag v-else-if="this.StoreAll.userVote.vote_category_id === '3'"
                                                        style="border: 1px solid rgb(8 5 5);background-color: rgba(9,167, 6, 0.1);color: rgb(0 0 0);">
                                                        <div class="flex align-items-center gap-2">
                                                            <i class="fa fa-hand-paper-o mr-1" aria-hidden="true"></i>
                                                            <span class="text-base text-uppercase"
                                                                style="font-size: 14px;">ABSTENÇÃO</span>
                                                        </div>
                                                    </Tag>

                                                    <div class="ml-3 pt-1">
                                                        <a v-if="vote_hidden" style="cursor: pointer;"
                                                            @click="Votehidden(false)"><i class="fa fa-eye-slash"
                                                                aria-hidden="true"></i></a>
                                                        <a v-else style="cursor: pointer;" @click="Votehidden(true)"><i
                                                                class="fas fa-eye" aria-hidden="true"></i></a>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div v-else>
                    <div v-if="document.document_status_vote_id == 5" class="d-flex justify-content-around">

                        <div class="d-grid justify-content-center text-center w-50 text-bold" style="align-items: center; border: 1px solid black;">
                            <span style="font-size: 21px;color: black;font-family: 'Dosis', sans-serif;">
                                DOCUMENTO EM VOTAÇÃO
                            </span>
                            <span style="font-size: 21px;color: black;font-family: 'Dosis', sans-serif;">
                                {{ document.name }}
                            </span>
                            <span style="font-size: 21px;color: black;font-family: 'Dosis', sans-serif;">
                                Autor(es): {{ autor }}
                            </span>
                        </div>

                        <div>

                        </div>
                        <div
                            style="width: 40%; margin-left: 2rem;text-align: center;color: rgb(0, 0, 0);display: flex;flex-direction: column;align-content: flex-end !important;">

                            <h2 style="font-size: 26px;color: black;font-family: 'Dosis', sans-serif;">RESULTADO DA VOTAÇÃO
                            </h2>

                            <div v-if="this.StoreAll.voteResult === 1" class="mt-2 mb-2">
                                <img src="../../../assets/img/check.png" style="width: 40%;">
                                <h2 style="font-size: 26px;color: green;font-family: 'Dosis', sans-serif;">APROVADO</h2>
                            </div>

                            <div v-else-if="this.StoreAll.voteResult === 2" class="mt-2 mb-2">
                                <img src="../../../assets/img/CheckX.png" style="width: 40%;">
                                <h2 style="font-size: 26px;color: red;font-family: 'Dosis', sans-serif;">REPROVADO</h2>
                            </div>

                            <div v-else class="mt-2 mb-2">
                                <img src="../../../assets/img/AbstenCheck.png" style="width: 40%;">
                                <h2 style="font-size: 26px;color: BLACK;font-family: 'Dosis', sans-serif;">ABSTENÇÃO</h2>
                            </div>

                            <div class="d-flex justify-content-center">
                                <h2 style="font-size: 26px;color: black;font-family: 'Dosis', sans-serif;">SEU VOTO:</h2>

                                <div class="mt-1 ml-1 mr-1">
                                    <div class="text-uppercase font-weight-bold rounded ml-2 pl-2 pr-2 text-black">

                                        <Tag v-if="this.StoreAll.userVote.length <= 0"
                                            style="border: 1px solid rgb(8 5 5);background-color: rgba(9,167, 6, 0.1);color: rgb(0 0 0);">
                                            <div class="flex align-items-center gap-2">
                                                <i class="fa fa-hand-paper-o mr-1" aria-hidden="true"></i>
                                                <span class="text-base text-uppercase"
                                                    style="font-size: 14px;">AGUARDE...</span>
                                            </div>
                                        </Tag>

                                        <Tag v-else-if="this.StoreAll.userVote.vote_category_id === '1'"
                                            style="border: 1px solid rgb(9 167 6);background-color: rgba(9,167, 6, 0.1);color: rgb(9 167 6);">
                                            <div class="flex align-items-center gap-2">
                                                <i class="fa fa-check mr-2" aria-hidden="true"></i>
                                                <span class="text-base text-uppercase" style="font-size: 14px;">A
                                                    FAVOR</span>
                                            </div>
                                        </Tag>

                                        <Tag v-else-if="this.StoreAll.userVote.vote_category_id === '2'"
                                            style="border: 1px solid rgb(231, 0, 0);background-color: rgba(9,167, 6, 0.1);color: rgb(231, 0, 0);">
                                            <div class="flex align-items-center gap-2">
                                                <i class="fa fa-times-circle-o mr-1" aria-hidden="true"></i>
                                                <span class="text-base text-uppercase"
                                                    style="font-size: 14px;">CONTRA</span>
                                            </div>
                                        </Tag>

                                        <Tag v-else-if="this.StoreAll.userVote.vote_category_id === '3'"
                                            style="border: 1px solid rgb(8 5 5);background-color: rgba(9,167, 6, 0.1);color: rgb(0 0 0);">
                                            <div class="flex align-items-center gap-2">
                                                <i class="fa fa-hand-paper-o mr-1" aria-hidden="true"></i>
                                                <span class="text-base text-uppercase"
                                                    style="font-size: 14px;">ABSTENÇÃO</span>
                                            </div>
                                        </Tag>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div>

                            <div class="d-flex justify-content-around"
                                v-if="document.document_status_vote_id == 4 && !this.StoreAll.voted && this.StoreAll.in_quorum">

                                <div class="d-grid justify-content-center text-center w-50 text-bold"
                                    style="align-items: center; border: 1px solid black;">
                                    <span style="font-size: 21px;color: black;font-family: 'Dosis', sans-serif;">
                                        DOCUMENTO EM VOTAÇÃO
                                    </span>
                                    <span style="font-size: 21px;color: black;font-family: 'Dosis', sans-serif;">
                                        {{ document.name }}
                                    </span>
                                    <span style="font-size: 21px;color: black;font-family: 'Dosis', sans-serif;">
                                        Autor(es): {{ autor }}
                                    </span>
                                </div>

                                <div v-if="loading">
                                    <div class="d-flex justify-content-center">
                                        <spinnerprime/>
                                    </div>
                                    <div class="text-center">
                                        <span class="text-dark"><strong>Aguarde, estamos computando seu voto...</strong></span>
                                    </div>
                                </div>

                                <div v-else class="mt-3">
                                    <div>
                                        <h2 style="color: black;">Como vota?</h2>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <button type="button" class="btn btn-success mb-4 text-lg" @click="vote(1)"
                                            style="width: 140px; height: 60px;">A Favor</button>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <button type="button" class="btn btn-danger mb-4 text-lg" @click="vote(2)"
                                            style="width: 140px; height: 60px;">Contra</button>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <button type="button" class="btn btn-secondary mb-1 text-lg" @click="vote(3)"
                                            style="width: 140px; height: 60px;">Se Abster</button>
                                    </div>
                                </div>

                            </div>

                            <div v-if="this.StoreAll.voted == true">

                                <div class="d-flex justify-content-around">
                                    <div class="d-grid justify-content-center text-center w-50 text-bold"
                                        style="align-items: center; border: 1px solid black;">
                                        <span style="font-size: 21px;color: black;font-family: 'Dosis', sans-serif;">
                                            DOCUMENTO EM VOTAÇÃO
                                        </span>
                                        <span style="font-size: 21px;color: black;font-family: 'Dosis', sans-serif;">
                                            {{ document.name }}
                                        </span>
                                        <span style="font-size: 21px;color: black;font-family: 'Dosis', sans-serif;">
                                            Autor(es): {{ autor }}
                                        </span>
                                    </div>

                                    <div class="mt"
                                        style="width: 40%; margin-left: 2rem;text-align: center;color: rgb(0, 0, 0);display: flex;flex-direction: column;align-content: flex-end !important;"
                                        v-if="document.document_status_vote_id == 4">
                                        <h2 style="font-size: 26px;color: black;font-family: 'Dosis', sans-serif;">
                                            VOTO CONFIRMADO</h2>

                                        <div class="d-flex justify-content-center">
                                            <h2 style="font-size: 26px;color: black;font-family: 'Dosis', sans-serif;">SEU
                                                VOTO:</h2>

                                            <div class="mt-1 ml-1 mr-1">
                                                <div
                                                    class="text-uppercase font-weight-bold rounded ml-2 pl-2 pr-2 text-black">

                                                    <Tag v-if="this.StoreAll.userVote.length <= 0"
                                                        style="border: 1px solid rgb(8 5 5);background-color: rgba(9,167, 6, 0.1);color: rgb(0 0 0);">
                                                        <div class="flex align-items-center gap-2">
                                                            <i class="fa fa-hand-paper-o mr-1" aria-hidden="true"></i>
                                                            <span class="text-base text-uppercase"
                                                                style="font-size: 14px;">AGUARDE...</span>
                                                        </div>
                                                    </Tag>

                                                    <Tag v-else-if="this.StoreAll.userVote.vote_category_id === '1'"
                                                        style="border: 1px solid rgb(9 167 6);background-color: rgba(9,167, 6, 0.1);color: rgb(9 167 6);">
                                                        <div class="flex align-items-center gap-2">
                                                            <i class="fa fa-check mr-2" aria-hidden="true"></i>
                                                            <span class="text-base text-uppercase"
                                                                style="font-size: 14px;">A FAVOR</span>
                                                        </div>
                                                    </Tag>

                                                    <Tag v-else-if="this.StoreAll.userVote.vote_category_id === '2'"
                                                        style="border: 1px solid rgb(231, 0, 0);background-color: rgba(9,167, 6, 0.1);color: rgb(231, 0, 0);">
                                                        <div class="flex align-items-center gap-2">
                                                            <i class="fa fa-times-circle-o mr-1" aria-hidden="true"></i>
                                                            <span class="text-base text-uppercase"
                                                                style="font-size: 14px;">CONTRA</span>
                                                        </div>
                                                    </Tag>

                                                    <Tag v-else-if="this.StoreAll.userVote.vote_category_id === '3'"
                                                        style="border: 1px solid rgb(8 5 5);background-color: rgba(9,167, 6, 0.1);color: rgb(0 0 0);">
                                                        <div class="flex align-items-center gap-2">
                                                            <i class="fa fa-hand-paper-o mr-1" aria-hidden="true"></i>
                                                            <span class="text-base text-uppercase"
                                                                style="font-size: 14px;">ABSTENÇÃO</span>
                                                        </div>
                                                    </Tag>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </template>
            <template #footer>
                <div v-if="document.document_status_vote_id === 5">       
                    <table>
                    <tr class="text-center">
                    <th style="font-size: 21px;font-family: 'Dosis', sans-serif;" rowspan="2">Resultado</th>

                    <th style="background-color: rgba(9,167, 6, 0.1);color: rgb(9, 167, 6); font-size: 21px;font-family: 'Dosis', sans-serif;">A favor</th>

                    <th style="background-color: rgb(167 6 6 / 10%);color: rgb(231, 0, 0); font-size: 21px;font-family: 'Dosis', sans-serif;">Contra</th>

                    <th style="background-color: rgb(0 0 0 / 10%);color: rgb(0, 0, 0); font-size: 21px;font-family: 'Dosis', sans-serif;">Abstenção</th>

                    <th style="background-color: rgb(0 0 0 / 10%);color: rgb(0, 0, 0); font-size: 21px;font-family: 'Dosis', sans-serif;">Total</th>
                    </tr>

                    <tr class="text-center">
                        <th style="font-size: 21px;font-family: 'Dosis', sans-serif;">{{ this.StoreAll.favorable }}</th>

                        <th style="font-size: 21px;font-family: 'Dosis', sans-serif;">{{ this.StoreAll.against }}</th>

                        <th style="font-size: 21px;font-family: 'Dosis', sans-serif;">{{ this.StoreAll.vista }}</th>

                        <th style="font-size: 21px;font-family: 'Dosis', sans-serif;">{{ this.StoreAll.favorable + this.StoreAll.against + this.StoreAll.vista }}</th>

                    </tr>
                    </table>
                </div>
            </template>
        </ModalBig>
    </div>
</template>
<style>
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap');

.swal2-container {
    z-index: 10001 !important;
}

.close {
    font-size: 2.3rem !important;
}

.fixed-buttons {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
}
</style>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid #000;
  padding: 10px;
  text-align: center;
}
</style>