<template>
  <div class="fixed-plugin">
    <a
      class="px-3 py-2 fixed-plugin-button text-dark position-fixed" style="background-color: #218800;"
      @click="refresh()"
    >
      <i class="fa fa-refresh" style="color: white;"></i>
    </a>
  </div>
</template>

<script>

export default {
  name: "Configurator",
  methods: {

    refresh(){
      location.reload()
    }
  }
};
</script>
