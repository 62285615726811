<script>
import { AxiosAPI } from '@/axios';
import { useMainStore, useBusStore } from '../../stores/index';
import Loading from '../../components/documents/Modals/Loading.vue';

export default {
	name: 'ControleE',
	components: {Loading},
	setup(){
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		StoreAll.Token();
		return{
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			explanation: false,
			vereadores: [],
			explanation_buttom: 0,
			explanation_user: false,
			explanation_users: [],
			Vereador_explanation_now: [],
			explanation_on: false,
			id_explanation: null,
			user_id: 0,
			voted_on: null,
			tribune_on: null,
			discut_on: null,
			header_error:'',
			body_error: '',
			header_success: '',
			body_success: '',
			socket_ids: [],
		};
	},

	created () {
		let OnSocket = setInterval(() => {
			if (this.StoreAll.websocket_host !== null) {
				clearInterval(OnSocket);
				window.Echo.channel(this.StoreAll.websocket_host).listen('.' + this.StoreAll.websocket_host, (e) => {
					if (e.socket_id === 21) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Check_explanation();
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 22) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Check_explanation();
							this.socket_ids.push(e.event_id);  
						}
					} 
        
					if (e.socket_id === 23) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Check_explanation();
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 24) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Check_explanation();
							this.socket_ids.push(e.event_id);  
						}
					} 
				});
			}}, 500);
	},


	mounted() {
		this.GetSession();
		this.GetUsersAll();
		this.User();
	},
	methods: {

		async GetUsersAll() {
			await this.StoreAll.UserAllEnvio();
			this.vereadores = this.StoreAll.user_envio;
		},

		async GetSession() {
			/* verificar essa situação */
			await this.StoreAll.Get_Data(); 
			this.session_id = this.StoreAll.session.id; 
			this.Check_explanation();
		},

		async User(){
			await this.StoreAll.User(); 
			this.user_id = this.StoreAll.user.id;
			this.category_user = this.StoreAll.user.user_category_id; 
		},

		async Check_explanation() {
			await AxiosAPI
				.get(
					'/big-discussion?quorum_id=' + this.StoreAll.quorum[0].id,
					{
						headers: {
							authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}
				).then((resp) => {
					if (resp.data.data.length >= 1) {
						this.explanation = false;

						if (resp.data.data[0].status_big_discussion == 1) {

							this.id_explanation = resp.data.data[0].id;
							this.Explanation_user_now();
							this.explanation_on = true;
							this.explanation_buttom = 1;
							this.explanation_user = true;

						} else if (resp.data.data[0].status_big_discussion == 2) {
							this.explanation = true;
							this.explanation_on = false;
						}
					} else {
						this.explanation_on = false;
						this.explanation_buttom = 0;
					}
				});
		},

		async Checks(){
			this.$refs.Loading.openModal();
			await AxiosAPI.get('/documents?session_id='+this.StoreAll.session.id +'&document_status_vote_id=4',
				{
					headers: {
						Authorization: 'Bearer '+ this.StoreAll.accessToken,
					},
				}
			)
				.then((response) => {
					if(response.data.data.length <= 0){
						this.voted_on = false;
					}else{
						this.voted_on = true;
					}
				});


			await AxiosAPI.get('/discussion?quorum_id=' + this.StoreAll.quorum[0].id, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken,
				},
			}).then((resp) => {
				for (let i = 0; i < resp.data.data.length; i++) {
					if (resp.data.data[i].status_discussion == 1) {
						this.discut_on = true;
					} else {
						this.discut_on = false;
					}
				}
			});

			/* TRIBUNA */
			const quorum = this.StoreAll.quorum;
			const accessToken = this.StoreAll.accessToken;
			try{
				const response = await AxiosAPI.get('/tribune?quorum_id=' + quorum[0].id, {
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				});

				const tribunaData = response.data.data[0];

				if (tribunaData.status_tribune == 1 || tribunaData.status_tribune == 2) {
					this.tribune_on = true;
				} else {
					this.tribune_on = false;
				}
			}
			catch{
				this.tribune_on = false;
			}

			await AxiosAPI.get('/big-discussion?quorum_id' + this.StoreAll.quorum[0].id, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken,
				},
			}).then((resp) => {
				for (let i = 0; i < resp.data.data.length; i++) {
					if (resp.data.data[i].status_big_discussion == 1) {
						this.explanation_on = true;
					} else {
						this.explanation_on = false;
					}
				}
			});

			await this.Start();
        
		},

		Startexplanation_error_show() {
			this.$toast.add({ severity: 'error', summary: 'Explanações não inciada', detail: 'As explanações não foram iniciadas!', life: 3000 });
		},

		Success_explanation() {
			this.$toast.add({severity:'success', summary: 'Explanações iniciadas', detail:'As explanações foram iniciadas.', life: 3000});
		},

		show_error() {
			this.$toast.add({ severity: 'error', summary: this.header_error, detail: this.body_error, group: 'tl', life: 5000 });
		},
		show_success() {
			this.$toast.add({ severity: 'success', summary: this.header_success, detail: this.body_success, group: 'tl', life: 3000 });
		},

		async Start() {
			if(this.voted_on === true){
				this.$refs.Loading.closeModal();
				this.$swal.fire({
					title: 'Existe uma votação em andamento, solicite o encerramento para iniciar as explanações',
					showDenyButton: false,
					showCancelButton: false,
					buttonsStyling: false,
					confirmButtonText: 'OK',
					customClass: {
						confirmButton: 'btn btn-success mr-1 ml-1',
					}, 
				}).then((result) => {
					if (result.isConfirmed) {
						this.Starttribune_error_show();      
					}else{
						this.Starttribune_error_show();
					}});
			}
			else if(this.tribune_on === true){
				this.$refs.Loading.closeModal();
				this.$swal.fire({
					title: 'A tribuna está em andamento, encerre para iniciar as explanações',
					showDenyButton: false,
					showCancelButton: false,
					buttonsStyling: false,
					confirmButtonText: 'OK',
					customClass: {
						confirmButton: 'btn btn-success mr-1 ml-1',
					}, 
				}).then((result) => {
					if (result.isConfirmed) {
						this.Starttribune_error_show();      
					}else{
						this.Starttribune_error_show();
					}});
			}
			else if(this.explanation_on === true){
				this.$refs.Loading.closeModal();
				this.$swal.fire({
					title: 'A explicações pessoais já estão em andamento',
					showDenyButton: false,
					showCancelButton: false,
					buttonsStyling: false,
					confirmButtonText: 'OK',
					customClass: {
						confirmButton: 'btn btn-success mr-1 ml-1',
					}, 
				}).then((result) => {
					if (result.isConfirmed) {
						this.Starttribune_error_show();      
					}else{
						this.Starttribune_error_show();
					}});
			}
			else if(this.discut_on === true){
				this.$refs.Loading.closeModal();
				this.$swal.fire({
					title: 'Existe uma discussão em andamento, encerre para iniciar as explanações',
					showDenyButton: false,
					showCancelButton: false,
					buttonsStyling: false,
					confirmButtonText: 'OK',
					customClass: {
						confirmButton: 'btn btn-success mr-1 ml-1',
					}, 
				}).then((result) => {
					if (result.isConfirmed) {
						this.Starttribune_error_show();      
					}else{
						this.Starttribune_error_show();
					}});
			}else{
				this.$refs.Loading.closeModal();
				await AxiosAPI
					.post(
						'/big-discussion',
						{
							quorum_id: this.StoreAll.quorum[0].id,
						},
						{
							headers: {
								authorization: 'Bearer ' + this.StoreAll.accessToken,
							},
						}
					).then((resp) => {
						if (resp.status === 200) {
							this.Check_explanation();
							this.Success_explanation();
						}
					});
			}},

		async Finish() {
			this.$refs.Loading.openModal();
			let message = '';
			let buttonStyle = false;
			let confirmText = '';

			try {
				const dataCheck = await this.StoreAll.VerifySession();
                
				const hasExplanation = dataCheck.some(Check =>
					Check.big_discussions.some(ExplanationCheck =>
						ExplanationCheck.big_discussion_users.some(ExplanationUserCheck => 
							ExplanationUserCheck.status_fala_big_discussion === '1'))
				);


				if (hasExplanation) {
					message = 'Existe um vereador falando nas explanações pessoais, encerre a fala para finalizar as explanações';
					buttonStyle = false;
					confirmText = 'OK';
				} else {
					message = 'Deseja realmente encerrar as explanações finais ?';
					buttonStyle = true;
					confirmText = 'SIM';
				}

				this.$refs.Loading.closeModal();

				this.$swal.fire({
					title: message,
					showDenyButton: false,
					showCancelButton: buttonStyle,
					buttonsStyling: false,
					confirmButtonText: confirmText,
					cancelButtonText: 'VOLTAR',
					customClass: {
						confirmButton: 'btn btn-success mr-5 ml-1 text-lg',
						cancelButton: 'btn btn-secondary mr-1 ml-1 text-lg'
					},
				}).then((result) => {
					if (confirmText === 'OK') {
						this.error_show();
					} else {
						if (result.isConfirmed) {
							this.FinishExplanation();
						} else {
							this.error_show();
						}
					}
                  
				});
			}
			catch {
				console.log('erro');
			}
		},

		error_show() {
			this.$toast.add({ severity: 'error', summary: 'Explanações não encerradas', detail: 'Explanações não encerradas!', life: 3000 });
		},

		FinishExplanation() {
			AxiosAPI
				.put(
					'/big-discussion/'+this.id_explanation+'?status_big_discussion=2',{},
					{
						headers: {
							authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}
				).then((resp) => {
					if (resp.status === 200) {
						this.Check_explanation();
						this.header_success = 'Explanações encerradas!';
						this.body_success = 'Explanações encerradas!';
						this.show_success();
					}
				});
		},

		async send_users() {
			this.$refs.Loading.openModal();
			localStorage.setItem('explanation_user', this.explanation_users);

			const formData = new FormData();
			formData.append('big_discussion_id', this.id_explanation,);
			formData.append('user_id', this.explanation_users);

			await AxiosAPI
				.post(
					'/big-discussion-users', formData,
					{
						headers: {
							authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}
				).then((resp) => {
					if (resp.status === 200) {
						this.explanation_users = [];
						this.header_success = 'Fala iniciada!';
						this.body_success = 'Fala do vereador iniciada!';
						this.show_success();
						this.$refs.Loading.closeModal();
					}
				});

		},

		async Explanation_user_now() {
			await AxiosAPI.get('/big-discussion-users?big_discussion_id=' + this.id_explanation, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((response) => {
				let user = [];

				for (let i = 0; i < response.data.data.length; i++) {
					if (response.data.data[i].status_fala_big_discussion != 1) {
						console.log('usernow');
					}
					else if (response.data.data[i].status_fala_big_discussion == 1) {
						user.push(response.data.data[i]);
						this.explanation_user = false;
						this.talk_now = true;
					}
				}

				this.Vereador_explanation_now = user;
			}
			);
		},

		async Stoptalk() {
			this.$refs.Loading.openModal();
			let user_id = localStorage.getItem('explanation_user');
			const formData = new FormData();
			formData.append('big_discussion_id', this.id_explanation,);
			formData.append('user_id', user_id);
			formData.append('status_fala_big_discussion', 2);

			await AxiosAPI.post('/big-discussion-users-upp', formData, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((response) => {
				if (response.status === 200) {
					localStorage.removeItem('explanation_user');
					this.header_success = 'Fala encerrada!';
					this.body_success = 'Fala do vereador encerrada!';
					this.show_success();
					this.$refs.Loading.closeModal();
				}
			});
		},


	},
};
</script>
<template>
  <div style="z-index: 1010 !important;">
    <Loading ref="Loading"></Loading>
  </div>
  <div class="py-4 h-100 container-fluid">
    <div class="row">
      <div class="text-center col-12">
        <h3 class="mt-auto text-white">Controle das explanações pessoais</h3>
        <h5 class="text-white font-weight-normal">
          Adicione, pause e finalize as falas dos vereadores nas explanações pessoais!
        </h5>
        <div class="mb-5 multisteps-form">

          <div v-if="explanation == false" class="row">
            <div class="m-auto col-12">
              <form class="multisteps-form__form">
                <div>
                  <div class="p-3 bg-white card multisteps-form__panel border-radius-xl js-active position-relative"
                    data-animation="FadeIn">
                    <div class="text-center row">
                      <div class="mx-auto col-10">
                        <div>
                          <button v-if="explanation_buttom === 0" type="button" class="btn btn-primary bg-success"
                            style="width: 100%; height: 50px;" @click="Checks()"> INICIAR EXPLANAÇÕES PESSOAIS </button>
                          <button v-if="explanation_buttom === 1 && explanation_user" type="button" class="btn btn-primary bg-danger"
                            style="width: 100%; height: 50px;" @click="Finish()"> ENCERRAR EXPLANAÇÕES PESSOAIS </button>

                          <div v-if="explanation_on">
                            <div v-if="explanation_user" class="mt-5">
                              <div class="d-flex justify-content-center">
                                <span style="font-size: 20px;" class="font-weight-bolder"> Usuário atual: &nbsp;
                                  Aguarde...
                                </span>
                              </div>

                              <div class="d-flex justify-content-center">
                                <button type="button" class="btn btn-primary bg-success"
                                  style="width: 100%; height: 50px;" data-dismiss="modal" data-toggle="modal"
                                  data-target=".Explanation">
                                  Selecionar veriador para explanação
                                </button>
                              </div>
                            </div>

                            <div v-if="!explanation_user" class="mb-2" style="border-style: double;">
                              <div class="d-flex justify-content-center mb-3">
                                <span style="font-size: 20px;" class="font-weight-bolder"> Explanação pessoal
                                </span>
                              </div>

                              <div class="d-flex justify-content-center">
                                <img :src="this.StoreAll.Dochost+Vereador_explanation_now[0].user.path_image"
								class="rounded-circle justify-content-start" style="object-fit: cover; width: 150px" alt="user photo"/>
                              </div>

                              <div class="d-flex justify-content-center">
                                <span style="font-size: 20px;" class="font-weight-bolder"> Usuário atual: &nbsp;
                                  {{ Vereador_explanation_now[0].user.nickname }}
                                </span>
                              </div>

                              <div v-if="talk_now">
                                <div class="d-flex justify-content-center">
                                  <span v-if="Vereador_explanation_now[0].status_fala_big_discussion == 1"
                                    style="font-size: 20px;" class="font-weight-bolder"> Status: &nbsp; Falando
                                  </span>
                                </div>
                                <div class="d-flex justify-content-center">
                                  <button id="fim_discut" style="width: 100%; height: 50px; font-size: 20px;"
                                    type="button" class="justify-content-center btn btn-primary bg-gray-600"
                                    @click="Stoptalk()">
                                    Encerrar fala do vereador
                                  </button>
                                </div>

                              </div>

                              <div v-if="!talk_now">
                                <span style="font-size: 20px;" class="font-weight-bolder"> Status: &nbsp; Aguarde...
                                </span>
                              </div>

                            </div>
                          </div>
                          <div v-if="!explanation_on">

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div v-else>
            <div class="m-auto col-12">
              <form class="multisteps-form__form">
                <div>
                  <div class="p-3 bg-white card multisteps-form__panel border-radius-xl js-active position-relative"
                    data-animation="FadeIn">
                    <div class="text-center row">
                      <div class="mx-auto col-10">
                        <div>
                          <h1> Já feita explanações nessa sessão </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="modal fade bd-example-modal-lg-startmodal Explanation" tabindex="-1" role="dialog"
      aria-labelledby="myLargeModalLabel" aria-hidden="true" style="z-index: 10000">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">
              Explanações pessoais
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label style="font-size: 18px">
              <b> Marque vereador que deseja discutir o documento. </b>
            </label>
            <div v-for="(vereadorr, index) in vereadores" :key="vereadorr.id" class="mt" style="display: flex">
              <input :key="index" v-model="explanation_users" :value="vereadorr.id" class="mr" type="checkbox" style="width: 1.7rem; height: 1.7rem"
                />
              <label style="font-size: 18px">{{ vereadorr.name }}</label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Voltar
            </button>
            <button type="button" class="btn btn-primary bg-success" data-dismiss="modal" @click="send_users()">
              Enviar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal orador" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Orador</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="d-flex justify-content-center text-black">
              <p>O usuário é o primeiro orador ?</p>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button type="button" class="btn btn-success" data-dismiss="modal"
              data-toggle="modal" data-target=".Discut_conf" @click="Checks_vote(); Orador(1);">Sim</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal"
              data-toggle="modal" data-target=".Discut_conf" @click="Checks_vote(); Orador(2);">Não</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.close {
  font-size: 2.3rem !important;
}
</style>
