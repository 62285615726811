<template>
    <Loading ref="Loading"></Loading>
    <div>
        <ModalBigger ref="viewDocument">
            <template #header>
                <strong style="color: black"> <span>Discussão </span> </strong>

                <div class="flex-start">
                    <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </template>

            <template #body>

                <div v-if="status == 1">
                <span class="text-dark font-weight-bold d-flex justify-content-center">Deseja realmente iniciar a discussão do documento: </span> 
                    <span style="color: red;" class="font-weight-bold d-flex justify-content-center"><br/>{{document_discut_name}}</span>
                </div>

                <div v-if="status == 2">
                <span class="text-dark font-weight-bold d-flex justify-content-center">Deseja realmente iniciar a discussão dos documentos </span>
                <span v-for="discuts_lotes in Multi_Select_Docs_Discut" :key="discuts_lotes" style="color: red;"
                    class="font-weight-bold d-flex justify-content-center">
                    Protocolo: {{ discuts_lotes.id }}</span>
                </div>

            </template>

            <template #footer>
                
                <div v-if="status == 1" class="d-flex justify-content-center">
                <button type="button" class="btn btn-danger mr-3" @click="$refs.viewDocument.closeModal()">Não</button>
                <button type="button" class="btn btn-success" @click="Checks(1);">Sim</button>
                </div>

                <div v-if="status == 2" class="d-flex justify-content-center">
                <button type="button" class="btn btn-danger mr-3" @click="$refs.viewDocument.closeModal()">Não</button>
                <button type="button" class="btn btn-success" @click="Checks(2);">Sim</button>
                </div>
            </template>
        </ModalBigger>

    </div>
</template>
    
<script>
import ModalBigger from "./ModalBigger.vue";
  import { AxiosAPI } from "@/axios";
  import Loading from '../Modals/Loading.vue';
/*   import {mapState} from 'vuex'; */

export default {
    components: {
        ModalBigger,
        Loading
    },

    data() {
        return {
            status: 1,
            document_discut_name: 'Aguarde...',
            document_discut_id: null,
            Multi_Select_Docs_Discut: null,
            voted_on: null,
            tribune_on: null,
            explanation_on: null,
            discut_on: null,
        }
    },

    methods: {      
        openModal(event) {
            console.log(event)
            this.$refs.viewDocument.openModal();
            this.Multi_Select_Docs_Discut = this.$pinia.state.value.main.MultiSelectDocs
            this.document_discut_name = event.name
            this.document_discut_id = event.id
        },

        openModalLote() {
            this.$refs.viewDocument.openModal();
            this.Multi_Select_Docs_Discut = this.$pinia.state.value.main.MultiSelectDocs
        },

        StatusDisc(event) {
            console.log(event)  
            this.status = event
        },

        async Checks(value){
        this.$refs.viewDocument.closeModal();
        this.$refs.Loading.openModal();
        /* Verfificações */
        /* VOTAÇÃO */
        await AxiosAPI.get("/documents?session_id="+this.$pinia.state.value.main.session.id +"&document_status_vote_id=4",
                {
                  headers: {
                    Authorization: `Bearer `+ this.$pinia.state.value.main.accessToken,
                  },
                }
              )
              .then((response) => {
                if(response.data.data.length <= 0){
                    this.voted_on = false
                }else{
                    this.voted_on = true
                }
               /*  console.log(response.data.data[0], 'votação') */
              })

        /* DISCUSSÃO */
        await AxiosAPI.get('/discussion?quorum_id=' + this.$pinia.state.value.main.quorum[0].id, {
            headers: {
              Authorization: `Bearer ` + this.$pinia.state.value.main.accessToken,
            },
          }).then((resp) => {
            for (let i = 0; i < resp.data.data.length; i++) {
              if (resp.data.data[i].status_discussion == 1) {
                this.discut_on = true
              } else {
                this.discut_on = false
              }
            }
          })

        /* TRIBUNA */
        const quorum = this.$pinia.state.value.main.quorum;
        const accessToken = this.$pinia.state.value.main.accessToken;
        try{
        const response = await AxiosAPI.get('/tribune?quorum_id=' + quorum[0].id, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            });

            const tribunaData = response.data.data[0];

            if (tribunaData.status_tribune == 1 || tribunaData.status_tribune == 2) {
                this.tribune_on = true
            } else {
                this.tribune_on = false
            }
        }
        catch{
            this.tribune_on = false
        }

        /* BIG_DISCUSSION */
        await AxiosAPI.get("/big-discussion?quorum_id" + this.$pinia.state.value.main.quorum[0].id, {
            headers: {
              Authorization: `Bearer ` + this.$pinia.state.value.main.accessToken,
            },
          }).then((resp) => {
            for (let i = 0; i < resp.data.data.length; i++) {
              if (resp.data.data[i].status_big_discussion == 1) {
                this.explanation_on = true
              } else {
                this.explanation_on = false
              }
            }
          })
          if(value === 1){
          await this.StartDiscut();
        }else{
            await this.StartDiscut_lote();
        }
        },

       async StartDiscut(){
        let array_discut = JSON.stringify({
            "status_discussion": "1",
            "quorum_id": this.$pinia.state.value.main.quorum[0].id,
            "document_id": [this.document_discut_id]
            })

        if(this.voted_on === true){
            this.$refs.Loading.closeModal();
            this.$swal.fire({
              title: 'Existe uma votação em andamento, encerre para continuar',
              showDenyButton: false,
              showCancelButton: false,
              buttonsStyling: false,
              confirmButtonText: 'OK',
              customClass: {
              confirmButton: 'btn btn-success mr-1 ml-1',
            }, 
            }).then((result) => {
              console.log(result)
                if (result.isConfirmed) {
                this.StartDiscut_error_show();      
                }else{
                this.StartDiscut_error_show();
              }})
        }
        else if(this.tribune_on === true){
            this.$refs.Loading.closeModal();
            this.$swal.fire({
              title: 'A tribuna está em andamento, solicite o encerramento para continuar',
              showDenyButton: false,
              showCancelButton: false,
              buttonsStyling: false,
              confirmButtonText: 'OK',
              customClass: {
              confirmButton: 'btn btn-success mr-1 ml-1',
            }, 
            }).then((result) => {
              console.log(result)
                if (result.isConfirmed) {
                this.StartDiscut_error_show();      
                }else{
                this.StartDiscut_error_show();
              }})
        }
        else if(this.explanation_on === true){
            this.$refs.Loading.closeModal();
            this.$swal.fire({
              title: 'A explicações pessoais estão em andamento, solicite o encerramento para continuar',
              showDenyButton: false,
              showCancelButton: false,
              buttonsStyling: false,
              confirmButtonText: 'OK',
              customClass: {
              confirmButton: 'btn btn-success mr-1 ml-1',
            }, 
            }).then((result) => {
              console.log(result)
                if (result.isConfirmed) {
                this.StartDiscut_error_show();      
                }else{
                this.StartDiscut_error_show();
              }})
        }
        else if(this.discut_on === true){
            this.$refs.Loading.closeModal();
            this.$swal.fire({
              title: 'Existe uma discussão já em andamento, solicite o encerramento para continuar',
              showDenyButton: false,
              showCancelButton: false,
              buttonsStyling: false,
              confirmButtonText: 'OK',
              customClass: {
              confirmButton: 'btn btn-success mr-1 ml-1',
            }, 
            }).then((result) => {
              console.log(result)
                if (result.isConfirmed) {
                this.StartDiscut_error_show();      
                }else{
                this.StartDiscut_error_show();
              }})
        }else{
        this.$refs.Loading.closeModal();
           await AxiosAPI
            .post(
                "/discussion", array_discut,
                {
                    headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ` + this.$pinia.state.value.main.accessToken,
                    },
                }
                ).then((resp) => {
                    console.log(resp)
                    if (resp.status === 200) {
                        this.$refs.viewDocument.closeModal();
                        this.StartDiscut_show();
                    }
                    })
                }
                },

                async StartDiscut_lote() {
                let documents_discut = []

                for (let i = 0; i < this.Multi_Select_Docs_Discut.length; i++) {
                    documents_discut.push(this.Multi_Select_Docs_Discut[i].id)
                }

                let array_discut = JSON.stringify({
                    "status_discussion": "1",
                    "quorum_id": this.$pinia.state.value.main.quorum[0].id,
                    "document_id": documents_discut
                })

                if(this.voted_on === true){
            this.$refs.Loading.closeModal();
            this.$swal.fire({
              title: 'Existe uma votação em andamento, encerre para continuar',
              showDenyButton: false,
              showCancelButton: false,
              buttonsStyling: false,
              confirmButtonText: 'OK',
              customClass: {
              confirmButton: 'btn btn-success mr-1 ml-1',
            }, 
            }).then((result) => {
              console.log(result)
                if (result.isConfirmed) {
                this.StartDiscut_error_show();      
                }else{
                this.StartDiscut_error_show();
              }})
        }
        else if(this.tribune_on === true){
            this.$refs.Loading.closeModal();
            this.$swal.fire({
              title: 'A tribuna está em andamento, encerre para continuar',
              showDenyButton: false,
              showCancelButton: false,
              buttonsStyling: false,
              confirmButtonText: 'OK',
              customClass: {
              confirmButton: 'btn btn-success mr-1 ml-1',
            }, 
            }).then((result) => {
              console.log(result)
                if (result.isConfirmed) {
                this.StartDiscut_error_show();      
                }else{
                this.StartDiscut_error_show();
              }})
        }
        else if(this.explanation_on === true){
            this.$refs.Loading.closeModal();
            this.$swal.fire({
              title: 'A explicações pessoais estão em andamento, encerre para continuar',
              showDenyButton: false,
              showCancelButton: false,
              buttonsStyling: false,
              confirmButtonText: 'OK',
              customClass: {
              confirmButton: 'btn btn-success mr-1 ml-1',
            }, 
            }).then((result) => {
              console.log(result)
                if (result.isConfirmed) {
                this.StartDiscut_error_show();      
                }else{
                this.StartDiscut_error_show();
              }})
        }
        else if(this.discut_on === true){
            this.$refs.Loading.closeModal();
            this.$swal.fire({
              title: 'Existe uma discussão já em andamento, encerre para continuar',
              showDenyButton: false,
              showCancelButton: false,
              buttonsStyling: false,
              confirmButtonText: 'OK',
              customClass: {
              confirmButton: 'btn btn-success mr-1 ml-1',
            }, 
            }).then((result) => {
              console.log(result)
                if (result.isConfirmed) {
                this.StartDiscut_error_show();      
                }else{
                this.StartDiscut_error_show();
              }})
        }else{
        this.$refs.Loading.closeModal();
                await AxiosAPI
                    .post(
                    "/discussion", array_discut,
                    {
                        headers: {
                        'Content-Type': 'application/json',
                        authorization: `Bearer ` + this.$pinia.state.value.main.accessToken,
                        },
                    }
                    ).then((resp) => {
                    console.log(resp)
                    if (resp.status === 200) {
                    this.StartDiscutLote_show();
                    this.$refs.viewDocument.closeModal()
                    }
                    })}
    },

        StartDiscut_show() {
            this.$toast.add({ severity: 'success', summary: 'Discussão iniciada', detail: 'A discussão foi iniciada!', life: 3000 });
        },

        StartDiscutLote_show() {
            this.$toast.add({ severity: 'success', summary: 'Discussão iniciada', detail: 'Os documentos selecionados estão em discussão!', life: 3000 });
        },

        StartDiscut_error_show() {
            this.$toast.add({ severity: 'error', summary: 'Discussão não inciada', detail: 'A discussão não foi iniciada!', life: 3000 });
        },
    }
}


</script>
    
<style>

</style>