<template>
    <!-- ADICIONAR DELETE DOCUMENTO OU EDITAR -->
      <div class="container-fluid">
        <div class="mt-4 row">
          <div class="col-12">
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <h5 class="mb-0">Documentos Recepcionados!</h5>
                <p class="mb-0 text-sm">Agora é so dar seguimento a eles.</p>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="table-responsive">
                      <DataTable :value="Docs_recp" show-gridlines responsive-layout="scroll" :paginator="true" :rows="10">
                          <Column field="document_id" header="Protocolo" class="text-sm font-weight-bold text-wrap text-uppercase" :sortable="true"></Column>
                          <Column field="document_name" header="Documento" class="text-sm font-weight-bold text-wrap text-uppercase"></Column> 
                          <Column field="authors" header="Autor(es)"
                            class="text-sm font-weight-bold text-wrap text-uppercase" :sortable="true">
                            <template #body="{ data }">
                              <span>{{Author(data.authors)}} </span>
                            </template>
                          </Column>
                          <Column field="created_at" header="Data" class="text-sm font-weight-bold text-wrap text-uppercase">
                          <template #body="{data}">
                          {{formatDate(data.created_at)}}
                      </template>
                        </Column>
                          <Column  header="Enviar" class="text-sm font-weight-bold text-wrap text-uppercase TT text-center">
                            <template #body="slotProps">
                              <button  data-toggle="modal" style="margin-bottom: 0rem !important;" data-target=".Doc_send" type="button" class="btn bg-smartlegis btnEdit envio" @click="EventDoc(slotProps.data)"><i style="color: white !important;" class="fa fa-paper-plane"></i></button>
                    </template>
                            </Column>
                            <Column header="Visualizar" class="text-sm font-weight-bold text-wrap text-uppercase TT text-center">
                            <template #body="slotProps">
                              <button  data-toggle="modal" style="margin-bottom: 0rem !important;" type="button" data-target=".rec" class="btn bg-smartlegis btnEdit envio" @click="EventDoc(slotProps.data)"><i style="color: white !important;" class="fa fa-eye"></i></button>
                            </template>
                            </Column>
                            
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    
        <div
          class="modal fade bd-example-modal-lg Doc_send"
          tabindex="-1"
          role="dialog"
          aria-labelledby="mySmallModalLabel"
          aria-hidden="true"
          style="z-index: 999; display: none"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 id="exampleModalLabel" class="modal-title">
                  <span> Enviar documento: </span> <span id="NameDoc" style="color: red"> {{documento_name}} </span>
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Fechar"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <label>Local de envio</label>
                <select
                  id="choices-category"
                  class="form-control"
                  name="choices-category"
                >
                  <option
                    v-for="option in options"
                    :key="option.id"
                    :value="option.id"  
                  >
                    {{ option.name }}
                  </option>
                </select>
    
                <div class="mt-2 mb-3">
                  <label>{{ label }}</label>
                  <MultiSelectPrime id="choices-category" v-model="Comissions_id" class="form-control" :options="result" optionlabel="name" placeholder="Selecione as comissões" style="z-index: 1000; height: 60px;" />
              </div>
                          
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Fechar
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  data-dismiss="modal"
                  @click="Enviar();  finallyloading();"
                >
                  Enviar documento!
                </button>
              </div>
            </div>
          </div>
        </div>

        <div id="mymodals" class="modal fade bd-example-modal-xl parecer_proc_just" tabindex="-1" role="dialog"
          aria-labelledby="myExtraLargeModalLabel" aria-hidden="true" style="z-index: 10000; display: none;"
         >
          <div class="modal-dialog modal-xl parecer_proc_just">
            <div class="modal-content">
              <div class="modal-header">
                <strong><span> PARECERES </span> </strong>
                <button id="closess" type="button" class="close"  data-dismiss="modal" aria-label="Fechar">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div>
                <div class="mb-3">
                  <div class="d-flex justify-content-center mb-2 text-black"><span><strong> PARECER PROCURADOR </strong></span></div>
                  <div class="d-flex justify-content-center">
                  <h1 v-if="documento_src_proc == undefined" >Parecer do procurador não adicionado</h1>
                <iframe v-else-if="this.$pinia.state.value.main.mobile == true" :src="'https://docs.google.com/gview?url='+documento_src_proc+'&embedded=true'"  width="100%" height="580"
                style="border: none;"></iframe>
                <iframe v-else :src=documento_src_proc  width="100%" height="580"
                style="border: none;"></iframe>
                </div>
              </div>

              
  
                </div>
                <div class="mb-2">
                <div class="d-flex justify-content-center mb-2 text-black"> <span><strong> PARECER JUSTIÇA </strong></span></div>
                <div class="d-flex justify-content-center">
                  <h1 v-if="parecer_justice == null" >Parecer comissão de justiça não adicionado</h1>
                  <iframe v-else-if="this.$pinia.state.value.main.mobile == true" :src="'https://docs.google.com/gview?url='+parecer_justice+'&embedded=true'"  width="100%" height="580"
                style="border: none;"></iframe>
                <iframe v-else :src=parecer_justice  width="100%" height="580"
                style="border: none;"></iframe>
                </div>
              </div>

                
                 
              <div class="modal-footer d-flex justify-content-center">
                <div>
                  <a target="_blank" :href="documento_src_proc" download="proposed_file_name">
                    <div>
                      <h3>Baixar o Documento</h3>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

        <div
        class="modal fade bd-example-modal-lg Doc_parecer"
        tabindex="-1"
        role="dialog"
        aria-labelledby="mySmallModalLabel"
        aria-hidden="true"
        style="z-index: 999; display: none"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="exampleModalLabel" class="modal-title">
                <span> Adicionar parecer ao documento: </span> <span id="NameDoc" style="color: red"> {{documento_name}} </span>
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Fechar"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="mt-3 mb-3">
                              <label for="formFile" class="form-label"
                                >Selecione o arquivo</label
                              >
                              <input 
                                id="formFile"
                                class="form-control"
                                type="file"
                              />
                            </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Fechar
              </button>
              <button
                type="button"
                class="btn btn-success"
                data-dismiss="modal"
                @click="parecer();"
              >
                Enviar parecer!
              </button>
            </div>
          </div>
        </div>
      </div>
  
    
    
        <div id="mymodals" class="modal fade bd-example-modal-xl rec" tabindex="-1" role="dialog"
            aria-labelledby="myExtraLargeModalLabel" aria-hidden="true" style="z-index: 9999; display: none;"
           >
            <div class="modal-dialog modal-xl rec">
              <div class="modal-content">
                <div class="modal-header">
                  <strong><span> {{ documento_name }} - </span> <span v-if="document_status_move == 4" style="color:red"> Enviado a comissão de justiça
                    </span> </strong>
                  <button id="closess" type="button" class="close"  data-dismiss="modal" aria-label="Fechar">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  
                   


                  <div
                  class="d-flex justify-content-center">
                  <button type="button" class="justify-content-center btn btn-success mb-2 mr-2" data-toggle="modal" data-target=".parecer_proc_just" @click="Getparecer()">
                    Ver parecerer <i class="ml-2 fa fa-file-import"></i></button></div>

                <div class="d-flex justify-content-center">
                <iframe v-if="this.$pinia.state.value.main.mobile == true" :src="'https://docs.google.com/gview?url='+documento_src+'&embedded=true'"  width="100%" height="580"
                style="border: none;"></iframe>
                <iframe v-else :src=documento_src  width="100%" height="580"
                style="border: none;"></iframe>

              
  
                </div>
                   
                <div class="modal-footer d-flex justify-content-center">
                  <div>
                    <a target="_blank" :href="documento_src" download="proposed_file_name">
                      <div>
                        <h3>Baixar o Documento</h3>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
                    class="modal fade bd-example-modal-sm envio_x"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="mySmallModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-sm">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h6>STATUS DO DOCUMENTO</h6>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            @click="finallyloading()"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div
                            id="Doc_Erro"
                            class="justify-content-center"
                            style="display: none"
                          >
                            <span
                              class="
                                justify-content-center
                                text-uppercase
                                font-weight-bold
                              "
                              style="font-size: 13px; color: red"
                            >
                              Erro ao fazer envio para sessão, contate o administrador
                            </span>
                          </div>
                          
                          <div
                            id="Doc_Ok"
                            class="justify-content-center"
                            style="display: none"
                          >
                            <span
                              class="
                                justify-content-center
                                text-uppercase
                                font-weight-bold
                              "
                              style="font-size: 13px; color: green"
                            >
                              Documento Enviado para sessão com sucesso!
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
    
                  <Toast />
    
      </div>
    </template>
    
    <script>
    import { AxiosAPI } from "@/axios";
    import moment from "moment";
    import { useMainStore } from "../../stores/index";
    export default {
      name: "DataTables",
      setup(){
      const store_all = useMainStore()
      store_all.Token();
      return{
        store_all: store_all,
      }
    },
      data() {
        return {
          Comissions_id: null,
          Docs_recp: [],
          start_url: 'https://docs.google.com/gview?url=',
          end_url: '&embedded=true',
          document_status_move: '...',
          documento_name: 'Carregando...',
          documento_src: '',
          documento_src_proc: '',
          parecer_justice: null,
          documento_id: 0,
          document_row_comission_id: null,
          form: {
            NameDoc: "",
            TypeDoc: 1,
          },
          id_doc: 0,
          id_doc_safe: 0,
          NameDoc: "",
          id_session: "",
          ordem_id: 0,
          categories: [
            { key: "1", category: "Projeto de lei do executivo" },
            { key: "2", category: "Projeto de lei do legislativo" },
            { key: "3", category: "Requerimento" },
            { key: "4", category: "Moção" },
            { key: "5", category: "Indicação" },
            { key: "6", category: "Emenda" },
            { key: "7", category: "ATA" },
            { key: "8", category: "Pauta mesa diretora" },
            { key: "9", category: "Decreto" },
            { key: "10", category: "Projeto de resolução" },
            { key: "11", category: "Requerimento de Vereador" },
            { key: "12", category: "Projeto de decreto Legislativo" },
            { key: "13", category: "Parecer" },
            { key: "14", category: "Redação final" },
            { key: "15", category: "Parecer juridico" },
            { key: "16", category: "Oficio" }
          ],
          options: [
            { id: 0, name: "Comissão" },
          ],
          label: "Comissão",
          result: [
            { id: 1, name: "Comissão da educação" },
            { id: 2, name: "Comissão de infraestrutura" },
            { id: 3, name: "Comissão da saúde" },
            { id: 4, name: "Comissão da direitos humanos" },
            { id: 5, name: "Comissão da fazenda" },
            ],
        };
      },
      mounted() {
        // eslint-disable-next-line no-unused-vars
        this.GetComissionDoc();
        this.GetSession();
        this.GetSessionOther();
        this.GetUser();
        this.GetUsersAll();
      },
    
      methods: {

        async GetSessionOther() {
      await this.store_all.Get_Data();
    },

    async GetUser(){
      await this.store_all.User(); 
      this.category_user = this.$pinia.state.value.main.user.user_category_id
    },

    async GetUsersAll() {
      await this.store_all.UserAllEnvio();
      this.vereadores = this.$pinia.state.value.main.user_envio;  
    },

        formatDate(value) {
           moment.locale("pt-br");
           return moment(value).format("DD/MMMM/YYYY");
          },

        GetComissionDoc(){
        let dados = []
        AxiosAPI
          .get("/documents-comission?comission=6",{
            headers: {
              Authorization: `Bearer ` + this.$pinia.state.value.main.accessToken,
            },
          })
          .then((response) => {
            console.log(response)
            for (let i = 0; i< response.data.data.length; i++) {
            if(response.data.data[i].status_leitura == 1){
              continue;
            }else{
              dados.push(response.data.data[i])
              this.Docs_recp =  dados
            }
            }
        })
      },
    
         Get_Docs(){
          AxiosAPI
          .get("/documents?id="+this.documento_id, {
            headers: {
              Authorization: `Bearer `+ this.$pinia.state.value.main.accessToken,
              },
            })
            .then((resp) => {
              console.log(resp.data.data)
              this.documento_src = resp.data.data.attachment
            })
        }, 

        Author(value){
      let Co_user = []
      for (let i = 0; i < value.length; i++) {
        Co_user.push(value[i].nickname)
      }
      return Co_user.join(", ");
    },

        EventDoc(event){
          this.documento_name = event.document_name

          this.document_status_move = event.document_status_movement_id
          
          this.documento_id = event.document_id

          this.document_row_comission_id = event.comission_document_id
          console.log(event)
          

          AxiosAPI
          .get("/documents?id="+event.document_id, {
            headers: {
              Authorization: `Bearer `+ this.$pinia.state.value.main.accessToken,
              },
            })
            .then((resp) => {
              console.log(resp.data.data)
              this.documento_src = resp.data.data[0].attachment
              this.documento_src_proc = resp.data.data[0].parecer_procurador_pdf
            })
        },
    
        Getparecer(){
          this.parecer_justice = null
          let parecer_justice = []

      AxiosAPI
          .get("/parecer-comissao?document_id="+this.documento_id, {
            headers: {
              Authorization: `Bearer ` + this.$pinia.state.value.main.accessToken,
            },
          })
          .then((response) => {
            console.log(response)
            if(response.data.parecer.length <= 0){
            this.parecer_on = false
          }else{
            this.parecer_on = true
          }
         for (let i = 0; i< response.data.parecer.length; i++) {         
          if(response.data.parecer[i].comission_id == 6){
            parecer_justice.push(response.data.parecer[i])
            console.log(response.data.parecer[i])
          }else{
            this.parecer_justice = null
          }
          

          if( parecer_justice.length <= 0){
            this.parecer_justice = null
          }else{
            this.parecer_justice = parecer_justice[0].parecer_pdf
          }

        }
      })
    },
        GetSession() {
          //Necessário fazer get da sessão aqui pois o time de resposta entre $storage e esse componente gera bug.
          AxiosAPI
            .get("/sessions", {
              headers: {
                Authorization: `Bearer `+this.$pinia.state.value.main.accessToken,
              },
            })
            .then((response) => {
              this.t = response
            });
        },
    
         Enviar() {
          let comissions_send = []
          for (let i = 0; i< this.Comissions_id.length; i++) {
              comissions_send.push(this.Comissions_id[i].id)
              }
          const dataDoc = JSON.stringify({document_id: this.documento_id,comission_id: comissions_send});
          AxiosAPI.post("/document-to-comission",dataDoc, {
            headers: {
                'Content-Type': 'application/json',
                  Authorization: `Bearer `+this.$pinia.state.value.main.accessToken,
                },
            })
            .then((resp) => {
              
              console.log(resp);
              if (resp.status === 200) {

                AxiosAPI.post("document/change-status-comission?comission_document_id="+this.document_row_comission_id+"&status_leitura=1",{}, {
            headers: {
              Authorization: `Bearer `+this.$pinia.state.value.main.accessToken,
            },
          })
          .then((resp) => {
            
          console.log(resp)

                AxiosAPI.post("/document/change-status-move?document_id=" + this.documento_id + "&document_status_movement_id=5&user_id="+ this.$pinia.state.value.main.user.id,{}, {
            headers: {
              Authorization: `Bearer `+this.$pinia.state.value.main.accessToken,
            },
          })
          .then((resp) => {
            console.log(resp);
            this.showSuccess_send();
            this.GetComissionDoc();
          });});
              }else{
              document.getElementById("Doc_Erro").style.display = "flex";
              }        
            });      
        },

        parecer(){
          

      let file = document.getElementById("formFile").files[0];

      const formData = new FormData();

    
      formData.append("comission_document_id", this.document_row_comission_id);
      formData.append("parecer_pdf", file);
      formData.append("status_parecer", 1);

  AxiosAPI.post("/parecer-comissao",formData, {
      headers: {
        Authorization: `Bearer `+this.$pinia.state.value.main.accessToken,
      },
    })
    .then((resp) => {
      console.log(resp);
      
      this.Doc_parecer_ok_send()
    });

  },

  
      Doc_parecer_ok_send() {
          this.$toast.add({severity:'success', summary: 'Parecer', detail:'Parecer anexado com sucesso', life: 3000});
      },
        showSuccess_send() {
          this.$toast.add({severity:'success', summary: 'Documento enviado as comissões', detail:'O documento foi enviado as comissões com sucesso', life: 3000});
      },
    
        finallyloading() {
          document.getElementById("Doc_Ok").style.display = "none";
          document.getElementById("Doc_Erro").style.display = "none";
        },
    
        showSuccess_del() {
              this.$toast.add({severity:'success', summary: 'Documento deletado', detail:'O documento foi deletado com sucesso', life: 3000});
            },
          showSuccess_edit() {
              this.$toast.add({severity:'success', summary: 'Documento editado', detail:'O documento foi editado com sucesso', life: 3000});
          },
          showError_del() {
                this.$toast.add({severity:'error', summary: 'Erro ao deletar', detail:'O documento selecionado não foi deletado', life: 3000});
            },
            showError_edit() {
                this.$toast.add({severity:'error', summary: 'Erro ao editar', detail:'Documento selecioando não foi editado', life: 3000});
            },
    
      },
    };
    </script>
    <style>
    .previous {
      padding-right: 11px;
    }
    .close{
      font-size: 2.3rem !important;
    }
    </style>
    