<template>
    <div>
        <ModalBigger ref="viewDocument">
            <template #header>
                <strong><span> Envio para prefeitura </span> </strong>

                <div class="flex-start">
                    <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </template>

            <template #body>
                <div class="d-flex justify-content-center">
                    <span style="color:black; font-size: 20px;"> Tem certeza que deseja enviar esses documentos para prefeitura ?</span>
                </div>
                <div  class="d-flex justify-content-center">
                    <span v-for="docs in Select_Docs" :key="docs" style="color:red; font-size: 20px;"> Protocolo: {{ docs.id }} // </span>
                </div>
            </template>

            <template #footer>
                <div class="d-flex justify-content-center">
                    <button type="button" class="btn btn-secondary mr-3"  @click="$refs.viewDocument.closeModal();">Voltar</button>
                    <button type="button" class="btn btn-success" @click="Send_to_prefeitura();">Continuar</button>
                </div>
            </template>
        </ModalBigger>

    </div>
</template>
    
<script>
import ModalBigger from "./ModalBigger.vue";
import { AxiosAPI } from "@/axios";

export default {
    components: {
        ModalBigger,
    },

    data() {
        return {
            Select_Docs: null,
        }
    },

    methods: {
        openModal() {
            this.Select_Docs = this.$pinia.state.value.main.MultiSelectDocs
            console.log(this.$pinia.state.value.main.MultiSelectDocs)
            console.log(this.Select_Docs)

            this.$refs.viewDocument.openModal();
        },

        async Send_to_prefeitura() {

        let documents_ids_prefeitura = []

        for (let i = 0; i < this.Select_Docs.length; i++) {
        documents_ids_prefeitura.push(this.Select_Docs[i].id)
        }

        /* 
        Selecionar os documento que serão enviados a prefeitura.
        Fazer verificação e informa que já está com prefeitura.
        */

        let array_docs = JSON.stringify({
        documents: documents_ids_prefeitura,
        user_id: this.$pinia.state.value.main.user.id
        })

        await AxiosAPI.post("document/change-status-when-voted", array_docs, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ` + this.$pinia.state.value.main.accessToken,
        },
        })
        .then((resp) => {
            console.log(resp);
            this.$refs.viewDocument.closeModal();
            if (resp.status === 200) {
            this.Send_docs_prefeitura();
            }
        });
    },

    Send_docs_prefeitura() {
      this.$toast.add({ severity: 'success', summary: 'Envio feito!', detail: 'Os documentos foram enviados para a prefeitura!', life: 3000 });
    },

    }
}


</script>
    
<style></style>