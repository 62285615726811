<script>
import ModalGTP from '../documents/Modals/ModalGTP.vue';
import { useMainStore } from '../../stores/index';
import { AxiosAPI } from '@/axios';
export default {
	name: 'DocsSend',
	components: {ModalGTP},
	setup(){
		const StoreAll = useMainStore();
		StoreAll.Token();
		return{
			StoreAll: StoreAll,
		};
	},
	data() {
		return {
			title: 'aaaaa',
			comission_send: false,
			form: {
				NameDoc: '',
				NumberDoc: '',
				TypeDocName: '',
				TypeDoc: 0,
				user_id: 'aaa',
				year: 2024,
			},
			header_error: '',
			body_error: '',
			autores: [],
			DocNameCreate: '',
			check_box: null,
			users:[],
			category_user: 0,
			categories: [{id: 0, name: 'Selecione o tipo do documento'}],
		};
	},
	mounted(){
		this.GetUsersAll();
		this.GetSession();
		this.GetUser();
		this.Notification_sign();
		this.emitter.on('GptSend', () => { this.SubmitGPT(); });
	},
	methods: {
		 GPTSend () {
			AxiosAPI
				.post('/documents/search-repeated?search=' + this.form.NameDoc, {}, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken
					}
				})
				.then(resp => {
					if (resp.data.documents.length >= 1) {
						this.$refs.ModalGTP.openModal(resp.data.documents);
					}
					if (resp.data.documents.length <= 0) {
						this.SubmitD();
					}
				});
		},

		SubmitGPT(){
			if(this.form.TypeDoc == 0){this.form.TypeDocName = '';}
			else if(this.form.TypeDoc == 1){this.form.TypeDocName = 'PLE';}
			else if(this.form.TypeDoc ==2){this.form.TypeDocName = 'PLL';}
			else if(this.form.TypeDoc ==3){this.form.TypeDocName = 'REQ';}
			else if(this.form.TypeDoc ==4){this.form.TypeDocName = 'MOÇÃO';}
			else if(this.form.TypeDoc ==5){this.form.TypeDocName = 'INDICAÇÃO';}
			else if(this.form.TypeDoc ==6){this.form.TypeDocName = 'EMENDA';}
			else if(this.form.TypeDoc ==7){this.form.TypeDocName = 'ATA';}
			else if(this.form.TypeDoc ==8){this.form.TypeDocName = 'PAUTA MESA DIRETORA';}
			else if(this.form.TypeDoc ==9){this.form.TypeDocName = 'DECRETO';}
			else if(this.form.TypeDoc ==10){this.form.TypeDocName = 'PROJETO DE RESOLUÇÃO';}
			else if(this.form.TypeDoc ==11){this.form.TypeDocName = 'RV';}
			else if(this.form.TypeDoc ==12){this.form.TypeDocName = 'PDL';}
			else if(this.form.TypeDoc ==13){this.form.TypeDocName = 'PARECER';}
			else if(this.form.TypeDoc ==14){this.form.TypeDocName = 'REDAÇÃO FINAL';}
			else if(this.form.TypeDoc ==15){this.form.TypeDocName = 'PARECER JURÍDICO';}
			else if(this.form.TypeDoc ==16){this.form.TypeDocName = 'OFÍCIO';}
			else if(this.form.TypeDoc ==17){this.form.TypeDocName = 'MENSAGEM DO EXECUTIVO';}
			else if(this.form.TypeDoc ==18){this.form.TypeDocName = 'PDL COLETIVO';}
			else if(this.form.TypeDoc ==19){this.form.TypeDocName = 'REQ COLETIVO';}
			else if(this.form.TypeDoc ==20){this.form.TypeDocName = 'MOÇÃO COLETIVA';}
			else if(this.form.TypeDoc ==21){this.form.TypeDocName = 'EMENDA COLETIVA';}
			else if(this.form.TypeDoc ==22){this.form.TypeDocName = 'INDICAÇÃO COLETIVA';}
			else if(this.form.TypeDoc ==23){this.form.TypeDocName = 'LEI SANCIONADA';}
			else if(this.form.TypeDoc ==24){this.form.TypeDocName = 'PR COLETIVO';}
			else if(this.form.TypeDoc ==25){this.form.TypeDocName = 'MENSAGEM DO LEGISLATIVO';}

			if(this.form.TypeDoc != 7){
				this.DocNameCreate = this.form.TypeDocName + ' ' + this.form.NumberDoc + '/' + this.form.year + ' - ' + this.form.NameDoc;
			}else{
				this.DocNameCreate = this.form.TypeDocName + ' ' + this.form.year + ' - ' + this.form.NameDoc;
			}
			this.submitDoc(7);
		},

		SubmitD(){
			if(this.form.TypeDoc == 0){this.form.TypeDocName = '';}
			else if(this.form.TypeDoc == 1){this.form.TypeDocName = 'PLE';}
			else if(this.form.TypeDoc ==2){this.form.TypeDocName = 'PLL';}
			else if(this.form.TypeDoc ==3){this.form.TypeDocName = 'REQ';}
			else if(this.form.TypeDoc ==4){this.form.TypeDocName = 'MOÇÃO';}
			else if(this.form.TypeDoc ==5){this.form.TypeDocName = 'INDICAÇÃO';}
			else if(this.form.TypeDoc ==6){this.form.TypeDocName = 'EMENDA';}
			else if(this.form.TypeDoc ==7){this.form.TypeDocName = 'ATA';}
			else if(this.form.TypeDoc ==8){this.form.TypeDocName = 'PAUTA MESA DIRETORA';}
			else if(this.form.TypeDoc ==9){this.form.TypeDocName = 'DECRETO';}
			else if(this.form.TypeDoc ==10){this.form.TypeDocName = 'PROJETO DE RESOLUÇÃO';}
			else if(this.form.TypeDoc ==11){this.form.TypeDocName = 'RV';}
			else if(this.form.TypeDoc ==12){this.form.TypeDocName = 'PDL';}
			else if(this.form.TypeDoc ==13){this.form.TypeDocName = 'PARECER';}
			else if(this.form.TypeDoc ==14){this.form.TypeDocName = 'REDAÇÃO FINAL';}
			else if(this.form.TypeDoc ==15){this.form.TypeDocName = 'PARECER JURÍDICO';}
			else if(this.form.TypeDoc ==16){this.form.TypeDocName = 'OFÍCIO';}
			else if(this.form.TypeDoc ==17){this.form.TypeDocName = 'MENSAGEM DO EXECUTIVO';}
			else if(this.form.TypeDoc ==18){this.form.TypeDocName = 'PDL COLETIVO';}
			else if(this.form.TypeDoc ==19){this.form.TypeDocName = 'REQ COLETIVO';}
			else if(this.form.TypeDoc ==20){this.form.TypeDocName = 'MOÇÃO COLETIVA';}
			else if(this.form.TypeDoc ==21){this.form.TypeDocName = 'EMENDA COLETIVA';}
			else if(this.form.TypeDoc ==22){this.form.TypeDocName = 'INDICAÇÃO COLETIVA';}
			else if(this.form.TypeDoc ==23){this.form.TypeDocName = 'LEI SANCIONADA';}
			else if(this.form.TypeDoc ==24){this.form.TypeDocName = 'PR COLETIVO';}
			else if(this.form.TypeDoc ==25){this.form.TypeDocName = 'MENSAGEM DO LEGISLATIVO';}

			if(this.form.TypeDoc != 7){
				this.DocNameCreate = this.form.TypeDocName + ' ' + this.form.NumberDoc + '/' + this.form.year + ' - ' + this.form.NameDoc;
			}else{
				this.DocNameCreate = this.form.TypeDocName + ' ' + this.form.year + ' - ' + this.form.NameDoc;
			}
			this.submitDoc(1);
		},

		async Notification_sign(){
			await this.StoreAll.Notification();
		},

		async GetUser(){
			await this.StoreAll.User();
			await this.StoreAll.UserAllEnvio();
			this.category_user = this.StoreAll.user.user_category_id;
			if(this.StoreAll.user.user_category_id === 1){
				this.autores = [];
			}else{
				this.autores.push(this.StoreAll.user);
			}

			let comission = this.StoreAll.comissions;
			let user = this.StoreAll.user_envio;
			user = user.concat(comission);
			this.users = user;
		},

		async GetUsersAll() {
			await this.StoreAll.UserAllEnvio();
		},


		async GetSession() {
			await this.StoreAll.Get_Data();
			await this.StoreAll.DocCategory();

			let OnSystem = setInterval(() => {
				if (this.StoreAll.document_category != null) {
					clearInterval(OnSystem);
					this.categories = this.StoreAll.document_category;
				}
			}, 500);
		},


		check_box_validated(id){
			if (id == 0){
				this.check_box = 0;
			}else if(id == 1){
				this.check_box = 2;
			}else{
				this.check_box = null;
			}
		},

		ValidatInputFile(){
			const selectedFile = document.getElementById('formFile').files[0];
			this.header_error = '';
			this.body_error = '';
			if (selectedFile) {
				if (selectedFile.type !== 'application/pdf') {
					this.header_error = 'Documento inválido';
					this.body_error = 'Apenas documentos PDF são permitidos para envio.';
					this.show_error();
					document.getElementById('formFile').value = '';
					this.form.NameDoc = '';
					return;
				}

				let file = document.getElementById('formFile').files[0].name;
				file = file.slice(0, -4);
				this.form.NameDoc = file;

			} else {
				console.log('Nenhum arquivo selecionado.');
			}

		},

		submitDoc(value) {
			document.getElementById('loadingstach').click();
			this.header_error = '';
			this.body_error = '';
			this.comission_send = false;
			if (this.form.NameDoc === '') {
				document.getElementById('hiddenButtonCloses').click();
				this.header_error = 'Você não colocou nome ao documento!';
				this.body_error = 'O documento precisa possuir um nome.';
				this.show_error();
			}
			else if (this.form.TypeDoc === '') {
				document.getElementById('hiddenButtonCloses').click();
				this.header_error = 'Você não colocou o tipo do documento!';
				this.body_error = 'O documento precisa possuir um tipo.';
				this.show_error();
			}
			else if (document.getElementById('formFile').files[0] === undefined) {
				document.getElementById('hiddenButtonCloses').click();
				this.header_error = 'Você esqueceu de colocar o documento!';
				this.body_error = 'É necessário adicionar um documento.';
				this.show_error();
			}
			else if(this.check_box == null){
				document.getElementById('hiddenButtonCloses').click();
				this.header_error = 'Você precisa informar se o documento já foi assinado!';
				this.body_error = 'É necessário marca uma das opções.';
				this.show_error();
			}else if(this.form.NumberDoc == '' && this.form.TypeDoc != 7){
				document.getElementById('hiddenButtonCloses').click();
				this.header_error = 'Você precisa informar o número do documento!';
				this.body_error = 'É necessário informar o número do documento.';
				this.show_error();
			}else if(this.form.TypeDoc == 0){
				document.getElementById('hiddenButtonCloses').click();
				this.header_error = 'Você precisa selecionar o tipo do documento!';
				this.body_error = 'É necessário selecionar o tipo do documento.';
				this.show_error();
			}else if(this.autores.length <= 0){
				document.getElementById('hiddenButtonCloses').click();
				this.header_error = 'Você precisa informar no mínimo um autor!';
				this.body_error = 'É necessário que ao menos um autor esteja adicionado ao documento.';
				this.show_error();
			}
			else{
				for(let i = 0; i < this.autores.length; i++){
					if(this.autores[0].user_category_id === 9){
						this.comission_send = true;
					}
				}
				if(this.comission_send === true){
					let file = document.getElementById('formFile').files[0];
					let autores = [];
					for(let i = 0; i < this.autores.length; i++){
						autores.push(this.autores[i].id);
					}

					let protocol_numbers = 0;
					if(this.form.NumberDoc == ''){
						protocol_numbers = 0;
					}else{
						protocol_numbers = this.form.NumberDoc;
					}

					let dados = {
						name : this.form.NameDoc,
						attachment: file,
						protocol_number: protocol_numbers,
						document_category_id: this.form.TypeDoc,
						document_status_move_id: value,
						document_status_vote_id: 1,
						comission_id: autores,
						status_sign: this.check_box,
					};

					this.StoreAll.SubmitDoc(dados)
						.then((resp) => {
							if (resp.status === 200) {
								this.form.NameDoc = '';
								this.DocNameCreate = '';
								this.form.TypeDocName = '';
								this.form.TypeDoc = 0;
								this.form.NumberDoc = '';
								let checkbox = document.getElementById('inlineRadio1');
								let checkbox2 = document.getElementById('inlineRadio2');
								checkbox.checked = false;
								checkbox2.checked = false;
								this.check_box = null;
								document.getElementById('hiddenButtonCloses').click();
								this.show_sucess();
							}
						})
						.catch((error) => {
							if(error.response.status != 200){
								if(error.response.request.responseText == '{"message":"The name must not be greater than 255 characters.","errors":{"name":["The name must not be greater than 255 characters."]}}'){
									document.getElementById('hiddenButtonCloses').click();
									this.header_error = 'Nome do documento muito grande!';
									this.body_error = 'Objeto do documento maior que o permitido (255 caracteres).';
									this.show_error();
								}else{
									document.getElementById('hiddenButtonCloses').click();
									this.header_error = 'Erro desconhecido!';
									this.body_error = 'Erro ao fazer envio para sessão, contate o administrador.';
									this.show_error();
								}
							}
						});

				}else{
					let file = document.getElementById('formFile').files[0];
					let autores = [];

					let protocol_numbers = 0;
					if(this.form.NumberDoc == ''){
						protocol_numbers = 0;
					}else{
						protocol_numbers = this.form.NumberDoc;
					}

					for(let i = 0; i < this.autores.length; i++){
						autores.push(this.autores[i].id);
					}

					let dados = {
						name : this.form.NameDoc,
						attachment: file,
						protocol_number: protocol_numbers,
						document_category_id: this.form.TypeDoc,
						document_status_move_id: value,
						document_status_vote_id: 1,
						authors: autores,
						status_sign: this.check_box,
					};
					this.StoreAll.SubmitDoc(dados)
						.then((resp) => {
							console.log(resp);
							if (resp.status === 200) {
								this.form.NameDoc = '';
								this.DocNameCreate = '';
								this.form.TypeDocName = '';
								this.form.TypeDoc = 0;
								this.form.NumberDoc = '';
								let checkbox = document.getElementById('inlineRadio1');
								let checkbox2 = document.getElementById('inlineRadio2');
								checkbox.checked = false;
								checkbox2.checked = false;
								this.check_box = null;
								document.getElementById('hiddenButtonCloses').click();
								this.show_sucess();
							}
						})
						.catch((error) => {
							if(error.response.status != 200){
								if(error.response.request.responseText == '{"message":"The name must not be greater than 255 characters.","errors":{"name":["The name must not be greater than 255 characters."]}}'){
									document.getElementById('hiddenButtonCloses').click();
									this.header_error = 'Nome do documento muito grande!';
									this.body_error = 'Objeto do documento maior que o permitido (255 caracteres).';
									this.show_error();
								}else{
									document.getElementById('hiddenButtonCloses').click();
									this.header_error = 'Erro desconhecido!';
									this.body_error = 'Erro ao fazer envio para sessão, contate o administrador.';
									this.show_error();
								}
							}
						});
				} }
		},

		show_error() {
			this.$toast.add({severity: 'error', summary: this.header_error , detail: this.body_error , group: 'tl', life: 5000});
		},

		show_sucess() {
			this.$toast.add({severity: 'success', summary: 'Documento enviado' , detail: 'Documento enviado com sucesso!' , group: 'tl', life: 5000});
		},
	},
};
</script>
<style>
  .close{
  font-size: 2.3rem !important;
}
</style>
<template>
  <div class="py-4 h-100 container-fluid">
	<ModalGTP ref="ModalGTP"></ModalGTP>
	<button type="button" id="loadingstach" ref="hiddenButtons" class="btn btn-primary d-none" data-toggle="modal" data-target="#Loading"></button>
	<div class="modal fade" id="Loading" tabindex="-1" role="dialog" aria-labelledby="Loading" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="background-color:  rgba(0,0,0,0) !important; border: 0px">
          <button type="button" id="hiddenButtonCloses" class="close d-none" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
          <div class="modal-body">
            <div>
              <div class="d-flex justify-content-center">
                <spinnerprime/>
              </div>
              <div class="text-center">
                <span class="text-dark"><strong>Aguarde...</strong></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="text-center col-12">
        <h3 class="mt-auto text-white">Envio de documentos para secretaria</h3>
        <h5 class="text-white font-weight-normal">
          Os documentos enviados serão recepcionados pelo(a) secretário(a) e
          enviados para a sessão da semana.
        </h5>
        <div class="mb-5 multisteps-form">
          <div class="row">
            <div class="m-auto col-12 col-lg-8">
              <form class="multisteps-form__form">
                <div>
                  <div
                    class="
                      p-3
                      bg-white
                      card
                      multisteps-form__panel
                      border-radius-xl
                      js-active
                      position-relative
                    "
                    data-animation="FadeIn"
                  >
                    <div class="text-center row">
                      <div class="mx-auto col-10">
                        <h5 class="font-weight-normal">
                          Informe alguns dados para envio do documento
                        </h5>
                      </div>
                    </div>

                    <form @submit.prevent="createDocument">
                      <div class="multisteps-form__content">
                        <div class="mt-3 row">
                          <div class="mt-4 col-12 col-sm-12 mt-sm-0 text-start">
                            <label>Objeto do documento</label>
                            <input
                              v-model="form.NameDoc"
                              class="mb-3 multisteps-form__input form-control"
                              type="text"
                              placeholder="Projeto de lei - organização de cadeiras"
                            />

                            <div v-if="form.TypeDoc != 7">
                              <label>Número do documento</label>
                              <input
                                v-model="form.NumberDoc"
                                class="mb-3 multisteps-form__input form-control"
                                type="text"
                                placeholder="Adicione um número ao documento"
                              />
                            </div>
                            <div>
                              <label>Selecione o(s) autor(es)</label>
                                <MultiSelectPrime id="choices-category" v-model="autores" display="chip" :options="users" option-label="name" placeholder="Selecione o(s) autor(es)"
                                class="form-control w-full md:w-20rem" style="height: calc(3.5rem + 2px) !important"/> 
                            </div>

                            <label>Tipo do documento</label>
                            <select
                              id="choices-category"
                              v-model="form.TypeDoc"
                              class="form-control"
                              name="choices-category"
                            >
                              <option
                                v-for="option in categories"
                                :key="option.id"
                                :value="option.id"
                              >
                                {{ option.name }}
                              </option>
                            </select>

                            <label>Ano</label>
                            <input
                              class="mb-3 multisteps-form__input form-control"
                              type="text"
                              placeholder="2024"
                              disabled
                            />

                            <div class="mt-3 mb-3">
                              <label for="formFile" class="form-label"
                                >Selecione o arquivo</label
                              >
                              <input
                                id="formFile"
                                class="form-control"
                                type="file"
                                accept=".pdf"
                                @change="ValidatInputFile"
                              />
                            </div>
                            <div class="d-flex justify-content-center">
                              <span class="text-black text-uppercase">Documento já foi assinado ?</span></div>
                            <div class="d-flex justify-content-center">
                              <div class="form-check form-check-inline">
                              <input id="inlineRadio1" class="form-check-input" type="radio" name="inlineRadioOptions" value="option1" @click="check_box_validated(1)">
                              <label class="form-check-label text-black text-uppercase" for="inlineRadio1" @click="check_box_validated(1)">Sim</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input id="inlineRadio2" class="form-check-input" type="radio" name="inlineRadioOptions" value="option2"  @click="check_box_validated(0)">
                              <label class="form-check-label text-black text-uppercase" for="inlineRadio2" @click="check_box_validated(0)">Não</label>
                            </div>
                          </div>
                          </div>
                          <div class="mt-4 button-row d-flex">
                            <button
							v-if="this.StoreAll.host != 'dev' && this.StoreAll.host != 'teste' && this.StoreAll.host != 'local' && this.StoreAll.host != 'goianinha'"
                              class="
                                mb-0
                                btn
                                bg-gradient-dark
                                ms-auto
                                js-btn-next
                              "
                              type="button"
                              @click="
                                SubmitD();
                              "
                            >
                              Enviar documento
                            </button>
							<button
							v-else
                              class="
                                mb-0
                                btn
                                bg-gradient-dark
                                ms-auto
                                js-btn-next
                              "
                              type="button"
                              @click="
							  GPTSend();
                              "
                            >
							Enviar documento
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

