<script>
import Tv_Standby from '../Two_tv/Tv_Standby.vue';
import TvTribuna from '../TvTribuna.vue';
import TvVote from '../TvVote.vue';
import TvDiscurso from '../TvDiscurso.vue';
import TvExplanation from '../TvExplanation.vue';
import TvHeader from '../TvHeader.vue';
import TvQuestionOrder from '../../secretario/Tv_QuestionOrder.vue';
import Tv_End from '../../secretario/Tv_End.vue';
import { useMainStore, useBusStore } from '../../../stores/index';

export default {
	name: 'TvFullActions',
	components: { Tv_Standby, TvTribuna, TvVote, TvDiscurso, TvHeader, TvExplanation, TvQuestionOrder, Tv_End },
	setup() {
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		return {
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			tela_tv: false,
			fullScren: false,
			voted: false,
			tela: '',
			isFullScreen: false,
			standby: false,
			vote: false,
			tribuna: false,
			discut: false,
			explanation: false,
			OldComp: '',
			comp: 'Tv_Standby',
			Ordem: false,
			socket_ids: [],
			compEnd: 'Tv_End',
		};
	},
	watch: {
		vote: function (novoValor, valorAntigo) {
			if (novoValor !== false) {
				this.vote = true;
				this.comp = 'TvVote';
			}else{
				this.vote = false;
				if (this.comp === 'TvQuestionOrder') {
					this.OldComp = 'Tv_Standby';
				} else {
					this.comp = 'Tv_Standby';
				}
			}
		},

		tribuna: function (novoValor, valorAntigo) {
			if (novoValor !== false) {
				this.tribuna = true;
				this.comp = 'TvTribuna';
			}else{
				this.tribuna = false;
				this.comp = 'Tv_Standby';
			}
		},

		discut: function (novoValor, valorAntigo) {
			if (novoValor !== false) {
				this.discut = true;
				this.comp = 'TvDiscurso';
			}else{
				this.discut = false;
				this.comp = 'Tv_Standby';
			}
		},


		explanation: function (novoValor, valorAntigo) {
			if (novoValor !== false) {
				this.explanation = true;
				this.comp = 'TvExplanation';
			}else{
				this.explanation = false;
				this.comp = 'Tv_Standby';
			}
		},

		Ordem: function (novoValor, valorAntigo) {
			if (novoValor !== false) {
				this.Ordem = true;
				this.OldComp = this.comp;
				this.comp = 'TvQuestionOrder';
			}else{
				this.Ordem = false;
				this.comp = this.OldComp; //LOGIA PARA RETORNAR AO ULTIMO COMPONENTE;
			}
		},

		'StoreAll.tribuna': function (novoValor, valorAntigo) {
			if (novoValor === true) {
				this.tribuna = true;
			} else {
				this.tribuna = false;
			}
		},

		'StoreAll.vote': function (novoValor, valorAntigo) {
			if (novoValor === true) {
				this.vote = true;
			} else {
				this.vote = false;
			}
		},
		
		'StoreAll.discut': function (novoValor, valorAntigo) {
			if (novoValor === true) {
				this.discut = true;
			} else {
				this.discut = false;
			}
		},

		'StoreAll.explanation': function (novoValor, valorAntigo) {
			if (novoValor === true) {
				this.explanation = true;
			} else {
				this.explanation = false;
			}
		},

		'StoreAll.Ordem': function (novoValor, valorAntigo) {
			if (novoValor === true) {
				this.Ordem = true;
			} else {
				this.Ordem = false;
			}
		},
	},

	created () {
		let OnSocket = setInterval(() => {
			if (this.StoreAll.websocket_host !== null) {
				clearInterval(OnSocket);
				window.Echo.channel(this.StoreAll.websocket_host).listen('.' + this.StoreAll.websocket_host, (e) => {
					if (e.socket_id === 10) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.tribuna = true;
							this.StoreAll.tribuna = true;
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 13) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.tribuna = false;
							this.StoreAll.tribuna = false;
							this.socket_ids.push(e.event_id);  
						}
					} 
			
					if (e.socket_id === 21) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.explanation = true;
							this.StoreAll.explanation = true;
							this.socket_ids.push(e.event_id);  
						}
					} 
        
					if (e.socket_id === 24) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.explanation = false;
							this.StoreAll.explanation = false;
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 25) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.discut = true;
							this.StoreAll.discut = true;
							this.socket_ids.push(e.event_id);  
						}
					}
			
					if (e.socket_id === 30) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.discut = false;
							this.StoreAll.discut = false;
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 31) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Ordem = true;
							this.StoreAll.Ordem = true;
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 34) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Ordem = false;
							this.StoreAll.Ordem = false;
							this.socket_ids.push(e.event_id);  
						}
					} 
				});
			}}, 500);

		let OnSystem = setInterval(() => {
			if (this.StoreAll.socket !== null) {
				clearInterval(OnSystem);
				this.StoreAll.socket.on('6', (e) => {
					if (this.vote === false) {			
						this.vote = true;
						this.StoreAll.vote = true;
					}
				});

				this.StoreAll.socket.on('8', (e) => {
					if (this.vote === true) {
						setTimeout(() => {
							this.vote = false;
							this.StoreAll.vote = false; 
						}, 3000);
					}	
				});
			}}, 500);
	},

	mounted() {
		this.testetela();
		this.telafull();
		this.testetando();
	},
  
	methods: {
		telafull(){
			this.isFullScreen = false;
			let elem = document.documentElement;
			localStorage.setItem('full_2', 'ok');
			//Se o estado atual for "FullScreen", desativá-lo.
			//Note que para as verificações é feito uma validação para todos os possíveis navegadores, facilitando a sua vida.
			/* if (document.exitFullscreen) {
      document.exitFullscreen();
      this.isFullScreen = false;
    } else 
    Erro acontecendo aqui, investigar.
    */ 
    
			if (document.mozCancelFullScreen) { /* Firefox */
				document.mozCancelFullScreen();
				this.isFullScreen = false;
			} else if (document.webkitExitFullscreen) { /* Chrome, Safari & Opera */
				document.webkitExitFullscreen();
			} else if (document.msExitFullscreen) { /* IE/Edge */
				document.msExitFullscreen();
				this.isFullScreen = false;
			}


			if (elem.requestFullscreen) {
				elem.requestFullscreen();
				this.isFullScreen = true;
			} else if (elem.mozRequestFullScreen) { /* Firefox */
				elem.mozRequestFullScreen();
				this.isFullScreen = true;
			} else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
				elem.webkitRequestFullscreen();
				this.isFullScreen = true;
			} else if (elem.msRequestFullscreen) { /* IE/Edge */
				elem.msRequestFullscreen();
				this.isFullScreen = true;
			}
		},
		testetela() {
			setTimeout(() => {
				this.testetela();
			}, 2000);
			let largura = window.screen.width;
			let altura = window.screen.height;
			if (
				(largura >= 1280 && largura <= 1365) ||
        (altura >= 720 && altura <= 767)
			) {
				this.tela = 'HD';
			}
			if (
				(largura >= 1366 && largura <= 1599) ||
        (altura >= 768 && altura <= 899)
			) {
				this.tela = 'WXGA';
			}
			if (
				(largura >= 1600 && largura <= 1919) ||
        (altura >= 900 && altura <= 1079)
			) {
				this.tela = 'HD+';
			}
			if (
				(largura >= 1920 && largura <= 2559) ||
        (altura >= 1080 && altura <= 1439)
			) {
				this.tela = 'FHD';
			}
			if (
				(largura >= 2560 && largura <= 3839) ||
        (altura >= 1440 && altura <= 2159)
			) {
				this.tela = 'WQHD';
			}
			if (largura >= 3840 && altura >= 2160) {
				this.tela = '4K';
			}
		},
		refresh() {
			location.reload();
		},
		testetando(){
			if(this.$route.name == 'Tela de sessão'){
				document.body.style.backgroundImage = 'linear-gradient(to right, #004c85, #00508d, #005fa5)';
			}else{
				document.body.style.backgroundImage = 'white';
			}
		},
	},
};
</script>
<template>
    <div>
      <div
        :class="{
          backHD: tela === 'HD',
          backWXGA: tela == 'WXGA',
          backHDM: tela == 'HD+',
          backFHD: tela == 'FHD',
          backWQHD: tela == 'WQHD',
          backFOURK: tela == '4K',
        }"
        style="height: 100vh; border-radius: 0px; "
      >
        <TvHeader />

		<component v-if="this.StoreAll.session.session_status_id !== 3" :is="comp" />
	  	<component v-if="this.StoreAll.session.session_status_id === 3" :is="compEnd" />
      </div>
    </div>
  </template>
  <style>
  .text-black {
    color: black;
  }
  .backHD {
    background-image: url(../../../assets/img/HD.png);
    background-size: cover;
  }
  .backWXGA {
    background-image: url(../../../assets/img/HD+.png);
    background-size: cover;
  }
  .backHDM {
    background-image: url(../../../assets/img/HD+.png);
    background-size: cover;
  }
  .backFHD {
    background-image: url(../../../assets/img/FHD.png);
    background-size: cover;
  }
  .backWQHD {
    background-image: url(../../../assets/img/WQHD.png);
    background-size: cover;
  }
  .backFOURK {
    background-image: url(../../../assets/img/FK.png);
    background-size: cover;
  }
  </style>
  