<template>
  <!-- ADICIONAR DELETE DOCUMENTO OU EDITAR -->
  <div>
    <Modal_view ref="Modal_view"></Modal_view>
    <Anexo ref="Anexo"></Anexo>
  </div>
  <div class="container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">Documentos do procurador!</h5>
            <p class="mb-0 text-sm">Agora é so dar seguimento a eles.</p>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="table-responsive">
                  <DataTable :value="Docs_recp" show-gridlines responsive-layout="scroll" :paginator="true" :rows="10">
                    <Column field="id" header="Protocolo" class="text-sm font-weight-bold text-wrap text-uppercase"
                      :sortable="true"></Column>
                    <Column field="name" header="Documento" class="text-sm font-weight-bold text-wrap text-uppercase">
                    </Column>
                    <Column field="comissions" header="Pareceres"
                      class="text-sm font-weight-bold text-wrap text-uppercase">
                      <template #body="{ data }">
                        <div v-if="data.parecer_procurador_pdf === null && data.comissions <= 0">
                          
                          <Tag
                            style="border: 1px solid rgb(231, 0, 0);background-color: rgba(9,167, 6, 0.1);color: rgb(231, 0, 0);">
                            <div class="flex align-items-center gap-2">
                              <i class="fa fa-circle-o-notch fa-spin fa-1x mr-2"></i>
                              <span class="text-base text-uppercase">Aguardando parecers</span>
                            </div>
                          </Tag>
                        </div>

                        <div v-else>

                        <div v-if="data.parecer_procurador_pdf !== null" class="mb-2">
                          <Tag
                              style="border: 1px solid rgb(9 167 6);background-color: rgba(9,167, 6, 0.1);color: rgb(9 167 6);">
                              <div class="flex align-items-center gap-2">
                                <i class="fa fa-check mr-2" aria-hidden="true"></i>
                                <span class="text-base text-uppercase">Procurador</span>
                              </div>
                            </Tag>
                        </div>

                        <div v-else></div>
                        <div v-if="data.comissions <= 0"></div>

                        <div v-else>
                          <div v-for="comission in data.comissions" :key="comission" class="mb-2">

                            <Tag
                              style="border: 1px solid rgb(9 167 6);background-color: rgba(9,167, 6, 0.1);color: rgb(9 167 6);">
                              <div class="flex align-items-center gap-2">
                                <i class="fa fa-check mr-2" aria-hidden="true"></i>
                                <span class="text-base text-uppercase">{{ comission.comission_name }}</span>
                              </div>
                            </Tag>

                          </div>
                        </div>
                        </div>

                      </template>
                    </Column>
                    <Column field="authors" header="Autor(es)" class="text-sm font-weight-bold text-wrap text-uppercase"
                      :sortable="true">
                      <template #body="{ data }">
                        <span>{{ Author(data.authors) }} </span>
                      </template>
                    </Column>
                    <Column field="document_category.name" header="Tipo"
                      class="text-sm font-weight-bold text-wrap text-uppercase"></Column>
                    <Column header="Enviar" class="text-sm font-weight-bold text-wrap text-uppercase TT text-center">
                      <template #body="slotProps">
                        <button data-toggle="modal" style="margin-bottom: 0rem !important;" data-target=".Doc_send"
                          type="button" class="btn bg-smartlegis btnEdit envio" @click="EventDoc(slotProps.data)"><i
                            style="color: white !important;" class="fa fa-paper-plane"></i></button>
                      </template>
                    </Column>
                    <Column header="Visualizar" class="text-sm font-weight-bold text-wrap text-uppercase TT text-center">
                      <template #body="slotProps">
                        <button style="margin-bottom: 0rem !important;" type="button"
                          class="btn bg-smartlegis btnEdit envio" @click="View_doc(slotProps.data)"><i
                            style="color: white !important;" class="fa fa-eye"></i></button>
                      </template>
                    </Column>
                    <Column header="Anexar Parecer"
                      class="text-sm font-weight-bold text-wrap text-uppercase TT text-center">
                      <template #body="slotProps">
                        <button data-toggle="modal" style="margin-bottom: 0rem !important;" type="button"
                          class="btn bg-smartlegis btnEdit envio" @click="Anexo_send(slotProps.data)"><i
                            style="color: white !important;" class="fa fa-file" aria-hidden="true"></i></button>
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-lg Doc_send" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
      aria-hidden="true" style="z-index: 999; display: none">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">
              <span> Enviar documento: </span> <span id="NameDoc" style="color: red"> {{ documento_name }} </span>
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Fechar">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label>Local de envio</label>
            <select id="choices-category" v-model="choice" class="form-control" name="choices-category" @change="change">
              <option v-for="option in options" :key="option.id" :value="option.id">
                {{ option.name }}
              </option>
            </select>



          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Fechar
            </button>
            <button type="button" class="btn btn-success" data-dismiss="modal" @click="Enviar();">
              Enviar documento!
            </button>
          </div>
        </div>
      </div>
    </div>


    <div class="modal fade bd-example-modal-sm envio_x" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h6>STATUS DO DOCUMENTO</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="finallyloading()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div id="Doc_Erro" class="justify-content-center" style="display: none">
              <span class="
                              justify-content-center
                              text-uppercase
                              font-weight-bold
                            " style="font-size: 13px; color: red">
                Erro ao fazer envio para sessão, contate o administrador
              </span>
            </div>

            <div id="Doc_Ok" class="justify-content-center" style="display: none">
              <span class="
                              justify-content-center
                              text-uppercase
                              font-weight-bold
                            " style="font-size: 13px; color: green">
                Documento Enviado para sessão com sucesso!
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Toast />

  </div>
</template>
  
<script>
import { AxiosAPI } from "@/axios";
import { useMainStore } from "../../../stores/index";
import Modal_view from '../../documents/Modals/Modal_View.vue';
import Anexo from '../../documents/Modals/Modal_Anexo_Parecer.vue';
export default {
  name: "Procurador",
  components: {
    Modal_view,
    Anexo
  },
  setup() {
    const store_all = useMainStore()
    store_all.Token();
    return {
      store_all: store_all,
    }
  },
  data() {
    return {
      Docs_recp: [],
      documento_name: 'Carregando...',
      documento_id: 0,
      parecer_education: null,
      parecer_farm: null,
      parecer_health: null,
      parecer_human: null,
      parecer_infra: null,
      parecer_justice: null,
      options: [{ id: 0, name: "Secretário" }],
      comissions: [],
      choice: null,
    };
  },
  mounted() {
    this.Getparecer();
    this.Get_Docs();
    this.GetSessionOther();
    this.GetUser();
    this.GetUsersAll();

    this.emitter.on("send_parecer", (send_parecer) => {
      this.Refresh_table()
    });
  },

  methods: {

    async GetUsersAll() {
      await this.store_all.UserAllEnvio();
      this.vereadores = this.$pinia.state.value.main.user_envio;
    },

    async GetUser() {
      await this.store_all.User();
      this.category_user = this.$pinia.state.value.main.user.user_category_id
    },

    async GetSessionOther() {
      await this.store_all.Get_Data();
    },


    Doc_parecer_ok_send() {
      this.$toast.add({ severity: 'success', summary: 'Parecer', detail: 'Parecer anexado com sucesso', life: 3000 });
    },

    Refresh_table() {
      this.Getparecer()
      this.Get_Docs()
    },

    Get_Docs() {
      AxiosAPI
        .get("/documents?document_status_movement_id=3", {
          headers: {
            Authorization: `Bearer ` + this.$pinia.state.value.main.accessToken,
          },
        })
        .then((resp) => {
          console.log(resp)
          this.Docs_recp = resp.data.data
        })
    },
    View_doc(event) {
      this.$pinia.state.value.main.document_details = event

      if (event.carimbo_camara_pdf == null) {
        this.$pinia.state.value.main.Leitura = false
        this.$pinia.state.value.main.document_src = event.attachment
      } else if (event.carimbo_camara_pdf != null && event.carimbo_prefeitura_pdf != null) {
        this.$pinia.state.value.main.Leitura = true
        this.$pinia.state.value.main.document_src = event.carimbo_prefeitura_pdf
      } else if (event.carimbo_camara_pdf != null && event.carimbo_prefeitura_pdf == null) {
        this.$pinia.state.value.main.Leitura = true
        this.$pinia.state.value.main.document_src = event.carimbo_camara_pdf
      } else {
        this.$pinia.state.value.main.Leitura = false
        this.$pinia.state.value.main.document_src = event.attachment
      }

      if (event.parecer_procurador_pdf != null) {
        this.$pinia.state.value.main.parecer_procurador_pdf = event.parecer_procurador_pdf
      } else {
        this.$pinia.state.value.main.parecer_procurador_pdf = null
      }

      this.$refs.Modal_view.openModal2()
    },

    Anexo_send(event) {
      this.$refs.Anexo.openModal(event)
    },

    EventDoc(event) {
      this.documento_name = event.name
      this.documento_src = event.attachment
      this.document_status_move = event.document_status_movement_id
      this.documento_statusid = event.document_status_id
      this.documento_id = event.id
      this.documento_src_proc = event.parecer_procurador_pdf
    },

    Enviar() {
      if (this.choice == 0) {
        AxiosAPI.post("/document/change-status-move?document_id=" + this.documento_id + "&document_status_movement_id=1&user_id=" + this.$pinia.state.value.main.user.id, {}, {
          headers: {
            Authorization: `Bearer ` + this.$pinia.state.value.main.accessToken,
          },
        })
          .then(() => {
            this.showSuccess_send();
            this.Get_Docs();
          });
      }
    },

    Author(value) {
      let Co_user = []
      for (let i = 0; i < value.length; i++) {
        Co_user.push(value[i].nickname)
      }
      return Co_user.join(", ");
    },

    Getparecer() {
      this.parecer_education = null
      this.parecer_farm = null
      this.parecer_health = null
      this.parecer_human = null
      this.parecer_infra = null
      this.parecer_justice = null

      let parecer_education = []
      let parecer_farm = []
      let parecer_health = []
      let parecer_human = []
      let parecer_infra = []
      let parecer_justice = []

      AxiosAPI
        .get("/parecer-comissao?document_id=" + this.documento_id, {
          headers: {
            Authorization: `Bearer ` + this.$pinia.state.value.main.accessToken,
          },
        })
        .then((response) => {
          if (response.data.parecer.length <= 0) {
            this.parecer_on = false
          } else {
            this.parecer_on = true
          }
          for (let i = 0; i < response.data.parecer.length; i++) {
            if (response.data.parecer[i].comission_id == 1) {
              parecer_education.push(response.data.parecer[i])
            }
            if (response.data.parecer[i].comission_id == 2) {
              parecer_farm.push(response.data.parecer[i])
            }
            if (response.data.parecer[i].comission_id == 4) {
              parecer_health.push(response.data.parecer[i])
            }
            if (response.data.parecer[i].comission_id == 5) {
              parecer_human.push(response.data.parecer[i])
            }
            if (response.data.parecer[i].comission_id == 3) {
              parecer_infra.push(response.data.parecer[i])
            }
            if (response.data.parecer[i].comission_id == 6) {
              parecer_justice.push(response.data.parecer[i])
            }

            if (parecer_education.length <= 0) {
              this.parecer_education = null
            } else {
              this.parecer_education = parecer_education[0].parecer_pdf
            }

            if (parecer_farm.length <= 0) {
              this.parecer_farm = null
            } else {
              this.parecer_farm = parecer_farm[0].parecer_pdf
            }

            if (parecer_health.length <= 0) {
              this.parecer_health = null
            } else {
              this.parecer_health = parecer_health[0].parecer_pdf
            }

            if (parecer_human.length <= 0) {
              this.parecer_human = null
            } else {
              this.parecer_human = parecer_human[0].parecer_pdf
            }

            if (parecer_infra.length <= 0) {
              this.parecer_infra = null
            } else {
              this.parecer_infra = parecer_infra[0].parecer_pdf
            }

            if (parecer_justice.length <= 0) {
              this.parecer_justice = null
            } else {
              this.parecer_justice = parecer_justice[0].parecer_pdf
            }

          }
        })
    },

    show_error() {
      this.$toast.add({ severity: 'error', summary: this.header_error, detail: this.body_error, group: 'tl', life: 5000 });
    },

    showSuccess_send() {
      this.$toast.add({ severity: 'success', summary: 'Documento enviado ao secretário', detail: 'O documento foi enviado ao secretário com sucesso', life: 3000 });
    },
    showSuccess_send_justice() {
      this.$toast.add({ severity: 'success', summary: 'Documento enviado', detail: 'O documento foi enviado para comissão de justiça', life: 3000 });
    },
    finallyloading() {
      document.getElementById("Doc_Ok").style.display = "none";
      document.getElementById("Doc_Erro").style.display = "none";
    },

    showSuccess_del() {
      this.$toast.add({ severity: 'success', summary: 'Documento deletado', detail: 'O documento foi deletado com sucesso', life: 3000 });
    },
    showSuccess_edit() {
      this.$toast.add({ severity: 'success', summary: 'Documento editado', detail: 'O documento foi editado com sucesso', life: 3000 });
    },
    showError_del() {
      this.$toast.add({ severity: 'error', summary: 'Erro ao deletar', detail: 'O documento selecionado não foi deletado', life: 3000 });
    },
    showError_edit() {
      this.$toast.add({ severity: 'error', summary: 'Erro ao editar', detail: 'Documento selecioando não foi editado', life: 3000 });
    },

  },
};
</script>
<style>
.previous {
  padding-right: 11px;
}

.close {
  font-size: 2.3rem !important;
}
</style>
  