<script>
import { AxiosAPI } from '@/axios';
import moment from 'moment';
import { useMainStore } from '../../stores/index';
import Modal_view from '../../components/documents/Modals/Modal_View.vue';

export default {
	name: 'DocRecepHall',
	components: {Modal_view},
	setup(){
		const StoreAll = useMainStore();
		StoreAll.Token();
		return{
			StoreAll: StoreAll,
		};
	},
	data() {
		return {
			Sessions_docs: null,
			expandedRows: [],
			Orders: [],
			session_expand_id: 0,
			previouslyExpandedRow: null,
			Leitura: null,
			Comissions_id: null,
			Docs_recp: [],
			document_status: '...',
			documento_src: '',
			id_doc: 0,
			ordem_id: 0,
		};
	},
	mounted() {
		this.Get_Docs();
		this.GetSession();
		this.GetSessionOther();
		this.GetUser();
		this.GetUsersAll();
		this.GetSessions();

		this.emitter.on('carimbo_prefeitura', () => {
			this.Get_Docs();
			this.GetChildrenSession();
		});
	},
  
	methods: {

		async GetSessions(){
			await AxiosAPI
				.get('/sessions', {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken
					}
				})
				.then((response_sessions) => {
					let Array_sessions = response_sessions.data.data;
					this.Sessions_docs = Array_sessions;
				});
		},
		formatDate(value) {
			moment.locale('pt-br');
			return moment(value).format('DD/MMMM/YYYY');
		},

		onRowExpand(event) {

			this.Orders = [];
			this.session_expand_id = event.data.id;
			this.GetChildrenSession();
         
			this.$toast.add({severity: 'info', summary: event.data.name, detail: 'Sessão selecionada', life: 3000});
        
			if(this.expandedRows.length >= 2){
				const { data } = event; 
				this.clearExpandedRows(data); 
			}
		},

		clearExpandedRows(newRowId) {
			this.expandedRows = [newRowId];
		},

		onRowCollapse(event) {
			this.$toast.add({severity: 'success', summary: event.data.name, detail: 'Sessão recolhida', life: 3000});

			this.previouslyExpandedRow = null;

		},

		collapseAll() {
			this.expandedRows = null;
		},

		GetChildrenSession(){
			AxiosAPI
				.get('/documents', {
					params: {
						session_id: this.session_expand_id,
						ordem: 1,
					},
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				})
				.then((response) => {
					let Documents_prefeitura = [];
					for (let i = 0; i < response.data.data.length; i++) {
						if (response.data.data[i].document_status_movement_id != 6) {
							continue;
						}else{
							Documents_prefeitura.push(response.data.data[i]);
						}
					} 
					this.Orders =  Documents_prefeitura;
				});
		},

		async GetUsersAll() {
			await this.StoreAll.UserAllEnvio();
			this.vereadores = this.StoreAll.user_envio;  
		},

		async GetUser(){
			await this.StoreAll.User(); 
			this.category_user = this.StoreAll.user.user_category_id;
		},

		async GetSessionOther() {
			await this.StoreAll.Get_Data();
		},
		Get_Docs(){
			AxiosAPI
				.get('/documents?document_status_movement_id=6', {
					headers: {
						Authorization: 'Bearer '+ this.StoreAll.accessToken,
					},
				})
				.then((resp) => {
					this.Docs_recp = resp.data.data;
				});
		},

		Author(value){
			let Co_user = [];
			for (let i = 0; i < value.length; i++) {
				Co_user.push(value[i].nickname);
			}
			return Co_user.join(', ');
		},
		EventDoc(event){
			this.StoreAll.document_details = event;
			if (event.carimbo_camara_pdf == null) {
				this.StoreAll.Leitura = false;
				this.StoreAll.Leitura_prefeitura = false;
				if (event.attachment_partial != null) {
					this.StoreAll.document_src = event.attachment_partial;
				} else {
					this.StoreAll.document_src = event.attachment;
				}
			} else if (event.carimbo_camara_pdf != null && event.carimbo_prefeitura_pdf != null) {
				this.StoreAll.Leitura = true;
				this.StoreAll.Leitura_prefeitura = true;
				this.StoreAll.document_src = event.carimbo_prefeitura_pdf;
			} else if (event.carimbo_camara_pdf != null && event.carimbo_prefeitura_pdf == null) {
				this.StoreAll.Leitura = true;
				this.StoreAll.Leitura_prefeitura = false;
				this.StoreAll.document_src = event.carimbo_camara_pdf;
			} else {
				this.StoreAll.Leitura = false;
				this.StoreAll.Leitura_prefeitura = false;
				if (event.attachment_partial != null) {
					this.StoreAll.document_src = event.attachment_partial;
				} else {
					this.StoreAll.document_src = event.attachment;
				}
			}
			/* Logica para documento atual */

			this.documento_src_proc = event.parecer_procurador_pdf;
			/* verificar impletação */

			this.$refs.Modal_view.openModal2();
		},
  
		GetSession() {
			//Necessário fazer get da sessão aqui pois o time de resposta entre $storage e esse componente gera bug.
			AxiosAPI
				.get('/sessions', {
					headers: {
						Authorization: 'Bearer '+this.StoreAll.accessToken,
					},
				})
				.then((response) => {
					this.result = [response.data.data[response.data.data.length - 1]];
				});
		},
  
	},
};
</script>
<template>
      <div>
      <Modal_view ref="Modal_view"></Modal_view>
    </div>
<div class="container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header pb-0">
              <h5 class="mb-0">Documentos prefeitura <h6 class="mb-0">Apenas documentos enviados para a prefeitura apareceram aqui!</h6></h5>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="table-responsive">
                      <DataTable v-model:expandedRows="expandedRows" :value="Sessions_docs"
                          @rowExpand="onRowExpand" @rowCollapse="onRowCollapse">
                  <template #header>
                  </template>
                  <Column :expander="true" :header-style="{'width': '3rem'}" />
                  <Column field="name" header="Sessão" sortable></Column> 
                  <Column field="datetime_start" header="Data" sortable>
                      <template #body="{data}">
                              {{formatDate(data.datetime_start)}}
                      </template>
                  </Column> 
                  <template #expansion="slotProps">
                      <div class="orders-subtable">
                          <h5>Documento em {{slotProps.data.name}}</h5>
                          <DataTable :value="Orders">
                              <Column field="id" header="Protocolo" sortable></Column>
                              <Column field="name" header="Documento" sortable></Column>
                              <Column field="document_status_movement_id" header="Status" sortable>
                                  <template #body="slotProp">
                                      <p v-if="slotProp.data.document_status_movement_id == 6" class="bg-danger text-center font-weight-bolder text-white" style="border-radius: 10px;"> Enviado a prefeitura </p>
                                  </template>
                              </Column>
                              <Column field="authors" header="Autor(es)"
                                class="text-sm font-weight-bold text-wrap text-uppercase" :sortable="true">
                                <template #body="{ data }">
                                  <span>{{Author(data.authors)}} </span>
                                </template>
                              </Column>
                              <Column field="document_category.name" header="Tipo" sortable></Column>
                              <Column field="crated_at" header="Data" sortable>
                                  <template #body="{data}">
                                      {{formatDate(data.created_at)}}
                                  </template>
                              </Column>
                              <Column header="Carimbo câmara" class="text-sm font-weight-bold text-wrap text-uppercase text-center">
                              <template #body="slotProps">
                                <i v-if="slotProps.data.carimbo_camara_pdf != null" class="fa fa-check-circle-o text-success" aria-hidden="true" style="font-size: 20px;"></i>
                                <i v-else class="fa fa-times-circle-o text-danger" aria-hidden="true"  style="font-size: 20px;"></i>
                              </template>
                            </Column>
                            <Column header="Carimbo recebido" class="text-sm font-weight-bold text-wrap text-uppercase text-center">
                              <template #body="slotProps">
                                <i v-if="slotProps.data.carimbo_prefeitura_pdf != null" class="fa fa-check-circle-o text-success" aria-hidden="true" style="font-size: 20px;"></i>
                                <i v-else class="fa fa-times-circle-o text-danger" aria-hidden="true"  style="font-size: 20px;"></i>
                              </template>
                            </Column>
                            <Column header="Assinado" class="text-sm font-weight-bold text-wrap text-uppercase text-center">
                            <template #body="slotProps">
                              <i v-if="slotProps.data.status_sign === 2 || slotProps.data.status_sign === 1" class="fa fa-check-circle-o text-success" aria-hidden="true" style="font-size: 20px;"></i>
                              <i v-else class="fa fa-times-circle-o text-danger" aria-hidden="true"  style="font-size: 20px;"></i>
                            </template>
                          </Column>
                              <Column header="Visualizar" class="text-sm font-weight-bold text-wrap text-uppercase TT text-center">
                                  <template #body="slotProp">
                                      <button style="margin-bottom: 0rem !important;" type="button" class="btn bg-smartlegis btnEdit envio" @click="EventDoc(slotProp.data)"><i style="color: white !important;" class="fa fa-eye"></i></button>
                                  </template>
                              </Column>
                          </DataTable>
                      </div>
                  </template>
              </DataTable>
              </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  <Toast />
</div>
</template>
  <style>
  .previous {
    padding-right: 11px;
  }
  .close{
    font-size: 2.3rem !important;
  }
  </style>
  