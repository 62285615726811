<script>
import { AxiosAPI } from '@/axios';
import moment from 'moment';
import { useMainStore } from '../../stores/index';
import Modal_View_signer from './Modals/Modal_View.vue';
import Modal_View_signer_user from './Modals/Modal_View_Signer.vue';
export default {
	name: 'SignerDocs',
	components: { Modal_View_signer, Modal_View_signer_user },
	setup() {
		const StoreAll = useMainStore();
		StoreAll.Token();
		return {
			StoreAll: StoreAll,
		};
	},
	data() {
		return {
			prefix_c: '',
			SignerDocs: [],
			key: '',
			documento_src: '',
			storage_name: '',
			category_id: '',
			document_id: 0,
			session_id: 'realizadass',
			selected_products: null,
			select: false
		};
	},
	watch: {
		selected_products: function (novoValor, valorAntigo) {
			if (novoValor !== null) {
				if(this.selected_products.length != 0 && this.selected_products.length >= 2){
					this.select = true;
				}else{
					this.select = false;
				}
			}
		}
	},
	mounted() {
		this.GetUser();
		this.GetUsersAll();
		this.GetSession();

		this.emitter.on('Assinado', () => {
			this.DocSearch();
		});
	},
	methods: {

		async GetUsersAll() {
			await this.StoreAll.UserAllEnvio();
			this.vereadores = this.StoreAll.user_envio;
		},

		async GetUser() {
			await this.StoreAll.User();
			this.DocSearch();
		},

		async GetSession() {
			await this.StoreAll.Get_Data();
		},


		onRowSelect(event) {
			let events = event;
		},

		onRowUnselect(event) {
			let events = event;
		},

		DocSearch() {
			AxiosAPI
				.get('/documents-notification', {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				})
				.then((response) => {
					this.SignerDocs = response.data.data;

				});
		},
		ViewDoc(event) {
			this.StoreAll.document_details = event;
			if (event.carimbo_camara_pdf == null) {
				this.StoreAll.Leitura = false;
				if (event.attachment_partial != null) {
					this.StoreAll.document_src = event.attachment_partial;
				} else {
					this.StoreAll.document_src = event.attachment;
				}
			} else if (event.carimbo_camara_pdf != null && event.carimbo_prefeitura_pdf != null) {
				this.StoreAll.Leitura = true;
				this.StoreAll.document_src = event.carimbo_prefeitura_pdf;
			} else if (event.carimbo_camara_pdf != null && event.carimbo_prefeitura_pdf == null) {
				this.StoreAll.Leitura = true;
				this.StoreAll.document_src = event.carimbo_camara_pdf;
			} else {
				this.StoreAll.Leitura = false;
				if (event.attachment_partial != null) {
					this.StoreAll.document_src = event.attachment_partial;
				} else {
					this.StoreAll.document_src = event.attachment;
				}
			}
			/* Logica para documento atual */

			this.documento_src_proc = event.parecer_procurador_pdf;
			this.$refs.Modal_View_signer.openModal2();

		},
		ViewDoc_signer(event) {
			this.StoreAll.document_details = event;
			this.StoreAll.MultiSelectDocs = this.selected_products;
			this.$refs.Modal_View_signer_user.openModal();
		},

		ViewDoc_signer_lote(event) {
			this.StoreAll.document_details = event;
			this.StoreAll.MultiSelectDocs = this.selected_products;
			this.$refs.Modal_View_signer_user.openModal_lote();
		},

		formatDate(value) {
			moment.locale('pt-br');
			return moment(value).format('DD/MMMM/YYYY');
		},


	},
};
</script>
<template>
  <div>
    <Modal_View_signer ref="Modal_View_signer"></Modal_View_signer>
  </div>
  <div>
    <Modal_View_signer_user ref="Modal_View_signer_user"></Modal_View_signer_user>
  </div>
  <div class="container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">Documentos que precisam de assinatura!</h5>
            <p class="mb-0 text-sm">
              Aquivo você pode assinar seus documentos enviados.
            </p>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card mt-4">
                <div class="table-responsive">
                  <DataTable v-model:selection="selected_products" :value="SignerDocs" responsiveLayout="scroll"
                    :paginator="true" :rows="10" data-key="id" @rowSelect="onRowSelect" @rowUnselect="onRowUnselect">
                    <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
                    <template #header>

                      <button v-if="select" type="button" class="btn btn-success mb-2 mr-2" @click="ViewDoc_signer_lote();" data-toggle="modal"
                        data-target=".signer_doc_lote" data-dismiss="modal"><i class="fa fa-pen text-white pr-1"></i>
                        Assine documentos selecionados juntos</button>

                    </template>
                    <Column field="id" header="Protocolo" :sortable="true"></Column>
                    <Column field="name" header="Documento"></Column>
                    <Column field="created_at" header="Data">
                      <template #body="{ data }">
                        {{ formatDate(data.created_at) }}
                      </template>
                    </Column>
                    <Column header="Visualizar">
                      <template #body="slotProps">
                        <button style="margin-bottom: 0rem !important;" type="button" @click="ViewDoc(slotProps.data)"
                          class="btn bg-smartlegis btnEdit envio"><i style="color: white !important;"
                            class="fa fa-eye"></i></button>
                      </template>
                    </Column>
                    <Column header="Assinar">
                      <template #body="slotProps">
                        <button style="margin-bottom: 0rem !important;" type="button" @click="ViewDoc_signer(slotProps.data)"
                         class="btn bg-smartlegis btnEdit envio"><i
                            style="color: white !important;" class="fa fa-pen"></i></button>
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <Toast position="center" group="tl" />
  </div>
</template>
<style>.close {
  font-size: 2.3rem !important;
}</style>
  
  