<script>
import { AxiosAPI } from '@/axios';
import moment from 'moment';
import DocView from './DocView.vue';

export default {
	name: 'DocSearchAll',
	components: {
		DocView,
	},
	data() {
		return {
			hostDoc: '',
			host: '',
			parecer_procurador_pdf: '',
			CamaraName: '',
			LoadingSearch: true,
			filter: {
				token: '',
				protocol: null,
				name: null,
				type_doc: 0,
				status_doc: 0,
				date_creat: null,
				authors: [],
			},
			vereadores: [],
			expandedKeys: {},
			messages: [],
			result_vote: [
				{ key: '0', result: 'Selecione o status do documento' },
				{ key: '1', result: 'Aprovado' },
				{ key: '2', result: 'Reprovado' },
				{ key: '3', result: 'Em vista' },
			],
			categories: [{id: 0, name: 'Selecione o tipo do documento'}],
			Search_all: [],
		};
	},
	mounted() {
		this.token = localStorage.getItem('TokenExterno');
		this.FiltersDoc();

		const url = window.location.href;
		const startIndex = url.indexOf('https://') + 8;
		const pathIndex = url.indexOf('/', startIndex);
		const result = url.substring(startIndex, pathIndex);
		if (result == 'dev.smartlegis.net.br') {
			this.CamaraName = 'CÂMARA MUNICIPAL DE DEVS - RN';
			this.hostDoc = 'https://smartlegis-dev.s3.amazonaws.com';
			this.host = 'dev';
		} else if (result == 'teste.smartlegis.net.br') {
			this.CamaraName = 'CÂMARA MUNICIPAL DE TESTE - RN';
			this.hostDoc = 'https://smartlegis-production.s3.amazonaws.com';
			this.host = 'teste';
		} else if (result == 'jc.smartlegis.net.br') {
			this.CamaraName = 'CÂMARA MUNICIPAL DE JOÃO CÂMARA - RN';
			this.hostDoc = 'https://smartlegis-production.s3.amazonaws.com';
			this.host = 'jc';
		} else if (result == 'bd.smartlegis.net.br') {
			this.CamaraName = 'CÂMARA MUNICIPAL DE BODÓ - RN';
			this.hostDoc = 'https://smartlegis-production.s3.amazonaws.com';
			this.host = 'bodo';
		} else if (result == 'pa.smartlegis.net.br') {
			this.CamaraName = 'CÂMARA MUNICIPAL DE PEDRO AVELINO - RN';
			this.hostDoc = 'https://smartlegis-production.s3.amazonaws.com';
			this.host = 'pedroavelino';
		} else if (result == 'pp.smartlegis.net.br') {
			this.CamaraName = 'CÂMARA MUNICIPAL DE PEDRA PRETA - RN';
			this.hostDoc = 'https://smartlegis-production.s3.amazonaws.com';
			this.host = 'pedrapreta';
		} else if (result == 'cg.smartlegis.net.br') {
			this.CamaraName = 'CÂMARA MUNICIPAL DE CAMPO GRANDE - RN';
			this.hostDoc = 'https://smartlegis-production.s3.amazonaws.com';
			this.host = 'campogrande';
		} else if (result == 'lajes.smartlegis.net.br') {
			this.CamaraName = 'CÂMARA MUNICIPAL DE LAJES - RN';
			this.hostDoc = 'https://smartlegis-production.s3.amazonaws.com';
			this.host = 'lajes';
		} else if (result == 'parazinho.smartlegis.net.br') {
			this.CamaraName = 'CÂMARA MUNICIPAL DE PARAZINHO - RN';
			this.hostDoc = 'https://smartlegis-production.s3.amazonaws.com';
			this.host = 'parazinho';
		} else if (result == 'jd.smartlegis.net.br') {
			this.CamaraName = 'CÂMARA MUNICIPAL DE JANDAÍRA - RN';
			this.hostDoc = 'https://smartlegis-production.s3.amazonaws.com';
			this.host = 'jandaira';
		} else if (result == 'tp.smartlegis.net.br') {
			this.CamaraName = 'CÂMARA MUNICIPAL DE TRIUNFO POTIGUAR - RN';
			this.hostDoc = 'https://smartlegis-production.s3.amazonaws.com';
			this.host = 'triunfo';
		} else if (result == 'upanema.smartlegis.net.br') {
			this.CamaraName = 'CÂMARA MUNICIPAL DE UPANEMA - RN';
			this.hostDoc = 'https://smartlegis-production.s3.amazonaws.com';
			this.host = 'upanema';
		} else if (result == 'ja.smartlegis.net.br') {
			this.CamaraName = 'CÂMARA MUNICIPAL DE JARDIM DE ANGICOS - RN';
			this.hostDoc = 'https://smartlegis-production.s3.amazonaws.com';
			this.host = 'ja';
		} else if (result == 'angicos.smartlegis.net.br') {
			this.CamaraName = 'CÂMARA MUNICIPAL DE ANGICOS - RN';
			this.hostDoc = 'https://smartlegis-production.s3.amazonaws.com';
			this.host = 'angicos';
		} else if (result == 'crv.smartlegis.net.br') {
			this.CamaraName = 'CÂMARA MUNICIPAL DE CRV - RN';
			this.hostDoc = 'https://smartlegis-production.s3.amazonaws.com';
			this.host = 'crv';
		} else if (result == 'goianinha.smartlegis.net.br') {
			this.CamaraName = 'CÂMARA MUNICIPAL DE GOIANINHA - RN';
			this.hostDoc = 'https://smartlegis-production.s3.amazonaws.com';
			this.host = 'goianinha';
		} else if (result == 'jucurutu.smartlegis.net.br') {
			this.CamaraName = 'CÂMARA MUNICIPAL DE JUCURUTU - RN';
			this.hostDoc = 'https://smartlegis-production.s3.amazonaws.com';
			this.host = 'jucurutu';
		}else if (result == 'odb.smartlegis.net.br') {
			this.CamaraName = 'CÂMARA MUNICIPAL DE OLHO-D\'ÁGUA DO BORGES - RN';
			this.hostDoc = 'https://smartlegis-production.s3.amazonaws.com';
			this.host = 'odb';
		}else if (result == 'localhost:8080') {
			this.CamaraName = 'CÂMARA MUNICIPAL DE LOCAL - RN';
			this.hostDoc = 'https://smartlegis-dev.s3.amazonaws.com';
			this.host = 'local';
		} else {
			this.CamaraName = 'CÂMARA MUNICIPAL DE LOCAL - RN';
			this.hostDoc = 'https://smartlegis-dev.s3.amazonaws.com';
			this.host = 'local';
		}
	},
	methods: {
		resultvote(value) {

			let inner = 'Documento' + ' ';
			let result_one = '';
			let result_two = '';

			if (value.voting_result_1 === 1) {
				result_one = 'aprovado em 1° votação';
			} else if (value.voting_result_1 === 2) {
				result_one = 'reprovado em 1° votação';
			} else {
				result_one = 'em vista em 1° votação';
			}

			if (value.voting_result_2 === 1) {
				result_two = ' ' + 'e aprovado em 2° votação';
			} else if (value.voting_result_2 === 2) {
				result_two = ' ' + 'e reprovado em 2° votação';
			} else {
				result_two = ' ' + 'e em vista em 2° votação';
			}

			let result = inner + result_one + result_two;
			return result;
		},

		async FiltersDoc() {
			this.LoadingSearch = true;

			await AxiosAPI.get('/external/documents-categories', {
				headers: {
					Authorization: 'Bearer ' + this.token
				},
			})
				.then((response) => {
					let data = response.data.data;
					let select = { id: 0, name: 'Selecione o tipo do documento' };
					data.push(select);

					function compararPorId(a, b) {
						return a.id - b.id;
					}

					data.sort(compararPorId);
					this.categories = data;
				});

			await AxiosAPI.get('/external/users', {
				headers: {
					Authorization: 'Bearer ' + this.token
				},
			})
				.then((response) => {
					let users = [];
					response.data.users.forEach(ValidateUsers => {
						if (ValidateUsers.status_user !== 2) {
							users.push(ValidateUsers);
						}

					});
					
					this.vereadores = users;
				});

			this.Get_Search_all();
		},


		FilterDoc() {
			this.LoadingSearch = true;
			function getValueOrEmpty(value) {
				return value != null && value !== '' ? value : '';
			}

			// Função para extrair IDs de autores
			function extractAuthorIds(authors) {
				return authors.map(author => author.id);
			}

			// Filtrar os valores e criar o objeto de filtro
			const filteredValues = {
				protocolo: getValueOrEmpty(this.filter.protocol),
				name: getValueOrEmpty(this.filter.name),
				type_doc: this.filter.type_doc !== 0 ? this.filter.type_doc : '',
				status_doc: this.filter.status_doc !== 0 ? this.filter.status_doc : '',
				date_creat: getValueOrEmpty(this.filter.date_creat),
				authors: this.filter.authors.length > 0 ? extractAuthorIds(this.filter.authors) : '',
			};

			// Verificar se todos os campos estão vazios
			const allFieldsEmpty = Object.values(filteredValues).every(value => value === '');

			let filt_no_id = '?document_category_id=' + filteredValues.type_doc + '&name=' + filteredValues.name + '&id=' + filteredValues.protocolo + '&voting_result=' + filteredValues.status_doc + '&created_at=' + filteredValues.date_creat;

			if (filteredValues.authors) {
				filt_no_id += '&authors=' + JSON.stringify(filteredValues.authors);
			}

			if (allFieldsEmpty) {
				AxiosAPI
					.get('/external/documents', {
						headers: {
							Authorization: 'Bearer ' + this.token,
						},
					})
					.then((resp) => {
						this.Search_all = resp.data.data;
						this.LoadingSearch = false;
					});
			} else {
				AxiosAPI
					.get('/external/documents' + filt_no_id, {
						headers: {
							Authorization: 'Bearer ' + this.token,
						},
					})
					.then((resp) => {
						this.Search_all = resp.data.data;
						this.LoadingSearch = false;
					});
			}

		},


		Get_Search_all() {
			AxiosAPI
				.get('/external/documents', {
					headers: {
						Authorization: 'Bearer ' + this.token,
					},
				})
				.then((resp) => {
					this.Search_all = resp.data.data;
					this.LoadingSearch = false;
				});

		},
		showError() {
			this.$toast.add({ severity: 'error', summary: 'Sessão Não Encotrada', detail: 'Documento não possui sessão', life: 3000 });
		},
		formatDate(value) {
			moment.locale('pt-br');
			return moment(value).format('DD/MMMM/YYYY');
		},

		ViewDoc(event) {
			let data = [];
			let linkdoc = '';
			/* Populando a variavel global */

			if (event.carimbo_camara_pdf == null) {
				linkdoc = event.attachment;
			} else if (event.carimbo_camara_pdf != null && event.carimbo_prefeitura_pdf != null) {
				linkdoc = event.carimbo_prefeitura_pdf;
			} else if (event.carimbo_camara_pdf != null && event.carimbo_prefeitura_pdf == null) {
				linkdoc = event.carimbo_camara_pdf;
			} else {
				linkdoc = event.attachment;
			}

			if(event.parecer_procurador_pdf != null){
				this.parecer_procurador_pdf = event.parecer_procurador_pdf;
			}else{
				this.parecer_procurador_pdf = null;
			}

			data.push(event);
			data.push(this.token);
			data.push(linkdoc);
			data.push(this.hostDoc);
			data.push(this.host);
			data.push(this.parecer_procurador_pdf);
			this.$refs.DocView.openModal2(data);
		},

		Author(value) {
			let Co_user = [];
			for (let i = 0; i < value.length; i++) {
				Co_user.push(value[i].nickname);
			}
			return Co_user.join(', ');
		},
		
	},
};
</script>
<template>
  <div>
    <DocView ref="DocView"></DocView>
  </div>
  <div class="container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <img
                src="../../assets/img/LogoExtend.png"
                alt="smartlegis"
            />
            <h5 class="mt-1 mb-0">Pesquise todos documentos enviados no sistema!</h5>
            <p class="mb-0 text-sm">
              Aquivo você pode visualizar todos os documentos enviados no sistema.
            </p>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="table-responsive">
                  <DataTable :value="Search_all" show-gridlines responsive-layout="scroll" :loading="LoadingSearch"
                    :paginator="true" :rows="10">
                    <template #header>
                      <div class="d-flex row">
                        <div class="col-md-4 col-md-auto col-sm">
                          <div class="card flex justify-content-center">
                            <label for="username">Protocolo</label>
                          </div>
                          <div>
                            <input placeholder="Protocolo do documento" v-model="filter.protocol" class="my_inp" />
                          </div>
                          <div class="card flex justify-content-center">
                            <small id="username-help">Informe o protocolo do documento.</small>
                          </div>
                        </div>


                        <div class="col-md-4 col-md-auto col-sm">
                          <div class="card flex justify-content-center">
                            <label for="username">Objeto do documento</label>
                          </div>
                          <div>
                            <input class="my_inp" placeholder="Objeto do documento" v-model="filter.name" />
                          </div>
                          <div class="card flex justify-content-center">
                            <small id="username-help">Informe o objeto do documento.</small>
                          </div>
                        </div>

                        <div class="col-md-4 col-md-auto col-sm">
                          <div class="card flex justify-content-center">
                            <label for="username">Tipo do documento</label>
                          </div>
                          <select class="my_inp" id="choices-category" v-model="filter.type_doc" name="choices-category">
                            <option v-for="option in categories" :key="option.id" :value="option.id">
                              {{ option.name }}
                            </option>
                          </select>
                          <div class="card flex justify-content-center">
                            <small id="username-help">Informe o tipo do documento.</small>
                          </div>
                        </div>

                        <div class="col-md-4 col-md-auto col-sm">
                          <div class="card flex justify-content-center">
                            <label for="username">Status do documento</label>
                          </div>
                          <select class="my_inp" id="choices-category" v-model="filter.status_doc"
                            name="choices-category">
                            <option v-for="option in result_vote" :key="option.key" :value="option.key">
                              {{ option.result }}
                            </option>
                          </select>
                          <div class="card flex justify-content-center">
                            <small id="username-help">Informe o status do documento</small>
                          </div>
                        </div>

                        <div class="col-md-4 col-md-auto col">
                          <div class="card flex justify-content-center">
                            <label for="username">Autor(es)</label>
                          </div>
                          <MultiSelectPrime id="choices-category" v-model="filter.authors" display="chip"
                            :options="vereadores" option-label="name" placeholder="Selecione o(s) autor(es)"
                            class="my_inp p-multiselect-label p-multiselect-token"
                            style="height: calc(3.5rem + 2px) !important" />
                          <div class="card flex justify-content-center">
                            <small id="username-help">Informe os autor(es) do(s) documento(s)</small>
                          </div>
                        </div>


                        <div class="col-md-4 col-md-auto col-sm">
                          <div class="card flex justify-content-center">
                            <label for="username">Data de envio</label>
                          </div>
                          <input type="date" class="mb-3 multisteps-form__input form-control my_inp"
                            v-model="filter.date_creat">
                          <div class="card flex justify-content-center">
                            <small id="username-help">Informe a data de envio</small>
                          </div>
                        </div>

                        <div class="d-flex justify-content-center">
                          <button class="btn bg-smartlegis text-white" style="width: 100%;"
                            @click="FilterDoc()">Filtrar</button>
                        </div>
                      </div>
                    </template>

                    <Column field="id" header="Protocolo" class="text-sm font-weight-bold text-wrap text-uppercase"
                      :sortable="true"></Column>

                    <Column field="name" header="Documento" class="text-sm font-weight-bold text-wrap text-uppercase">
                    </Column>

                    <Column field="document_category.name" header="Tipo"
                      class="text-sm font-weight-bold text-wrap text-uppercase"></Column>

                    <Column field="authors" header="Autor(es)" class="text-sm font-weight-bold text-wrap text-uppercase"
                      :sortable="true">
                      <template #body="{ data }">
                        <span>{{ Author(data.authors) }} </span>
                      </template>
                    </Column>

                    <Column field="created_at" header="Data" class="text-sm font-weight-bold text-wrap text-uppercase">
                      <template #body="{ data }">
                        {{ formatDate(data.created_at) }}
                      </template>
                    </Column>

                    <Column field="voting_result_1" header="Resultado" class="text-sm font-weight-bold text-uppercase">
                      <template #body="{ data }">
                        <span v-if="data.voting_result_1 === null" class="text-center font-weight-bolder text-white"
                          style="background-color: #261e1e; border-radius: 6px;font-size: 15px;padding: 4px;"> Não votado
                        </span>

                        <span v-else-if="data.voting_result_1 === 1 && data.voting_result_2 === null"
                          class="bg-success text-center font-weight-bolder text-white"
                          style="border-radius: 6px;font-size: 15px;padding: 4px;"> Documento aprovado
                        </span>
                        <span v-else-if="data.voting_result_1 === 2 && data.voting_result_2 === null"
                          class="bg-danger text-center font-weight-bolder text-white"
                          style="border-radius: 6px;font-size: 15px;padding: 4px;"> Documento reprovado
                        </span>
                        <span v-else-if="data.voting_result_1 === 3 && data.voting_result_2 === null"
                          class="bg-secondary text-center font-weight-bolder text-white"
                          style="border-radius: 6px;font-size: 15px;padding: 4px;"> Documento em vista
                        </span>
                        <span v-else-if="data.voting_result_1 != null && data.voting_result_2 != null"
                          class="text-center font-weight-bolder text-white"
                          style="background-color: #2a8b89; border-radius: 6px;font-size: 15px;padding: 4px;">
                          {{ resultvote(data) }} </span>

                        <span v-else class="text-center font-weight-bolder text-white"
                          style="background-color: #261e1e; border-radius: 6px;font-size: 15px;padding: 4px;"> Não
                          votado</span>
                      </template>
                    </Column>
                    <Column header="Visualizar"
                      class="text-sm font-weight-bold text-wrap text-uppercase TT justify-content-center text-center">
                      <template #body="slotProps">
                        <button style="margin-bottom: 0rem !important;" type="button"
                          class="btn bg-smartlegis btnEdit envio" @click="ViewDoc(slotProps.data)"><i
                            style="color: white !important;" class="fa fa-eye"></i></button>
                      </template>
                    </Column>
                  </DataTable>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Toast />

  </div>
</template>
<style scoped>
.close {
  font-size: 2.3rem !important;
}

.p-multiselect-token {
  padding: 0.175rem 0.75rem !important;
}

.p-multiselect-label {
  padding: 0px !important;
}

.my_inp {
  width: 100%;
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.38);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
}</style>
