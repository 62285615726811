<template>
    <div>
      <div class="py-1 h-100 container-fluid">
        <div class="row">
            <div class="text-center col-12">
        <h3 class="mt-auto text-white">Comissão de direitos humanos</h3>
        <h5 class="text-white font-weight-normal">
          Documentos para visualização de comição de direitos humanos
        </h5>
    </div>
</div>

        <div class="mt-4 row">
          <div class="col-12">
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <div class="d-flex justify-content-between">
                <h5 class="text-start mb-0" style="font-size: 25px;">Documentos em aguardo</h5>
              </div> </div>
        
        <div class="row">
                <div class="col-12 ">
                  <div class="card">
                    <div class="table-responsive">
  
                    <DataTable v-model:selection="selectedProducts" :value="Docs_recp" responsive-layout="scroll" :paginator="true" :rows="10"
                    selection-mode="multiple" :metakeyselection="false" @rowSelect="onRowSelect" @rowUnselect="onRowUnselect">
                        
                        <Column field="document_id" header="Protocolo" class="text-sm font-weight-bold text-wrap text-uppercase" :sortable="true"></Column>
                        <Column field="document_name" header="Documento" class="text-sm font-weight-bold text-wrap text-uppercase"></Column>
                        <Column field="authors" header="Autor(es)"
                          class="text-sm font-weight-bold text-wrap text-uppercase" :sortable="true">
                          <template #body="{ data }">
                            <span>{{Author(data.authors)}} </span>
                          </template>
                        </Column>
                        <Column field="created_at" header="Data" class="text-sm font-weight-bold text-wrap text-uppercase">
                          <template #body="{data}">
                          {{formatDate(data.created_at)}}
                      </template>
                        </Column>
                       
                        <Column  header="Enviar" class="text-sm font-weight-bold text-wrap text-uppercase TT text-center">
                            <template #body="slotProps">
                              <button  data-toggle="modal" style="margin-bottom: 0rem !important;" data-target=".Doc_send" type="button" class="btn bg-smartlegis btnEdit envio" @click="EventDoc(slotProps.data)"><i style="color: white !important;" class="fa fa-paper-plane"></i></button>
                    </template>
                            </Column>
                            <Column header="Visualizar" class="text-sm font-weight-bold text-wrap text-uppercase TT text-center">
                            <template #body="slotProps">
                              <button  data-toggle="modal" style="margin-bottom: 0rem !important;" type="button" data-target=".rec" class="btn bg-smartlegis btnEdit envio" @click="EventDoc(slotProps.data)"><i style="color: white !important;" class="fa fa-eye"></i></button>
                            </template>
                            </Column>
                            
                    </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>

        <div
          class="modal fade bd-example-modal-lg Doc_send"
          tabindex="-1"
          role="dialog"
          aria-labelledby="mySmallModalLabel"
          aria-hidden="true"
          style="z-index: 999; display: none"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 id="exampleModalLabel" class="modal-title">
                  <span> Enviar documento: </span> <span id="NameDoc" style="color: red"> {{documento_name}} </span>
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Fechar"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <label>Local de envio</label>
                <select
                  id="choices-category"
                  class="form-control"
                  name="choices-category"
                >
                  <option
                    v-for="option in options"
                    :key="option.id"
                    :value="option.id"  
                  >
                    {{ option.name }}
                  </option>
                </select>
                          
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Fechar
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  data-dismiss="modal"
                  @click="Enviar();"
                >
                  Enviar documento!
                </button>
              </div>
            </div>
          </div>
        </div>

        <div id="mymodals" class="modal fade bd-example-modal-xl rec" tabindex="-1" role="dialog"
            aria-labelledby="myExtraLargeModalLabel" aria-hidden="true" style="z-index: 9999; display: none;"
           >
            <div class="modal-dialog modal-xl rec">
              <div class="modal-content">
                <div class="modal-header">
                  <strong><span> {{ documento_name }} </span> </strong>
                  <button id="closess" type="button" class="close"  data-dismiss="modal" aria-label="Fechar">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  
                   


                  <div
                  class="d-flex justify-content-center">
                  <button type="button" class="justify-content-center btn btn-success mb-2 mr-2" data-toggle="modal" data-target=".parecer_proc_just" @click="Getparecer()">
                    Ver parecerer <i class="ml-2 fa fa-file-import"></i></button></div>

                <div class="d-flex justify-content-center">
                <iframe v-if="this.$pinia.state.value.main.mobile == true" :src="'https://docs.google.com/gview?url='+documento_src+'&embedded=true'"  width="100%" height="580"
                style="border: none;"></iframe>
                <iframe v-else :src=documento_src  width="100%" height="580"
                style="border: none;"></iframe>

              
  
                </div>
                   
                <div class="modal-footer d-flex justify-content-center">
                  <div>
                    <a target="_blank" :href="documento_src" download="proposed_file_name">
                      <div>
                        <h3>Baixar o Documento</h3>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div
        class="modal fade bd-example-modal-lg Doc_parecer"
        tabindex="-1"
        role="dialog"
        aria-labelledby="mySmallModalLabel"
        aria-hidden="true"
        style="z-index: 999; display: none"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="exampleModalLabel" class="modal-title">
                <span> Adicionar parecer ao documento: </span> <span id="NameDoc" style="color: red"> {{documento_name}} </span>
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Fechar"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="mt-3 mb-3">
                              <label for="formFile" class="form-label"
                                >Selecione o arquivo</label
                              >
                              <input 
                                id="formFile"
                                class="form-control"
                                type="file"
                              />
                            </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Fechar
              </button>
              <button
                type="button"
                class="btn btn-success"
                data-dismiss="modal"
                @click="parecer();"
              >
                Enviar parecer!
              </button>
            </div>
          </div>
        </div>
      </div>
  
      <div id="mymodals" class="modal fade bd-example-modal-xl parecer_proc_just" tabindex="-1" role="dialog"
          aria-labelledby="myExtraLargeModalLabel" aria-hidden="true" style="z-index: 10000; display: none;"
         >
          <div class="modal-dialog modal-xl parecer_proc_just">
            <div class="modal-content">
              <div class="modal-header">
                <strong><span> PARECERES </span> </strong>
                <button id="closess" type="button" class="close"  data-dismiss="modal" aria-label="Fechar">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div>
                <div class="mb-3">
                  <div class="d-flex justify-content-center mb-2 text-black"><span><strong> PARECER PROCURADOR </strong></span></div>
                  <div class="d-flex justify-content-center">
                  <h1 v-if="documento_src_proc == undefined" >Parecer do procurador não adicionado</h1>
                <iframe v-else-if="this.$pinia.state.value.main.mobile == true" :src="'https://docs.google.com/gview?url='+documento_src_proc+'&embedded=true'"  width="100%" height="580"
                style="border: none;"></iframe>
                <iframe v-else :src=documento_src_proc  width="100%" height="580"
                style="border: none;"></iframe>
                </div>
              </div>

              
  
                </div>
                <div class="mb-2">
                <div class="d-flex justify-content-center mb-2 text-black"> <span><strong> PARECER JUSTIÇA </strong></span></div>
                <div class="d-flex justify-content-center">
                  <h1 v-if="parecer_justice == null" >Parecer comissão de justiça não adicionado</h1>
                  <iframe v-else-if="this.$pinia.state.value.main.mobile == true" :src="'https://docs.google.com/gview?url='+parecer_justice+'&embedded=true'"  width="100%" height="580"
                style="border: none;"></iframe>
                <iframe v-else :src=parecer_justice  width="100%" height="580"
                style="border: none;"></iframe>
                </div>
              </div>

                
              <div v-if="parecer_education != null" class="mb-2">
                <div class="d-flex justify-content-center mb-2 text-black"> <span><strong> PARECER EDUCAÇÃO </strong></span></div>
                <div class="d-flex justify-content-center">
                  <h1 v-if="parecer_education == null" >Parecer comissão de justiça não adicionado</h1>
                  <iframe v-else-if="this.$pinia.state.value.main.mobile == true" :src="'https://docs.google.com/gview?url='+parecer_education+'&embedded=true'"  width="100%" height="580"
                style="border: none;"></iframe>
                <iframe v-else :src=parecer_education  width="100%" height="580"
                style="border: none;"></iframe>
                </div>
              </div>
              
              <div v-if="parecer_farm != null" class="mb-2">
                <div class="d-flex justify-content-center mb-2 text-black"> <span><strong> PARECER FAZENDA </strong></span></div>
                <div class="d-flex justify-content-center">
                  <h1 v-if="parecer_farm == null" >Parecer comissão de justiça não adicionado</h1>
                  <iframe v-else-if="this.$pinia.state.value.main.mobile == true" :src="'https://docs.google.com/gview?url='+parecer_farm+'&embedded=true'"  width="100%" height="580"
                style="border: none;"></iframe>
                <iframe v-else :src=parecer_farm  width="100%" height="580"
                style="border: none;"></iframe>
                </div>
              </div>
              
              <div v-if="parecer_health != null" class="mb-2">
                <div class="d-flex justify-content-center mb-2 text-black"> <span><strong> PARECER SAÚDE </strong></span></div>
                <div class="d-flex justify-content-center">
                  <h1 v-if="parecer_health == null" >Parecer comissão de justiça não adicionado</h1>
                  <iframe v-else-if="this.$pinia.state.value.main.mobile == true" :src="'https://docs.google.com/gview?url='+parecer_health+'&embedded=true'"  width="100%" height="580"
                style="border: none;"></iframe>
                <iframe v-else :src=parecer_health  width="100%" height="580"
                style="border: none;"></iframe>
                </div>
              </div>
              
              <div v-if="parecer_human != null" class="mb-2">
                <div class="d-flex justify-content-center mb-2 text-black"> <span><strong> PARECER DIREITOS HUMANOS </strong></span></div>
                <div class="d-flex justify-content-center">
                  <h1 v-if="parecer_human == null" >Parecer comissão de justiça não adicionado</h1>
                  <iframe v-else-if="this.$pinia.state.value.main.mobile == true" :src="'https://docs.google.com/gview?url='+parecer_human+'&embedded=true'"  width="100%" height="580"
                style="border: none;"></iframe>
                <iframe v-else :src=parecer_human  width="100%" height="580"
                style="border: none;"></iframe>
                </div>
              </div>
              
              <div v-if="parecer_infra != null" class="mb-2">
                <div class="d-flex justify-content-center mb-2 text-black"> <span><strong> PARECER INFRAESTRUTURA </strong></span></div>
                <div class="d-flex justify-content-center">
                  <iframe v-if="this.$pinia.state.value.main.mobile == true" :src="'https://docs.google.com/gview?url='+parecer_infra+'&embedded=true'"  width="100%" height="580"
                style="border: none;"></iframe>
                <iframe v-else :src=parecer_infra  width="100%" height="580"
                style="border: none;"></iframe>
                </div>
              </div>

                 
              <div class="modal-footer d-flex justify-content-center">
                <div>
                  <a target="_blank" :href="documento_src_proc" download="proposed_file_name">
                    <div>
                      <h3>Baixar o Documento</h3>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

        <div class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" style="z-index: 998 !important;"
          aria-hidden="true">
          <div class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="modal-header">
                <h6>Iniciando Votação</h6>
                <button id="closesas" type="button" class="close" data-dismiss="modal" aria-label="Close"
                  @click="finallyloading()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div id="load" class="justify-content-center" style="display: none">
                  <div class="spinner-grow text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow text-secondary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow text-success" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow text-danger" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow text-warning" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow text-info" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow text-dark" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
  
  
  
  </template>
  
  <script>
  import { AxiosAPI } from "@/axios"; 
  import moment from "moment";
  import { useMainStore } from "../../stores/index";
  export default {
    name: "DataTables",
    setup(){
      const store_all = useMainStore()
      store_all.Token();
      return{
        store_all: store_all,
      }
    },
    data() {
      return {
        start_url: 'https://docs.google.com/gview?url=',
        end_url: '&embedded=true',
        ComissionDoc: [],
        Docs_recp: [],
        parecer_education: null,
        parecer_farm: null,
        parecer_health: null,
        parecer_human: null,
        parecer_infra: null,
        parecer_justice: null,
        selectedProducts: null,
        documento_src_proc: null,
        document_discut_name: 'Aguarde...',
        document_discut_id: 0,
        session_id: 0,
        document_status: '...',
        documento_name: 'Carregando...',
        documento_src: '',
        documento_id: 0,
        documento_statusid: 0,
        category_user: 0,
        session: {},
        session_status_name: "",
        vereadores: [],
        Users_Quorum_Now: [],
        usersq: [],
        arraynew: [],
        NewUser: [],
        adduser: [],
        quorum: [],
        quorum_add: [],
        quorum_remove: [],
        quorumid: 0,
        ready2vote: true,
        voteFunction: '',
        votes: {},
        favorable: 0,
        against: 0,
        vista: 0,
        ordem: 1,
        total_votes: 0,
        grapic: false,
        chartData : [] ,
        options: [
          { id: 0, name: "Secretário" }
        ],
        chartOptions : { 
          legend: {position: 'none'},
          height: 350,
        } 
      };
    },
  
    mounted() {
      this.GetSession()
      this.GetUsersAll();
      this.GetUser(); 
    },
  
    methods: {
      GetComissionDoc(){
        let dados = []
        AxiosAPI
          .get("/documents-comission?comission=5",{
            headers: {
              Authorization: `Bearer ` + this.$pinia.state.value.main.accessToken,
            },
          })
          .then((response) => {
            console.log(response)
            for (let i = 0; i< response.data.data.length; i++) {
            if(response.data.data[i].status_leitura == 1){
              continue;
            }else{
              dados.push(response.data.data[i])
              this.Docs_recp =  dados
            }
            }
        })
      },

      EventDoc(event){
          this.documento_name = event.document_name

          this.document_status_move = event.document_status_movement_id
          
          this.documento_id = event.document_id

          this.document_row_comission_id = event.comission_document_id
          console.log(event)
          

          AxiosAPI
          .get("/documents?id="+event.document_id, {
            headers: {
              Authorization: `Bearer `+ this.$pinia.state.value.main.accessToken,
              },
            })
            .then((resp) => {
              console.log(resp.data.data)
              this.documento_src = resp.data.data[0].attachment
              this.documento_src_proc = resp.data.data[0].parecer_procurador_pdf
            })
        },

      formatDate(value) {
           moment.locale("pt-br");
           return moment(value).format("DD/MMMM/YYYY");
          },
  
          Author(value){
      let Co_user = []
      for (let i = 0; i < value.length; i++) {
        Co_user.push(value[i].nickname)
      }
      return Co_user.join(", ");
    },
      
          async GetSession() {
          await this.store_all.Get_Data();
          this.session = this.$pinia.state.value.main.session; this.session_status = this.$pinia.state.value.main.session.session_status.id;
          this.session_id = this.$pinia.state.value.main.session.id; this.session_status_name = this.$pinia.state.value.main.session.session_status.name;
          this.GetComissionDoc();
        },

          async GetUsersAll() {
          await this.store_all.UserAllEnvio();
          this.vereadores = this.$pinia.state.value.main.user_all;  
        },
          async GetUser(){
          await this.store_all.User(); 
          this.category_user = this.$pinia.state.value.main.user.user_category_id
        },
  
      
      parecer(){
          
        let file = document.getElementById("formFile").files[0];
        const formData = new FormData();
          formData.append("comission_document_id", this.document_row_comission_id);
          formData.append("parecer_pdf", file);
          formData.append("status_parecer", 1);
    
        AxiosAPI.post("/parecer-comissao",formData, {
          headers: {
            Authorization: `Bearer `+this.$pinia.state.value.main.accessToken,
          },
        })
        .then((resp) => {
          console.log(resp);
          this.Doc_parecer_ok_send()
        });
    
      },
      
      Doc_parecer_ok_send() {
          this.$toast.add({severity:'success', summary: 'Parecer', detail:'Parecer anexado com sucesso', life: 3000});
      },
      
      Enviar() {
      
      AxiosAPI.post("document/change-status-comission?comission_document_id="+this.document_row_comission_id+"&status_leitura=1",{}, {
          headers: {
            Authorization: `Bearer `+this.$pinia.state.value.main.accessToken,
          },
        })
        .then((resp) => {
          
        console.log(resp)

              AxiosAPI.post("/document/change-status-move?document_id=" + this.documento_id + "&document_status_movement_id=1&user_id="+ this.$pinia.state.value.main.user.id,{}, {
          headers: {
            Authorization: `Bearer `+this.$pinia.state.value.main.accessToken,
          },
        })
        .then((resp) => {
          console.log(resp);
          this.showSuccess_send();
          this.GetComissionDoc();
        });});
      },

      showSuccess_send() {
        this.$toast.add({severity:'success', summary: 'Documento devolvido ao secretário', detail:'O documento foi enviado ao secretário com sucesso', life: 3000});
    },

      Getparecer(){
        this.parecer_education = null
        this.parecer_farm = null
        this.parecer_health = null
        this.parecer_human = null
        this.parecer_infra = null
        this.parecer_justice = null

      let parecer_education = []
      let parecer_farm = []
      let parecer_health = []
      let parecer_human = []
      let parecer_infra = []
      let parecer_justice = []

      AxiosAPI
          .get("/parecer-comissao?document_id="+this.documento_id, {
            headers: {
              Authorization: `Bearer ` + this.$pinia.state.value.main.accessToken,
            },
          })
          .then((response) => {
            console.log(response)
            if(response.data.parecer.length <= 0){
            this.parecer_on = false
          }else{
            this.parecer_on = true
          }
         for (let i = 0; i< response.data.parecer.length; i++) {
          if(response.data.parecer[i].comission_id == 1){
            parecer_education.push(response.data.parecer[i])
          }
          if(response.data.parecer[i].comission_id == 2){
            parecer_farm.push(response.data.parecer[i])
          }
          if(response.data.parecer[i].comission_id == 4){
            parecer_health.push(response.data.parecer[i])
          }
          if(response.data.parecer[i].comission_id == 5){
            parecer_human.push(response.data.parecer[i])
          }
          if(response.data.parecer[i].comission_id == 3){
            parecer_infra.push(response.data.parecer[i])
          }
          if(response.data.parecer[i].comission_id == 6){
            parecer_justice.push(response.data.parecer[i])
          }

          if( parecer_education.length <= 0){
            this.parecer_education = null
          }else{
            this.parecer_education = parecer_education[0].parecer_pdf
          }

          if( parecer_farm.length <= 0){
            this.parecer_farm = null
          }else{
            this.parecer_farm = parecer_farm[0].parecer_pdf
          }

          if( parecer_health.length <= 0){
            this.parecer_health = null
          }else{
            this.parecer_health = parecer_health[0].parecer_pdf
          }

          if( parecer_human.length <= 0){
            this.parecer_human = null
          }else{
            this.parecer_human = parecer_human[0].parecer_pdf
          }

          if( parecer_infra.length <= 0){
            this.parecer_infra = null
          }else{
            this.parecer_infra = parecer_infra[0].parecer_pdf
          }

          if( parecer_justice.length <= 0){
            this.parecer_justice = null
          }else{
            this.parecer_justice = parecer_justice[0].parecer_pdf
          }

        }
      })
    },
  
    },
  };
  </script>
  <style>
  .previous {
    padding-right: 11px;
    padding-bottom: 0.5rem;
  }
  
  .close {
    font-size: 2.3rem !important;
  }
  .modal-backdrop{
    z-index: 997 !important;
  }
  </style>
  <style scoped>
  
  
  ::v-deep(.vtl-paging-change-div){
    display: none;
  }
  
  ::v-deep(.vtl-paging, .vtl-row){
    justify-content: flex-end
  }
  
  
  </style>
  