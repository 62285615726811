<template>
    <div>
        <Loading ref="Loading" style="z-index: 1002 !important;"></Loading>
    </div>
    <div>
        <ModalBigger ref="viewDocument" style="z-index: 1001;">
            <template #header style="display: block !important;">
                <span style="color: black;"><strong> Confirmar carimbo </strong></span> 

                <div class="flex-start">
                    <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

            </template>

            <template #body>

                <div class="d-flex justify-content-center">
                    <canvas ref="pdfCanvass" style="z-index: 999; width: 70%;"></canvas>
              </div>

            </template>

            <template #footer>
                <div v-if="this.$pinia.state.value.main.user.user_category_id == 1" class="d-flex justify-content-center">
                    <button type="button" class="btn btn-primary bg-danger mr-3" @click="$refs.viewDocument.closeModal()">
                        Voltar
                    </button>
                    <button type="button" class="btn btn-primary bg-success" @click="send_stamp(1)">
                        Carimbar
                    </button>
                </div>
                <div v-if="this.$pinia.state.value.main.user.user_category_id == 5" class="d-flex justify-content-center">
                    <button type="button" class="btn btn-primary bg-danger mr-3" @click="$refs.viewDocument.closeModal()">
                        Voltar
                    </button>
                    <button type="button" class="btn btn-primary bg-success" @click="send_stamp(2)">
                        Carimbar
                    </button>
                </div>
            </template>
        </ModalBigger>

    </div>
</template>
    
<script>
import ModalBigger from "./ModalBigger.vue";
import 'pdfjs-dist/web/pdf_viewer.css';
import { PDFDocument, rgb, scale, StandardFonts } from 'pdf-lib';
import { AxiosAPI } from "@/axios";
import Loading from '../Modals/Loading.vue';
export default {
    components: {
        ModalBigger,
        Loading,
    },

    data() {
        return {
            pageNumber: 1,
            pdfPage: null,
            pdfBlob: null,
        }
    },
    methods: {
        openModal(value) {
            this.$refs.viewDocument.openModal();
            this.pdfBlob = value
            console.log(value)

            this.loadPdf();
            
        },

        closeModal() {
            this.$refs.viewDocument.closeModal();           
        },

        async loadPdf() {
        let pdfjs;
            pdfjs = await import("../../../../node_modules/pdfjs-dist/build/pdf"); 
            const pdfjsWorker = await import("../../../../node_modules/pdfjs-dist/build/pdf.worker.entry");
            pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker; 

            console.log(this.pdfBlob)
            if (!this.pdfBlob) {
                console.error('Blob do PDF não definido corretamente.');
                return;
            }

            const pdfData = new Uint8Array(await this.pdfBlob.arrayBuffer());
            this.pdfDocument = await pdfjs.getDocument(pdfData).promise;
            this.renderPage();


        },

        async renderPage() {
            this.pdfDocument.getPage(this.pageNumber).then((page) => {
            this.pdfPage = page
            const canvas = this.$refs.pdfCanvass;
            const context = canvas.getContext('2d');

            const viewport = page.getViewport({ scale: 2 });

            canvas.width = viewport.width;
            canvas.height = viewport.height;
            
            page.render({
                canvasContext: context,
                viewport: viewport,
            });
            }); 
/*             const base64Pdf = this.pdfBase64;

            const existingPdfBytes = Uint8Array.from(atob(base64Pdf), c => c.charCodeAt(0)).buffer; */

            const pdfDoc = await PDFDocument.load(this.pdfDocument)

            const pages = pdfDoc.getPages()
            const firstPage = pages[0]

            const { width, height } = firstPage.getSize()
            let dat  = { width, height }
            this.Intera(dat);
        },


        send_stamp(value){
            if(value === 1){
            this.emitter.emit("stamp_approve", 1);
            this.$refs.Loading.openModal();
        }else{
            this.emitter.emit("stamp_approve", 2);
            this.$refs.Loading.openModal();
        }
        },

        closeModal_Loading(){
            this.$refs.Loading.closeModal();
        },

    }
}


</script>
    