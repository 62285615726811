import Cookie from 'js-cookie';
import {AxiosAPI} from '@/axios';
import Swal from 'sweetalert2';

export default {
	auth(to, from, next) {
		const token = Cookie.get('User');

		if (!token) {
			next('/login');
			Swal.fire({
				title: 'Sessão expirada!',
				text: 'Faça login novamente para continuar',
				icon: 'error',
				confirmButtonText: 'Ok'
			});
		} else {
			AxiosAPI.get('/sessions', {
				headers: {
					Authorization: 'Bearer '+token,
				}
			}
			).then(() => {})
				.catch((error) => {
					if(error.response.status == 401){
						AxiosAPI.get('/sessions', {
							headers: {
								Authorization: 'Bearer '+token,
							}
						}).then(() => {})
							.catch((error) => {
								if(error.response.status == 401){
								 Cookie.remove('User');
								 next();
								}
							});
					}
				});
			next();
		}
	}
};
