<script>
import { AxiosAPI } from '@/axios';
import sound from '@/assets/sound/fim.mp3';
import { useMainStore, useBusStore } from '../../stores/index';
export default {
	name: 'TvDiscussion',
	components: {},
	setup(){
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		StoreAll.Token();
		return{
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			tribuna: false,
			document_lote: [],
			user_doc: null,
			user_on: false,
			x: false,
			voted: false,
			legendavote: false,
			f_fala: false,
			seconds: 2,
			minutes: 0,
			Document_discut_name: [
				'AGUARDE - AGUARDE',
				'AGUARDE - AGUARDE',
				'AGUARDE - AGUARDE',
			],
			Document_discut_autor: 'Aguarde...',
			Document_discut_tipo: 'Aguarde...',
			Document_discut_id: 0,
			Discut_id: 0,
			discut_vereadores: [],
			cronometro: 'Aguarde...',
			dia: 0,
			mes: '',
			time: '',
			timer_session: 'Aguarde o inicio',
			autor: '',
			socket_ids: [],
			timerOn: false,
			TimerOver: false,
		};
	},

	watch: {
		TimerOver: function (newValue, oldPath) {
			if (newValue === true) {
				this.TimerOver = false;
				this.Sound();
				this.Finalizar_fala();
			}
		},
	},
	computed: {

		document_lote_colored() {
			const LIMIT = 60;
			return this.Document_discut_name.map((name) => {
				const parts = name.split(' - ');
				return [
					{ text: parts[0], colorClass: 'text-uppercase font-weight-bold text-warning' },
					{ text: ' - ', colorClass: 'text-uppercase font-weight-bold text-white' },
					{
						text: parts[1].length > LIMIT ? parts[1].substring(0, 199) : parts[1],
						colorClass: 'text-uppercase font-weight-bold text-white',
					},
				];
			});
		},
	},

	created () {
		let OnSocket = setInterval(() => {
			if (this.StoreAll.websocket_host !== null) {
				clearInterval(OnSocket);

				window.Echo.channel(this.StoreAll.websocket_host).listen('.' + this.StoreAll.websocket_host, (e) => {
					if (e.socket_id === 26) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.DiscutOn();
							this.socket_ids.push(e.event_id);
						}
					}

					if (e.socket_id === 27) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.DiscutOn();
							this.socket_ids.push(e.event_id);
						}
					}

					if (e.socket_id === 28) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.DiscutOn();
							this.socket_ids.push(e.event_id);
						}
					}

					if (e.socket_id === 29) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.DiscutOn();
							this.socket_ids.push(e.event_id);
						}
					}
				});
			}}, 500);
	},

	mounted() {
		this.GetSession();
	},

	methods: {

		async GetSession() {
			await this.StoreAll.Get_Data();
			this.session = this.StoreAll.session; this.session_status = this.StoreAll.session.session_status.id;
			this.session_id = this.StoreAll.session.id; this.session_status_name = this.StoreAll.session.session_status.name;
			this.TimesNow();
			this.Session_timer();
			this.Hora();
			this.Discut_comp();
			setInterval(this.Hora, 1000);
		},

		TimesNow(){
			let verificarTimer = setInterval(() => {
				if (this.StoreAll.timers !== null) {
					clearInterval(verificarTimer);
					this.seconds = this.StoreAll.timers[0].Vereador_discussion.seconds;
					this.minutes = this.StoreAll.timers[0].Vereador_discussion.minutes;

				}
			}, 500);
		},

		Session_timer() {
			setTimeout(() => {
				this.Session_timer();
			}, 900);

			if(this.StoreAll.quorum.length <= 0){
				return;
			}

			let inicio = new Date(this.StoreAll.quorum[0].created_at).getTime();;
			let fim = new Date().getTime();;
			let diferenca = fim - inicio;
			let hours = Math.floor((diferenca % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			let minutes = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60));
			let seconds = Math.floor((diferenca % (1000 * 60)) / 1000);
			let timer_session = hours + 'h: ' + minutes + 'm: ' + seconds + 's';
			this.timer_session = timer_session;
		},

		Hora() {
			let date_time = new Date();
			let dia = date_time.getDate();
			let mesesEmPortugues = [
				'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
				'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
			];
			let mes = mesesEmPortugues[date_time.getMonth()];

			let minutes = date_time.getMinutes().toString().padStart(2, '0');
			let hours = date_time.getHours().toString().padStart(2, '0');
			let seconds = date_time.getSeconds().toString().padStart(2, '0');

			this.time = `${hours}: ${minutes}: ${seconds}`;
			this.dia = dia;
			this.mes = mes;
		},

		async Quorum_data(){
			await this.StoreAll.Get_Data();
		},

		async Discut_comp(){
			let Document_discut_id = [];
			let Discut_id = [];
			let documents_lote = [];
			let documents_lote_name = [];

			if(!this.StoreAll.discut){
				return;
			}
			if(this.StoreAll.quorum == null){
				this.Quorum_data();
				return;
			}

			await AxiosAPI.get('/discussion?quorum_id='+this.StoreAll.quorum[0].id,{
				headers: {
					Authorization: 'Bearer '+this.StoreAll.accessToken,
				},
			}).then((resp) => {

				if (resp.data.data.length <= 0) {
					return;
				}

				resp.data.data.forEach(dataDiscussion => {
					if (dataDiscussion.status_discussion === '1') {
						documents_lote.push(dataDiscussion);
						Document_discut_id.push(dataDiscussion.document_id);
						Discut_id.push(dataDiscussion.id);
						documents_lote_name.push(dataDiscussion.document.name);
					}
				});

				this.document_lote = documents_lote_name;
				this.Document_discut_id = Document_discut_id;
				this.Discut_id = Discut_id;


				this.user_doc = documents_lote[0].document.authors;
				this.Document_discut_tipo =  documents_lote[0].document.document_category_id;
				this.Detail();
			});

		},

		Detail(){
			this.Document_discut_autor = this.user_doc;
			let elementos = this.document_lote;
			let LIMIT = 200;
			let names_d = [];

			for (let i = 0; i < elementos.length; i++) {
				if (elementos[i].length > LIMIT) {
					names_d.push(elementos[i].substring(0, 200) + '...');
				} else {
					names_d.push(elementos[i]);
				}
			}
			this.Document_discut_name = names_d;
			this.Author();
		},

		Author(){
			let Co_user = [];
			for (let i = 0; i < this.Document_discut_autor.length; i++) {
				Co_user.push(this.Document_discut_autor[i].nickname);
			}
			let result = Co_user.join(', ');

			this.autor = result;
		},

		async DiscutOn() {
			if (!this.voted) {
				await AxiosAPI.get('/discussion-users?discussion_id=' + this.Discut_id[0], {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				}).then((response) => {
					let discut_vereadores = [];
					let ArrayLess = [];

					for (let i = 0; i < response.data.data.length; i++) {
						discut_vereadores.push(response.data.data[i]);
					}

					for (let i = 0; i < discut_vereadores.length; i++) {
						const TalkDiscussion = discut_vereadores[i];
						if (response.data.data[i].status_fala_discussion === '1') {
							TalkDiscussion['state'] = 'F';
							this.StoreAll.Discut_User = TalkDiscussion.user.id;
							localStorage.setItem('id_user_tv_discut', TalkDiscussion.user.id);
							this.user_on = true;
							this.fala = true;
							this.f_fala = false;

							if(response.data.data[i].orador === 1){
								this.seconds = this.StoreAll.timers[0].Vereador_discussion.seconds;
								this.minutes = this.StoreAll.timers[0].Vereador_discussion.minutes;
							}

							if(response.data.data[i].orador === 2){
								this.seconds = this.StoreAll.timers[0].Vereador_reduzido_discussion.seconds;
								this.minutes = this.StoreAll.timers[0].Vereador_reduzido_discussion.minutes;
							}
						}

						if (response.data.data[i].status_fala_discussion === '2') {
							TalkDiscussion['state'] = 'FS';
						}
						ArrayLess.push(TalkDiscussion);
					}
					this.discut_vereadores = ArrayLess;
					this.CronometroStatus(ArrayLess);
				});
			}
		},

		CronometroStatus(value) {
			if (value.length <= 0) {
				return;
			}
			const TalkDiscussion = [];
			const FimDiscussion = [];

			value.forEach(Talk => {
				if (Talk.state === 'F') {
					TalkDiscussion.push(Talk);
				}

				if (Talk.state === 'FS') {
					FimDiscussion.push(Talk);
				}
			});

			if (TalkDiscussion.length >= 1) {
				this.timerOn = true;
				this.timer();
			}

			if (TalkDiscussion.length <= 0) {
				this.timerOn = false;
				this.ResetTimer(FimDiscussion);
			}

		},

		timer() {
			if (!this.timerOn) {
				return;
			}

			setTimeout(() => {
				this.timer();
			}, 1000);

			let sec = this.seconds--;
			let min = this.minutes;

			if (min == 0 && sec == 0) {
				this.seconds = 0;
				this.minutes = 0;
				this.cronometro = '';
				let text = 'Acabou seu tempo!';
				document.getElementById('timerzz').innerText = text;
				this.timerOn = false;
				this.TimerOver = true;
			} else {

				if (sec <= 9) {
					sec = '0' + sec;
					this.t = sec;
				}
				if (sec == 0) {
					this.seconds = 59;
					let min = this.minutes--;
					this.t = min;
				}
				if (min == 0 && sec <= 59) {
					document.getElementById('timerz').style.color = '#FF0000';
					document.getElementById('timerzz').style.color = '#FF0000';
				}

				this.cronometro = min + ' : ' + sec;
				this.t = sec;
			}
		},

		ResetTimer() {
			this.seconds = this.StoreAll.timers[0].Vereador_discussion.seconds;
			this.minutes = this.StoreAll.timers[0].Vereador_discussion.minutes;
			this.StoreAll.Discut_User = null;
		},

		Finalizar_fala(){
			const formData = new FormData();
			formData.append('discussion_id', this.Discut_id[0]);
			formData.append('user_id', localStorage.getItem('id_user_tv_discut'));
			formData.append('status_fala_discussion', 2);

			AxiosAPI.post('/discussion-users-upp', formData, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((resp) => {
				this.t = resp;
				this.ResetTimer();
			});
		},

		Sound () {
			let audio = new Audio(sound);
			audio.play();
		},
	}

};
</script>
<template>
  <div>
    <div>
      <div class="display_vh">
        <div>
          <div class="d-flex" style="border-top: 2px solid #005c9f;">
          </div>
          <div class="d-flex justify-content-start ml-4">
            <div>

              <div v-for="name in document_lote_colored" :key="name">

				<div style="width: 100%; white-space: normal; overflow: hidden; text-overflow: ellipsis;">
					<span v-for="part in name" :key="part" :class="part.colorClass" style="font-size: clamp(0.1vw, 1.3vw, 25px); font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"> 
						{{ part.text }} </span> 
				</div>
              </div>
            </div>
            </div>
          </div>

          <template v-if="user_on == false">

              <div style="margin-top: 4%">
                <div class="d-flex justify-content-center"> <span class="text-uppercase font-weight-bold" style="font-size: clamp(0vw, 3vw, 3vw); color: red;"> AGUARDANDO VEREADOR...  </span> </div>

                </div>
          </template>

          <template v-for="discut in discut_vereadores" v-else :key="discut">

              <div v-if="discut.state == 'F'" class="d-flex justify-content-center"
                style="width: 100%;">
                <div class="d-flex justify-content-center text-white" style="margin-top: 4%">
                  <div class="d-flex align-items-center flex-column display_cronometro">
                    <img :src=this.StoreAll.Dochost+discut.user.path_image class="text-center rounded-circle display_img"
                      style="object-fit: cover;" alt="user photo" />
                    <span class="text-center p-2"
                      style="font-size: clamp(0vw, 2.8vw, 3vw); font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">
                      {{ discut.user.nickname }} </span>
                  </div>
                  <div class="d-flex align-items-center">
                    <span id="timerz" style="font-size: clamp(0vw, 10vw, 10vw);"> {{ cronometro }} </span>
                    <span id="timerzz" class="text-uppercase font-weight-bold mt-7"
                      style="font-size: clamp(0vw, 3vw, 3vw); color: red"> </span>
                  </div>
                </div>
              </div>
            </template>

          </div>
        <div>
      </div>
    </div>

    <div class="d-flex justify-content-between">

      <div style="display: flex;">
        <span class="text-white text-center"
          style="font-size: clamp(1em, 1em + 1vw, 1.5em); width: 8.5rem; border-right: 1px solid">
          {{ dia }} <br />
          {{ mes }}
        </span>
        <span class="text-white d-flex justify-content-center"
          style="font-size:clamp(2em, 1em + 1vw, 1.5em); width: 12rem; margin-top: 1rem;">
          {{ time }}
        </span>
      </div>
      <div class="d-flex justify-content-center" style="width: 45%;">
        <div style="display: block; font-size:clamp(2em, 1em + 1vw, 1.5em); font-family: 'Old Standard TT', serif; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
          <span class="text-center text-white text-uppercase"> <b> AUTOR(ES): {{ autor.substring(0, 80) }}{{autor.length > 50 ? '...' : ''}} </b></span> 
          </div>
        </div>

      <div class="text-white text-center d-flex justify-content-center"
        style="border-left: 1px solid white; width: 15%; font-family: monospace; font-size: clamp(0.5em, 0.5em + 1vw, 1.5em);">
        Tempo de sessão <br />
        {{ timer_session }}
      </div>
    </div>
  </div>
</template>
<style>
.text-black {
  color: black;
}
.status_circle {
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.status_circle_active {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  border: 2px solid #0db80d;
  background-color: #0db80d;
}
.display_vh {
  height: clamp(0.1vw, 44.6vw, 855px);
}
.display_sign {
  width: clamp(0.1vw, 22.6vw, 410px);
}
.display_cronometro {
  width: clamp(0.1vw, 50vw, 600px);
}
.display_img {
  width: clamp(0.1vw, 20vw, 310px);
  height: clamp(0.1vw, 20vw, 310px);
}

.display_margin {
  margin-right: 12%;
}
@media only screen and (max-width: 1600px) {
  .display_vh {
    height: clamp(0.1vw, 43vw, 690px);
  }
  .display_sign {
    width: clamp(0.1vw, 25.6vw, 410px);
  }
  .display_cronometro {
    width: clamp(0.1vw, 30vw, 500px);
  }
  .display_margin {
    margin-right: 7%;
  }
}
@media only screen and (max-width: 1380px) {
  .display_vh {
    height: clamp(0.1vw, 43vw, 570px);
  }
  .display_sign {
    width: clamp(0.1vw, 28.6vw, 400px);
  }
  .display_margin {
    margin-right: 4%;
  }
}
@media only screen and (max-width: 1280px) {
  .display_vh {
    height: clamp(0.1vw, 43vw, 530px);
  }
  .display_sign {
    width: clamp(0.1vw, 29.6vw, 400px);
  }
  .display_margin {
    margin-right: 2%;
  }
}
</style>
