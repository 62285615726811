<template>
    <div>
        <ModalBigger ref="viewDocument"  style="z-index: 1000;">
            <template #header>
                <span class="text-dark"><strong> Enviar para segunda votação! </strong></span> 

                <div class="flex-start">
                    <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </template>

            <template #body>

                <div class="d-flex justify-content-center">
                    <span class="text-dark"> <strong> esse documento sera incluido na ordem do dia da sessão:&nbsp;</strong></span>
                    <span class="text-danger"> <strong> {{ this.$pinia.state.value.main.session.name }} </strong></span>
                </div>

            </template>

            <template #footer>
                <div class="d-flex justify-content-center">
                    <button type="button" class="btn btn-primary bg-danger mr-3" @click="$refs.viewDocument.closeModal()">
                        Voltar
                    </button>
                    <button type="button" class="btn btn-primary bg-success" @click="send_session()">
                        Confirmar
                    </button>
                </div>

          
            </template>
        </ModalBigger>

    </div>
</template>
    
<script>
import ModalBigger from "./ModalBigger.vue";
import { AxiosAPI } from "@/axios";

export default {
    components: {
        ModalBigger,
    },

    data() {
        return {
            carimbo_locale: 0,
            status_stamp: 1
        }
    },

    methods: {
      openModal() {
            this.$refs.viewDocument.openModal();
            this.status_stamp = 1
        },

        send_session(){
        const move_doc = {
          document_id: [this.$pinia.state.value.main.document_details.id],
          document_status_vote_id: 2,
          user_id: this.$pinia.state.value.main.user.id
        };
        AxiosAPI.post("/document/change-status",move_doc,{
             headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer `+this.$pinia.state.value.main.accessToken,
            }
          }).then(( resp )=> {
            if(resp.status === 200){

            let document_send = {
            session_id: this.$pinia.state.value.main.session.id,
            document_id: this.$pinia.state.value.main.document_details.id,
            ordem_do_dia: [1]
            }

            //
            console.log(document_send)
            const dat = JSON.stringify(document_send)

            AxiosAPI.post("/attach-document",dat, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer `+this.$pinia.state.value.main.accessToken,
                },
            })
            .then((resp) => {
            console.log(resp);
            if(resp.status === 200){
                this.showSuccess_secondvote();
                this.$refs.viewDocument.closeModal();
            }else{
                this.showError_secondvote();
                this.$refs.viewDocument.closeModal();
            }
            
            });
            }else{
                this.showError_secondvote();
                this.$refs.viewDocument.closeModal();
            }

        })
        },
    
    showSuccess_secondvote() {
      this.$toast.add({ severity: 'success', summary: 'Documento enviado para segunda votação', detail: 'Documento enviado para segunda votação com sucesso', life: 3000 });
    },
    showError_secondvote(){
        this.$toast.add({ severity: 'error', summary: 'Algum erro aconteceu!', detail: 'Um erro atrapalhou o envio, tente novamente ou entre em contato com o administrador', life: 3000 });
    }
    }
}


</script>
    
<style></style>