<script>
import { useMainStore, useBusStore } from '../../../stores/index';

export default {
	name: 'TvHeader',
	components: {},
	setup(){
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		StoreAll.Token();
		return{
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			brasao: '',
			session: { name: 'Aguarde o quorum ser iniciado...', },
			socket_ids: [],
		};
	},

	mounted() {
		this.GetSession();
		this.BrasaoAndName();
	},
  
	methods: {
		BrasaoAndName(){
			if(this.StoreAll.host == 'dev'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_bd.png');
        
			}else if(this.StoreAll.host == 'teste'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_bd.png');
        
			}else if(this.StoreAll.host == 'jc'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_jc.png');
        
			}else if(this.StoreAll.host == 'bodo'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_bd.png');
        
			}else if(this.StoreAll.host == 'pedroavelino'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_pa.png');
        
			}else if(this.StoreAll.host == 'pedrapreta'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_pp.png');
        
			}else if(this.StoreAll.host == 'campogrande'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_cg.png');
        
			}else if(this.StoreAll.host == 'parazinho'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_parazinho.png');
        
			}else if(this.StoreAll.host == 'local'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_angicos.png');
        
			}else if(this.StoreAll.host == 'jandaira'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_jd.png');
        
			}else if(this.StoreAll.host == 'triunfo'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_tp.png');      
      
			}else if(this.StoreAll.host == 'upanema'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_upanema.png');
        
			}else if(this.StoreAll.host == 'ja'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_ja.png');
        
			}else if(this.StoreAll.host == 'angicos'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_angicos.png');
        
			}else if(this.StoreAll.host == 'crv'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_crv.png');
        
			}else if(this.StoreAll.host == 'goianinha'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_goianinha.png');
			}
			else if(this.StoreAll.host == 'jucurutu'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_jucurutu.png');
			}
			else if(this.StoreAll.host == 'odb'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_odb.png');
			}
			else if(this.StoreAll.host == 'sm'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_sm.png');
			}
			else if(this.StoreAll.host == 'lp'){
				this.brasao = require('../../../assets/img/brasoes/BrasaoCamara_lagoapedra.png');
			}
		},

		async GetSession() {
			await this.StoreAll.Get_Data();
			this.session = this.StoreAll.session; this.session_status = this.StoreAll.session.session_status.id;
			this.session_id = this.StoreAll.session.id; this.session_status_name = this.StoreAll.session.session_status.name;
		},
	},
};
</script>
<template>
  <div>
    <div>
      <div class="d-flex ml-1">
        <img :src="brasao" class="justify-content-start mt-1 display_image" />
        <div style="display: grid">
          <span class="text-white ml-2" style="font-size: clamp(0.1vw, 2.5vw, 40px); font-weight: 700 !important"> {{ this.StoreAll.name_camara }}</span>
          <span class="text-white ml-2" style="font-size: clamp(0.1vw, 2vw, 40px); font-weight: 700 !important">{{
              session.name
          }}</span>
        </div>
        <h1 class="ml-auto p-2 mr-5 mt-3" style="color:#c7c700;">QUÓRUM</h1>
      </div>
    </div>
  </div>
</template>
<style>
.text-black {
  color: black;
}

.display_image {
  width: 160px;
  height: 130px;
}

@media only screen and (max-width: 1650px) {
  .display_image {
    width: 135px;
    height: 110px;
  }
}

@media only screen and (max-width: 1380px) {
  .display_image {
    width: 120px;
    height: 105px;
  }
}

@media only screen and (max-width: 1280px) {
  .display_image {
    width: 120px;
    height: 95px;
  }
}
</style>
