import { defineStore } from 'pinia';
import { AxiosAPI } from '@/axios';
import Cookie from 'js-cookie';

export const useMainStore = defineStore('main', {
	state: () => ({
		socket: null,
		desconect: null,
		SocketLink: '',
		Dochost: '',
		voteSocket: '',
		VoteConf: null,
		accessToken: null,
		socketToken: null,
		host: '',
		Ordem: false,
		Large_host: null,
		history_doc: false,
		parecer_procurador_pdf: null,
		name_camara: '',
		mobile: null,
		document_details: null,  /* Logica aplicada no componente  */
		parecershow: null,
		MultiSelectDocs: null,
		Leitura: false, /* Logica aplicada no componente */
		Leitura_prefeitura: false,
		carimbo_camara: null,
		carimbo_prefeitura: null,
		document_src: null, /* Logica aplicada no componente */
		document_category: null,
		parecer_src: null,
		parecer_id: null,
		doc_link: '',
		request_signature_key_comission: null,
		notific: false,
		notific_parecer: false,
		user: {},
		usersq: {},
		user_all: {},
		user_envio: {},
		vereadores: {},
		session: {},
		sessionToSend: [],
		sessionstory: {},
		comission: false,
		comissions: [],
		comission_justice: false,
		comission_health: false,
		comission_education: false,
		comission_farm: false,
		comission_human: false,
		comission_infra: false,
		comission_presidents: null,
		comission_vice_presidents: null,
		comission_relator: null,
		comission_user: null,
		document: null,
		doctrue: null,
		quorum: null,
		quorum_users: [],
		quorum_size: {},
		quorum_moment: false,
		in_quorum: null,
		quorumUsers: [],
		status_tribune: null,
		tribuna: false,
		tribuna_id: 0,
		cronometro_tribuna: 'Aguarde',
		in_tribuna: false,
		discut: false,
		disct: false,
		Discut_User: {},
		explanation: false,
		explanationUsers: {},
		OrdemUsers: [],
		votachion: [],
		total_votes: null,
		votes: {},
		voteoff: false,
		vote: false,
		vote_secret: false,
		result_secret: false,
		voted: false,
		voteResult: null,
		userVote: [],
		votedlegenda: false,
		votacon: false, /* verificar essa variavel */
		favorable: null,
		against: null,
		vista: null,
		allvotes: null,
		timers: null,
		/* framework */
		hideConfigButton: true,
	}),
	actions: {

		Login(value) {
			return new Promise((resolve, reject) => {
				AxiosAPI
					.post('/auth/login', {
						email: value.email,
						password: value.password
					})
					.then(resp => {
						Cookie.set('User', resp.data.data.token, { expires: 1 });
						Cookie.set('Socket', resp.data.data.token_socket, { expires: 1 });
						this.accessToken = resp.data.data.token;
						this.socketToken =  resp.data.data.token_socket;
						this.User();
						resolve(resp);
					})
					.catch(err => {
						reject(err);
					});
			});
		},

		Token() {
			if (Cookie.get('User') == null && this.accessToken == null) {
				return;
			} else if (Cookie.get('User') != null && this.accessToken == null) {
				this.accessToken = Cookie.get('User');
				this.socketToken = Cookie.get('Socket');
			} else {
				return;
			}
		},

		async User() {
			if (this.accessToken == null) {
				return;
			} else {
				await AxiosAPI.get('/user', {
					headers: {
						Authorization: 'Bearer ' + this.accessToken
					}
				})
					.then(response => {
						this.user = response.data;

						AxiosAPI.get('/comissions', {
							headers: {
								Authorization: 'Bearer ' + this.accessToken
							}
						})
							.then(resp => {

								let comissions_date = resp.data.data;
								const comissions = resp.data.data.map(obj => {
									const { comission_name, ...rest } = obj;
									return { id: obj.id, name: comission_name.trim(), ...rest, user_category_id: 9 };
								});

								this.comissions = comissions;

								for (const array of comissions_date) {

									for (const user of array.users) {

										if (user.id === this.user.id) {
											this.comission_user = true;
											break;
										}
									}
								}

							});
					});
			}
		},

		async UserAll() {
			if (this.accessToken == null) {
				return;
			} else {
				let UserALL = [];
				await AxiosAPI.get('/users', {
					headers: {
						Authorization: 'Bearer ' + this.accessToken
					},
				})
					.then((response) => {
						for (let i = 0; i < response.data.length; i++) {
							if (response.data[i].status_user == 2 || response.data[i].status_user == 3) {
								continue;
							} else {
								UserALL.push(response.data[i]);
							}
						}
						this.user_all = UserALL;
					});

			}
		},

		async UserAllEnvio() {
			if (this.accessToken == null) {
				return;
			} else {
				let UserALLE = [];
				await AxiosAPI.get('/users', {
					headers: {
						Authorization: 'Bearer ' + this.accessToken
					},
				})
					.then((response) => {
						for (let i = 0; i < response.data.length; i++) {
							if (response.data[i].status_user == 2) {
								continue;
							} else {
								UserALLE.push(response.data[i]);
							}
						}
						this.user_envio = UserALLE;
					});
			}
		},

		async Get_Data() {
			if (this.accessToken == null) {
				return;
			} else {
				await AxiosAPI.get('/sessions', {
					headers: {
						Authorization: 'Bearer ' + this.accessToken
					}
				}).then((resp) => {
					const sessionsOn = [];
					resp.data.data.forEach(element => {
						if (element.session_status_id === 1 || element.session_status_id === 2) {
							sessionsOn.push(element);
						}
					});

					if (sessionsOn.length === 0) {
						let size = resp.data.data.length;
						sessionsOn.push(resp.data.data[size - 1]);
					}
					console.log(sessionsOn);
					this.sessionToSend = sessionsOn;
					this.session = sessionsOn[0];
					/* Nessa situação, caso haja mais de uma sessão aguardando inicio ou já iniciada
					   iremos utilizar a sessão com o id mais baixo, que representaria a sessão "mais antiga" */

					/* Caso não exista nenhuma sessão em aberto ou em andamento, pegamos o valor da sessão mais nova. */

					/* Solicitar a Daniel que remova a cron que faz as sessões todas serem encerradas após criar uma nova */



					/* let size = resp.data.data.length;
					let sessions = resp.data.data[size - 1]; */

					/* this.session = sessions; */
				});
			}

			await AxiosAPI.get('/quorum?session_id=' + this.session.id, {
				headers: {
					Authorization: 'Bearer ' + this.accessToken,
				},
			})
				.then((response) => {
					console.log(this.session.id);
					console.log(response);
					let quorum = response.data.data;
					this.quorum = quorum;
				});

		},

		async DocCategory() {

			await AxiosAPI.get('/documents-categories', {
				headers: {
					Authorization: 'Bearer ' + this.accessToken,
				},
			})
				.then((response) => {
					let data = response.data.data;
					let select = { id: 0, name: 'Selecione o tipo do documento' };
					data.push(select);

					function compararPorId(a, b) {
						return a.id - b.id;
					}

					data.sort(compararPorId);
					this.document_category = data;
				});

		},

		async GetSessionStory() {
			if (this.accessToken == null) {
				return;
			} else {
				await AxiosAPI.get('/sessions?id=' + localStorage.getItem('Session_id'), {
					headers: {
						Authorization: 'Bearer ' + this.accessToken
					}
				}).then((resp) => {
					let session = resp.data.data[0];
					this.sessionstory = session;
				});
			}
		},

		VerifySession() {
			return new Promise((resolve) => {
				AxiosAPI
					.get(
						'/quorum?session_id=' + this.session.id,
						{
							headers: {
								Authorization: 'Bearer ' + this.accessToken,
							},
						}
					)
					.then((response) => {
						resolve(response.data.data);
					});
			});
		},

		GetDocument() {
			return new Promise((resolve) => {
				let OnSystem = setInterval(() => {
					if (this.session.id !== undefined) {
						clearInterval(OnSystem);
						AxiosAPI
							.get(
								'/documents?session_id=' +
                this.session.id +
                '&document_status_vote_id=4',
								{
									headers: {
										Authorization: 'Bearer ' + this.accessToken,
									},
								}
							)
							.then((response) => {
								resolve(response.data.data);

								if (this.document != null) {
									if (this.document.document_status_vote_id === 5) {
										this.voteResult = null;
										this.favorable = 0,
										this.against = 0,
										this.vista = 0,
										this.voted = null,
										this.document = [];
										this.userVote = [];
									} 
								}

								if (response.data.data[0] != undefined) {
									this.document = response.data.data[0];

									response.data.data.forEach(documents => {
										documents.document_sessions.forEach(Secret => {
											if (Secret.secret_vote === true) {
												this.vote_secret = true;
											} else {
												this.vote_secret = false;
											}
										});
									});
								}  else {
									this.document = null;
								}
              
							});
					}
				}, 500);

			});

		},

		GetDocumentEndVote(value) {
			return new Promise((resolve) => {
				AxiosAPI
					.get(
						'/documents?id=' + value,{
							headers: {
								Authorization: 'Bearer ' + this.accessToken,
							},
						}
					)
					.then((response) => {
						resolve(response.data.data);
						if (response.data.data[0] != undefined) {
							this.document = response.data.data[0];

							response.data.data.forEach(documents => {
								documents.document_sessions.forEach(Secret => {
									if (Secret.secret_vote === true) {
										this.vote_secret = true;
									} else {
										this.vote_secret = false;
									}
								});
							});
						}
					});
			});
		},

		async GetDocumentSecret() {
			await AxiosAPI.get('/session/' + this.session.id + '/document/' + this.document.id,
				{
					headers: {
						Authorization: 'Bearer ' + this.accessToken,
					},
				}
			)
				.then((response) => {
					if (response.data.data.secret_vote === true) {
						this.vote_secret = true;
					} else {
						this.vote_secret = false;
					}
				});
		},

		async Notification() {
			if (this.accessToken == null) {
				return;
			} else {
				await AxiosAPI
					.get('/documents-notification', {
						headers: {
							Authorization: 'Bearer ' + this.accessToken,
						},
					})
					.then((response) => {

						if (response.data.data.length <= 0) {
							this.notific = false;
						} else {
							this.notific = true;
						}

					});

				AxiosAPI
					.get('/documents-notification-comission', {
						headers: {
							Authorization: 'Bearer ' + this.accessToken,
						},
					})
					.then((response) => {
						if (response.data.data.length <= 0) {
							this.notific_parecer = false;
						} else {
							this.notific_parecer = true;
						}

					});
			}
		},

		SubmitDoc(payload) {
			return new Promise((resolve, reject) => {
				const formData = new FormData();
				let protocol_number = 0;
				if (payload.authors == undefined) {

					let authors = payload.comission_id;
					if (payload.protocol_number == '') {
						protocol_number = 0;
					} else {
						protocol_number = payload.protocol_number;
					}
					formData.append('name', payload.name);
					formData.append('attachment', payload.attachment);
					formData.append('protocol_number', protocol_number);
					formData.append('document_category_id', payload.document_category_id);
					formData.append('document_status_vote_id', payload.document_status_vote_id);
					formData.append('document_status_movement_id', payload.document_status_move_id);
					formData.append('comission_id', authors);
					formData.append('status_sign', payload.status_sign);
					/* formData.append("coauthors", coautorJSON); */

					AxiosAPI
						.post('/documents', formData, {
							headers: {
								'Content-Type': 'application/json',
								Authorization: 'Bearer ' + this.accessToken,
							}
						}).then((response) => {
							this.doctrue = response;
							resolve(response);
						})
						.catch(err => {
							reject(err);
						});
				} else {
					let authors = payload.authors;
					let authorsJSON = JSON.stringify(authors);

					formData.append('name', payload.name);
					formData.append('attachment', payload.attachment);
					formData.append('protocol_number', payload.protocol_number);
					formData.append('document_category_id', payload.document_category_id);
					formData.append('document_status_vote_id', payload.document_status_vote_id);
					formData.append('document_status_movement_id', payload.document_status_move_id);
					formData.append('authors', authorsJSON);
					formData.append('status_sign', payload.status_sign);
					/* formData.append("coauthors", coautorJSON); */

					AxiosAPI
						.post('/documents', formData, {
							headers: {
								'Content-Type': 'application/json',
								Authorization: 'Bearer ' + this.accessToken,
							}
						}).then((response) => {
							this.doctrue = response;
							resolve(response);
						})
						.catch(err => {
							reject(err);
						});
				}
			});
		},

		async getTimers() {
			await AxiosAPI
				.get('/timers', {
					headers: {
						Authorization: 'Bearer ' + this.accessToken,
					},
				})
				.then((response) => {

					let timer = [{
						Subscriber_tribune: { minutes: 0, seconds: 0 },
						Vereador_tribune: { minutes: 0, seconds: 0 },
						Vereador_lider_tribune: { minutes: 0, seconds: 0 },
						Convidado_tribune: { minutes: 0, seconds: 0 },
						Extra_vereador: { minutes: 0, seconds: 0 },
						Extra_convidado: { minutes: 0, seconds: 0 },
						Apartiamento_tribune: { minutes: 0, seconds: 0 },
						Apartiamento_reduzido_tribune: { minutes: 0, seconds: 0 },
						Vereador_discussion: { minutes: 0, seconds: 0 },
						Vereador_reduzido_discussion: { minutes: 0, seconds: 0 },
						Vereador_BigDiscussion: { minutes: 0, seconds: 0 },
						Vereador_Ordem: { minutes: 0, seconds: 0 },
						Prefeitura_tribune: { minutes: 0, seconds: 0 },
					}];


					for (let i = 0; i < response.data.data.length; i++) {
						const data = response.data.data[i];
						const timeParts = data.timer.split(':');
						const minutes = parseInt(timeParts[1]);
						const seconds = parseFloat(timeParts[2]);


						if (timer[0][data.locale]) {
							timer[0][data.locale].minutes = minutes;
							timer[0][data.locale].seconds = seconds;
						}

					}

					this.timers = timer;
				});
		},

	},

});

export const useSocketStore = defineStore('socket_all', {
	state: () => ({
		socket_id_nominalvote: [],		
	}),
	actions: {},

});

export const useBusStore = defineStore('Bus', {
	state: () => ({
		AxexoSuccess: null,
		AxexoSecretario: null,
		docSendSession: null,
		RemoveParecer: null,
		RemoveParecerView: null,
		RemoveParecerModalView: null,
		GetSessionStart: null,
		ModalVoteOn: false,

		nominalVote: null,

		tribuneEmitModalOne: null,
		tribuneEmitModalTwo: null,
		tribuneEmitModalThree: null,

		votePresidente: null,
	}),
	actions: {},

});
