<script>
import { useMainStore } from '../../stores/index';
export default {
	name: 'DocLegs',
	components: {},
	setup(){
		const StoreAll = useMainStore();
		StoreAll.Token();
		return{
			StoreAll: StoreAll,
		};
	},
	data() {
		return {
			url_doc: '',
		};
	},
	mounted() {
		this.GetUser();
		this.GetSession(); 
	},

	methods: {
    
		async GetUser(){
			await this.StoreAll.User(); 
		},

		async GetSession() {
			await this.StoreAll.Get_Data();
			this.link();
		},

		link(){
			if(this.StoreAll.host == 'dev'){
				this.url_doc = 'https://sandbox.smartlegis.net.br/storage/Regimento_Joao_Camara.pdf';
			}else if(this.StoreAll.host == 'teste'){
				this.url_doc = 'https://sandbox.smartlegis.net.br/storage/Regimento_Joao_Camara.pdf';
			}else if(this.StoreAll.host == 'jc'){
				this.url_doc = 'https://sandbox.smartlegis.net.br/storage/Regimento_Joao_Camara.pdf';
			}else if(this.StoreAll.host == 'local'){
				this.url_doc = 'https://sandbox.smartlegis.net.br/storage/Regimento_Joao_Camara.pdf';
			}else if(this.StoreAll.host == 'bodo'){
				this.url_doc = 'bd';
			}else if(this.StoreAll.host == 'pedroavelino'){
				this.url_doc = 'pa';
			}else if(this.StoreAll.host == 'pedrapreta'){
				this.url_doc = 'https://sandbox.smartlegis.net.br/storage/Regimento_Pedra_Preta.pdf';
			}else if(this.StoreAll.host == 'campogrande'){
				this.url_doc = 'cg';
			}else if(this.StoreAll.host == 'parazinho'){
				this.url_doc = 'parazinho';
			}else if(this.StoreAll.host == 'jandaira'){
				this.url_doc = 'https://sandbox.smartlegis.net.br/storage/Regimento_Jandaira.pdf';
			}else if(this.StoreAll.host == 'triunfo'){
				this.url_doc = 'https://sandbox.smartlegis.net.br/storage/Regimento_Triunfo_Potiguar.pdf';
			}else if(this.StoreAll.host == 'ja'){
				this.url_doc = 'ja';
			}else if(this.StoreAll.host == 'upanema'){
				this.url_doc = 'upanema';
			}else if(this.StoreAll.host == 'angicos'){
				this.url_doc = 'angicos';
			}
		}

	},
};
</script>
<template>
    <div class="py-4 h-100 container-fluid">
      <div class="row selectdoc">
        <div class="text-center col-12">
          <h3 class="mt-auto text-white">Documentos do legislatio</h3>
          <div class="mb-5 multisteps-form">
            <div class="row">
              <div class="m-auto col-12 col-lg-8">
                <div>
                  <div
                    class="
                      p-3
                      bg-white
                      card
                      multisteps-form__panel
                      border-radius-xl
                      js-active
                      position-relative
                    "
                    data-animation="FadeIn"
                  >
                    <div class="col-lg-12">
                      <div class="row d-flex justify-content-center">
                        <div class="text-center row">
                          <div class="mx-auto col-10">
                            <h5 class="font-weight-normal">
                              Faça o download dos documentos abaixo!
                            </h5>
                          </div>
                        </div>
                        
                        <div
                        class="card"
                        style="width: 15.5rem; cursor: pointer"
                      >
                      <a target="_blank" :href="url_doc"
                        download="proposed_file_name">
                      <div>
                        <img
                          class="card-img-top"
                          style="width: 8.3rem"
                          src="../../assets/img/pdfimage.png"
                          alt="Card image cap"
                        />
                      </div>
                        <div class="card-body">
                          <h5 class="card-title" style="font-size: 16px">
                            Regimento interno
                          </h5>
                        </div>
                      </a>
                      </div>        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <style>
  
  </style>