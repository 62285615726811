<script>
import ModalBig from '../../components/documents/Modals/ModalBig.vue';
import { AxiosAPI } from '@/axios';
import ParecerExternal from './ParecerExternal.vue';
import ResultExternal from './ResultExternal.vue';
import Pdf from './Pdf.vue';
import { saveAs } from 'file-saver';

export default {
	components: {
		ModalBig,
		ParecerExternal,
		Pdf,
		ResultExternal
	},

	data() {
		return {
			status_vote_doc: 'Aguarde ...',
			onlypdf: '',
			unique_vote: null,
			Docs_recp: [],
			votes: [],
			result_secret: null,
			favorable: 0,
			against: 0,
			vista: 0,
			parecershow: false,
			parecer_procurador_pdf: false,
			document_details: [],
			document_src: '',
			HostDoc: '',
			host: '',
			token: ''
		};
	},


	methods: {
		openModal2(event) {	
			this.status_vote_doc= 'Aguarde ...';
			this.onlypdf= '';
			this.unique_vote= null;
			this.Docs_recp= [];
			this.votes= [];
			this.result_secret= null;
			this.favorable= 0;
			this.against= 0;
			this.vista= 0;
			this.parecershow= false;
			this.parecer_procurador_pdf = false;
			this.document_details= [];
			this.document_src= '';
			this.HostDoc= '';
			this.host= '';
			this.token= '';
            


			this.document_details = event[0];
			this.token = event[1];
			this.document_src = event[2];
			this.HostDoc = event[3];
			this.host = event[4];
			this.parecer_procurador_pdf = event[5];
			this.parecershow = false;
			this.getVotes();
			this.getParecer();
			this.$refs.viewDocument.openModal();
			this.Docs_recp = event;
		},

		Show_getParecer() {
			let data = [];
			data.push(this.host);
			data.push(this.document_details);
			data.push(this.token);
			data.push(this.parecer_procurador_pdf);
			data.push(this.HostDoc);
			this.$refs.ParecerExternal.openModal(data);
		},
		Show_votes() {
			let data = [];
			data.push(this.votes);
			data.push(this.result_secret);

			this.$refs.ResultExternal.openModal(data);
		},

		
		async getVotes() {
			this.unique_vote = null;
			await AxiosAPI.get('/external/votes?document_id=' + this.document_details.id, {
				headers: {
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				let arraydata = response.data.data;
				this.votes = arraydata;
				let data_array = [];
				let favorable = 0, against = 0, vista = 0;
				for (let i = 0; i < arraydata.length; i++) {
					let obj = {};
					obj.vote_category_id = arraydata[i].vote_category_id;
					obj.user_id = arraydata[i].user_id;
					data_array.push(obj);
					switch (obj.vote_category_id) {
					case 1:
						favorable++;
						break;
					case 2:
						against++;
						break;
					case 3:
						vista++;
						break;
					default:
						console.log('ERRO! TIPO DE VOTO NÃO RECONHECIDO' + favorable + against + vista);
					}
				}
				/* Logica para caso não possua segunda votação ainda*/

				for (let i = 0; i < arraydata.length; i++) {
					if (arraydata[i].order === 2) {
						this.unique_vote = true;
					}
				}

				this.favorable = favorable;
				this.against = against;
				this.vista = vista;


				if (this.favorable > this.against) {
					this.status_vote_doc = 'Aprovado';
				} else if (this.against > this.favorable) {
					this.status_vote_doc = 'Reprovado';
				} else if (this.favorable == 0 && this.against == 0) {
					this.status_vote_doc = 'Aguarde ...';
				} else {
					this.status_vote_doc = 'Aguarde ...';
				}

				if (response.data.data.length >= 1) {
					if (response.data.data[0].document_session !== null) {
						this.result_secret = response.data.data[0].document_session.secret_vote;
					}
				}			

			});
		},

		async Document_64() {
			await AxiosAPI.get('/external/documents/' + this.document_details.id + '/votes', {
				headers: {
					Authorization: 'Bearer ' + this.token,
				},
			})
				.then((response) => {
					this.onlypdf = 'data:application/pdf;base64,' + response.data.data;
					this.Pdf_Download();
				});
		},

		async Pdf_Download() {
			const pdf = await fetch(this.onlypdf);
			const blob = await pdf.blob();
			saveAs(blob, 'Documento_Completo' + '.pdf');
		},

		async getParecer() {
			let parecer = {
				1: [], // education
				2: [], // farm
				3: [], // infra
				4: [], // health
				5: [], // human
				6: []  // justice
			};

			if (this.parecer_procurador_pdf == null) {
				this.parecershow = false;
			} else {
				this.parecershow = true;
			}

			await AxiosAPI
				.get('/external/parecer-comissao?document_id=' + this.document_details.id, {
					headers: {
						Authorization: 'Bearer ' + this.token,
					},
				})
				.then((response) => {
					for (let i = 0; i < response.data.parecer.length; i++) {
						const comission_id = response.data.parecer[i].comission_id;
						if (parecer[comission_id]) {
							parecer[comission_id].push(response.data.parecer[i]);
						}
					}

					let parecer_education = parecer[1][0]?.parecer_pdf || null;
					let parecer_farm = parecer[2][0]?.parecer_pdf || null;
					let parecer_infra = parecer[3][0]?.parecer_pdf || null;
					let parecer_health = parecer[4][0]?.parecer_pdf || null;
					let parecer_human = parecer[5][0]?.parecer_pdf || null;
					let parecer_justice = parecer[6][0]?.parecer_pdf || null;

					if (parecer_education != null) { this.parecershow = true; }
					if (parecer_farm != null) { this.parecershow = true; }
					if (parecer_infra != null) { this.parecershow = true; }
					if (parecer_health != null) { this.parecershow = true; }
					if (parecer_human != null) { this.parecershow = true; }
					if (parecer_justice != null) { this.parecershow = true; }


				})
				.catch((error) => {
					console.error(error);
				});
		},

	}
};
</script>
<template>
    <div>
        <ParecerExternal ref="ParecerExternal"></ParecerExternal>
        <ResultExternal ref="ResultExternal"></ResultExternal>
    </div>
    <div>
        <ModalBig ref="viewDocument">
          <!--  eslint-disable-next-line vue/no-useless-template-attributes -->
            <template #header style="display: block !important;">
                <div class="start">
                    <div class="d-flex justify-content-between">
                        <div>
                            <strong>
                                <span>{{ document_details.name }}</span>
                            </strong>
                        </div>
                        <div class="">
                            <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>


                <div v-if="document_details.document_status_vote_id == 5">
                    <div class="end d-flex">
                        <div class="d-flex justify-content-start" style="float: right; width: 50%;">
                            <button v-if="document_details.document_status_vote_id == 5"
                                type="button" class="btn btn-success" @click="Show_votes()">
                                Ver resultado <i class="ml-2 fa fa-check-circle"></i>
                            </button>
                            <span v-if="document_details.document_status_vote_id == 5"
                                class="mt-3 ml-2"> <strong> Situação: </strong></span>
                            <span v-if="document_details.document_status_vote_id == 5"
                                class="mt-3 ml-2"
                                :class="{ 'text-success': status_vote_doc == 'Aprovado', 'text-danger': status_vote_doc == 'Reprovado', 'text-dark': status_vote_doc == 'Aguarde ...' }">
                                <strong>{{ status_vote_doc }}</strong></span>
                        </div>
                        <div class="d-flex justify-content-end" style="float: left; width: 50%;">
                            <button v-if="this.parecershow === true" type="button"
                                class="btn btn-success " @click="Show_getParecer()">
                                Ver parecer <i class="ml-2 fa fa-file-import"></i>
                            </button>

                        </div>

                    </div>
                    <button
                        v-if="document_details.document_status_vote_id >= 5 && document_details.document_status_vote_id != 6"
                        type="button" class="btn text-white" @click="Document_64();"
                        style="background-color: #49a571;">Baixar documento completo</button>
                    <a v-if="document_details.document_status_vote_id < 5 || document_details.document_status_vote_id == 6"
                        target="_blank"
                        :href="HostDoc + document_src"
                        download="proposed_file_name">
                        <button type="button" class="btn text-white" style="background-color: #49a571;">Baixar documento</button>
                    </a>
                </div>



                <div v-else class="end d-flex">
                    <div class="d-flex justify-content-start" style="float: right; width: 50%;">
                        <button
                            v-if="document_details.document_status_vote_id >= 5 && document_details.document_status_vote_id != 6"
                            type="button" class="btn text-white" @click="Document_64();"
                            style="background-color: #49a571;">Baixar documento completo</button>
                        <a v-if="document_details.document_status_vote_id <= 5 || document_details.document_status_vote_id == 6"
                            target="_blank"
                            :href="HostDoc + document_src"
                            download="proposed_file_name">
                            <button type="button" class="btn text-white" style="background-color: #49a571;">Baixar
                                documento</button>
                        </a>
                    </div>
                    <div class="d-flex justify-content-end" style="float: left; width: 50%;">
                        <button v-if="this.parecershow === true" type="button"
                            class="btn btn-success " @click="Show_getParecer()">
                            Ver parecer <i class="ml-2 fa fa-file-import"></i>
                        </button>

                    </div>

                </div>

            </template>

            <template #body>
                <div>
                    <div>
                        <Pdf :token="this.token" :HostDoc="this.HostDoc" :DocSrc="this.document_src"/>
                    </div>
                </div>

            </template>

            <template #footer>
                <div>
                    <a v-if="document_details.document_status_vote_id >= 5 && document_details.document_status_vote_id != 6"
                        download="proposed_file_name">
                        <div class="d-flex justify-content-center">
                            <h3 style="cursor: pointer;" @click="Document_64();">Baixar o Documento completo</h3>
                        </div>
                    </a>
                    <a v-if="document_details.document_status_vote_id < 5 || document_details.document_status_vote_id == 6"
                        target="_blank"
                        :href="HostDoc + document_src"
                        download="proposed_file_name">
                        <div class="d-flex justify-content-center">
                            <h3>Baixar o Documento</h3>
                        </div>
                    </a>
                </div>
            </template>
        </ModalBig>

    </div>
</template>
<style>.swal2-container {
    z-index: 10001 !important;
}

.close {
    font-size: 2.3rem !important;
}</style>