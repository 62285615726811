<script>
  import ModalBigger from "./ModalBigger.vue";
  import {AxiosAPI} from "@/axios"
  import { useBusStore } from "../../../stores/index";
  import { useMainStore } from "../../../stores/index";
  export default {
    components : {
         ModalBigger
      },
      setup() {
        const StoreAll = useMainStore();
        const Bus = useBusStore();
        return {
          StoreAll: StoreAll,
          Bus
        }
      },
      data() {
        return {
        tribuna_vereador: [],
        t: null, 
        socket_ids:[],
        }
      },

	created () {
    let OnSystem = setInterval(() => {
			if (this.StoreAll.websocket_host !== null) {
				clearInterval(OnSystem);
          window.Echo.channel(this.StoreAll.websocket_host).listen('.' + this.StoreAll.websocket_host, (e) => {
            if (e.socket_id === 11) {
              if (this.socket_ids.includes(e.event_id)) {
                return;
              } else {
                this.GetTribunaUsers_websocket();
                this.socket_ids.push(e.event_id);  
              }
            }

            if (e.socket_id === 12) {
              if (this.socket_ids.includes(e.event_id)) {
                return;
              } else {
                this.close_tribuna_end();
                this.socket_ids.push(e.event_id);  
              }
            }
          });
  }}, 500);
	},
      methods: {
        openModal2() {
            this.$refs.viewDocument.openModal();
            this.GetTribunaUsers_websocket();
        },
        finallyloading() {
        document.getElementById("loads").style.display = "flex";
        document.getElementById("sim_tribune").style.display = "none";
        document.getElementById("nao_tribune").style.display = "none";
        document.getElementById("fim_tribune").style.display = "none";
      },
  
        subscription(id){
          if(id == 1){
  
            const inscrit = {
              tribune_id: this.StoreAll.tribuna_id,
              users_id: [this.StoreAll.user.id],
              status_fala: 1
          };
  
           AxiosAPI.post('/tribune-users',inscrit,{
                  headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer `+ this.StoreAll.accessToken
                  } 
                  }).then((response)=>{if(response.status == 200){
                      this.StoreAll.in_tribuna = true;
                      this.Bus.tribuneEmitModalOne = true;
                  }})
                  
  
                  
          }else if(id == 2){
              this.StoreAll.in_tribuna = true;
              this.Bus.tribuneEmitModalTwo = true;      
          }
        },
        
      closers(){
        this.$refs.viewDocument.closeModal();
      },
  
      close_tribuna_end(){
        try{
          this.$refs.viewDocument.closeModal();
        }catch{
          console.log('já fechado')
        }
      },
  
      
     async GetTribunaUsers_websocket(){
          if(this.StoreAll.user.user_category_id != 1){
            if(this.StoreAll.tribuna_id == 0){
              return
            }else{         
            await AxiosAPI.get('/tribune-users?tribune_id='+this.StoreAll.tribuna_id+'&status_fala=1',{
              headers: {
                Authorization: `Bearer `+ this.StoreAll.accessToken
              }
          }).then((response)=>{
              this.tribuna_vereador = response.data.data      
          })
        }}else{return}
      },
       
      }
  }
</script>
<template>
  <div>
   <ModalBigger ref="viewDocument">
        <template #header>
          <div class="status_label">
                <strong><span class="ml mr title">Inscrição para presidente</span></strong>
            </div>
          <div class="flex-start">
  
                          <button
                          id="close"
                          type="button"
                          class="close"
                          @click="$refs.viewDocument.closeModal()"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
          </div>
        </template>
  
        <template #body>
  
           <div class="d-flex justify-content-center"> 
          <button type="button" class="btn btn-success mb-1  mr-3" 
          style="width: 150px; height: 60px;" @click="subscription(1); $refs.viewDocument.closeModal()">Sim</button>
  
          <button type="button" class="btn btn-danger mb-1" 
          style="width: 150px; height: 60px;" @click="subscription(2); $refs.viewDocument.closeModal()">Não</button>
          </div>
  
        </template>
        <template #footer>
  
        </template>
    </ModalBigger>
  
    <div
                  class="modal fade tribunaconf"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="mySmallModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-sm">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h6>STATUS INSCRIÇÃO DA TRIBUNA</h6>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          @click="finallyloading()"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
  
                      <div class="modal-body">
                        <div
                          id="loads"
                          class="justify-content-center"
                          style="display: none"
                        >
                          <div class="spinner-grow text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                          <div class="spinner-grow text-secondary" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                          <div class="spinner-grow text-success" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                          <div class="spinner-grow text-danger" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                          <div class="spinner-grow text-warning" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                          <div class="spinner-grow text-info" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                          <div class="spinner-grow text-dark" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
  
                        <div
                          id="sim_tribune"
                          class="justify-content-center"
                          style="display: none"
                        >
                          <span
                            class="
                              justify-content-center
                              text-uppercase
                              font-weight-bold
                            "
                            style="font-size: 13px; color: green"
                          >
                            Você foi inscrito na tribuna!
                          </span>
                        </div>
  
                        <div
                          id="nao_tribune"
                          class="justify-content-center"
                          style="display: none"
                        >
                          <span
                            class="
                              justify-content-center
                              text-uppercase
                              font-weight-bold
                            "
                            style="font-size: 13px; color: green"
                          >
                            Não adicionado a tribuna.
                          </span>
                        </div>
  
                         <div
                          id="fim_tribune"
                          class="justify-content-center"
                          style="display: none"
                        >
                          <span
                            class="
                              justify-content-center
                              text-uppercase
                              font-weight-bold
                            "
                            style="font-size: 13px; color: green"
                          >
                            Inscrições finalizadas!
                          </span>
                        </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <style>
  .swal2-container {
    z-index: 10001 !important;
  }
  .close{
    font-size: 2.3rem !important;
  }
  </style>