<script>
import { AxiosAPI } from '@/axios';
import moment from 'moment';
import { useMainStore } from '../../stores/index';
import Loading from '../../components/documents//Modals/Loading.vue';;
export default {
	name: 'DataTables',
	components: {
		Loading
	},
	setup(){
		const StoreAll = useMainStore();
		StoreAll.Token();
		return{
			StoreAll: StoreAll,
		};
	},

	data() {
		return {
			session_id: '',
			History: [],
			FilterType: [ {id: 0, label: 'Data'}, {id: 1, label: 'Sessão'} ],
			FilterTypeModel: 0,
			dateStart: '',
			dateEnd: '',
			SessionStart: '',
			SessionEnd: '',
			pdfData: ''
		};

	},
	mounted() {
		this.get_History();
		this.GetSession();
		this.User();
		this.GetUsersAll();
	},
	methods: {

		async GetSession() {
			await this.StoreAll.Get_Data();
			this.session_id = this.StoreAll.session.id;
		},

		async User(){
			await this.StoreAll.User();
			this.category_user = this.StoreAll.user.user_category_id;
		},

		async GetUsersAll() {
			await this.StoreAll.UserAllEnvio();
			this.vereadores = this.StoreAll.user_envio;
		},

		get_History() {
			AxiosAPI
				.get('/sessions', {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken
					}
				})
				.then((resp) => {
					this.History = resp.data.data;
				});
		},

		formatDate(value) {
			moment.locale('pt-br');
			return moment(value).format('DD/MMMM/YYYY');
		},

		editProduct(event) {
			localStorage.setItem('Session_id', event.id);
		},

		DownloadRelatorio () {
			document.getElementById('CloseRelatorioSession').click();
			this.$refs.Loading.openModal();
			let params = {};
			if (this.FilterTypeModel === 0) {
				params = { start_date: this.dateStart, end_date: this.dateEnd };
			}
			if (this.FilterTypeModel === 1) {
				params = { start_session_id: this.SessionStart, end_session_id: this.SessionEnd };
			}
			AxiosAPI.get('/reports/sessions', {
				params,
				headers: {
					authorization: 'Bearer ' + this.StoreAll.accessToken,
				},
			}).then((response) => {
				if (response.status === 200) {
					const linkSource = `data:application/pdf;base64,${response.data.data}`;
					const downloadLink = document.createElement('a');
					const fileName = 'Relatorio.pdf';
					downloadLink.href = linkSource;
					downloadLink.download = fileName;
					downloadLink.click();
					this.$refs.Loading.closeModal();
					this.$toast.add({ severity: 'success', summary: 'Relatório gerado!', detail: 'O Relatório foi gerado', life: 5000 });
				}
			}) .catch((error) => {
				if (error.response.status === 400) {
					this.$refs.Loading.closeModal();
					this.$toast.add({severity: 'info', summary: 'Erro', detail: 'Não foi possível gerar o Relatório', life: 5000});
				}
			});
		}

	}
};
</script>
<template>
  <div class="container-fluid">
	<div style="z-index: 1010 !important;"><Loading ref="Loading"></Loading></div>
	<a download="Relatório" :href="pdfData" id="downloadRelatorioLink" class="d-none"></a>
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">Aqui você consegue visualizar as sessões já realizadas!</h5>
            <p class="mb-0 text-sm">
              É so clicar e visualizar
            </p>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="table-responsive">
				<button class="btn btn-success ml-2" data-toggle="modal" data-target="#RelatorioSession">Baixar Relatório de sessões</button>
                  <DataTable :value="History" show-gridlines responsive-layout="scroll" :paginator="true" :rows="10">
                    <Column field="name" header="Sessão" class="text-sm font-weight-bold text-wrap text-uppercase"
                      :sortable="true"></Column>
                    <Column field="datetime_start" header="Data" class="text-sm font-weight-bold text-wrap text-uppercase">
                      <template #body="{ data }">
                        {{ formatDate(data.datetime_start) }}
                      </template>
                    </Column>
                    <Column field="session_status.name" header="Status"
                      class="text-sm font-weight-bold text-wrap text-uppercase" :sortable="true">
                      <template #body="slotProps">
                        <p :class="{ 'bg-success': slotProps.data.session_status.name == 'Concluída', 'bg-danger': slotProps.data.session_status.name == 'Aguardando votação', 'bg-warning': slotProps.data.session_status.name == 'Em votação' }"
                          style="border-radius: 10px;" class="text-center font-weight-bolder text-white">
                          {{ slotProps.data.session_status.name }}</p>
                      </template>
                    </Column>
                    <Column header="Visualizar"
                      class="text-sm font-weight-bold text-wrap text-uppercase TT text-center">
                      <template #body="slotProps">
                        <a  style="margin-bottom: 0rem !important;" type="button"
                          href="/historico_sessao/" class="btn bg-smartlegis btnEdit" @click="editProduct(slotProps.data)"> <i
                            style="color: white !important;" class="fa fa-eye"></i> </a>
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
	<div class="modal fade RelatorioSession" style="z-index: 1001 !important;" id="RelatorioSession" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Relatório de sessões</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="CloseRelatorioSession">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label style="font-size: 15px;">Por:</label>
            <select
                  id="choices-category"
                  v-model="FilterTypeModel"
                  class="form-control"
                  name="choices-category"
                  >
                  <option
                    v-for="option in FilterType"
                    :key="option.id"
                    :value="option.id"
                  >
                    {{ option.label }}
                  </option>
                </select>
				<div v-if="FilterTypeModel === 0" class="mt-3">
					<div class="row">
						<div class="col">
							<label style="font-size: 15px;">De</label>
							<input type="date" class="form-control" v-model="dateStart" id="date" name="date" required>
						</div>
						<div class="col">
							<label style="font-size: 15px;">Até</label>
							<input type="date" class="form-control" v-model="dateEnd" id="date" name="date" required>
						</div>
					</div>
				</div>

				<div v-if="FilterTypeModel === 1" class="mt-2">
					<div class="row">
						<div class="col">
							<label style="font-size: 15px;">Da sessão:</label>
							<select
								id="choices-category"
								v-model="SessionStart"
								class="form-control"
								name="choices-category"
								>
								<option
									v-for="option in History"
									:key="option.id"
									:value="option.id"
								>
									{{ option.name }}
								</option>
							</select>

							<label class="mt-2" style="font-size: 15px;">Até a sessão:</label>
							<select
								id="choices-category"
								v-model="SessionEnd"
								class="form-control"
								name="choices-category"
								>
								<option
									v-for="option in History"
									:key="option.id"
									:value="option.id"
								>
									{{ option.name }}
								</option>
							</select>
						</div>
					</div>
				</div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Voltar</button>
            <button type="button" class="btn btn-success" @click="DownloadRelatorio();">Baixar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>

</style>
