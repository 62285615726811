<script>
import { AxiosAPI } from '@/axios';
import { useMainStore, useBusStore } from '../../stores/index';

export default {
	name: 'TvBody',
	setup() {
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		StoreAll.Token();
		StoreAll.User();
		return {
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			votedlegenda: false,
			session_id: 0,
			DocVoted: '',
			us: [],
			User_Tot: [],
			users: [],
			document: [],
			user_presidente: [],
			users_quorums: [],
			tela: '',
			presentess: 0,
			ausentess: 0,
			totall: 0,
			tribuna_id: 0,
			dia: 0,
			mes: '',
			time: '',
			timer_session: 'Aguarde o inicio',
			Session_start: false,
			QuorumRemoveUser: false,
			QuorumAddUser: false,
			DocumentVoteStart: false,
			EndVote: false,
			Loading: true,
			socket_ids: [],
		};
	},

	watch: {
		Session_start: function (novoValor, valorAntigo) {
			if (novoValor !== false) {
				this.Get_Quorum_Socket_Tv_full();
				this.Session_start = false;
			}
		},

		QuorumRemoveUser: function (novoValor, valorAntigo) {
			if (novoValor !== false) {
				this.Get_Quorum_Socket_Tv_full();
				this.QuorumRemoveUser = false;
			}
		},

		QuorumAddUser: function (novoValor, valorAntigo) {
			if (novoValor !== false) {
				this.Get_Quorum_Socket_Tv_full();
				this.QuorumAddUser = false;
			}
		},

		DocumentVoteStart: function (novoValor, valorAntigo) {
			if (novoValor !== false) {
				this.Get_Quorum_Socket_Tv_full();
				this.DocumentVoteStart = false;
			}
		},

		EndVote: function (novoValor, valorAntigo) {
			if (novoValor !== false) {
				this.DocumentVoted();
				this.EndVote = false;
			}
		},
	},

	created () {
		let OnSocket = setInterval(() => {
			if (this.StoreAll.websocket_host !== null) {
				clearInterval(OnSocket);
				
				window.Echo.channel(this.StoreAll.websocket_host).listen('.' + this.StoreAll.websocket_host, (e) => {
					if (e.socket_id === 1) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Session_start = true;
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 4) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.QuorumAddUser = true;
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 5) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.QuorumRemoveUser = true;
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 10) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							setTimeout(() => { this.tribunaOn(); }, 2000);
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 12) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							setTimeout(() => {  this.tribunaOn(); }, 2000);
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 13) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.tribuna_of();
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 21) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Explanation_on();
							this.socket_ids.push(e.event_id);  
						}
					} 
        
					if (e.socket_id === 24) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Explanation_on();
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 25) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Discut();
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 25) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Discut();
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 30) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Discut_off();
							this.socket_ids.push(e.event_id);  
						}
					}
				});
			}}, 500);

		let OnSystem = setInterval(() => {
			if (this.StoreAll.socket !== null) {
				clearInterval(OnSystem);

				this.StoreAll.socket.on('6', (e) => {
					if (this.DocumentVoteStart === false) {			
						this.DocumentVoteStart = true;
					}
				});

				this.StoreAll.socket.on('8', (e) => {
					if (this.EndVote === true) {
						this.EndVote = true;
					}	
				});
			}}, 500);
	},

	mounted() {
		this.GetSession();
	},
  
	methods: {
		async GetSession() {
			this.Loading = true;
			await this.StoreAll.Get_Data();
			const { session } = this.StoreAll;
			if (session == null) {
				this.GetSession();
				return;
			}
			const { session_status, id } = session;
			this.session = session;
			this.session_status = session_status.id;
			this.session_id = id;
			this.session_status_name = session_status.name;

			this.get_expediente();
			this.DocumentVoted();
			this.tribunaOn();
			this.Discut();
			this.Explanation_on();
			this.OrdemOn();
			this.Session_timer();
			this.Hora();
			setInterval(this.Hora, 1000);
			this.Get_Quorum_Socket_Tv_full();
		},

		async Get_Quorum_Socket_Tv_full() {
			await this.StoreAll.Get_Data();
			this.Quorum_atual_full();
		},

		async get_expediente() {
			try {
				const response = await AxiosAPI.get('/users', {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				});

				this.users = response.data.filter(user => {
					const excludedStatusUser = [2, 3];
					return !excludedStatusUser.includes(user.status_user) && user.user_category_id !== 3;
				});

				this.users2 = response.data.filter(user => user.user_category_id === 3);
				this.user_presidente = this.users2;

			} catch (error) {
				console.log(error);
			}
		},

		Session_timer() {
			setTimeout(() => {
				this.Session_timer();
			}, 300);

			if (this.StoreAll.quorum.length <= 0) {
				return;
			} else {
				let inicio = new Date(this.StoreAll.quorum[0].created_at).getTime();;
				let fim = new Date().getTime();;
				let diferenca = fim - inicio;
				let hours = Math.floor((diferenca % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				let minutes = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60));
				let seconds = Math.floor((diferenca % (1000 * 60)) / 1000);
				let timer_session = hours + 'h: ' + minutes + 'm: ' + seconds + 's';
				this.timer_session = timer_session;
			}
		},

		Hora() {
			let date_time = new Date();
			let dia = date_time.getDate();
			let mesesEmPortugues = [
				'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
				'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
			];
			let mes = mesesEmPortugues[date_time.getMonth()];

			let minutes = date_time.getMinutes().toString().padStart(2, '0');
			let hours = date_time.getHours().toString().padStart(2, '0');
			let seconds = date_time.getSeconds().toString().padStart(2, '0');

			this.time = `${hours}: ${minutes}: ${seconds}`;
			this.dia = dia;
			this.mes = mes;
		},

		async Discut() {
			if (this.StoreAll.quorum == null) {
				return;
			} else if (this.StoreAll.quorum.length <= 0) {
				return;
			} else {
				if (this.StoreAll.disct == true) {
					return;
				} else {
					await AxiosAPI.get('/discussion?quorum_id=' + this.StoreAll.quorum[0].id, {
						headers: {
							Authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}).then((resp) => {
						for (let i = 0; i < resp.data.data.length; i++) {
							if (resp.data.data[i].status_discussion == 1) {
								this.StoreAll.discut = true;
							} else {
								this.StoreAll.discut = false;
							}
						}
					});
				}
			}
		},

		async Explanation_on() {
			if (this.StoreAll.quorum == null) {
				return;
			} else if (this.StoreAll.quorum.length <= 0) {
				return;
			} else {
				if (this.StoreAll.disct == true) {
					return;
				} else {
					await AxiosAPI.get('/big-discussion?quorum_id' + this.StoreAll.quorum[0].id, {
						headers: {
							Authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}).then((resp) => {
						for (let i = 0; i < resp.data.data.length; i++) {
							if (resp.data.data[i].status_big_discussion == 1) {
								this.StoreAll.explanation = true;
							} else {
								this.StoreAll.explanation = false;
							}
						}
					});
				}
			}
		},


		async Quorum_atual_full() {
			if (this.StoreAll.quorum == null) {
				return;
			} 
			if (this.StoreAll.quorum.length <= 0) {
				return;
			}
			if (this.StoreAll.quorum.length >= 1 && this.StoreAll.quorum != null) {
				try {
					await AxiosAPI.get('/quorum-users?quorum_id=' + this.StoreAll.quorum[0].id, {
						headers: {
							authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}).then((response) => {
						this.users_quorums = response.data.data;
						this.ContagemUsersAndPresidente();
					});
				}
				catch {
					console.log('error');
				}
			};
		},

		ContagemUsersAndPresidente() {
			let users_presentes = 0;
			let presidente_presente = 0;

			// Inicialização das variáveis
			let user_P = [];
			let user_A = [];
			let user_T = [];

			// Definir o estado 'Ausente' para todos os usuários
			this.users.forEach(user => {
				user[1] = 'A';
			});

			// Definir o estado 'Ausente' para todos os presidentes
			this.user_presidente.forEach(user => {
				user[1] = 'A';
			});

			// Verificar usuários presentes nos quorums
			this.users_quorums.forEach(quorum => {
				const foundUser = this.users.find(user => user.name === quorum.user.name);
				if (foundUser) {
					foundUser[1] = 'P';
					users_presentes++;
				}

				const foundPresidente = this.user_presidente.find(user => user.name === quorum.user.name);
				if (foundPresidente) {
					foundPresidente[1] = 'P';
					presidente_presente++;
				}
			});

			// Filtrar usuários presentes e ausentes
			user_P = this.users.filter(user => user[1] === 'P');
			user_A = this.users.filter(user => user[1] === 'A');
			user_T = [...user_P, ...user_A];
			this.User_Tot = user_T;

			// Atualizar contagem total de usuários
			this.totall = this.users.length + this.user_presidente.length;
			this.presentess = users_presentes + presidente_presente;
			this.ausentess = this.totall - this.presentess;

			// Atualizar estados de usuários presentes
			this.us = this.users;
			this.isQuorumLoaded = true;
			this.StoreAll.quorum_moment = true;
			this.Loading = false;
		},

		async tribunaOn() {
			const quorum = this.StoreAll.quorum;
			const vote = this.StoreAll.vote;
			const tribuna = this.StoreAll.tribuna;
			const accessToken = this.StoreAll.accessToken;

			if (quorum == null || quorum.length <= 0 || vote === true) {
				return;
			}

			if (tribuna === true) {
				return;
			}

			try {
				const response = await AxiosAPI.get('/tribune?quorum_id=' + quorum[0].id, {
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				});

				const tribunaData = response.data.data[0];

				if (tribunaData.status_tribune == 1 || tribunaData.status_tribune == 2) {
					this.tribuna_id = tribunaData.id;
					this.StoreAll.tribuna = true;
				} else {
					this.StoreAll.tribuna = false;
					return;
				}
			} catch (error) {
				console.log(error);
			}
		},

		DocumentVoted() {
			if (this.StoreAll.tribuna == false) {
				if (this.StoreAll.document == null) {
					this.StoreAll.vote = false;
					this.StoreAll.votedlegenda = false;
				} else if (
					this.StoreAll.document.document_status_vote_id == 4
				) {
					this.StoreAll.vote = true;
					this.StoreAll.votedlegenda = true;
					this.DocVoted = this.StoreAll.document.name;
				} else {
					this.StoreAll.vote = false;
					this.StoreAll.votedlegenda = false;
				}
			} else { return; }
		},

		Discut_off() {
			this.StoreAll.discut = false;
		},

		tribuna_of() {
			this.StoreAll.tribuna = false;
		},

		async OrdemOn() { 
			try {
				await AxiosAPI
					.get(
						'/question-orders?quorum_id=' + this.StoreAll.quorum[0].id,
						{
							headers: {
								authorization: 'Bearer ' + this.StoreAll.accessToken,
							},
						}
					).then((resp) => {
						let OrdemNow = [];

						resp.data.data.forEach(ordem => {
							if (resp.data.data) {
								if (ordem.status === '1') {
									OrdemNow.push(ordem);
								}
							}
						});

						if (OrdemNow.length <= 0) {
							this.StoreAll.Ordem = false;
						} else {
							this.StoreAll.Ordem = true;
						}

					});
			}
			catch (error) {}
		},
		isPresent(user) {
			return user[1] === 'P';
		},
		userStatus(user) {
			return this.isPresent(user) ? 'PRESENTE' : 'AUSENTE';
		},
		userImagePath(user) {
			return this.StoreAll.Dochost + user.path_image;
		}

	},
};
</script>
<template>
  <div>
    <div>
      <div v-if="!this.StoreAll.quorum_moment" class="d-flex justify-content-center">
        <span
          style="transform: translate(-50%, 80%);margin-right: -50%;top: 39%;left: 50%;position: absolute;font-size: clamp(0.1vw, 2.5vw, 40px);font-weight: 700 !important;color: white;">
          AGUARDANDO O INICIO DO QUORUM</span>
      </div>

	  <div v-if="this.StoreAll.quorum_moment && Loading" class="d-flex justify-content-center">
        <span
          style="transform: translate(-50%, 80%);margin-right: -50%;top: 39%;left: 50%;position: absolute;font-size: clamp(0.1vw, 2.5vw, 40px);font-weight: 700 !important;color: white;">
          Carregando vereadores!</span>
      </div>

      <div v-if="this.StoreAll.quorum_moment && !Loading">

        <div v-if="(this.StoreAll.vote == false && this.StoreAll.tribuna == false && this.StoreAll.discut == false && this.StoreAll.explanation == false)">
          <div class="d-flex" style="margin-top: 5px; border-top: 2px solid #005c9f; border-bottom: 2px solid #005c9f;">
            <div v-for="user in user_presidente" :key="user" class="ml-2">
              <div class="d-flex justify-content-start" style="border-radius: 10px; width: clamp(0.1vw, 20vw, 380px);margin-left: clamp(11%, 11.5%, 13%);">
                <div class="text-center">

                  <div>
                    <p class="text-white ml-3 mb-0" style="font-size:clamp(0vw, 2.2vw, 2.5vw);">
                      <b>VER. PRESIDENTE</b>
                    </p>
                  </div>

				<img v-if="isPresent(user)" :src="userImagePath(user)" class="rounded-circle justify-content-start display_fast_body" style="object-fit: cover;" alt="user photo" />
				<img v-else :src="userImagePath(user)" class="rounded-circle justify-content-start display_fast_body" style="object-fit: cover; opacity: 50%;" alt="user photo" />

				<div v-if="isPresent(user)">
					<span class="text-uppercase text-white font-weight-bold" style="font-size: clamp(0.99vw, 0vw, 1vw);"> {{ user.nickname }} - {{ user.category_party.name_party }} </span>
				</div>

				<div v-else>
					<span class="text-uppercase text-white font-weight-bold" style="font-size: clamp(0.99vw, 0vw, 1vw); opacity: 50%;"> {{ user.nickname }} - {{ user.category_party.name_party }} </span>
				</div>

				<div v-if="isPresent(user)">
					<span id="status" class="text-white pl-2 pr-2 font-weight-bold bg-success" style="border-radius: 10px; font-size: clamp(1vw, 1.2vw, 1.5vw);">{{ userStatus(user) }}</span>
				</div>

				<div v-else>
					<span id="status" class="text-white pl-2 pr-2 font-weight-bold bg-dangerlegis" style="border-radius: 10px; opacity: 50%; font-size: clamp(1vw, 1.2vw, 1.5vw);">{{ userStatus(user) }}</span>
				</div>
            </div>
        </div>
    </div>

            <div style="margin-left: 10%; margin-top: 2%;">
              
				<div class="d-flex justify-content-center text-black font-weight-bold"
                style="background-color: #ebd21f; border-radius: 5px 5px 0.1vw 0.1vw; font-family: 'Old Standard TT', serif; font-size: clamp(17px, 1.5vw, 30px); width: 100%;">
                <span>
                  QUÓRUM DA SESSÃO
                </span>
              </div>

              <div class="d-flex">
                <div class="d-flex justify-content-center p-4 text-center shadow bg-success QuorumBox"
                  style="border-radius: 0.1vw 0 0 5px; "
                  :class="{ paddHD: tela == 'HD' }">
                  <div class="text-white font-weight-bold TextMargin">
                    PRESENTES
                  </div>
                  <div class="ml-2"
                    style="border: 1px #222020;width: clamp(0.1vw, 5vw, 302px);border-style: double;height:clamp(0.1vw, 5vw, 302px);color: white;border-radius: 50%;box-shadow: 3px 2px black;">
                    <span class="text-white font-weight-bold TextQuorum">
                      {{ presentess }} </span>
                  </div>
                </div>
                <div class="d-flex justify-content-center p-4 text-center bg-dangerlegis shadow QuorumBox">
                  <div class="text-white font-weight-bold TextMargin">
                    AUSENTES
                  </div>
                  <div class="ml-2"
                    style="border: 1px #222020;width: clamp(0.1vw, 5vw, 302px);border-style: double;height: clamp(0.1vw, 5vw, 302px);color: white;border-radius: 50%;box-shadow: 3px 2px black;">
                    <span class="text-white font-weight-bold TextQuorum">
                      {{ ausentess }} </span>
                  </div>
                </div>
                <div class="d-flex justify-content-center p-4 text-center shadow QuorumBox"
                  style="background-color: #bfbfbf; border-radius: 0 0 5px 0;">
                  <div class="text-black font-weight-bold TextMargin">
                    TOTAL
                  </div>
                  <div class="ml-2"
                    style="border: 1px #222020;background-image: linear-gradient(rgb(112 112 112), rgb(138 141 141));width: clamp(0.1vw, 5vw, 302px);border-style: double;height: clamp(0.1vw, 5vw, 302px);color: white;border-radius: 50%;box-shadow: 3px 2px black;">
                    <span class="text-white font-weight-bold TextQuorum">{{
                        totall }} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <p class="text-white mb-0" style="font-size:clamp(0vw, 2.2vw, 2.5vw);margin-left: clamp(5%, 5%, 7%);">
              <b>VEREADORES</b>
            </p>
          </div>
          
		  <div class="d-flex flex-wrap" style="border-bottom: 2px solid #005c9f;">
            <div v-for="(user, index) in User_Tot" :key="index" class="mb-0 display_body_nine"
              :class="{ 'col-3': this.StoreAll.Large_host == 9, 'col-1-5-users-11': this.StoreAll.Large_host == 11, 'col-2': this.StoreAll.Large_host == 13, 'col-1-5-users-14': this.StoreAll.Large_host == 14 }">

              <div style="border-radius: 10px;">
                <div class="text-center">

				<img v-if="isPresent(user)" :src="userImagePath(user)" class="rounded-circle justify-content-start display_fast_body" style="object-fit: cover;" alt="user photo" />
				<img v-else :src="userImagePath(user)" class="rounded-circle justify-content-start display_fast_body" style="object-fit: cover; opacity: 50%;" alt="user photo" />

				<div v-if="isPresent(user)">
					<span class="text-uppercase text-white font-weight-bold" style="font-size: clamp(0.99vw, 0vw, 1vw);"> {{ user.nickname }} - {{ user.category_party.name_party }} </span>
				</div>

				<div v-else>
					<span class="text-uppercase text-white font-weight-bold" style="font-size: clamp(0.99vw, 0vw, 1vw); opacity: 50%;"> {{ user.nickname }} - {{ user.category_party.name_party }} </span>
				</div>

				<div v-if="isPresent(user)">
					<span id="status" class="text-white pl-2 pr-2 font-weight-bold bg-success" style="border-radius: 10px; font-size: clamp(1vw, 1.2vw, 1.5vw);">{{ userStatus(user) }}</span>
				</div>

				<div v-else>
					<span id="status" class="text-white pl-2 pr-2 font-weight-bold bg-dangerlegis" style="border-radius: 10px; opacity: 50%; font-size: clamp(1vw, 1.2vw, 1.5vw);">{{ userStatus(user) }}</span>
				</div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="(this.StoreAll.vote == false && this.StoreAll.tribuna == false && this.StoreAll.discut == false && this.StoreAll.explanation == false)" class="d-flex justify-content-between">
          <div style="display: flex;">
            <span class="text-white text-center"
              style="font-size: clamp(1em, 1em + 1vw, 1.5em); width: 8.5rem; border-right: 1px solid">
              {{ dia }} <br />
              {{ mes }}
            </span>
            <span class="text-white d-flex justify-content-center"
              style="font-size:clamp(2em, 1em + 1vw, 1.5em); width: 12rem; margin-top: 1rem;">
              {{ time }}
            </span>
          </div>
          <div class="mt-3" style="width: 50%;">
            <p class="text-white d-flex justify-content-center"
              style="font-size: clamp(1em, 1em + 1vw, 1.5em); font-family: sans-serif;">
              <span>{{ this.StoreAll.name_camara }} &nbsp;</span>
            </p>
          </div>
          <div class="text-white text-center d-flex justify-content-center"
            style="border-left: 1px solid white; width: 15%; font-family: monospace; font-size: clamp(0.5em, 0.5em + 1vw, 1.5em);">
            Tempo de sessão <br />
            {{ timer_session }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.text-black {
  color: black;
}

.display_fast_body {
  width: clamp(0.1vw, 8.5vw, 300px);
  height: clamp(0.1vw, 8.5vw, 300px);
}

.display_body_nine {
  width: clamp(0.1vw, 25vw, 475px);
}

.font_element_vote {
  font-size: 27px;
}
@media only screen and (max-width: 1600px) {
  .display_fast_body {
    width: clamp(0.1vw, 8vw, 300px);
    height: clamp(0.1vw, 8vw, 300px);
  }

  .font_element_vote {
  font-size: 21px;
}
}

@media only screen and (max-width: 1380px) {
  .display_fast_body {
    width: clamp(0.1vw, 7.5vw, 300px);
    height: clamp(0.1vw, 7.5vw, 300px);
  }

  .display_body_nine {
    width: clamp(0.1vw, 25vw, 330px);
  }

  .font_element_vote {
  font-size: 19px;
}
}

@media only screen and (max-width: 1280px) {
  .display_fast_body {
    width: clamp(0.1vw, 7.1vw, 300px);
    height: clamp(0.1vw, 7.1vw, 300px);
  }

  .font_element_vote {
  font-size: 19px;
}
}

.QuorumBox{
	width: clamp(0.1vw, 22.5vw, 450px); 
	height: clamp(0.1vw, 9vw, 160px);
}

.TextQuorum{
	font-size: clamp(1vw, 4vw, 4vw);
	font-family: 'Old Standard TT', serif; 
	position: absolute; 
	transform: translate(-50%, -10%);
}

.TextMargin{
	margin-top: 5%; 
	font-size: clamp(1vw, 2vw, 3vw);
	font-family: 'Old Standard TT', serif;
}

.col-1-5-users-14 {
  width: 14% !important;
}

.col-1-5-users-11 {
  width: 20% !important;
}

.rounded-border {
  border-radius: 10px;
}

.text-white {
  color: white;
}

.font-weight-bold {
  font-weight: bold;
}
</style>
