<script>
import { useMainStore } from '../../../stores/index';
import Tv_Full_quorum from './Tv_Full_quorum.vue';
import Tv_Full_Actions from './Tv_Full_Actions.vue';
export default {
	name: 'TvExtend',
	components: { Tv_Full_quorum, Tv_Full_Actions },
	setup(){
		const StoreAll = useMainStore();
		StoreAll.Token();
		return{
			StoreAll: StoreAll,
		};
	},
	data() {
		return {
			fullScren: false,
			fullScren_quorum: false,
			fullScren_actions: false
		};
	},
  
	mounted() {
		this.GetSession();
	},
	methods: {
 
		async User() {await this.StoreAll.User();}, 
    
		closes(){
			document.getElementById('Full').style.display = 'none';
			document.getElementById('fade').style.display = 'none';
		},
		FullScren(value) {
			this.fullScren = true;  
			if(value == 1){
				this.fullScren_quorum = true;  
			}else{
				this.fullScren_actions = true;  
			}  
			document.getElementById('Full').style.display = 'none';
			this.emitter.emit('FullScren', this.fullScren);   
        
		},
		FullScrennot() {
			document.getElementById('Full').style.display = 'none';
			document.getElementById('fade').style.display = 'none';
			this.fullScren = false;
			this.emitter.emit('FullScrennot', this.fullScren);
		},
		FullScrennot2() {
			document.getElementById('Full').style.display = 'none';
			document.getElementById('fade').style.display = 'none';
			this.fullScren = false;
			this.emitter.emit('FullScrennot', this.fullScren);
		},


		async GetSession() {
			await this.StoreAll.Get_Data();
			const { session } = this.StoreAll; 
			if (session == null) {
				this.GetSession();
				return;
			}
			const { session_status, id } = session;
			this.session = session;
			this.session_status = session_status.id;
			this.session_id = id;
			this.session_status_name = session_status.name;  
		},
   
	}
};
</script>
<template>
    <div>
    <div id="Full">
        <div class="py-4 h-100 container-fluid">
    <div class="row">
      <div class="text-center col-12">
        <h3 class="mt-auto text-white">Seleção de telas</h3>
        <h5 class="text-white font-weight-normal">
          Selecione qual tela você deseja visualizar!
        </h5>
        <div class="mb-5 multisteps-form">

          <div class="row">
            <div class="m-auto col-12">
              <form class="multisteps-form__form">
                <div>
                  <div class="p-3 bg-white card multisteps-form__panel border-radius-xl js-active position-relative"
                    data-animation="FadeIn">
                    <div class="text-center row">
                        <h3>Qual das telas você deseja abrir?</h3>
                      <div class="mx-auto col-10">
                        <button type="button" class="btn btn-primary bg-success mr-3" @click="FullScren(1)"> QUÓRUM </button>
                        <button type="button" class="btn btn-primary bg-success" @click="FullScren(2)"> AÇÕES </button>
                    </div>
                </div>
            </div>
        
        </div>
    </form>
    </div>
</div>
</div>
</div>
</div>
</div>            
</div>
<div v-if="fullScren == true && fullScren_quorum == true">
        <Tv_Full_quorum />
      </div>

      <div v-else-if="fullScren == true && fullScren_actions == true">
        <Tv_Full_Actions />
      </div>
  
</div>
  </template>
  <style>
  .text-black {
    color: black;
  }
  .close{
    font-size: 2.3rem !important;
  }
  .vtl-table .vtl-thead .vtl-thead-th {
    color: black;
    background-color: #e9ecef;
    border-color: #e9ecef;
  }
  
  </style>
  <style scoped>
  ::v-deep(.dataTables_paginate, .pagination, .page-link, .vtl-paging-count-dropdown, .vtl-paging-page-dropdown) {
    display: none;
  }
  
  ::v-deep(.vtl-paging, .vtl-row){
    display: none;
  }
  </style>