<script>
import ModalBigger from './ModalBigger.vue';
import { AxiosAPI } from '@/axios';
import Loading from '../Modals/Loading.vue';
import { useMainStore, useBusStore } from '../../../stores/index';

export default {
	components: {
		ModalBigger,
		Loading
	},

	setup() {
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		return {
			StoreAll: StoreAll,
			Bus
		};
	},

	data() {
		return {
			selecionarTodos: false,
			loading: true,
			status: 1,
			quorum: [],
			quorum_add: [],
			quorum_remove: [],
			vereados_off_quorum: [],
			User_quorum: [],
		};
	},
  
	methods: {
		Checkall(){
			if (this.selecionarTodos) {
				this.quorum = this.StoreAll.vereadores.map(vereador => vereador.id);
			} else {
				this.quorum = [];
			}
		},
		async FindUsers() {
			this.loading = true;
			if (this.StoreAll.quorum == null) {
				this.loading = false;
				return;
			} else if (this.StoreAll.quorum.length <= 0) {
				this.loading = false;
				return;
			} else {
				let NewUser = [];
				let Users_Quorum_Now = [];

				for (let i = 0; i < this.StoreAll.user_all.length; i++) {
					NewUser.push(this.StoreAll.user_all[i]);
				}

				await AxiosAPI.get('/quorum-users?quorum_id=' + this.StoreAll.quorum[0].id, {
					headers: {
						authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				}).then((resp) => {

					this.User_quorum = resp.data.data;
                  
					for (let i = 0; i < resp.data.data.length; i++) {

						Users_Quorum_Now.push(resp.data.data[i].user_id);
					}
					this.NewInQuorum();
				});

			}
		},

		async Addvereador() {
			await AxiosAPI
				.post(
					'/quorum-users',
					{
						quorum_id: this.StoreAll.quorum[0].id,
						users: this.quorum_add,
					},
					{
						headers: {
							authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}
				).then(() => {
					this.AddQuorum_show();
					this.quorum_add = [];
					this.$refs.viewDocument.closeModal();
				});
		},

		async RemoveVereador() {
			await  AxiosAPI
				.delete(
					'/quorum-users/delete', {
						headers: {
							'Content-Type': 'application/json',
							authorization: `Bearer ${this.StoreAll.accessToken}`,
						},
						data: { 
							quorum_id: this.StoreAll.quorum[0].id,
							users: this.quorum_remove
						}
					}
				).then(() => {
					this.RemoveQuorum_show();
					this.quorum_remove = [];
                  
					AxiosAPI
						.get('/quorum-users?quorum_id=' + this.StoreAll.quorum[0].id, {
							headers: {
								Authorization: 'Bearer '+ this.StoreAll.accessToken,
							},
						})
						.then((response) => {
							let quorum_users_array = response.data.data;
							let size = quorum_users_array.length;
							this.StoreAll.quorum_size = size;
							this.StoreAll.in_quorum = false;
							for (let i = 0; i < size; i++) {
								if (
									this.StoreAll.user.id == quorum_users_array[i].user_id
								) {
									this.StoreAll.in_quorum = true;
								}
							}
              
						});

					this.$refs.viewDocument.closeModal();
				});

		},

		NewInQuorum() {
			let array1 = this.StoreAll.vereadores;
			let array2 = this.User_quorum;

			const array3 = array1.filter(item1 => {
				const foundItem2 = array2.find(item2 => item1.id === item2.user_id);
				return !foundItem2;
			}).map(item => ({
				id: item.id,
				name: item.name,
				nickname: item.nickname
			}));

			this.vereados_off_quorum = array3;
			this.loading = false;
		},

		openModal(value) {
			this.status = value;
			this.$refs.viewDocument.openModal();
		},

		async StartQuorum() {
			this.$refs.Loading.openModal();
			await AxiosAPI.get('/quorum?session_id=' + this.StoreAll.session.id, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken,
				},
			})
				.then((response) => {
					if (response.data.data.length >= 1 && response.data.data[0].session.session_status_id == 2) {
						this.$refs.Loading.closeModal();
						this.$toast.add({ severity: 'error', summary: 'QUORUM JÁ INICIADO', detail: 'JÁ EXISTE UM QUORUM ATIVO, VAMOS ATUALIZAR SUA PÁGINA', group: 'tl', life: 8000 });
						setTimeout(() => {
							location.reload();
						}, 1500);
					}
					if (response.data.data.length >= 1 && response.data.data[0].session.session_status_id == 1) {
						this.$toast.add({ severity: 'error', summary: 'QUORUM JÁ INICIADO', detail: 'JÁ EXISTE UM QUORUM ATIVO', group: 'tl', life: 8000 });
						this.StartSession();
					}
					if (response.data.data.length <= 0) {
						this.QuorumNew();
					}
				});
		},

		async QuorumNew() {
			await AxiosAPI.post('/quorum?session_id=' + this.StoreAll.session.id, {}, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken,
				},
			}).then((response) => {
				let quorumids = response.data.data.id;

				AxiosAPI

					.post(
						'/quorum-users',
						{
							quorum_id: quorumids,
							users: this.quorum,
						},
						{
							headers: {
								authorization: 'Bearer ' + this.StoreAll.accessToken,
							},
						}
					)
					.then((resp) => {
						if (resp.status === 200) {
							this.StartSession();
							this.selecionarTodos = false;
						}
					});
			});
		},

		async StartSession() {
			await AxiosAPI
				.post(
					'/session/change-status?session_id=' +
        this.StoreAll.session.id +
        '&session_status_id=2',
					{},
					{
						headers: {
							authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}
				)
				.then(() => {
					this.StartQuorum_show();
					this.$refs.Loading.closeModal();
					this.Bus.GetSessionStart = true;
					this.$refs.viewDocument.closeModal();
				});
		},

		StartQuorum_show() {
			this.$toast.add({ severity: 'success', summary: 'Sessão iniciada', detail: 'A sessão atual foi iniciada!', life: 3000 });
		},

		AddQuorum_show() {
			this.$toast.add({ severity: 'success', summary: 'Vereador(es) adicionado(s)', detail: 'O(s) veriador(es) foi(ram) adicionado(s)!', life: 3000 });
		},

		RemoveQuorum_show() {
			this.$toast.add({ severity: 'success', summary: 'Vereador(es) removido(s)', detail: 'O(s) veriador(es) foi(ram) removidos(s)!', life: 3000 });
		},

	}
};

</script>
  
<style>
.vereadores-container {
display: flex;
flex-wrap: wrap;
}

.vereadores-item {
width: calc(33.33% - 10px); /* Define a largura de cada item */
margin-right: 10px; /* Define a margem entre os itens */
margin-bottom: 10px; /* Define a margem inferior dos itens */
}
</style>
<template>
  <div>
    <Loading ref="Loading" style="z-index: 1051 !important;"></Loading>
  </div>
  <div>
      <ModalBigger ref="viewDocument" style="z-index: 1050 !important;">
          <template #header>
              <strong style="color: black"> <span>Quórum da sessão: </span><span style="color: red"> {{ this.StoreAll.session.name }} </span> </strong>

              <div class="flex-start">
                  <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
          </template>

          <template #body>

          <div v-if="status == 1"> 
          <label class="d-flex justify-content-center" style="font-size: 18px"><b> Marque os vereadores presentes no quórum atual: </b></label>
          
          <div class="mt d-flex justify-content-center" style="display: flex">
          <input v-model="selecionarTodos" class="mr" type="checkbox" style="width: 1.7rem; height: 1.7rem" @change="Checkall" />
          <label style="font-size: 18px"> Marque todos </label>
          </div>

          <div class="vereadores-container">
            <div v-for="(vereador, index) in this.StoreAll.vereadores" :key="vereador.id" class="vereadores-item" style="display: flex">
              <input :key="index" v-model="quorum" :value="vereador.id" class="mr" type="checkbox" style="width: 1.7rem; height: 1.7rem" />
              <label style="font-size: 18px">{{ vereador.nickname }}</label>
            </div>
          </div>
          </div>
          
          <div v-if="status == 2">
            <div v-if="loading">
              <div>
              <div class="d-flex justify-content-center">
                <spinnerprime/>
              </div>
              <div class="text-center">
                <span class="text-dark"><strong>Aguarde...</strong></span>
              </div>
            </div>
            </div>
            <div v-else>
            <label class="d-flex justify-content-center" style="font-size: 18px"><b> Marque os vereadores que deseja adicionar no quórum atual: </b></label>
            <div v-if="vereados_off_quorum.length <= 0"  class="d-flex justify-content-center">
              <span><strong> Nenhum vereador está faltando, se necessário, atualize a tv! </strong></span>
            </div>
            <div v-else class="vereadores-container">
              <div v-for="(vereador, index) in vereados_off_quorum" :key="vereador.id" class="vereadores-item" style="display: flex">
                <input :key="index" v-model="quorum_add" :value="vereador.id" class="mr" type="checkbox" style="width: 1.7rem; height: 1.7rem" />
                <label style="font-size: 18px">{{ vereador.nickname }}</label>
              </div>
            </div>
          </div>
          </div>

          <div v-if="status == 3">
            <div v-if="loading">
              <div>
              <div class="d-flex justify-content-center">
                <spinnerprime/>
              </div>
              <div class="text-center">
                <span class="text-dark"><strong>Aguarde...</strong></span>
              </div>
            </div>
            </div>
            <div v-else>
          <label class="d-flex justify-content-center" style="font-size: 18px"><b> Marque os vereadores que deseja retirar do quórum atual: </b></label>

            <div v-if="User_quorum.length <= 0"  class="d-flex justify-content-center">
              <span><strong> Nenhum vereador está no quorum </strong></span>
            </div>

            <div v-else class="vereadores-container">
              <div v-for="(vereador, index) in User_quorum" :key="vereador.user_id" class="vereadores-item" style="display: flex">
                <input :key="index" v-model="quorum_remove" :value="vereador.user_id" class="mr" type="checkbox" style="width: 1.7rem; height: 1.7rem" />
                <label style="font-size: 18px">{{ vereador.user.nickname }}</label>
              </div>
            </div>
          </div>
        
          </div>
          </template>

          <template #footer>
          <div v-if="status == 1" class="d-flex justify-content-center">
          <button type="button" class="btn btn-secondary mr-3" @click="$refs.viewDocument.closeModal()">
            Voltar
          </button>
          <button type="button" class="btn btn-primary bg-success" @click="StartQuorum()">
            Iniciar
          </button>
          </div>

          <div v-if="status == 2" class="d-flex justify-content-center">
          <button type="button" class="btn btn-secondary mr-3" @click="$refs.viewDocument.closeModal()">
            Voltar
          </button>
          <button type="button" class="btn btn-primary bg-success" @click="Addvereador();">
            Adicionar
          </button>
          </div> 

          <div v-if="status == 3" class="d-flex justify-content-center">
          <button type="button" class="btn btn-secondary mr-3" @click="$refs.viewDocument.closeModal()">
            Voltar
          </button>
          <button type="button" class="btn btn-primary bg-danger"  @click="RemoveVereador();">
            Remover
          </button>
          </div>

          </template>
      </ModalBigger>

  </div>
</template>
