<script>
import { AxiosAPI } from '@/axios';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import moment from 'moment';
import ModalGTP from '../../components/documents/Modals/ModalGTP.vue';
import { useMainStore } from '../../stores/index';
import ApproveModal from '../../components/documents/Modals/ApproveModal.vue';

export default {
	name: 'DocSearchAll',
	components: {
		ApproveModal,
		ModalGTP
	},
	setup() {
		const StoreAll = useMainStore();
		StoreAll.Token();
		return {
			StoreAll: StoreAll,
		};
	},
	data() {
		return {
			filter: {
				protocol: null,
				name: null,
				type_doc: 0,
				status_doc: 0,
				date_creat: null,
				authors: [],
			},
			vereadores: [],
			expandedKeys: {},
			messages: [],
			Analyze: [],
		};
	},
	mounted() {
		this.GetUser();
		this.GetUsersAll();
		this.GetSession();
		this.emitter.on('ApproveDocument', () => { this.GetAnalyze(); });
		this.emitter.on('ReproveDocument', () => { this.GetAnalyze(); });
	},
	methods: {
		async GetUsersAll() {
			await this.StoreAll.UserAllEnvio();
			this.vereadores = this.StoreAll.user_envio;
		},

		async GetUser() {
			await this.StoreAll.User();
			this.GetAnalyze();
		},

		async GetSession() {
			await this.StoreAll.Get_Data();
			await this.StoreAll.DocCategory();

			let OnSystem = setInterval(() => {
				if (this.StoreAll.document_category != null) {
					clearInterval(OnSystem);
					this.categories = this.StoreAll.document_category;
				}
			}, 500);
		},

		async GetAnalyze() {
			this.Analyze = [];
			const data = [];
			if (this.StoreAll.user.user_category_id === 1) {
				await AxiosAPI
					.get('/documents?document_status_movement_id=7', {
						headers: {
							Authorization: 'Bearer '+ this.StoreAll.accessToken,
						},
					})
					.then((resp) => {
						data.push(...resp.data.data);
						this.GetAnalyzeReproves(data);
					});
			}
			if (this.StoreAll.user.user_category_id !== 1) {
				const userData = [this.StoreAll.user.id];
				let user = JSON.stringify(userData);
				await AxiosAPI
					.get('/documents?document_status_movement_id=7&authors=' + user, {
						headers: {
							Authorization: 'Bearer '+ this.StoreAll.accessToken,
						},
					})
					.then((resp) => {
						data.push(...resp.data.data);
						this.GetAnalyzeReproves(data);
					});
			}
		},

		async GetAnalyzeReproves(value) {
			const data = value;
			if (this.StoreAll.user.user_category_id === 1) {
				await AxiosAPI
					.get('/documents?document_status_movement_id=8', {
						headers: {
							Authorization: 'Bearer '+ this.StoreAll.accessToken,
						},
					})
					.then((resp) => {
						data.push(...resp.data.data);
						this.mergeData(data);
					});
			}
			if (this.StoreAll.user.user_category_id !== 1) {
				const userData = [this.StoreAll.user.id];
				let user = JSON.stringify(userData);
				await AxiosAPI
					.get('/documents?document_status_movement_id=8&authors=' + user, {
						headers: {
							Authorization: 'Bearer '+ this.StoreAll.accessToken,
						},
					})
					.then((resp) => {
						data.push(...resp.data.data);
						this.mergeData(data);
					});
			}
		},

		mergeData (value) {
			const uniqueArray = value.filter((item, index, self) =>
				index === self.findIndex((obj) => obj.id === item.id)
			);
			this.Analyze = uniqueArray;
		},

		showError() {
			this.$toast.add({ severity: 'error', summary: 'Sessão Não Encotrada', detail: 'Documento não possui sessão', life: 3000 });
		},

		formatDate(value) {
			moment.locale('pt-br');
			return moment(value).format('DD/MMMM/YYYY');
		},

		ViewDoc(event) {
			this.StoreAll.document_details = event;

			if (event.carimbo_camara_pdf == null) {
				this.StoreAll.Leitura = false;
				if (event.attachment_partial != null) {
					this.StoreAll.document_src = event.attachment_partial;
				} else {
					this.StoreAll.document_src = event.attachment;
				}
			} else if (event.carimbo_camara_pdf != null && event.carimbo_prefeitura_pdf != null) {
				this.StoreAll.Leitura = true;
				this.StoreAll.document_src = event.carimbo_prefeitura_pdf;
			} else if (event.carimbo_camara_pdf != null && event.carimbo_prefeitura_pdf == null) {
				this.StoreAll.Leitura = true;
				this.StoreAll.document_src = event.carimbo_camara_pdf;
			} else {
				this.StoreAll.Leitura = false;
				if (event.attachment_partial != null) {
					this.StoreAll.document_src = event.attachment_partial;
				} else {
					this.StoreAll.document_src = event.attachment;
				}
			}

			this.$refs.ApproveModal.openModal2();
		},

		Author(value) {
			let Co_user = [];
			for (let i = 0; i < value.length; i++) {
				Co_user.push(value[i].nickname);
			}
			return Co_user.join(', ');
		},

		GPTSend (value) {
			AxiosAPI
				.post('/documents/search-repeated?search=' + value, {}, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken
					}
				})
				.then(resp => {
					this.$refs.ModalGTP.openModal2(resp.data.documents);
				});
		},


	},
};
</script>
<template>
  <div>
    <ApproveModal ref="ApproveModal"></ApproveModal>
    <ModalGTP ref="ModalGTP"></ModalGTP>
  <div class="container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <div v-if="this.StoreAll.user.user_category_id === 1" class="card-header">
            <h5 class="mb-0">Aprove ou reprove documentos parecidos!</h5>
            <p class="mb-0 text-sm">
              Aqui você pode responder as solicitações dos veredores.
            </p>
          </div>

          <div v-if="this.StoreAll.user.user_category_id !== 1" class="card-header">
            <h5 class="mb-0">Analise de documento!</h5>
            <p class="mb-0 text-sm">
              Aqui você pode visualizar documentos aguardando e resposta das analises.
            </p>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="table-responsive">
                  <DataTable :value="Analyze" show-gridlines responsive-layout="scroll"
                    :paginator="true" :rows="10">

                    <Column field="id" header="Protocolo" class="text-sm font-weight-bold text-wrap text-uppercase"
                      :sortable="true"></Column>

                    <Column field="name" header="Documento" class="text-sm font-weight-bold text-wrap text-uppercase">
                    </Column>

                    <Column field="document_category.name" header="Tipo"
                      class="text-sm font-weight-bold text-wrap text-uppercase"></Column>

                    <Column field="authors" header="Autor(es)" class="text-sm font-weight-bold text-wrap text-uppercase"
                      :sortable="true">
                      <template #body="{ data }">
                        <span>{{ Author(data.authors) }} </span>
                      </template>
                    </Column>

                    <Column field="created_at" header="Data" class="text-sm font-weight-bold text-wrap text-uppercase">
                      <template #body="{ data }">
                        {{ formatDate(data.created_at) }}
                      </template>
                    </Column>

                    <Column header="Resultado da analise"
                      class="text-sm font-weight-bold text-wrap text-uppercase TT text-center">
                      <template #body="slotProps">
                        <p v-if="slotProps.data.document_status_movement_id === 7" class="text-center font-weight-bolder text-white" style="border-radius: 10px; background-color:#ad7725" > Aguardando analise </p>
                        <p v-if="slotProps.data.document_status_movement_id === 8" class="bg-danger text-center font-weight-bolder text-white" style="border-radius: 10px;" > Reprovado </p>
                      </template>
                    </Column>
                    <Column header="Visualizar"
                      class="text-sm font-weight-bold text-wrap text-uppercase TT justify-content-center text-center">
                      <template #body="slotProps">
                        <button style="margin-bottom: 0rem !important;" type="button"
                          class="btn bg-smartlegis btnEdit envio" @click="ViewDoc(slotProps.data)"><i
                            style="color: white !important;" class="fa fa-eye"></i></button>
                      </template>
                    </Column>
                    <Column header="Documentos parecidos"
                      class="text-sm font-weight-bold text-wrap text-uppercase TT justify-content-center text-center">
                      <template #body="slotProps">
                        <button style="margin-bottom: 0rem !important;" type="button"
                          class="btn bg-smartlegis btnEdit envio" @click="GPTSend(slotProps.data.name)"><i
                            style="color: white !important;" class="fa fa-eye"></i></button>
                      </template>
                    </Column>
                  </DataTable>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Toast />
    </div>
  </div>
</template>
<style scoped>
.close {
  font-size: 2.3rem !important;
}

.p-multiselect-token {
  padding: 0.175rem 0.75rem !important;
}

.p-multiselect-label {
  padding: 0px !important;
}

.my_inp {
  width: 100%;
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.38);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
}</style>
