<script>
import { useMainStore } from '../../stores/index';
import {  GChart  } from 'vue-google-charts';
import { AxiosAPI } from '@/axios';

export default {
	name: 'PdfDocs',
	components: {GChart},
	setup() {
		const StoreAll = useMainStore();
		StoreAll.Token();
		return {
			StoreAll: StoreAll,
		};
	},
	data() {
		return {
			loading: true,
			UserRanking: [],
			UserRankingUniq: [],
			typeDetail: 0,
			typeRelatorio: 0,
			pdfData: '',
			RelatorioPDF: false,
			RelatorioPDFAll: false,
			pdfDataAll: '',
			typeRelatorioSlot: [{id: 0, name: 'Tabela'}, {id: 1, name: 'Gráfico'}],
			typeDetailSlot: [{id: 0, name: 'Seus envios'}, {id: 1, name: 'Geral'}],
			date_start: '',
			date_end: '',
			dataIsValid: null,
			tabelaUniq: [],
			pdfDataDetal: '',
			RelatorioPDFDetal: '',
			chartData : [
				[ 'Documentos' ,  '' ,  { role: 'style' } ] ,
				[ 'Aguarde' ,  0 , '#20875b'] ,
				[ 'Aguarde' , 0, 'red' ] ,
				[ 'Aguarde' ,  0, 'silver' ],
			] ,
			chartOptions : {
				title: 'NÚMERO DE DOCUMENTOS ENVIADOS E TIPO, TOTAL:',
				legend: {position: 'none'},
				height: 350,
			},
			chartDataUniq : [
				[ 'Documentos' ,  '' ,  { role: 'style' } ] ,
				[ 'Aguarde' ,  0 , '#20875b'] ,
				[ 'Aguarde' , 0, 'red' ] ,
				[ 'Aguarde' ,  0, 'silver' ],
			] ,
			chartOptionsUniq : {
				title: 'NÚMERO DE DOCUMENTOS ENVIADOS E TIPO, TOTAL:',
				legend: {position: 'none'},
				height: 350,
			}
		};
	},
	mounted() {
		this.GetUser();
		this.GetSession();
	},

	watch: {
		date_start: 'checkDates',
		date_end: 'checkDates',
	},
	methods: {

		async GetUser() {
			await this.StoreAll.User();
		},

		async GetSession() {
			await this.StoreAll.Get_Data();
			this.Counter_Docs();
			this.CounterDocUniq();
		},

		selectType() {
			if (this.typeDetail === 0) {
				this.CounterDocUniq();
			} else {
				this.Counter_Docs();
			}
		},

		checkDates() {
			if (this.date_start && this.date_end) {
				this.CounterDocUniq();
			}
		},

		async CounterDocUniq() {
			if (this.date_start === '' && this.date_end === '' ||
			this.date_start !== '' && this.date_end === '' ||
			this.date_start === '' && this.date_end !== '') {
				this.loading = false;
				this.dataIsValid = false;
				return;
			}
			if (this.date_start >= this.date_end ){
				this.dataIsValid = false;
				this.loading = false;
				return;
			}
			this.loading = true;
			this.RelatorioPDF = false;
			this.RelatorioPDFAll = false;
			await AxiosAPI.get('/reports/documents-sent?datetime_start='+this.date_start+'&datetime_end='+this.date_end+'&user_id='+this.StoreAll.user.id+'', {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((resp) => {
				this.pdfData = 'data:application/pdf;base64,'+ resp.data.data;
				this.RelatorioPDF = true;
			});

			await AxiosAPI.get('/reports/documents-sent-with-details?datetime_start='+this.date_start+'&datetime_end='+this.date_end+'&user_id='+this.StoreAll.user.id+'', {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((resp) => {
				this.pdfDataDetal = 'data:application/pdf;base64,'+ resp.data.data;
				this.RelatorioPDFDetal = true;
			});

			await AxiosAPI.get('/documents?datetime_start='+this.date_start+'&datetime_end='+this.date_end+'&authors=['+this.StoreAll.user.id+']', {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((resp) => {
				if (resp.data.data.length <= 0) {
					this.UserRankingUniq = null;
				}
				else {
					this.chartOptionsUniq =  {
						title: 'NÚMERO DE DOCUMENTOS PRODUZIDOS, TIPO E TOTAL: ' + resp.data.data.length,
						legend: {position: 'none'},
						height: 350,
					};
					const docTypes = {};

					for (let i = 0; i < resp.data.data.length; i++) {
						const documentCategory = resp.data.data[i].document_category;

						if (!docTypes.hasOwnProperty(documentCategory.id)) {
							docTypes[documentCategory.id] = [];
						}

						docTypes[documentCategory.id].push(resp.data.data[i]);
					}

					const DocUsers = {};
					for (let i = 0; i < resp.data.data.length; i++) {
						for(let k = 0; k < resp.data.data[i].authors.length; k++) {

							const documentuser = resp.data.data[i].authors[k];
							if (documentuser.user_category_id !== 1) {
								if (!DocUsers.hasOwnProperty(documentuser.id)) {
									DocUsers[documentuser.id] = [];
								}

								DocUsers[documentuser.id].push(resp.data.data[i]);
							}}
					}


					const DocUSerData = [];

					if (DocUsers.hasOwnProperty(this.StoreAll.user.id)) {
						const documents = DocUsers[this.StoreAll.user.id];

						const documentUser = documents[0].authors[0].nickname;
						const documentUserPhoto = documents[0].authors[0].path_image;
						const documentCount = documents.length;

						const docUserObj = {
							photo: documentUserPhoto,
							Name: documentUser,
							quantidade: documentCount
						};

						DocUSerData.length = 0;
						DocUSerData.push(docUserObj);

						this.UserRankingUniq = DocUSerData;
					} else {
						console.log('O usuário com o ID especificado não foi encontrado.');
					}

					const docTypeData = [];

					for (const documentCategoryId in docTypes) {
						const documents = docTypes[documentCategoryId];

						const documentType = documents[0].document_category.name;

						const documentCount = documents.length;

						const docTypeObj = {
							Tipo: documentType,
							quantidade: documentCount
						};

						docTypeData.push(docTypeObj);
					}

					this.results = docTypeData;
					this.tabelaUniq = docTypeData;

					let chatArray = [
						['Documentos', '', { role: 'style' }]
					];

					for (let i = 0; i < docTypeData.length; i++) {
						let dataRow = [docTypeData[i].Tipo, docTypeData[i].quantidade, '#20875b'];
						chatArray.push(dataRow);
					}


					this.chartDataUniq = chatArray;
				}
				this.loading = false;
				this.dataIsValid = true;
			});
		},

		async Counter_Docs() {
			this.loading = true;
			this.RelatorioPDF = false;
			this.RelatorioPDFAll = false;
			await AxiosAPI.get('/reports/all-documents-sent', {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((resp) => {
				this.pdfDataAll = 'data:application/pdf;base64,'+ resp.data.data;
				this.RelatorioPDFAll = true;
			});
			AxiosAPI.get('/documents', {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((resp) => {
				this.chartOptions =  {
					title: 'NÚMERO DE DOCUMENTOS ENVIADOS, TIPO E TOTAL: ' + resp.data.data.length,
					legend: {position: 'none'},
					height: 350,
				};
				const docTypes = {};

				for (let i = 0; i < resp.data.data.length; i++) {
					const documentCategory = resp.data.data[i].document_category;

					if (!docTypes.hasOwnProperty(documentCategory.id)) {
						docTypes[documentCategory.id] = [];
					}

					docTypes[documentCategory.id].push(resp.data.data[i]);
				}

				const DocUsers = {};
				for (let i = 0; i < resp.data.data.length; i++) {
					for(let k = 0; k < resp.data.data[i].authors.length; k++) {

						const documentuser = resp.data.data[i].authors[k];
						if (documentuser.user_category_id !== 1) {
							if (!DocUsers.hasOwnProperty(documentuser.id)) {
								DocUsers[documentuser.id] = [];
							}

							DocUsers[documentuser.id].push(resp.data.data[i]);
						}}
				}

				const DocUSerData = [];

				for (const documentUserId in DocUsers) {

					const documents = DocUsers[documentUserId];

					if(documents[0].authors.name == 'Secretário'){
						continue;
					}else{
						const documentUser = documents[0].authors[0].nickname;

						const documentUserPhoto = documents[0].authors[0].path_image;

						const documentCount = documents.length;

						const docUserObj = {
							photo: documentUserPhoto,
							Name: documentUser,
							quantidade: documentCount
						};

						DocUSerData.push(docUserObj);
					}
				}
				const compareByQuantidade = (a, b) => b.quantidade - a.quantidade;

				DocUSerData.sort(compareByQuantidade);

				const Ranking = DocUSerData.slice(0, 3);

				this.UserRanking = Ranking;

				const docTypeData = [];

				for (const documentCategoryId in docTypes) {
					const documents = docTypes[documentCategoryId];

					const documentType = documents[0].document_category.name;

					const documentCount = documents.length;

					const docTypeObj = {
						Tipo: documentType,
						quantidade: documentCount
					};

					docTypeData.push(docTypeObj);
				}

				this.results = docTypeData;

				let chatArray = [
					['Documentos', '', { role: 'style' }]
				];

				for (let i = 0; i < docTypeData.length; i++) {
					let dataRow = [docTypeData[i].Tipo, docTypeData[i].quantidade, '#20875b'];
					chatArray.push(dataRow);
				}


				this.chartData = chatArray;
				this.loading = false;
			});
		},
	},
};
</script>
<template>
    <div class="py-4 h-100 container-fluid">
        <div class="row selectdoc">
            <div class="text-center col-12">
                <h3 class="mt-auto text-white">Relatório de documentos</h3>
                <h5 class="text-white font-weight-normal">
                    Aqui você encontra algumas informações sobre seus envios e os documentos de sua câmara!
                </h5>
                <div class="mb-5 multisteps-form">
                    <div class="row">
                        <div class="m-auto col-12 col-lg-12">
                            <div>
                                <div class="
                      p-3
                      bg-white
                      card
                      multisteps-form__panel
                      border-radius-xl
                      js-active
                      position-relative
                    " data-animation="FadeIn">
                                    <div class="col-lg-12">
                                        <div class="row d-flex justify-content-center">
                                            <div class="text-center row">
                                                <div class="mx-auto col-10">
                                                </div>
                                            </div>

                                            <label style="font-size: 20px; color: black;">Tipo de Relatório</label>
                                                <div class="d-flex justify-content-center mb-3">
                                                  <select
                                                    id="choices-category"
                                                    v-model="typeDetail"
                                                    class="form-control d-flex justify-content-center aling"
                                                    name="choices-category"
                                                    style="width: 30% !important; color: #000 !important; font-size: 15px;"
                                                    @change="selectType"
                                                  >
                                                    <option
                                                      v-for="option in typeDetailSlot"
                                                      :key="option.id"
                                                      :value="option.id"
                                                    >
                                                      {{ option.name }}
                                                    </option>
                                                  </select>
                                                </div>
                                          <div v-if="loading === true"> Carregando</div>

                                          <div v-else>
                                            <div v-if="typeDetail === 1">
                                                <div class="d-flex justify-content-center">
                                                    <h4 class="text-uppercase text-black font-weight-bold text-center">
                                                        MAIORES NÚMEROS DE DOCUMENTOS ENVIADOS </h4>
                                                </div>
                                                <div class="d-flex justify-content-center text-black">
                                                    <div v-for="User_Rank in UserRanking" :key="User_Rank"
                                                        class="text-center mr-3">
                                                        <img :src="this.StoreAll.Dochost+User_Rank.photo"
                                                            class="rounded-circle justify-content-start photo_ranking"
                                                            style="object-fit: cover;" />

                                                        <div>
                                                            <span
                                                                class="text-uppercase text-black font-weight-bold text-center"
                                                                style="font-size: 20px;">
                                                                <b> {{ User_Rank.Name }} </b>
                                                            </span>
                                                        </div>

                                                        <div>
                                                            <span
                                                                class="text-uppercase text-black font-weight-bold text-center"
                                                                style="font-size: 20px;">
                                                                Documentos: <b>{{ User_Rank.quantidade }} </b>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <GChart type="ColumnChart" :data="chartData" :options="chartOptions" />

												<div class="mt-4">
													<a download="Relatório" :href="pdfDataAll">
														<button v-if="RelatorioPDFAll" type="button" class="btn btn-success mb-2 mr-2"> Baixar relatório</button>
													</a>
												</div>
                                            </div>

                                            <div v-else>
											<div class="d-flex justify-content-center">
												<div>
													<label>início</label>
													<input type="date" v-model="date_start" class="form-control" />
												</div>
												<div>
													<label>fim</label>
													<input type="date" v-model="date_end" class="form-control" /></div>
											</div>
											<div v-if="!dataIsValid">
												<span>Data invalida.</span>
											</div>
											<div v-if="dataIsValid">
                                              <div v-if="UserRankingUniq === null">
                                                <p> Nenhum documento enviado </p>
                                              </div>
                                              <div v-else>
                                              <div class="d-flex justify-content-center">
                                                    <h4 class="text-uppercase text-black font-weight-bold text-center">
                                                        Dados computados </h4>
                                                </div>
                                                <div class="d-flex justify-content-center text-black">
                                                    <div v-for="User_Rank in UserRankingUniq" :key="User_Rank"
                                                        class="text-center mr-3">
                                                        <img :src="this.StoreAll.Dochost+User_Rank.photo"
                                                            class="rounded-circle justify-content-start photo_ranking"
                                                            style="object-fit: cover;" />

                                                        <div>
                                                            <span
                                                                class="text-uppercase text-black font-weight-bold text-center"
                                                                style="font-size: 20px;">
                                                                <b> {{ User_Rank.Name }} </b>
                                                            </span>
                                                        </div>

                                                        <div>
                                                            <span
                                                                class="text-uppercase text-black font-weight-bold text-center"
                                                                style="font-size: 20px;">
                                                                Documentos produzidos: <b>{{ User_Rank.quantidade }} </b>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <label style="font-size: 20px; color: black;">Formato de Relatório</label>
                                                <div class="d-flex justify-content-center mb-3">
                                                  <select
                                                    id="choices-category"
                                                    v-model="typeRelatorio"
                                                    class="form-control d-flex justify-content-center aling"
                                                    name="choices-category"
                                                    style="width: 30% !important; color: #000 !important; font-size: 15px;"
                                                  >
                                                    <option
                                                      v-for="option in typeRelatorioSlot"
                                                      :key="option.id"
                                                      :value="option.id"
                                                    >
                                                      {{ option.name }}
                                                    </option>
                                                  </select>
                                                </div>
                                                <div v-if="typeRelatorio === 0">
                                                  <table>
                                                    <tr class="text-center">
                                                      <th style="font-size: 21px;font-family: 'Dosis', sans-serif; width: 10%;">TIPO</th>
                                                      <th v-for=" UniqData in tabelaUniq" :key="UniqData.tipo" style="font-size: 21px;font-family: 'Dosis', sans-serif;"> {{ UniqData.Tipo }} </th>
                                                    </tr>

                                                    <tr class="text-center">
                                                      <th style="font-size: 21px;font-family: 'Dosis', sans-serif; width: 10%;">QUANTIDADE</th>
                                                      <th v-for=" UniqData in tabelaUniq" :key="UniqData.tipo" style="font-size: 21px;font-family: 'Dosis', sans-serif;"> {{ UniqData.quantidade }} </th>
                                                    </tr>
                                                  </table>
                                                </div>
                                                <div v-else>
                                                  <GChart type="ColumnChart" :data="chartDataUniq" :options="chartOptionsUniq" />
                                                </div>
                                            </div>
										</div>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="mt-4">
                                          <a download="Relatório" :href="pdfData">
                                            <button v-if="RelatorioPDF" type="button" class="btn btn-success mb-2 mr-2"> Baixar relatório simplificado</button>
                                          </a>
										  <a download="Relatório" :href="pdfDataDetal">
                                            <button v-if="RelatorioPDFDetal" type="button" class="btn btn-success mb-2 mr-2"> Baixar relatório detalhado</button>
                                          </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style></style>
<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid #000;
  padding: 10px;
  text-align: center;
}
</style>