<template>
      <div class="container-fluid">
        <div class="mt-4 row">
          <div class="col-12">
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <h5 class="mb-0">Pesquise todos documentos enviados no sistema!</h5>
                <p class="mb-0 text-sm">
                  Aquivo você pode visualizar todos os documentos enviados no sistema.
                </p>
              </div>
    
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="table-responsive">                  
                      
                        <div>
                            <button @click="trancs" :disabled="recording">Iniciar Gravação</button>
                            <button @click="stopRecording" :disabled="!recording">Parar Gravação</button>
                            <p v-if="recording">Gravando...</p>
                        </div>
                        
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    

    
      </div>
    </template>
    
<script>
/* import { AxiosAPI } from "@/axios"; */
import { useMainStore } from '../../stores/index';
/* import AWS from 'aws-sdk'; */
/*  import { TranscribeClient, CreateCallAnalyticsCategoryCommand } from "@aws-sdk/client-transcribe";
     */
export default {
	name: 'DocLive',
	components: {
	},
	setup(){
		const store_all = useMainStore();
		store_all.Token();
		return{
			store_all: store_all,
		};
	},
	data() {
		return {
			recording: false,
			stream: null,
			mediaRecorder: null,
			transcribeService: null,
			transcriptionJobName: 'teste1',
			transcript: '',
		};
	},
	mounted() {
		this.GetUser();
		this.GetUsersAll();
		this.GetSession();
	},

	methods: {  
    
		async GetUsersAll() {
			await this.store_all.UserAllEnvio();
			this.vereadores = this.$pinia.state.value.main.user_envio;  
		},
    
		async GetUser(){
			await this.store_all.User(); 
			this.category_user = this.$pinia.state.value.main.user.user_category_id;
		},
    
		async GetSession() {
			await this.store_all.Get_Data();
		},
    
		async startRecording() {
			try {
				const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
				this.stream = stream;
				this.mediaRecorder = new MediaRecorder(stream);
				this.mediaRecorder.ondataavailable = this.handleDataAvailable;
				this.mediaRecorder.start();
				this.recording = true;
			} catch (error) {
				console.error('Erro ao iniciar a gravação:', error);
			}
		},
		stopRecording() {
			if (this.mediaRecorder && this.recording) {
				this.mediaRecorder.stop();
				this.recording = false;
				this.stream.getTracks().forEach((track) => track.stop());
			}
		},
		handleDataAvailable(event) {
			if (event.data.size > 0) {
				const audioBlob = event.data;

				// Enviar a parte do áudio para a API de transcrição
				this.transcribeAudioChunk(audioBlob);
			}
		},
		/*     transcribeAudioChunk(audioBlob) {
      const params = {
        TranscriptionJobName: this.transcriptionJobName,
        LanguageCode: 'en-US', // Especifique o código de idioma apropriado
        Media: { MediaFileUri: URL.createObjectURL(audioBlob) },
        MediaFormat: 'audio/wav', // Certifique-se de usar o formato correto aqui (exemplo: 'audio/wav')
      };

      this.transcribeService.startTranscriptionJob(params, (err, data) => {
        if (err) {
          console.error('Erro ao iniciar trabalho de transcrição:', err);
        } else {
          console.log('Trabalho de transcrição iniciado:', data);
        }
      });
    }, */
		startTranscription() {
			// Create an instance of the Amazon Transcribe client.
			const transcribeClient = new TranscribeClient({
				region: 'us-east-1',
			});

			// Start the audio stream.
			const audioStream = new AudioStream();
			audioStream.start();

			// Send the audio stream to the Amazon Transcribe client.
			transcribeClient.startStreamTranscription({
				mediaStream: audioStream,
				languageCode: 'pt-BR',
			});

			// Listen for transcription events.
			transcribeClient.onTranscriptionEvent((event) => {
				// Print the transcript to the console.
				this.transcript = event.transcript;
			});

			/* "accessKeyId": "AKIATJKKVKVMHXRSPEKF",
    "secretAccessKey": "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", */
		},

    
	},
};
</script>
    <style>
    .close{
      font-size: 2.3rem !important;
    }
    
    </style>
    