<template>
  <div>
    <Modal_view_Tipe_Doc ref="Modal_view_Tipe_Doc"></Modal_view_Tipe_Doc>
  </div>
  <div class="py-4 h-100 container-fluid">
    <div class="row selectdoc">
      <div class="text-center col-12">
        <h3 class="mt-auto text-white">Precisa criar um documento?</h3>
        <h5 class="text-white font-weight-normal">
          Basta selecionar, editar ou baixar em sua maquina!
        </h5>
        <div class="mb-5 multisteps-form">
          <div class="row">
            <div class="m-auto col-12 col-lg-8">
              <div>
                <div
                  class="
                    p-3
                    bg-white
                    card
                    multisteps-form__panel
                    border-radius-xl
                    js-active
                    position-relative
                  "
                  data-animation="FadeIn"
                >
                  <div class="col-lg-12">
                    <div class="row d-flex justify-content-center">
                      <div class="text-center row">
                        <div class="mx-auto col-10">
                          <h5 class="font-weight-normal">
                            Selecione um dos modelos de documento
                          </h5>
                        </div>
                      </div>
                      
                      <div
                        class="card"
                        style="width: 9.5rem; cursor: pointer"
                      >
                      <router-link :to="{ name: 'Modelo de Indicação'}" exact>
                        <img
                          class="card-img-top"
                          style="width: 8.3rem"
                          src="../../assets/img/pdfimage.png"
                          alt="Card image cap"
                        />
                        <div class="card-body">
                          <h5 class="card-title" style="font-size: 16px">
                            Indicação
                          </h5>
                        </div>
                      </router-link>
                      </div>
                      <div
                        class="card"
                        style="width: 9.5rem; cursor: pointer"
                        @click="Types_Doc()"
                      >
                        <img
                          class="card-img-top"
                          style="width: 8.3rem"
                          src="../../assets/img/pdfimage.png"
                          alt="Card image cap"
                        />
                        <div class="card-body">
                          <h5 class="card-title" style="font-size: 16px">
                            Moção
                          </h5>
                        </div>
                      </div>
                      <div
                        class="card"
                        style="width: 9.5rem; cursor: pointer"
                        data-toggle="modal"
                        data-target=".bd-example-modal-xl-PLL"
                      >
                      <router-link :to="{ name: 'Modelo PLL'}" exact>
                        <img
                          class="card-img-top"
                          style="width: 8.3rem"
                          src="../../assets/img/pdfimage.png"
                          alt="Card image cap"
                        />
                        <div class="card-body">
                          <h5 class="card-title" style="font-size: 16px">
                            PLL
                          </h5>
                        </div>
                      </router-link>
                      </div>
                      <div
                        class="card"
                        style="width: 9.5rem; cursor: pointer"
                        data-toggle="modal"
                        data-target=".bd-example-modal-xl-PLE"
                      >
                      <router-link :to="{ name: 'Modelo PLE'}" exact>
                        <img
                          class="card-img-top"
                          style="width: 8.3rem"
                          src="../../assets/img/pdfimage.png"
                          alt="Card image cap"
                        />
                        <div class="card-body">
                          <h5 class="card-title" style="font-size: 16px">
                            PLE
                          </h5>
                        </div>
                      </router-link>
                      </div>
                      <div
                        class="card"
                        style="width: 9.5rem; cursor: pointer"
                        data-toggle="modal"
                        data-target=".bd-example-modal-xl-PDL"
                      >
                      <router-link :to="{ name: 'Modelo PDL'}" exact>
                        <img
                          class="card-img-top"
                          style="width: 8.3rem"
                          src="../../assets/img/pdfimage.png"
                          alt="Card image cap"
                        />
                        <div class="card-body">
                          <h5 class="card-title" style="font-size: 16px">
                            PDL
                          </h5>
                        </div>
                      </router-link>
                      </div>
                      <div
                        class="card"
                        style="width: 9.5rem; cursor: pointer"
                        data-toggle="modal"
                        data-target=".bd-example-modal-xl-RV"
                      >
                      <router-link :to="{ name: 'Modelo RV'}" exact>
                        <img
                          class="card-img-top"
                          style="width: 8.3rem"
                          src="../../assets/img/pdfimage.png"
                          alt="Card image cap"
                        />
                        <div class="card-body">
                          <h5 class="card-title" style="font-size: 16px">REQ</h5>
                        </div>
                      </router-link>
                      </div>            
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useMainStore } from '../../stores/index';
import Modal_view_Tipe_Doc from '../../components/documents/Modals/Modal_Tipe_Doc.vue';
export default {
	name: 'ModelosDoc',
	components: {Modal_view_Tipe_Doc},
	setup(){
		const store_all = useMainStore();
		store_all.Token();
		return{
			store_all: store_all,
		};
	},
	data() {
		return {
      
		};
	},
	mounted() {
		this.GetUser();
		this.GetSession(); 
    
	},

	methods: {
    
		async GetUser(){
			await this.store_all.User(); 
		},

		async GetSession() {
			await this.store_all.Get_Data();
		},

		Types_Doc(){
			this.$refs.Modal_view_Tipe_Doc.openModal();
		},

	},
};
</script>
<style>

</style>