<script>
import { useMainStore } from '../../stores/index';
import ArgonInput from '@/components/ArgonInput.vue';
import ArgonSwitch from '@/components/ArgonSwitch.vue';
import ArgonButton from '@/components/ArgonButton.vue';
import ModalEdit from '../documents/Modals/Modal_Edit.vue';

export default {
	name: 'DashboardDefault',
	components: {  
		ArgonInput,
		ArgonSwitch,
		ArgonButton,
		ModalEdit,
	},
	setup() {
		const StoreAll = useMainStore();
		StoreAll.Token();
		return {
			StoreAll: StoreAll,
		};
	},
	data() {
		return {
			data_start: '',
			session_name: '',
			session_id: 0,
			user_name: '',
			user: [],
			emailoculto: '',
			password: '',
		};
	},
	mounted() {
		this.User();
		this.GetSession();
		this.GetUsersAll();
		this.Notification_sign();

		this.emitter.on('edit', () => {
			this.User();
		});
	},
	methods: {
		async GetUsersAll() {
			await this.StoreAll.UserAllEnvio();
		},

		async GetSession() {
			await this.StoreAll.Get_Data();
			this.session_id = this.StoreAll.session.id;
		},

		async User() {
			await this.StoreAll.User();
			this.user = this.StoreAll.user;
			this.Data_User(this.user.email);
		},

		async Notification_sign() {
			await this.StoreAll.Notification();
		},

		async Data_User(email) {
			const posicaoArroba = email.indexOf('@');

			// Obtém as três primeiras letras do email
			const tresPrimeirasLetras = email.substring(0, 3);

			// Cria uma string de asteriscos com base no tamanho do email após o "@"
			const asteriscosRestantes = '*'.repeat(posicaoArroba - 3);

			// Retorna o email oculto
			this.emailoculto = tresPrimeirasLetras + asteriscosRestantes + email.substring(posicaoArroba);
		},

		edit_image() {
			this.$refs.ModalEdit.openModal_avatar();
		},

		edit_name() {
			this.$refs.ModalEdit.openModal_name();
		},

		edit_email() {
			this.$refs.ModalEdit.openModal_email();
		},

		edit_apelido() {
			this.$refs.ModalEdit.openModal_apelido();
		},

		edit_password() {
			this.$refs.ModalEdit.openModal_password();
		},


		showSuccess_edit() {
			this.$toast.add({ severity: 'success', summary: 'Edição realizada com sucesso', detail: 'A edição foi um sucesso!', life: 3000 });
		},


		showErro_edit() {
			this.$toast.add({ severity: 'error', summary: 'Edição não realizada', detail: 'Contate o administrador para verificar o error', life: 3000 });
		},

		finallyloading() {
			document.getElementById('loads').style.display = 'flex';
			document.getElementById('loadingoff').style.display = 'none';
			document.getElementById('sessionoff').style.display = 'none';
			document.getElementById('dataoff').style.display = 'none';
			document.getElementById('start_tribune').style.display = 'none';
		},

	},
};
</script>
<template>
    <div>
      <ModalEdit ref="ModalEdit"></ModalEdit>
    </div>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="container-fluid">
                        <div class="mt-4 row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="d-flex justify-content-center">
                                            <h5 class="mb-0">Aqui estão algumas informações sobre sua conta.</h5>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <p class="mb-0 text-sm">
                                                Você pode visualizar e editar seus dados.
                                            </p>
                                        </div>
                                    </div>

                                    <div class="d-flex justify-content-center">
                                        <div class="image-container" style="width: 200px;" @click="edit_image()">
                                            <img class="hover-image" :src="this.StoreAll.Dochost+user.path_image" style="width: 200px;">
                                            <div class="icon-container">
                                                <i class="fa fa-edit"></i>
                                                <span class="text-dark">Editar</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                            <h5 class="mb-0">IMPORTANTE: AO ALTERAR SEU <span class="text-danger">EMAIL E NOME COMPLETO</span>, CASO EXISTAM DOCUMENTOS PEDENTES DE ASSINATURA, NÃO PODERÃO SER MAIS ASSINADOS.</h5>
                                        </div>
                                    <div class="d-flex justify-content-center">
                                        <div style="width: 50%;">
                                            <label>Nome completo</label>
                                            <input class="mb-3 multisteps-form__input form-control" type="text"
                                                :placeholder="user.name" disabled />
                                                <div>
                                                <button class="mb-0 btn bg-success text-white js-btn-next" type="button"
                                                @click="edit_name()">
                                                    Editar nome completo
                                                </button></div>
                                            <label class="mt-3">Email</label>
                                            <input class="mb-3 multisteps-form__input form-control" type="text"
                                                :placeholder="emailoculto" disabled />
                                            <div>
                                            <button class="mb-0 btn bg-success text-white js-btn-next" type="button"
                                            @click="edit_email()">
                                                    Editar email
                                            </button></div>

                                            <label class="mt-3">Apelido (Nome em tv)</label>
                                            <input class="mb-3 multisteps-form__input form-control" type="text"
                                                :placeholder="user.nickname" disabled />
                                                <div>
                                            <button class="mb-0 btn bg-success text-white js-btn-next" type="button"
                                            @click="edit_apelido()">
                                                    Editar apelido
                                            </button></div>

                                            <label class="mt-3">Senha</label>
                                            <input class="mb-3 multisteps-form__input form-control" type="text"
                                                placeholder="********" disabled />
                                                <div>
                                            <button class="mb-0 btn bg-success text-white js-btn-next" type="button"
                                            @click="edit_password()">
                                                    Editar senha
                                            </button></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.image-container {
    position: relative;
    cursor: pointer;
}

.hover-image {
    width: 300px;
    /* Defina o tamanho desejado para a imagem */
    transition: opacity 0.3s;
}

.icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    pointer-events: none;
    /* Impede que o ícone seja clicável */
    transition: opacity 0.3s;
}

.icon {
    width: 50px;
    /* Defina o tamanho desejado para o ícone */
}

.image-container:hover .hover-image {
    opacity: 0.4;
    /* Defina a opacidade desejada ao passar o mouse */
}

.image-container:hover .icon-container {
    opacity: 1;
}
</style>
  