<script>
import { AxiosAPI } from '@/axios';
import Modal_view from '../../components/documents/Modals/Modal_View.vue';
import Modal_view_Quorum from '../../components/documents/Modals/Modal_View_Quorum.vue';
import Modal_view_Discut from '../../components/documents/Modals/Modal_View_Discut.vue';
import Modal_View_Send_Prefeitura from '../../components/documents/Modals/Modal_View_Send_Prefeitura.vue';
import Modal_View_Send_VoteLote from '../../components/documents/Modals/Modal_View_VoteLote.vue';
import Modal_view_Stamp from '../../components/documents/Modals/Modal_View_Stamp.vue';
import Loading from '../../components/documents/Modals/Loading.vue';
import { orderBy } from 'lodash';
import { useMainStore, useBusStore } from '../../stores/index';
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import moment from 'moment';

export default {
	name: 'DataTables',
	components: {
		Modal_view,
		Modal_view_Quorum,
		Modal_view_Discut,
		Modal_View_Send_Prefeitura,
		Modal_View_Send_VoteLote,
		Modal_view_Stamp,
		Loading,
	},
	setup() {
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		StoreAll.Token();
		StoreAll.User();

		return {
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			filters_expediente: {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
				'name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
				'document_category.name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
				'created_at': {value: null, matchMode: FilterMatchMode.EQUALS},
				'id': {value: null, matchMode: FilterMatchMode.EQUALS}
			},
			filters_ordem: {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
				'name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
				'document_category.name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
				'created_at': {value: null, matchMode: FilterMatchMode.EQUALS},
				'id': {value: null, matchMode: FilterMatchMode.EQUALS}
			},
			DayOrder: [],
			WorkDay: [],
			name_session_edit: '',
			selected_products: null,
			selections: [],
			session: {},
			vereadores: [],
			vereadores_edit: [],
			ordem: 1,
			autores: [],
			documento_id: 0,
			documento_name: '',
			form: {
				NameDoc: '',
				TypeDoc: 1,
			},
			categories: [],
			select: false,
			socket_ids: [],
			pdfPresentes: ''
		};
	},
	watch: {
		selected_products: function (novoValor, valorAntigo) {
			if (novoValor !== null) {
				if(this.selected_products.length != 0 && this.selected_products.length >= 1){
					this.select = true;
				}else{
					this.select = false;
				}
			}
		},

		'Bus.nominalVote': function (newValue, oldPath) {
			if (newValue === true) {
				this.GetDayOrder();
				this.GetWorkDay();
			}
		},

		'Bus.docSendSession': function (newValue, oldPath) {
			if (newValue === true) {
				this.Bus.docSendSession = null;
				this.GetWorkDay();
				this.GetDayOrder();
			}
		},

		'Bus.GetSessionStart': function (newValue, oldPath) {
			if (newValue === true) {
				this.Bus.GetSessionStart = null;
				this.GetSessionStart();
			}
		},

	},

	created () {
		let OnSocket = setInterval(() => {
			if (this.StoreAll.websocket_host !== null) {
				clearInterval(OnSocket);
				window.Echo.channel(this.StoreAll.websocket_host).listen('.' + this.StoreAll.websocket_host, (e) => {
					if (e.socket_id === 1) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							if (this.$route.name == 'Sessão da semana') {
								this.GetSessionStart();
							}
							this.socket_ids.push(e.event_id);
						}
					}

					if (e.socket_id === 2) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							if (this.$route.name == 'Sessão da semana') {
								this.GetSessionStart();
							}
							this.socket_ids.push(e.event_id);
						}
					}

					if (e.socket_id === 3) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							if (this.$route.name == 'Sessão da semana') {
								this.GetDayOrder();
								this.GetWorkDay();
							}
							this.socket_ids.push(e.event_id);
						}
					}
				});
			}}, 500);

		let OnSystem = setInterval(() => {
			if (this.StoreAll.socket !== null) {
				clearInterval(OnSystem);
				this.StoreAll.socket.on('8', (e) => {
					this.GetDayOrder();
					this.GetWorkDay();
				});
			}}, 500);
	},

	mounted() {
		this.GetSession();
		this.GetUsersAll();
		this.User();
	},

	methods: {

		Author(value){
			let Co_user = [];
			for (let i = 0; i < value.length; i++) {
				Co_user.push(value[i].nickname);
			}
			return Co_user.join(', ');
		},
		Discut(event) {
			this.$refs.Modal_view_Discut.openModal(event);
		},

		Discut_lote() {
			this.StoreAll.MultiSelectDocs = this.selected_products;
			this.$refs.Modal_view_Discut.openModalLote();
		},

		DiscutStatus(value) {
			this.$refs.Modal_view_Discut.StatusDisc(value);
		},

		SendPrefeitura_show() {
			this.StoreAll.MultiSelectDocs = this.selected_products;
			this.$refs.Modal_View_Send_Prefeitura.openModal();
		},

		VoteLote_show() {
			this.StoreAll.MultiSelectDocs = this.selected_products;
			this.$refs.Modal_View_Send_VoteLote.openModal();
		},

		EventDoc_edit(event){
			this.autores = [];
			this.autores = event.authors;
			this.documento_id  = event.id;
			this.documento_name = event.name;
			this.form.NameDoc = event.name;
			this.form.TypeDoc = event.document_category_id;
		},

		async EditDoc(){
			this.$refs.Loading.openModal();

			let autores = [];

			for(let i = 0; i < this.autores.length; i++){
				autores.push(this.autores[i].id);
			}

			if (document.getElementById('formFile').files[0] === undefined) {
				await AxiosAPI.put('document/' + this.documento_id,{
					name: this.form.NameDoc,
					document_category_id: this.form.TypeDoc,
				},
				{
					headers: {
						authorization: 'Bearer '+ this.StoreAll.accessToken,
					},
				}
				).then((resp)=>{
					if(resp.status === 200){
						let dat = JSON.stringify({
							document_id: this.documento_id,
							authors: autores,
						});
						AxiosAPI.patch('document/update-authors',dat,
							{
								headers: {
									'Content-Type': 'application/json',
									authorization: 'Bearer '+ this.StoreAll.accessToken,
								},
							}
						).then((resp)=>{
							if(resp.status === 200){
								this.$refs.Loading.closeModal();
								this.showSuccess_edit();
								this.GetDayOrder();
								this.GetWorkDay();
      
							}
						});}else{
						this.$refs.Loading.closeModal();
						this.showError_edit();
					}
				});
			}else{
				let file = document.getElementById('formFile').files[0];
				const formData = new FormData();
				formData.append('_method', 'PATCH');
				formData.append('document_id', this.documento_id);
				formData.append('attachment', file);

				await AxiosAPI.post('document/update-attachment', formData,{
					headers: {
						authorization: 'Bearer '+ this.StoreAll.accessToken,
					},
				}
				).then((resp)=>{
					if(resp.status === 200){
						document.getElementById('formFile').value = '';

						AxiosAPI.put('document/' + this.documento_id,{
							name: this.form.NameDoc,
							document_category_id: this.form.TypeDoc,
						},
						{
							headers: {
								authorization: 'Bearer '+ this.StoreAll.accessToken,
							},
						}
						).then((resp)=>{
							if(resp.status === 200){
								let dat = JSON.stringify({
									document_id: this.documento_id,
									authors: autores,
								});
								AxiosAPI.patch('document/update-authors',dat,
									{
										headers: {
											'Content-Type': 'application/json',
											authorization: 'Bearer '+ this.StoreAll.accessToken,
										},
									}
								).then((resp)=>{
									if(resp.status === 200){
										this.$refs.Loading.closeModal();
										this.showSuccess_edit();
										this.GetDayOrder();
										this.GetWorkDay();}
								});}else{
								this.$refs.Loading.closeModal();
								this.showError_edit();
							}});
					}
          
				}).catch(error => { 
					this.error_msg = error.response.data.message;
					document.getElementById('formFile').value = '';
					this.$refs.Loading.closeModal();
					this.showError_edit_model();
				});
      
			}

		},

		EditSession(){
			AxiosAPI
				.put('/session/'+this.StoreAll.session.id+'?name='+this.name_session_edit,{}, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				})
				.then((response) => {
					this.GetSession();
					console.log(response);
				});
		},
		async ChangeOrder(event){    
			const session_id = this.StoreAll.session.id;
			await AxiosAPI
				.patch(`/session/${session_id}/change-order`,{
					'documents': [event.id]
				}, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				})
				.then((response) => {
					this.GetDayOrder();
					this.GetWorkDay();
					console.log(response);
				});
		},

		clearFilter_ordem() {
			this.initFilters_ordem();
		},

		initFilters_ordem() {
			this.filters_ordem = {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
				'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
				'document_category.name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
				'created_at': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
				'id': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
			};
		},

		clearFilter_Expediente() {
			this.initFilters_Expediente();
		},

		initFilters_Expediente() {
			this.filters_expediente = {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
				'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
				'document_category.name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
				'created_at': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
				'id': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
			};
		},

		editProduct(event) {
			this.StoreAll.document_details = [];
			this.StoreAll.document_src = [];
			this.StoreAll.document_details = event;
			this.StoreAll.carimbo_camara = false;
			this.StoreAll.carimbo_prefeitura = false;

			if (event.carimbo_camara_pdf == null) {
				this.StoreAll.Leitura = false;
				if (event.attachment_partial != null) {
					this.StoreAll.document_src = event.attachment_partial;
				} else {
					this.StoreAll.document_src = event.attachment;
				}
				this.StoreAll.carimbo_camara = false;
				this.StoreAll.carimbo_prefeitura = false;
			} else if (event.carimbo_camara_pdf != null && event.carimbo_prefeitura_pdf != null) {
				this.StoreAll.Leitura = true;
				this.StoreAll.document_src = event.carimbo_prefeitura_pdf;
				this.StoreAll.carimbo_camara = false;
				this.StoreAll.carimbo_prefeitura = true;
			} else if (event.carimbo_camara_pdf != null && event.carimbo_prefeitura_pdf == null) {
				this.StoreAll.Leitura = true;
				this.StoreAll.document_src = event.carimbo_camara_pdf;
				this.StoreAll.carimbo_camara = true;
				this.StoreAll.carimbo_prefeitura = false;
			} else {
				this.StoreAll.Leitura = false;
				if (event.attachment_partial != null) {
					this.StoreAll.document_src = event.attachment_partial;
				} else {
					this.StoreAll.document_src = event.attachment;
				}
				this.StoreAll.carimbo_camara = false;
				this.StoreAll.carimbo_prefeitura = false;
			}

			if(event.parecer_procurador_pdf != null){
				this.StoreAll.parecer_procurador_pdf = event.parecer_procurador_pdf;
			}else{
				this.StoreAll.parecer_procurador_pdf = null;
			}

			this.$refs.Modal_view.openModal2();
		},

		ShowCarimbo(){
			this.StoreAll.MultiSelectDocs = this.selected_products;
			this.$refs.Modal_view_Stamp.openModal3();
		},

		Quorum_modal(value) {
			this.$refs.Modal_view_Quorum.openModal(value);
			this.$refs.Modal_view_Quorum.FindUsers();
		},
		onRowSelect(event) {
			console.log(event);
		},
		onRowUnselect(event) {
			console.log(event);
		},

		GetWorkDay() {
			AxiosAPI
				.get('/documents', {
					params: {
						session_id: this.StoreAll.session.id,
						ordem: 0,
					},
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				})
				.then((response) => {
					this.WorkDay = response.data.data;
					this.OrdeDocs();
				});
		},

		GetDayOrder() {
			AxiosAPI
				.get('/documents', {
					params: {
						session_id: this.StoreAll.session.id,
						ordem: 1,
					},
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				})
				.then((response) => {
					this.DayOrder = response.data.data;
				});
		},

		OrdeDocs(){
			const sortOrder1 = ['ATA'];
			this.WorkDay = orderBy(this.WorkDay, (item) => {
				const index = sortOrder1.indexOf(item.document_category.name);
				return index === -1 ? Infinity : index;
			});
		},


		formatDate(value) {
			moment.locale('pt-br');
			return moment(value).format('DD/MMMM/YYYY');
		},

		async GetSession() {
			await this.StoreAll.Get_Data();
			const { session } = this.StoreAll;
			if (session == null) {
				this.GetSession();
				return;
			}
			const { session_status } = session;
			this.session = session;
			this.session_status = session_status.id;
			this.name_session_edit = session.name;
			this.GetDayOrder();
			this.GetWorkDay();
			await this.StoreAll.DocCategory();

			let OnSystem = setInterval(() => {
				if (this.StoreAll.document_category != null) {
					clearInterval(OnSystem);
					this.categories = this.StoreAll.document_category;
				}
			}, 500);
		},

		async GetSessionStart() {
			await this.StoreAll.Get_Data();
			const { session } = this.StoreAll;
			if (session == null) {
				this.GetSession();
				return;
			}
			const { session_status } = session;
			this.session = session;
			this.session_status = session_status.id;
			this.GetDayOrder();
			this.GetWorkDay();
		},

		async GetUsersAll() {
			await this.StoreAll.UserAll();
			let vereadores = [];
			for (let i = 0; i < this.StoreAll.user_all.length; i++) {
				if (this.StoreAll.user_all[i].user_category_id == 1 || this.StoreAll.user_all[i].user_category_id == 5 ||
          this.StoreAll.user_all[i].user_category_id == 6 || this.StoreAll.user_all[i].user_category_id == 7) {
					continue;
				} else {
					vereadores.push(this.StoreAll.user_all[i]);
				}
			}
			this.vereadores = vereadores;
			this.StoreAll.vereadores = vereadores;

			await this.StoreAll.UserAllEnvio();
			this.vereadores_edit = this.StoreAll.user_envio;
		},

		async User() {
			await this.StoreAll.User();
		},

		EndSession() {
			AxiosAPI
				.post(
					'/session/change-status?session_id=' +
          this.StoreAll.session.id +
          '&session_status_id=3',
					{},
					{
						headers: {
							authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}
				)
				.then(() => {
					this.GetSessionStart();
					this.finish_session_show();
				},);
		},

		async listDownload () {
			await AxiosAPI.get('session/'+this.StoreAll.session.id+'/attendance', {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((resp) => {
				const byteCharacters = atob(resp.data.data);
				const byteNumbers = new Array(byteCharacters.length);
				for (let i = 0; i < byteCharacters.length; i++) {
					byteNumbers[i] = byteCharacters.charCodeAt(i);
				}
				const byteArray = new Uint8Array(byteNumbers);
				const blob = new Blob([byteArray], { type: 'application/pdf' });
				const url = URL.createObjectURL(blob);
				window.open(url, '_blank');

			});
		},

		finish_session_show() {
			this.$toast.add({ severity: 'success', summary: 'Sessão encerrada!', detail: 'Os documentos foram enviados para a prefeitura!', life: 3000 });
		},

		show_error() {
			this.$toast.add({severity: 'error', summary: this.header_error , detail: this.body_error , group: 'tl', life: 5000});
		},

		showSuccess_del() {
			this.$toast.add({severity:'success', summary: 'Documento deletado', detail:'O documento foi deletado com sucesso', life: 3000});
		},
		showSuccess_edit() {
			this.$toast.add({severity:'success', summary: 'Documento editado', detail:'O documento foi editado com sucesso', life: 3000});
		},
		showError_del() {
			this.$toast.add({severity:'error', summary: 'Erro ao deletar', detail:'O documento selecionado não foi deletado', life: 3000});
		},
		showError_edit() {
			this.$toast.add({severity:'error', summary: 'Erro ao editar', detail:'Documento selecioando não foi editado', life: 3000});
		},

		showError_edit_model() {
			this.$toast.add({severity:'error', summary: 'Erro ao editar documento', detail: this.error_msg, life: 3000});
		},


	},
};
</script>
<template>
  <div>
    <div>
      <Modal_view ref="Modal_view"></Modal_view>
      <Modal_view_Quorum ref="Modal_view_Quorum"></Modal_view_Quorum>
      <Modal_view_Discut ref="Modal_view_Discut"></Modal_view_Discut>
      <Modal_View_Send_Prefeitura ref="Modal_View_Send_Prefeitura"></Modal_View_Send_Prefeitura>
      <Modal_View_Send_VoteLote ref="Modal_View_Send_VoteLote"></Modal_View_Send_VoteLote>
      <Modal_view_Stamp ref="Modal_view_Stamp"></Modal_view_Stamp>
      <Loading ref="Loading"></Loading>
    </div>

    <div class="py-1 h-100 container-fluid">
      <div class="row">
        <div class="d-flex justify-content-around col-12  mt-5">
          <div class="d-flex"><h3 class="mt-auto text-white">{{ session.name }} </h3> 
            <div v-if="this.StoreAll.session.session_status_id == 1 && this.StoreAll.user.user_category_id == 1" style="cursor: pointer;" class="mt-1" data-toggle="modal" data-target=".edit_session">
            <i style="font-size: 25px;" class="fas fa-edit ml-4 text-white"></i>
            <span style="font-size: 15px; cursor: pointer;" class="text-white">EDITAR</span>
          </div>
        </div>

          <h3 v-if="this.StoreAll.session.session_status_id == 1" style="color: #dd002c">
            Aguardando votação
          </h3>
          <h3 v-else-if="this.StoreAll.session.session_status_id == 2" style="color: rgb(253 192 19)">
            Em votação
          </h3>
          <h3 v-else-if="this.StoreAll.session.session_status_id == 3" style="color: rgb(76 255 54)">
            Concluída
          </h3>
          <h3 v-else style="color: #dd002c">
            Aguarde...
          </h3>

        </div>
		<div v-if="this.StoreAll.user.user_category_id == 1 && this.StoreAll.session.session_status_id == 3" class="d-flex justify-content-center">
			<button type="button" class="btn btn-success btn-lg" @click="listDownload()">
                      Baixar lista de presença desta sessão
            </button>
		</div>
        <div v-if="this.StoreAll.user.user_category_id == 3">
          <div v-if="this.StoreAll.session.session_status_id == 1" class="d-flex justify-content-center">
            <button type="button" class="btn btn-primary btn-lg bg-success mb-0" @click="Quorum_modal(1)">
              Iniciar Sessão!
            </button>
          </div>
          <div v-if="this.StoreAll.session.session_status_id == 2" class="d-flex justify-content-around">
            <button type="button" class="btn btn-warning btn-lg mb-0" style="background-color: rgb(191 124 7)"
              @click="EndSession()">
              Encerrar Sessão!
            </button>
            <button type="button" class="btn btn-warning btn-lg bg-success mb-0" @click="Quorum_modal(2)">
              Adicionar vereadores ao quorum!
            </button>
            <button id="te" type="button" class="btn btn-warning btn-lg bg-danger mb-0" @click="Quorum_modal(3)">
              Remover vereadores do quorum!
            </button>
          </div>
          <div v-else>

          </div>
        </div>
      </div>
    </div>


    <div class="container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <h5 class="text-start mb-0" style="font-size: 25px;">Expediente do dia</h5>
                <div v-if="this.$route.fullPath == '/sessao_semana/@'">
                  <router-link :to="{ name: 'Histórico de sessões' }" exact>
                    <button v-if="this.$route.fullPath == '/sessao_semana/@'" type="button" class="btn btn-primary">
                      Voltar para histórico de sessões
                    </button>
                  </router-link>
                </div>
                <div v-if="this.$route.fullPath == '/sessao_semana/@@'">
                  <router-link :to="{ name: 'Pesquisa de documentos gerais' }" exact>
                    <button type="button" class="btn btn-primary">
                      Voltar para pesquisa de todos os documentos
                    </button>
                  </router-link>
                </div>
                <div v-if="this.$route.fullPath == '/sessao_semana/@@@'">
                  <router-link :to="{ name: 'Meus documentos enviados' }" exact>
                    <button type="button" class="btn btn-primary">
                      Voltar para pesquisa de seus documentos documentos
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="table-responsive">
                    <DataTable v-model:filters="filters_expediente" :value="WorkDay" responsive-layout="scroll" :paginator="true" :rows="10" filter-display="menu"
                  :globalfilterfields="['id','name','document_category.name','created_at']">
                      <template #header>
                          <div class="d-flex justify-content-between">
                              <Buttonprime type="button" icon="pi pi-filter-slash" label="Limpar Filtro" class="p-button-outlined" @click="clearFilter_Expediente()"/>
                              <span class="p-input-icon-left">
                                  <i class="pi pi-search" />
                                  <InputText v-model="filters_expediente['global'].value" placeholder="Pesquise o documento" />
                              </span>
                          </div>
                      </template>
                      <Column field="id" header="Protocolo" :sortable="true" class="text-sm font-weight-bold text-wrap text-uppercase"></Column>
                      <Column field="name" header="Documento" class="text-sm font-weight-bold text-wrap text-uppercase"></Column>
                      <Column field="document_category.name" header="Tipo" class="text-sm font-weight-bold text-wrap text-uppercase"></Column>
                      <Column field="authors" header="Autor(es)"
                        class="text-sm font-weight-bold text-wrap text-uppercase" :sortable="true">
                        <template #body="{ data }">
                          <span>{{Author(data.authors)}} </span>
                        </template>
                      </Column>
                      <Column field="created_at" header="Data" class="text-sm font-weight-bold text-wrap text-uppercase">
                        <template #body="{ data }">
                          {{ formatDate(data.created_at) }}
                        </template>
                      </Column>
                      <Column header="Assinado" class="text-sm font-weight-bold text-wrap text-uppercase text-center">
                      <template #body="slotProps">
                        <i v-if="slotProps.data.status_sign === 2 || slotProps.data.status_sign === 1" class="fa fa-check-circle-o text-success" aria-hidden="true" style="font-size: 20px;"></i>
                        <i v-else class="fa fa-times-circle-o text-danger" aria-hidden="true"  style="font-size: 20px;"></i>
                      </template>
                    </Column>
                      <Column header="Visualizar" class="justify-content-center">
                        <template #body="slotProps">
                          <div class="text-center"><button style="margin-bottom: 0rem !important;" type="button"
                              class="btn bg-smartlegis btnEdit envio" @click="editProduct(slotProps.data)">
                              <i style="color: white !important;" class="fa fa-eye"></i></button>
                          </div>
                        </template>
                      </Column>
                      <Column v-if="this.StoreAll.user.user_category_id == 1 && this.StoreAll.session.session_status_id == 1" header="Mover para ordem" class="justify-content-center">
                        <template #body="slotProps">
                          <div class="text-center"><button style="margin-bottom: 0rem !important;" type="button"
                              class="btn bg-smartlegis btnEdit envio" @click="ChangeOrder(slotProps.data)">
                              <!-- <i style="color: white !important;" class="fa fa-eye"></i> -->
                              <i style="color: white !important;" class="fa fa-arrow-up mr-1" aria-hidden="true"></i>
                              <i style="color: white !important;" class="fa fa-arrow-down" aria-hidden="true"></i>
                            </button>
                          </div>
                        </template>
                      </Column>
                      <Column v-if="this.StoreAll.user.user_category_id == 1 && this.StoreAll.session.session_status_id == 1"  header="Editar" class="text-sm font-weight-bold text-wrap text-uppercase TT text-center">
                        <template #body="slotProps">
                          <button  data-toggle="modal" style="margin-bottom: 0rem !important;" data-target=".edit_in_session" type="button" class="btn bg-smartlegis btnEdit envio" @click="EventDoc_edit(slotProps.data)"><i style="color: white !important;" class="fa fa-pencil"></i></button>
                        </template>
                        </Column>
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card heade -->
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <h5 class="text-start mb-0" style="font-size: 25px;">Ordem do dia</h5>
                <div v-if="this.StoreAll.user.user_category_id == 3 && this.select === true" class="d-flex">

                  <button type="button" class="btn btn-success mb-2 mr-2"
                    @click="VoteLote_show();"><i class="fa fa-check text-white pr-1"></i>
                    Vote documentos selecionados</button>

                  <button type="button" class="btn mb-2 mr-2 text-white"
                    @click="Discut_lote(); DiscutStatus(2);" style="background-color: #872020;"><i style="color: white !important;" class="fa fa-microphone pr-1"></i>
                    Discutir documentos selecionados</button>
                </div>
                <div v-if="this.StoreAll.user.user_category_id == 1 && this.select === true && this.StoreAll.session.session_status_id == 2 || this.StoreAll.user.user_category_id == 1 && this.StoreAll.session.session_status_id == 3" class="d-flex">
                <button
                  type="button" class="btn btn-success mb-2 mr-2" @click="SendPrefeitura_show()"><i
                    class="fa fa-check text-white pr-1"></i>
                  Envie os documentos selecionados para prefeitura</button>

                  <!-- <button
                  type="button" class="btn mb-2 mr-2 text-white" @click="ShowCarimbo()" style="background-color: #204a87;"><i
                    class="fa fa-check text-white pr-1"></i>
                  Carimbe documentos selecionados</button> -->
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 ">
                <div class="card">
                  <div class="table-responsive">
                    <DataTable v-model:filters="filters_ordem" v-model:selection="selected_products" :value="DayOrder" responsive-layout="scroll"
                      :paginator="true" :rows="10" data-key="id" @rowSelect="onRowSelect" @rowUnselect="onRowUnselect" filter-display="menu"
                  :globalfilterfields="['id','name','document_category.name','created_at']">
                      <template #header>
                          <div class="d-flex justify-content-between">
                              <Buttonprime type="button" icon="pi pi-filter-slash" label="Limpar Filtro" class="p-button-outlined" @click="clearFilter_ordem()"/>
                              <span class="p-input-icon-left">
                                  <i class="pi pi-search" />
                                  <InputText v-model="filters_ordem['global'].value" placeholder="Pesquise o documento" />
                              </span>
                          </div>
                      </template>
                      <Column selection-mode="multiple" header-style="width: 3em"></Column>
                      <!-- selection-mode deveria ser selectionMode, data-key deveria ser dataKey, metakey-selection deveria ser metaKeySelection, esperar alguma situação-->
                      <Column field="id" header="Protocolo" class="text-sm font-weight-bold text-wrap text-uppercase"
                        :sortable="true"></Column>
                      <Column field="name" header="Documento" class="text-sm font-weight-bold text-wrap text-uppercase">
                      </Column>
                      <Column field="document_category.name" header="Tipo"
                        class="text-sm font-weight-bold text-wrap text-uppercase" :sortable="true"></Column>
                      <Column field="authors" header="Autor(es)"
                        class="text-sm font-weight-bold text-wrap text-uppercase" :sortable="true">
                        <template #body="{ data }">
                          <span>{{Author(data.authors)}} </span>
                        </template>
                      </Column>
                      <Column field="created_at" header="Data" class="text-sm font-weight-bold text-wrap text-uppercase">
                        <template #body="{ data }">
                          {{ formatDate(data.created_at) }}
                        </template>
                      </Column>
                      <Column field="document_status_vote_id" header="Status"
                        class="text-sm font-weight-bold text-wrap text-uppercase" :sortable="true">
                        <template #body="slotProps">
                          <p v-if="slotProps.data.document_status_vote_id == 2"
                            class="bg-danger text-center font-weight-bolder text-white" style="border-radius: 10px;">
                            Aguardando votação </p>
                          <p v-if="slotProps.data.document_status_vote_id == 3"
                            class="bg-danger text-center font-weight-bolder text-white" style="border-radius: 10px;"> Em
                            vista </p>
                          <p v-if="slotProps.data.document_status_vote_id == 4"
                            class="bg-warning text-center font-weight-bolder text-white" style="border-radius: 10px;"> Em
                            votação </p>
                          <p v-if="slotProps.data.document_status_vote_id == 5"
                            class="bg-success text-center font-weight-bolder text-white" style="border-radius: 10px;">
                            Votação concluída</p>
                          <p v-if="slotProps.data.document_status_vote_id == 6"
                            class="bg-danger text-center font-weight-bolder text-white" style="border-radius: 10px;">
                            Leitura </p>
                        </template>
                      </Column>
                      <Column v-if="this.StoreAll.user.user_category_id == 1" header="Carimbo" class="text-sm font-weight-bold text-wrap text-uppercase text-center">
                      <template #body="slotProps">
                        <i v-if="slotProps.data.carimbo_camara_pdf != null" class="fa fa-check-circle-o text-success" aria-hidden="true" style="font-size: 20px;"></i>
                        <i v-else class="fa fa-times-circle-o text-danger" aria-hidden="true"  style="font-size: 20px;"></i>
                      </template>
                    </Column>
                    <Column header="Assinado" class="text-sm font-weight-bold text-wrap text-uppercase text-center">
                      <template #body="slotProps">
                        <i v-if="slotProps.data.status_sign === 2 || slotProps.data.status_sign === 1" class="fa fa-check-circle-o text-success" aria-hidden="true" style="font-size: 20px;"></i>
                        <i v-else class="fa fa-times-circle-o text-danger" aria-hidden="true"  style="font-size: 20px;"></i>
                      </template>
                    </Column>
                      <Column header="Visualizar"
                        class="text-sm font-weight-bold text-wrap text-uppercase TT text-center">
                        <template #body="slotProps">
                          <button data-toggle="modal" style="margin-bottom: 0rem !important;" type="button"
                            class="btn bg-smartlegis btnEdit envio" @click="editProduct(slotProps.data)"><i
                              style="color: white !important;" class="fa fa-eye"></i></button>
                        </template>
                      </Column>
                      <Column v-if="this.StoreAll.user.user_category_id == 1 && this.StoreAll.session.session_status_id == 1" header="Mover para expediente" class="justify-content-center">
                        <template #body="slotProps">
                          <div class="text-center"><button style="margin-bottom: 0rem !important;" type="button"
                              class="btn bg-smartlegis btnEdit envio" @click="ChangeOrder(slotProps.data)">
                              <!-- <i style="color: white !important;" class="fa fa-eye"></i> -->
                              <i style="color: white !important;" class="fa fa-arrow-up mr-1" aria-hidden="true"></i>
                              <i style="color: white !important;" class="fa fa-arrow-down" aria-hidden="true"></i>
                            </button>
                          </div>
                        </template>
                      </Column>
                      <Column v-if="this.StoreAll.user.user_category_id == 1 && this.StoreAll.session.session_status_id == 1" header="Editar" class="text-sm font-weight-bold text-wrap text-uppercase TT text-center">
                        <template #body="slotProps">
                          <button  data-toggle="modal" style="margin-bottom: 0rem !important;" data-target=".edit_in_session" type="button" class="btn bg-smartlegis btnEdit envio" @click="EventDoc_edit(slotProps.data)"><i style="color: white !important;" class="fa fa-pencil"></i></button>
                        </template>
                        </Column>
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div
      class="modal fade bd-example-modal-lg edit_session"
      tabindex="-1"
      role="dialog"
      aria-labelledby="mySmallModalLabel"
      aria-hidden="true"
      style="z-index: 10000; display: none"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">
              <span> Editar nome da sessão - </span>  <span id="NameDoc"> {{session.name}} </span>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Fechar"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label>Altere o nome da sessão</label>
            <input
              v-model="name_session_edit"
              class="mb-3 multisteps-form__input form-control"
              type="text"
              placeholder="Projeto de lei - organização de cadeiras"
              />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-success"
              data-dismiss="modal"
              @click="EditSession();"
            >
              Salvar edição
            </button>

            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade bd-example-modal-lg edit_in_session"
      tabindex="-1"
      role="dialog"
      aria-labelledby="mySmallModalLabel"
      aria-hidden="true"
      style="z-index:999; display: none"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">
              <span> Editar ou deletar documento - </span>  <span id="NameDoc"> {{documento_name}} </span>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Fechar"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label>Altere o nome do documento</label>
            <input
              v-model="form.NameDoc"
              class="mb-3 multisteps-form__input form-control"
              type="text"
              placeholder="Projeto de lei - organização de cadeiras"
              />
              <label>Altere o tipo do documento</label>
                <select
                  id="choices-category"
                  v-model="form.TypeDoc"
                  class="form-control"
                  name="choices-category"
                  >
                  <option
                  v-for="option in categories"
                  :key="option.id"
                  :value="option.id"
                  >
                  {{ option.name }}
                  </option>
                 </select>

                 <div class="mt-3">
                    <label>Selecione o(s) autor(es)</label>
                      <MultiSelectPrime id="choices-category" v-model="autores" display="chip" :options="vereadores_edit" option-label="nickname" placeholder="Selecione o(s) autor(es)"
                      class="form-control w-full md:w-20rem" style="height: calc(3.5rem + 2px) !important"/> 
                  </div>

                 <div class="mt-3 mb-3">
                    <label for="formFile" class="form-label"
                        >Altere o documento enviado:</label>
                          <input
                              id="formFile"
                              class="form-control"
                              type="file"
                              accept=".pdf"
                              @change="ValidatInputFile"
                          />
                  </div>

          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-success"
              data-dismiss="modal"
              @click="EditDoc();"
            >
              Salvar edição
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="DeleteDoc();"
            >
              Deletar Documento
            </button>

            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>

    <Toast />
  </div>
</template>
<style>
.radio-favor:checked+label {
  color: green;
}

.radio-against:checked+label {
  color: red;
}

.radio-abstention:checked+label {
  color: gray;
}

.previous {
  padding-right: 11px;
  padding-bottom: 0.5rem;
}

.close {
  font-size: 2.3rem !important;
}

.modal-backdrop {
  z-index: 997 !important;
}
</style>
<style scoped>
::v-deep(.vtl-paging-change-div) {
  display: none;
}

::v-deep(.vtl-paging, .vtl-row) {
  justify-content: flex-end
}
</style>