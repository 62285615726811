<script>
import ModalBigger from "./ModalBig.vue";
import { AxiosAPI } from "@/axios";
import VuePdfEmbed from 'vue-pdf-embed';
import Loading from '../Modals/Loading.vue';
import { useMainStore } from "../../../stores/index";
import { useBusStore } from "../../../stores/index";
export default {
    components: {
        ModalBigger,
        VuePdfEmbed,
        Loading
    },
    setup() {
        const StoreAll = useMainStore();
        const Bus = useBusStore();
        return {
          StoreAll: StoreAll,
          Bus
        }
      },
    data() {
        return {
            pdfonly: null
        }
    },

    methods: {
      openModal() {
        this.convertToBase64();
        this.$refs.viewDocument.openModal();
        },
        async convertToBase64() {
      
        let ur = JSON.stringify({hiperlink: this.StoreAll.Dochost + this.StoreAll.parecer_src})

        await AxiosAPI.post("/get-base64", ur, {
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ` + this.StoreAll.accessToken,
                }
            }).then((response) => {
                this.pdfonly = response.data.base64
            })
        },

        async RemoveParecer () {
            this.$refs.Loading.openModal();
            await AxiosAPI.delete("/documents-comission/" + this.StoreAll.parecer_id, {
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ` + this.StoreAll.accessToken,
                }
            }).then(() => {
                this.$refs.Loading.closeModal();
                this.CloseModal();
                this.Bus.RemoveParecer = true
                this.Bus.RemoveParecerView = true
            })
        },

        CloseModal(){
            this.$refs.viewDocument.closeModal();        
        },
    }}


</script>
<template>
    <div>
        <Loading ref="Loading" style="z-index: 1002 !important;"></Loading>
    </div>
    <div>
        <ModalBigger ref="viewDocument"  style="z-index: 1001;">
            <template #header>
                <div class="d-flex justify-content-between">
                <strong><span> Parecer </span> </strong>

                <div class="flex-start">
                    <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <a target="_blank" :href="this.StoreAll.Dochost+this.StoreAll.parecer_src"
                        download="proposed_file_name">
                            <button type="button" class="btn btn-success">Baixar documento</button>
                    </a>   
            </template>

            <template #body>
                <div class="d-flex justify-content-center">
                    <button v-if="this.StoreAll.user.user_category_id == 1 || this.StoreAll.user.user_category_id == 4" type="button" class="btn btn-danger" @click="RemoveParecer()">
                        <span>Remover parecer deste documento</span>
                    </button>
                </div>
                <div id="app">
                <div class="app-content" style="height: 580px">
                    <vue-pdf-embed
                    ref="pdfRef"
                    :source="`data:application/pdf;base64,`+pdfonly"
                    :page="page"
                    class="justify-content-center"
                    />
                </div>
                </div>

            </template>

            <template #footer>
                <div>
                    <a target="_blank" :href="this.StoreAll.Dochost+this.StoreAll.parecer_src"
                        download="proposed_file_name">
                        <div class="d-flex justify-content-center">
                            <h3>Baixar o Documento</h3>
                        </div>
                    </a>
                </div>
            </template>
        </ModalBigger>

    </div>
</template>
<style></style>