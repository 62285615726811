<script>
import ModalBigger from "./ModalBigger.vue";
import { AxiosAPI } from "@/axios";
import { useMainStore } from "../../../stores/index";
import { useBusStore } from "../../../stores/index";
export default {
    components: {
        ModalBigger,
    },
    setup() {
        const StoreAll = useMainStore();
        const Bus = useBusStore();
        return {
          StoreAll: StoreAll,
          Bus
        }
      },
    data() {
        return {
            UserVote_lote: [],
        }
    },

    methods: {
        openModal() {
            this.FindUsers();
            this.$refs.viewDocument.openModal();
        },

        selectAllAsFavor(){
          for (let i = 0; i < this.UserVote_lote.length; i++) {
            this.UserVote_lote[i].vote_category_id = 1
          }
        },
        selectAllAsAgainst(){
          for (let i = 0; i < this.UserVote_lote.length; i++) {
            this.UserVote_lote[i].vote_category_id = 2
          }
        },
        selectAllAsAbstention(){
          for (let i = 0; i < this.UserVote_lote.length; i++) {
            this.UserVote_lote[i].vote_category_id = 3
          }
        },

        Nominal_vote_popule() {
        if (this.UserVote_lote.length == 0) { console.log('nenhum') } else {

        const userVotesList = [...this.UserVote_lote];
        const requestBody = JSON.stringify({
          session_id: this.StoreAll.session.id,
          votes: [{ document_id: this.StoreAll.document_details.id, user_votes: userVotesList }]
        });

        AxiosAPI.post("/vote-nominal-favor", requestBody, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.StoreAll.accessToken}`,
          },
        }).then((resp) => {
          this.resp = resp

          let dad = JSON.stringify({
            document_id: [this.StoreAll.document_details.id],
            document_status_vote_id: 5,
            user_id: this.StoreAll.user.id
        })
          AxiosAPI.post("/document/change-status", dad, {
            headers: {
            'Content-Type': 'application/json',
              Authorization: `Bearer ` + this.StoreAll.accessToken,
            },
          })
            .then((resp) => {
              this.resp = resp
              this.$refs.viewDocument.closeModal();
              this.Bus.docSendSession = true
              /* this.GetDayOrder();
              this.GetWorkDay(); */
            });
        });
      }
    },

    Sucess_NominalVote_show() {
      this.$toast.add({ severity: 'success', summary: 'Documento votado!', detail: 'O Documento foi votado nominalmente com sucesso!', life: 3000 });
    },

    FindUsers() {
      if (this.StoreAll.quorum == null) {
        return;
      } else if (this.StoreAll.quorum.length <= 0) {
        return
      } else {
        let NewUser = []
        let Users_Quorum_Now = []

        for (let i = 0; i < this.StoreAll.user_all.length; i++) {
          NewUser.push(this.StoreAll.user_all[i])
        }

        AxiosAPI.get('/quorum-users?quorum_id=' + this.StoreAll.quorum[0].id, {
          headers: {
            authorization: `Bearer ` + this.StoreAll.accessToken,
          },
        }).then((resp) => {

          this.StoreAll.usersq = resp.data.data

          this.array_popule();

          for (let i = 0; i < resp.data.data.length; i++) {
            Users_Quorum_Now.push(resp.data.data[i].user_id)
          }
        })

      }
    },

    array_popule() {
      let date = []

      for (let i = 0; i < this.StoreAll.usersq.length; i++) {
        date.push({ user_id: this.StoreAll.usersq[i].user.id, user_name: this.StoreAll.usersq[i].user.nickname, user_photo:this.StoreAll.Dochost+this.StoreAll.usersq[i].user.path_image, vote_category_id: null, voto_nominal: 1 })
      }
      this.UserVote_lote = date
    },

    }
}


</script>
<template>
    <div>
        <ModalBigger ref="viewDocument"  style="z-index: 1000;">
            <template #header>
                
               <strong><span style="color: rgb(29, 27, 27)">Documento para votação nominal: </span><span style="color: red;"> {{ this.StoreAll.document_details.name }} </span></strong> 

                <div class="flex-start">
                    <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </template>

            <template #body>
                <label class="d-flex justify-content-center" style="font-size: 18px"><b> Selecione os votos dos vereadores presentes </b></label>

          <div class="d-flex justify-content-center">
            <button type="button" class="btn btn-primary bg-success" @click="selectAllAsFavor">Selecionar Todos como A
              Favor</button>
            <button type="button" class="btn btn-primary bg-danger" @click="selectAllAsAgainst">Selecionar Todos como
              Contra</button>
            <button type="button" class="btn btn-primary bg-secondary" @click="selectAllAsAbstention">Selecionar Todos
              como Abstenção</button>
          </div>
          <div class="vereadores-container">
          <div v-for="(user_vote, index) in UserVote_lote" :key="index" class="mb-2 vereadores-item">
            <label class="d-flex" style="font-size: 18px">{{ user_vote.user_name }}</label>
            <div class="d-flex justify-content-start">
              <input v-model="user_vote.vote_category_id" type="radio" :value="1"
                style="width: 1.7rem; height: 1.7rem">
              <label style="font-size: 18px;">A favor</label>
            </div>
            <div class="d-flex justify-content-start">
              <input v-model="user_vote.vote_category_id" type="radio" :value="2"
                style="width: 1.7rem; height: 1.7rem">
              <label style="font-size: 18px;">Contra</label>
            </div>
            <div class="d-flex justify-content-start">
              <input v-model="user_vote.vote_category_id" type="radio" :value="3"
                style="width: 1.7rem; height: 1.7rem">
              <label style="font-size: 18px;">Abstenção</label>
            </div>
          </div>
        </div>

            </template>

            <template #footer>
              <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-primary bg-danger mr-3" @click="$refs.viewDocument.closeModal()">
                  Voltar
                </button>
                <button type="button" class="btn btn-primary bg-success" @click="Nominal_vote_popule()">
                  Enviar
                </button>
            </div>
            </template>
        </ModalBigger>

    </div>
</template>
<style>
.vereadores-container {
display: flex;
flex-wrap: wrap;
}

.vereadores-item {
width: calc(33.33% - 10px); /* Define a largura de cada item */
margin-right: 10px; /* Define a margem entre os itens */
margin-bottom: 10px; /* Define a margem inferior dos itens */
}
</style>