<script>
import ModalBigger from "./ModalBig.vue";
import { AxiosAPI } from "@/axios";
import { useMainStore } from "../../../stores/index";
export default {
    components: {
        ModalBigger,
    },
    setup() {
        const StoreAll = useMainStore();
        return {
          StoreAll: StoreAll
        }
      },
    data() {
        return {
            UserVote_lote: [],
            Docs_Select_Lote: null,
            selections: [],
        }
    },

    methods: {
        openModal() {
            this.FindUsers();
            this.Docs_Select_Lote = this.StoreAll.MultiSelectDocs
            this.$refs.viewDocument.openModal();
        },

        markAllVotes(option) {
        this.UserVote_lote.forEach((user_vote) => {
            this.Docs_Select_Lote.forEach((document) => {
            if (option === 1) {
                user_vote.vote_category_id = 1;
            } else if (option === 2) {
                user_vote.vote_category_id = 2;
            } else if (option === 3) {
                user_vote.vote_category_id = 3;
            }
          this.updateVotes(document.id, user_vote.user_id, user_vote.vote_category_id);
        });
      });
    },

    updateVotes(documentId, userId, voteCategoryId) {

    const existingSelectionIndex = this.selections.findIndex(s => s.votes.document_id === documentId && s.votes.user_votes[0].user_id === userId);

        if (existingSelectionIndex !== -1) {
            const existingSelection = this.selections[existingSelectionIndex];
            existingSelection.votes.user_votes = [{ user_id: userId, vote_category_id: voteCategoryId, voto_nominal: 1 }];
        } else {
            const newSelection = { session_id: this.StoreAll.session.id, votes: { document_id: documentId, user_votes: [{ user_id: userId, vote_category_id: voteCategoryId, voto_nominal: 1 }] } };
            this.selections.push(newSelection);
        }
    },

    sendVotes() {
      const data = this.selections;
      const tempObj = {};

      for (const vote of data) {
        const documentId = vote.votes.document_id;
        const userVote = vote.votes.user_votes[0];
        const voteCategory = userVote.vote_category_id;
        const votoNominal = 1;

        if (!tempObj[documentId]) {
          tempObj[documentId] = {
            document_id: documentId,
            user_votes: []
          };
        }

        tempObj[documentId].user_votes.push({
          user_id: userVote.user_id,
          vote_category_id: voteCategory,
          voto_nominal: votoNominal
        });
      }

      const result = {
        session_id: data[0].session_id,
        votes: Object.values(tempObj)
      };

      const votesByDocumentAndUser = {};
      this.Docs_Select_Lote.forEach(product => {
        votesByDocumentAndUser[product.id] = {};
        this.UserVote_lote.forEach(user => {
          votesByDocumentAndUser[product.id][user.user_id] = 0;
        });
      });

      result.votes.forEach(vote => {
        const documentId = vote.document_id;
        vote.user_votes.forEach(userVote => {
          const userId = userVote.user_id;
          if (votesByDocumentAndUser[documentId] && votesByDocumentAndUser[documentId][userId] !== undefined) {
            votesByDocumentAndUser[documentId][userId]++;
          }
        });
      });

      let allVotesPresent = true;
      this.Docs_Select_Lote.forEach(product => {
        const documentId = product.id;
        this.UserVote_lote.forEach(user => {
          const userId = user.user_id;
          const expectedVotes = user.voto_nominal;
          const actualVotes = votesByDocumentAndUser[documentId][userId];
          if (expectedVotes !== actualVotes) {
            allVotesPresent = false;
            return;
          }
        });
      });

      if (allVotesPresent) {
        AxiosAPI.post("/vote-nominal-favor", result, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.StoreAll.accessToken}`,
          },
        }).then((resp) => {
          this.resp = resp
          let id_docs_lote = []

          for (let i = 0; i < this.Docs_Select_Lote.length; i++) {
            id_docs_lote.push(this.Docs_Select_Lote[i].id)
          }

          const vot_lote = JSON.stringify({ document_id: id_docs_lote, document_status_vote_id: 5, user_id: this.StoreAll.user.id });

          AxiosAPI.post("/document/change-status", vot_lote, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ` + this.StoreAll.accessToken,
            },
          })
            .then((resp) => {
              this.resp = resp
              this.$refs.viewDocument.closeModal();
              /* this.GetDayOrder();
              this.GetWorkDay();
              document.getElementById('close_modal_vote_lote').click(); */
            });
        });

      } else {
        alert('Nem todos os votos foram selecionados!')
        /* Alterar para algum modal */
      }
    },

    FindUsers() {
      if (this.StoreAll.quorum == null) {
        return;
      } else if (this.StoreAll.quorum.length <= 0) {
        return
      } else {
        let NewUser = []
        let Users_Quorum_Now = []

        for (let i = 0; i < this.StoreAll.user_all.length; i++) {
          NewUser.push(this.StoreAll.user_all[i])
        }

        AxiosAPI.get('/quorum-users?quorum_id=' + this.StoreAll.quorum[0].id, {
          headers: {
            authorization: `Bearer ` + this.StoreAll.accessToken,
          },
        }).then((resp) => {

          this.StoreAll.usersq = resp.data.data

          this.array_popule();

          for (let i = 0; i < resp.data.data.length; i++) {
            Users_Quorum_Now.push(resp.data.data[i].user_id)
          }
        })

      }
    },

    array_popule() {
      let date = []

      for (let i = 0; i < this.StoreAll.usersq.length; i++) {
        date.push({ user_id: this.StoreAll.usersq[i].user.id, user_name: this.StoreAll.usersq[i].user.nickname, user_photo:this.StoreAll.usersq[i].user.path_image, vote_category_id: null, voto_nominal: 1 })
      }
      this.UserVote_lote = date
    },

    }
}


</script>
<template>
    <div>
        <ModalBigger ref="viewDocument">
            <template #header>
              <div class="d-flex justify-content-between">
                <strong><span> Votação multipla, selecione os votos dos vereadores!</span> </strong>

                <div class="flex-start">
                    <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
              </div>
            </template>

            <template #body>
                <div class="d-flex justify-content-center">
                    <button type="button" class="btn btn-primary bg-success mr-3" @click="markAllVotes(1)">Todos a favor</button>
                    <button type="button" class="btn btn-primary bg-danger mr-3" @click="markAllVotes(2)">Todos contra</button>
                    <button type="button" class="btn btn-primary bg-secondary mr-3" @click="markAllVotes(3)">Todos abstenção</button>
                </div>

                <div class="d-flex justify-content-center">
                    <table style="width: 100%;">
                    <thead class="mb-3">
                        <tr>
                        <th>
                            <span class="d-flex justify-content-center text-black mb-3"><strong> Vereador </strong></span>
                        </th>
                        <th>
                            <span class="d-flex justify-content-center text-black"><strong> Documentos </strong></span>
                        </th>
                        <th>
                            <span class="d-flex justify-content-center text-black"><strong> Votos </strong></span>
                        </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(user_vote, index) in UserVote_lote" :key="index">
                        <td>
                            <div style="display: flex; flex-direction: column;" class="mb-3">
                            <div class="d-flex justify-content-center">
                                <img :src="this.StoreAll.Dochost+user_vote.user_photo" class="rounded-circle"
                                style="object-fit: cover; width: clamp(0.1vw, 8.5vw, 300px); height: clamp(0.1vw, 8.5vw, 300px);"
                                alt="user photo" />
                            </div>
                            <span class="d-flex justify-content-center text-black">
                                <strong> {{ user_vote.user_name }} </strong>
                            </span>
                            </div>
                        </td>

                        <td>
                            <table class="d-flex justify-content-center">
                            <tbody>
                                <tr v-for="document in Docs_Select_Lote" :key="document.id">
                                <td>
                                    <div class="mb-3">
                                    <span class="text-black"><strong> {{ document.name.substring(0, 50) }}{{ document.name.length
                                        > 30 ? '...' : '' }} </strong> </span>
                                    </div>


                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </td>

                        <td>
                            <table class="d-flex justify-content-center">
                            <tbody>
                                <tr v-for="document in Docs_Select_Lote" :key="document.id">
                                <td>
                                    <input :id="`favor-${document.id}-${user_vote.user_id}`"
                                    :name="`vote-${document.id}-${user_vote.user_id}`"
                                    :checked="user_vote.vote_category_id === 1" type="radio" class="radio-favor"
                                    @change="updateVotes(document.id, user_vote.user_id, 1)">
                                    <label :for="`favor-${document.id}-${user_vote.user_id}`" class="mr-2"
                                    style="font-size: 17px;">A FAVOR</label>
                                </td>
                                <td>
                                    <input :id="`against-${document.id}-${user_vote.user_id}`"
                                    :name="`vote-${document.id}-${user_vote.user_id}`"
                                    :checked="user_vote.vote_category_id === 2" type="radio" class="radio-against"
                                    @change="updateVotes(document.id, user_vote.user_id, 2)">
                                    <label :for="`against-${document.id}-${user_vote.user_id}`" class="mr-2"
                                    style="font-size: 17px;">CONTRA</label>
                                </td>
                                <td>
                                    <input :id="`abstention-${document.id}-${user_vote.user_id}`"
                                    :name="`vote-${document.id}-${user_vote.user_id}`"
                                    :checked="user_vote.vote_category_id === 3" type="radio" class="radio-abstention"
                                    @change="updateVotes(document.id, user_vote.user_id, 3)">
                                    <label :for="`abstention-${document.id}-${user_vote.user_id}`"
                                    style="font-size: 17px;">ABSTENÇÃO</label>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    </div>

            </template>

            <template #footer>
              <div class="d-flex justify-content-center">
                
                <button type="button" class="btn btn-primary bg-danger mr-3" @click="$refs.viewDocument.closeModal()">
                    Voltar
                </button>

                <button type="button" class="btn btn-primary bg-success" @click="sendVotes();">
                    Enviar
                </button>
              </div>
            </template>
        </ModalBigger>

    </div>
</template>  
<style></style>