<script>
import { AxiosAPI } from '@/axios';
import Tv_Full from '../secretario/Tv_Full.vue';
import VueTableLite from 'vue3-table-lite';
import { useMainStore } from '../../stores/index';
export default {
	name: 'TvStop',
	components: { Tv_Full, VueTableLite },
	setup(){
		const StoreAll = useMainStore();
		StoreAll.Token();
		return{
			StoreAll: StoreAll,
		};
	},
	data() {
		return {
			//Modificar Styles.
			tabes: {
				columns: [
					{
						label: 'Vereador',
						field: 'nickname',
						width: '100%',
						sortable: true,
					},
					{
						label: 'Situação',
						field: 'stat',
						width: '100%',
						sortable: true,
					},
				],
				rows: [{
					nickname: 'Aguarde...',
					stat: 'Aguarde...'
				},{
					nickname: 'Aguarde...',
					stat: 'Aguarde...'
				}],
				messages:{
					pagingInfo: '',
					pageSizeChangeLabel: '',
					gotoPageLabel: '',
					noDataAvailable: '',
				},
			},
			fullScren: false,
			time: '',
			cronometro: '',
			users: [],
			iframe_fb: '',
			link_sent: false,
			seconds: 59,
			minutes: 5,
			session_id: 0,
			session: {
				name: 'Carregando Informações...',
				id: 9999999,
			},
			tela: '',
			quorum_users: [],
			presentes: 0,
			ausentes: 0,
			total: 0,
		};
	},

	mounted() {
		this.GetSession();
		this.iframestela(); 
	},
  
	methods: {
		async Get_Quorum_Socket_Tv() {
			await this.StoreAll.Get_Data();
			this.GetUsersAll();
			this.User();
			this.DocumentVoted();
		},

		async GetUsersAll() {
			await this.StoreAll.UserAll();
			this.users = this.StoreAll.user_all; 
			this.Quorum_atual();
		},

		async User() {await this.StoreAll.User();},

		DocumentVoted() {

			if (this.StoreAll.document == null) {
				this.voted = false;
			} else if (
				this.StoreAll.document.document_status_id == 3
			) {
				this.FullScren();
			}else{return;}
		},

		async Quorum_atual(){
			if(this.StoreAll.quorum == null){
				return;
			}else{
				if(this.StoreAll.quorum.length <= 0){
					return;
				}else{
					await AxiosAPI
						.get('/quorum-users?quorum_id=' + this.StoreAll.quorum[0].id, {
							headers: {
								authorization: 'Bearer '+this.StoreAll.accessToken,
							},
						})
						.then((response) => {
							this.quorum_users = [];
							let dummy_presentes = 0;
          
							for (let i = 0; i < this.users.length; i++) {
								this.users[i]['stat'] = 'A';
							}

							for (let i = 0; i < response.data.data.length; i++) {
								for (let j = 0; j < this.users.length; j++) {
									if (this.users[j].name == response.data.data[i].user.name) {
										this.users[j]['stat'] = 'P';
										dummy_presentes = dummy_presentes + 1;
										continue;
									}
								}
							}
							this.tabes.rows = this.users;
							this.total = this.users.length;
							this.presentes = dummy_presentes;
							this.ausentes = this.total - dummy_presentes;
							this.isQuorumLoaded = true;


						});
					this.tabes.rows = this.users;
				}}},
		iframestela(){
			setTimeout(()=>{
				if(localStorage.getItem('iframe_tv') != null){
					this.link();
				}else{
					return;
				}}, 3000);
		},
		link(){
			this.link_sent = true;
			let fbif = document.getElementById('fbif');
			let face = document.getElementById('face');
			this.Hora();
			fbif.innerHTML = localStorage.getItem('iframe_tv');
			face.innerHTML = localStorage.getItem('iframe_tv');
		},
		atualizarlink() {
			this.link_sent = true;
			let fbif = document.getElementById('fbif');
			let face = document.getElementById('face');
			this.Hora();
			localStorage.setItem('iframe_tv', this.iframe_fb);
			fbif.innerHTML = this.iframe_fb;
			face.innerHTML = this.iframe_fb;
		},
		closes(){
			document.getElementById('Full').style.display = 'none';
			document.getElementById('fade').style.display = 'none';
		},
		FullScren() {
			document.getElementById('Full').style.display = 'none';
			document.getElementById('fade').style.display = 'none';
			this.fullScren = true;
			this.emitter.emit('FullScren', this.fullScren);
			this.Get_Quorum_Socket_Tv();     
		},
		FullScrennot() {
			document.getElementById('Full').style.display = 'none';
			document.getElementById('fade').style.display = 'none';
			this.fullScren = false;
			this.emitter.emit('FullScrennot', this.fullScren);
		},
		FullScrennot2() {
			document.getElementById('Full').style.display = 'none';
			document.getElementById('fade').style.display = 'none';
			this.fullScren = false;
			this.emitter.emit('FullScrennot', this.fullScren);
		},

		async GetSession() {
			await this.StoreAll.Get_Data();
			const { session } = this.StoreAll; 
			if (session == null) {
				this.GetSession();
				return;
			}
			const { session_status, id } = session;
			this.session = session;
			this.session_status = session_status.id;
			this.session_id = id;
			this.session_status_name = session_status.name;
			this.Get_Quorum_Socket_Tv();    
		},

		timer() {
			let sec = this.seconds--;
			let min = this.minutes;
			let texts = '';
			document.getElementById('fim').innerText = texts;
			if (sec <= 9) {
				sec = '0' + sec;
			}
			if (sec == 0) {
				this.seconds = 59;
				let min = this.minutes--;
				console.log(min);
			}
			if (min == 0 && sec == 0) {
				let text = 'Seu tempo acabou';
				let timerz = '00 : 00';
				document.getElementById('fim').innerText = text;
				document.getElementById('timerz').innerText = timerz;
				this.seconds = 59;
				this.minutes = 5;
				return;
			}
			this.cronometro = '0' + min + ' : ' + sec;
			console.log(sec);

			setTimeout(() => {
				this.timer();
			}, 1000);
		},
		Hora() {
			let date_time = new Date();
			let minutes = date_time.getMinutes();
			if (minutes.toString().length == 1) {
				minutes = '0' + minutes;
			}
			let hours = date_time.getHours();
			if (hours.toString().length == 1) {
				hours = '0' + hours;
			}
			let seconds = date_time.getSeconds();
			if (seconds.toString().length == 1) {
				seconds = '0' + seconds;
			}
			this.time = hours + ' : ' + minutes + ' : ' + seconds;
			setTimeout(() => {
				this.Hora();
			}, 300);
		},
    
	}
};
</script>
<template>
  <div>
    <div id="fade" class="modal-backdrop fade show"></div>
    <div
      id="Full"
      class="modal"
      tabindex="-1"
      role="dialog"
      style="display: flex"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Exibir tela em modo tela cheia?</h5>
            <button 
              id="closes"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Fechar"
              @click="closes()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="text-center">Exibir tela cheia ?</p>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button id="clicando" type="button" class="btn btn-primary" @click="FullScren()">
              Sim
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="FullScrennot()"
            >
              Não
            </button>
          </div>
        </div>
      </div>
    </div>
    <div     
      v-if="fullScren == true"
    >
      <Tv_Full />
    </div>

    <div v-else>
      <div class="container-fluid">
        <div class="mt-4 row">
          <div class="col-12">
            <div class="card">
              <!-- Card header -->
              <div
                class="modal fade bd-example-modal-xl live"
                tabindex="-1"
                role="dialog"
                aria-labelledby="myExtraLargeModalLabel"
                aria-hidden="true"
                style="z-index: 10000"
              >
                <div class="modal-dialog modal-xl">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 id="exampleModalLabel" class="modal-title">
                        facebook
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Fechar"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div>
                        <textarea
                          id="iframecode"
                          v-model="iframe_fb"
                          class="bordered"
                          name="iframecode"
                          cols="90"
                          rows="10"
                        >
                        </textarea>
                      </div>
                      <div class="modal-footer d-flex justify-content-center">
                        <button
                          type="button"
                          class="btn btn-primary"
                          data-dismiss="modal"
                          @click="atualizarlink()"
                        >
                          Começar!
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div
                  class="card-header"
                  style="padding-bottom: 0.5rem !important"
                >
                </div>
                <div class="d-flex justify-content-between"
                  style="margin-right: 4rem !important"
                >
                <h5 class=" ml-2 mb-0">Sessão: {{ session.name }}</h5>
                  <h5>Quórum da sessão</h5>
                </div>
                <div class="card">

                    <div class="d-flex">
                      <div class="d-flex align-items-center flex-column">
                        <div id="fbif" class="mt-2 pt-1"> </div>
                        <span
                  class="p-2 d-flex justify-content-center" 
                  style="font-size: 60px;"
                >
                  {{ time }}
                </span>

                      </div>
                      <div class="table-responsive d-flex align-items-end flex-column">
                        <VueTableLite 
                        :is-slot-mode="true"
                        :columns="tabes.columns"
                        :rows="tabes.rows"
                        :page-options="tabes.pageOptions"
                        :is-loading="tabes.isLoading"
                        :is-re-search="tabes.isReSearch"
                        :total="tabes.totalRecordCount"
                        :sortable="tabes.sortable"
                        :messages="tabes.messages"
                      >

                      <template #nickname="tradess">

                      <div class="text-base text-black text-uppercase font-weight-bold" style="">{{tradess.value.nickname}}</div>

                      </template>

                      <template #stat="trades">

                      <div v-if="trades.value.stat == 'P'" style="color: green;">
                        <div class="text-center "><span id="status" class="text-white pl-2 pr-2 font-weight-bold bg-success" style="border-radius: 15px;padding: 0.20rem;">PRESENTE</span></div>
                      </div>
                      <div v-else style="color: red;">
                        <div class="text-center"><span id="status" class="text-white pl-2 pr-2 font-weight-bold bg-dangerlegis" style="border-radius: 15px;padding: 0.20rem;">AUSENTE</span></div>
                      </div>

                      </template>
                      </VueTableLite>
                    
                    <div style="width: 365px">
                      <div
                        class="
                          p-2
                          text-center
                          d-flex
                          justify-content-between
                          mb-2
                          mt-2
                          rounded
                          shadow
                          bg-success
                        "
                      >
                        <span class="text-white pl-3 font-weight-bold"
                          >PRESENTE</span
                        >
                        <span class="text-white pr-3 font-weight-bold">{{
                          presentes
                        }}</span>
                      </div>
                      <div
                        class="
                          p-2
                          text-center
                          d-flex
                          justify-content-between
                          bg-dangerlegis
                          mb-2
                          rounded
                          shadow
                        "
                        style=""
                      >
                        <span class="text-white pl-3 font-weight-bold"
                          >AUSENTE</span
                        >
                        <span class="text-white pr-3 font-weight-bold">{{
                          ausentes
                        }}</span>
                      </div>
                      <div
                        class="
                          p-2
                          text-center
                          d-flex
                          justify-content-between
                          rounded
                          shadow
                        "
                        style="background-color: #bfbfbf"
                      >
                        <span class="text-black pl-3 font-weight-bold"
                          >TOTAL</span
                        >
                        <span class="text-black pr-3 font-weight-bold">{{
                          total
                        }}</span>
                      </div>
                    </div>
                  </div>
                  </div></div>
                
          
                <button
                  type="button"
                  class="btn btn-primary mt-2"
                  data-toggle="modal"
                  data-target=".live"
                >
                  Adicionar vídeo ao vivo
                </button>
                <!-- modal seleção de vereador -->

                <div
                  class="modal fade bd-example-modal-sm"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="mySmallModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-sm">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 id="exampleModalLabel" class="modal-title">
                          Escolha o vereador
                        </h5>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Fechar"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <label>Selecione</label>
                        <select
                          id="choices-category"
                          class="form-control"
                          name="choices-category"
                        >
                          <option
                            v-for="user in users"
                            :key="user.id"
                            :value="user.id"
                          >
                            {{ users.name }}
                          </option>
                          <option
                            v-for="user in users"
                            :key="user.id"
                            :value="user.id"
                          >
                            {{ user.name }}
                          </option>
                        </select>
                      </div>
                      <div class="modal-footer d-flex justify-content-center">
                        <button
                          type="button"
                          class="btn btn-primary"
                          data-toggle="modal"
                          data-target=".bd-example-modal-xl-fala"
                          data-dismiss="modal"
                        >
                          Avançar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- modal fala -->

                <div
                  class="modal fade bd-example-modal-xl-fala"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="myExtraLargeModalLabel"
                  aria-hidden="true"
                  style="z-index: 10000"
                >
                  <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 id="exampleModalLabel" class="modal-title">
                          Momento de fala
                        </h5>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Fechar"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div
                        id="face"
                        class="d-flex justify-content-center"
                        ></div>
                        <span 
                        id="timerz"
                        class="d-flex justify-content-center"
                        style="font-size: 60px"
                        >
                          {{ cronometro }}
                        </span>
                        <span
                          id="fim"
                          class="
                            d-flex
                            justify-content-center
                            text-uppercase
                            font-weight-bold
                          "
                          style="font-size: 40px; color: red"
                        >
                        </span>
                        <div class="modal-footer d-flex justify-content-center">
                          <button
                            type="button"
                            class="btn btn-primary"
                            @click="timer()"
                          >
                            Começar!
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.text-black {
  color: black;
}
.close{
  font-size: 2.3rem !important;
}
.vtl-table .vtl-thead .vtl-thead-th {
  color: black;
  background-color: #e9ecef;
  border-color: #e9ecef;
}

</style>
<style scoped>
::v-deep(.dataTables_paginate, .pagination, .page-link, .vtl-paging-count-dropdown, .vtl-paging-page-dropdown) {
  display: none;
}

::v-deep(.vtl-paging, .vtl-row){
  display: none;
}
</style>