<script>
import { AxiosAPI } from '@/axios';
import sound from '@/assets/sound/fim.mp3';
import { useMainStore, useBusStore } from '../../stores/index';

export default {
	name: 'TvOrdem',
	components: {},
	setup(){
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		StoreAll.Token();
		return{
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			id_ordem: null,
			user_on: false,
			voted: false,
			f_fala: false,
			seconds: 2,
			minutes: 0,
			ordem_vereadores: [],
			cronometro: 'Aguarde...',
			dia: 0,
			mes: '',
			time: '',
			timer_session: 'Aguarde o inicio',
			timerOn: false,
			TimerOver: false,
			socket_ids:[],
		};
	},

	watch: {
		TimerOver: function (newValue, oldPath) {
			if (newValue === true) {
				this.TimerOver = false;
				this.Sound();
				this.Finalizar_fala();
			}    
		},
	},
	created () {
		let OnSocket = setInterval(() => {
			if (this.StoreAll.websocket_host !== null) {
				clearInterval(OnSocket);			
				window.Echo.channel(this.StoreAll.websocket_host).listen('.' + this.StoreAll.websocket_host, (e) => {
					if (e.socket_id === 32) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Check_ordem();
							this.socket_ids.push(e.event_id);  
						}
					} 

					if (e.socket_id === 33) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Check_ordem();
							this.socket_ids.push(e.event_id);  
						}
					} 
				});
			}}, 500);
	},

	mounted() {
		this.GetSession();
	},
    
	methods: {

		async GetSession() {
			await this.StoreAll.Get_Data();
			this.session = this.StoreAll.session; this.session_status = this.StoreAll.session.session_status.id;
			this.session_id = this.StoreAll.session.id; this.session_status_name = this.StoreAll.session.session_status.name;
			this.Check_ordem();
			this.TimesNow();
			this.Session_timer();
			this.Hora();
			setInterval(this.Hora, 1000);
		},

		TimesNow(){
			let verificarTimer = setInterval(() => {
				if (this.StoreAll.timers !== null) {
					clearInterval(verificarTimer);
					this.seconds = this.StoreAll.timers[0].Vereador_Ordem.seconds;
					this.minutes = this.StoreAll.timers[0].Vereador_Ordem.minutes;

				}
			}, 500);
		},

		async Check_ordem() {
			console.log(this.StoreAll.Ordem);
			await AxiosAPI
				.get(
					'/question-orders?quorum_id=' + this.StoreAll.quorum[0].id,
					{
						headers: {
							authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}
				).then((resp) => {
					console.log(resp);
					let OrdemNow = [];

					resp.data.data.forEach(ordem => {
						if (resp.data.data) {
							if (ordem.status === '1') {
								OrdemNow.push(ordem);
							}
						}
					});

					if (OrdemNow.length <= 0) {
						this.StoreAll.Ordem = false;
					} else {
						this.StoreAll.Ordem = true;
					}
					this.id_ordem = OrdemNow[0].id;
					this.ordem_start();
				});
		},

		Session_timer() {
			setTimeout(() => {
				this.Session_timer();
			}, 300);

			if (this.StoreAll.quorum.length <= 0) {
				return;
			} else {
				let inicio = new Date(this.StoreAll.quorum[0].created_at).getTime();;
				let fim = new Date().getTime();;
				let diferenca = fim - inicio;
				let hours = Math.floor((diferenca % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				let minutes = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60));
				let seconds = Math.floor((diferenca % (1000 * 60)) / 1000);
				let timer_session = hours + 'h: ' + minutes + 'm: ' + seconds + 's';
				this.timer_session = timer_session;
			}
		},

		Hora() {
			let date_time = new Date();
			let dia = date_time.getDate();
			let mesesEmPortugues = [
				'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
				'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
			];
			let mes = mesesEmPortugues[date_time.getMonth()];

			let minutes = date_time.getMinutes().toString().padStart(2, '0');
			let hours = date_time.getHours().toString().padStart(2, '0');
			let seconds = date_time.getSeconds().toString().padStart(2, '0');

			this.time = `${hours}: ${minutes}: ${seconds}`;
			this.dia = dia;
			this.mes = mes;
		},

		async ordem_start() {
			if (!this.voted) {
				await AxiosAPI.get('/question-order-users?question_order_id=' + this.id_ordem, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				}).then((response) => {
					let ordem_vereadores = [];
					let ArrayLess = [];

					for (let i = 0; i < response.data.data.length; i++) {
						ordem_vereadores.push(response.data.data[i]);
					}

					for (let i = 0; i < ordem_vereadores.length; i++) {
						const OrdemTalk = ordem_vereadores[i];
						if (response.data.data[i].status_fala === '1') {
							OrdemTalk['state'] = 'F';
							this.StoreAll.OrdemUsers = OrdemTalk.user.id;
							localStorage.setItem('id_user_tv_ordem', OrdemTalk.user.id);
							this.user_on = true;
							this.f_fala = false;

						}

						if (response.data.data[i].status_fala === '2') {
							OrdemTalk['state'] = 'FS';
						}

						ArrayLess.push(OrdemTalk);
					}
					this.ordem_vereadores = ArrayLess;
					this.CronometroStatus(ArrayLess);
				});
			}
		},

		CronometroStatus(value) {
			if (value.length <= 0) {
				return;
			}
			const TalkOrdem = [];
			const FimOrdem = [];

			value.forEach(Talk => {
				if (Talk.state === 'F') {
					TalkOrdem.push(Talk);
				}

				if (Talk.state === 'FS') {
					FimOrdem.push(Talk);
				}
			});	 

			if (TalkOrdem.length >= 1) {
				this.timerOn = true;
				this.timer();
			}

			if (TalkOrdem.length <= 0) {
				this.timerOn = false;
				this.ResetTimer(FimOrdem);
			}

		},

		timer() {
			if (!this.timerOn) {
				return;
			}

			setTimeout(() => {
				this.timer();
			}, 1000);

			let sec = this.seconds--;
			let min = this.minutes;

			if (min == 0 && sec == 0) {
				this.seconds = 0;
				this.minutes = 0;
				this.cronometro = '';
				let text = 'Acabou seu tempo!';
				document.getElementById('timerzz').innerText = text;
				this.timerOn = false;
				this.TimerOver = true;

			} else {

				if (sec <= 9) {
					sec = '0' + sec;
					this.t = sec;
				}
				if (sec == 0) {
					this.seconds = 59;
					let min = this.minutes--;
					this.t = min;
				}
				if (min == 0 && sec <= 59) {
					document.getElementById('timerz').style.color = '#FF0000';
					document.getElementById('timerzz').style.color = '#FF0000';
				}

				this.cronometro = min + ' : ' + sec;
				this.t = sec;
			}
		},

		Finalizar_fala() {
			const formData = new FormData();
			formData.append('question_order_id', this.id_ordem);
			formData.append('user_id', localStorage.getItem('id_user_tv_ordem'));
			formData.append('status_fala', 2);

			AxiosAPI.post('/question-order-users-upp', formData, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((resp) => {
				this.t = resp;
				this.ResetTimer();
			});
				
		},

		ResetTimer() {
			this.seconds = this.StoreAll.timers[0].Vereador_Ordem.seconds;
			this.minutes = this.StoreAll.timers[0].Vereador_Ordem.minutes;
			this.StoreAll.OrdemUsers = null;

		},

		Sound () {
			let audio = new Audio(sound);
			audio.play();
		},
	}

};
</script>
<template>
    <div>
        <div>
            <div class="display_vh">
                <div>
                    <div class="d-flex" style="margin-top: 5px; border-top: 2px solid #005c9f;">
                    </div>
                    <div class="d-flex justify-content-center mt-4">
                        <!-- WTFK? -->
                    </div>
                </div>

                <template v-if="user_on == false">
                    <div style="margin-top: 4%">
                        <div class="d-flex justify-content-center">
                            <span class="text-uppercase font-weight-bold"
                                style="font-size: clamp(0vw, 3vw, 3vw); color: red;">
                                AGUARDANDO VEREADOR...
                            </span>
                        </div>
                    </div>
                </template>

                <template v-for="discut in ordem_vereadores" v-else :key="discut" >

                    <div v-if="discut.state == 'F'" class="d-flex justify-content-center" style="width: 100%;">
                        <div class="d-flex justify-content-center text-white" style="margin-top: 8%">
                            <div class="d-flex align-items-center flex-column display_cronometro">
                                <img :src=this.StoreAll.Dochost+discut.user.path_image class="text-center rounded-circle display_img"
                                    style="object-fit: cover;" alt="user photo" />
                                <span class="text-center p-2"
                                    style="font-size: clamp(0vw, 2.8vw, 3vw); font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">
                                    {{ discut.user.nickname }}  - {{ discut.user.category_party.name_party }}</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span id="timerz" style="font-size: clamp(0vw, 10vw, 10vw);"> {{ cronometro }} </span>
                                <span id="timerzz" class="text-uppercase font-weight-bold mt-7"
                                    style="font-size: clamp(0vw, 3vw, 3vw); color: red"> </span>
                            </div>
                        </div>
                    </div>
                </template>

            </div>
            <div>
            </div>
        </div>

        <div class="d-flex justify-content-between">

            <div style="display: flex;">
                <span class="text-white text-center"
                    style="font-size: clamp(1em, 1em + 1vw, 1.5em); width: 8.5rem; border-right: 1px solid">
                    {{ dia }} <br />
                    {{ mes }}
                </span>
                <span class="text-white d-flex justify-content-center"
                    style="font-size:clamp(2em, 1em + 1vw, 1.5em); width: 12rem; margin-top: 1rem;">
                    {{ time }}
                </span>
            </div>

            <div class="mt-3" style="width: 50%;">
                <p class="text-white d-flex justify-content-center"
                    style="font-size: clamp(1em, 1em + 1vw, 1.5em); font-family: sans-serif;">
                    <span>{{ this.StoreAll.name_camara }} &nbsp;</span>
                </p>
            </div>

            <div class="text-white text-center d-flex justify-content-center"
                style="border-left: 1px solid white; width: 15%; font-family: monospace; font-size: clamp(0.5em, 0.5em + 1vw, 1.5em);">
                Tempo de sessão <br />
                {{ timer_session }}
            </div>
        </div>
    </div>
</template>
<style>
.text-black {
    color: black;
}

.status_circle {
    border-radius: 50%;
    width: 35px;
    height: 35px;
}

.status_circle_active {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    border: 2px solid #0db80d;
    background-color: #0db80d;
}

.display_vh {
    height: clamp(0.1vw, 44.6vw, 855px);
}

.display_sign {
    width: clamp(0.1vw, 22.6vw, 410px);
}

.display_cronometro {
    width: clamp(0.1vw, 50vw, 600px);
}

.display_img {
    width: clamp(0.1vw, 20vw, 310px);
    height: clamp(0.1vw, 20vw, 310px);
}

.display_margin {
    margin-right: 12%;
}

@media only screen and (max-width: 1600px) {
    .display_vh {
        height: clamp(0.1vw, 43vw, 690px);
    }

    .display_sign {
        width: clamp(0.1vw, 25.6vw, 410px);
    }

    .display_cronometro {
        width: clamp(0.1vw, 30vw, 500px);
    }

    .display_margin {
        margin-right: 7%;
    }
}

@media only screen and (max-width: 1380px) {
    .display_vh {
        height: clamp(0.1vw, 43vw, 570px);
    }

    .display_sign {
        width: clamp(0.1vw, 28.6vw, 400px);
    }

    .display_margin {
        margin-right: 4%;
    }
}

@media only screen and (max-width: 1280px) {
    .display_vh {
        height: clamp(0.1vw, 43vw, 530px);
    }

    .display_sign {
        width: clamp(0.1vw, 29.6vw, 400px);
    }

    .display_margin {
        margin-right: 2%;
    }
}</style>
  