<template>
    <div>
        <ModalBigger ref="viewDocument" style="z-index: 1000;">
            <template #header>
                <strong><span> Selecione qual tipo do documento </span> </strong>

                <div class="flex-start">
                    <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </template>

            <template #body>
                <div class="d-flex justify-content-center">
                <div class="card text-center" style="width: 15.5rem; cursor: pointer" data-toggle="modal"
                    data-target=".bd-example-modal-xl-Mocao" @click="close1()"> 
                    <div>
                        <img class="card-img-top" style="width: 8.3rem" src="../../../assets/img/pdfimage.png"
                            alt="Card image cap" />
                        </div>
                        <div class="card-body">
                            <h5 class="card-title" style="font-size: 16px">
                                Moção de parabenização
                            </h5>
                        </div>             
                </div>

                <div class="card text-center" style="width: 15.5rem; cursor: pointer" data-toggle="modal"
                    data-target=".bd-example-modal-xl-Mocao" @click="close2()">
                    <div>
                        <img class="card-img-top" style="width: 8.3rem" src="../../../assets/img/pdfimage.png"
                            alt="Card image cap" />
                        </div>
                        <div class="card-body">
                            <h5 class="card-title" style="font-size: 16px">
                                Moção de aplausos
                            </h5>
                        </div>
                </div>

                <div class="card text-center" style="width: 15.5rem; cursor: pointer" data-toggle="modal"
                    data-target=".bd-example-modal-xl-Mocao" @click="close3()">
                    <div>
                        <img class="card-img-top" style="width: 8.3rem" src="../../../assets/img/pdfimage.png"
                            alt="Card image cap" />
                        </div>
                        <div class="card-body">
                            <h5 class="card-title" style="font-size: 16px">
                                Moção de pesar
                            </h5>
                        </div>
                </div>
            </div>
            </template>

            <template #footer>

            </template>
        </ModalBigger>

    </div>
</template>
    
<script>
import ModalBigger from "./ModalBigger";
/* import { AxiosAPI } from "@/axios"; */

export default {
    components: {
        ModalBigger,
    },

    data() {
        return {

        }
    },

    methods: {
        openModal() {
            this.$refs.viewDocument.openModal();
        },

        openModal2() {
            this.$refs.viewDocument.openModal();
        },

        close1(){
            this.$refs.viewDocument.closeModal()
            this.$router.push({
            name: 'Modelo de Moção de parabenização'
          });
        },

        close2(){
            this.$refs.viewDocument.closeModal()
            this.$router.push({
            name: 'Modelo de Moção de aplausos'
          });
        },

        close3(){
            this.$refs.viewDocument.closeModal()
            this.$router.push({
            name: 'Modelo de Moção de pesar'
          });
        }

    }
}


</script>
    
<style></style>