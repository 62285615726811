<template>
    <div>
     <ModalBigger ref="viewDocument">
          <template #header>
            <strong><span> Resultado da votação </span> </strong>

                <div class="flex-start">
                    <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
          </template>
    
          <template #body>

          <div v-if="this.$pinia.state.value.main.result_secret">
            <div class="d-flex justify-content-center"><span class="text-center text-dark"><strong>VOTAÇÃO</strong></span></div>
              
              <div class="mt-1 ml-1 mr-1" style="background-color: darkred;">
                <div class="text-white text-uppercase font-weight-bold rounded pl-2 pr-2 text-black text-center">
                        VOTAÇÃO SECRETA
                </div>
              </div>
          </div>


          <div v-else>
            <div v-if="unique_vote === null">
              <div class="d-flex justify-content-center">
                <spinnerprime/>
              </div>
              <div class="text-center">
                <span class="text-dark"><strong>Aguarde...</strong></span>
              </div>
            </div>


            <div v-else-if="unique_vote === true">
              <div class="d-flex justify-content-center"><span class="text-center text-dark"><strong>VOTAÇÃO</strong></span></div>
              
              <div v-for="(vote, index) in this.$pinia.state.value.main.votes" :key="`vote-${index}`" class="mt-1 ml-1 mr-1">
                <div class="text-white text-uppercase font-weight-bold rounded pl-2 pr-2 text-black"
                    :class="{ 'bg-success': vote.vote_category.id == 1, 'bg-dangerlegis': vote.vote_category.id == 2, 'bg-secondary': vote.vote_category.id == 3 }">
                        {{ vote.user.nickname }}
                </div>
              </div>
            </div>

          <div v-else>
            <div class="d-flex justify-content-center"><span class="text-center text-dark"><strong>PRIMEIRA VOTAÇÃO</strong></span></div>
            
            <div v-for="(vote, index) in First_vote" :key="`vote-${index}`" class="mt-1 ml-1 mr-1">
                <div class="text-white text-uppercase font-weight-bold rounded pl-2 pr-2 text-black"
                    :class="{ 'bg-success': vote.vote_category.id == 1, 'bg-dangerlegis': vote.vote_category.id == 2, 'bg-secondary': vote.vote_category.id == 3 }">
                        {{ vote.user.nickname }}
                </div>  
            </div>
            <div class="d-flex justify-content-center mt-2"><span class="text-center text-dark"><strong>SEGUNDA VOTAÇÃO</strong></span></div>
            
            <div v-for="(vote, index) in Second_vote" :key="`vote-${index}`" class="mt-1 ml-1 mr-1">
                <div class="text-white text-uppercase font-weight-bold rounded pl-2 pr-2 text-black"
                    :class="{ 'bg-success': vote.vote_category.id == 1, 'bg-dangerlegis': vote.vote_category.id == 2, 'bg-secondary': vote.vote_category.id == 3 }">
                        {{ vote.user.nickname }}
                </div>  
            </div>
          </div>
        </div>
          </template>
          <template #footer>
            <div v-if="this.unique_vote === null"><span>Aguarde...</span></div>

            <div v-else-if="this.unique_vote === true" class="d-flex justify-content-center">      
            <div class="d-flex justify-content-center">
              <div style="width: 30px; height: 30px; border-radius: 50%;" class="bg-success d-flex justify-content-center">
              <span class="text-white mt-1"> {{ favorable }} </span>
              </div>
              <span class="text-uppercase font-weight-bold text-black ml-1 mt-1 mr-1">A FAVOR</span>
            </div>

            <div class="d-flex justify-content-center">
              <div style="width: 30px; height: 30px; border-radius: 50%;" class="bg-dangerlegis  d-flex justify-content-center">
                <span class="text-white mt-1"> {{ against }}  </span>
              </div>
              <span class="text-uppercase font-weight-bold text-black ml-1 mt-1 mr-1">CONTRA</span>
            </div>

            <div class="d-flex justify-content-center">
              <div style="width: 30px; height: 30px; border-radius: 50%;" class="bg-secondary  d-flex justify-content-center">
                <span class="text-white mt-1"> {{ absten }}  </span>
              </div>
              <span class="text-uppercase font-weight-bold text-black ml-1 mt-1 mr-1">ABSTENÇÃO</span>
            </div>
          </div>

          <div v-else>
            <div class="d-flex justify-content-center"><span class="text-center text-dark"><strong>PRIMEIRA VOTAÇÃO</strong></span></div>
            <div class="d-flex justify-content-center">
            
            <div class="d-flex justify-content-center">
              <div style="width: 30px; height: 30px; border-radius: 50%;" class="bg-success d-flex justify-content-center">
              <span class="text-white mt-1"> {{ first_vote_favorable }} </span>
              </div>
              <span class="text-uppercase font-weight-bold text-black ml-1 mt-1 mr-1">A FAVOR</span>
            </div>

            <div class="d-flex justify-content-center">
              <div style="width: 30px; height: 30px; border-radius: 50%;" class="bg-dangerlegis  d-flex justify-content-center">
                <span class="text-white mt-1"> {{ first_vote_against }}  </span>
              </div>
              <span class="text-uppercase font-weight-bold text-black ml-1 mt-1 mr-1">CONTRA</span>
            </div>

            <div class="d-flex justify-content-center">
              <div style="width: 30px; height: 30px; border-radius: 50%;" class="bg-secondary  d-flex justify-content-center">
                <span class="text-white mt-1"> {{ first_vote_absten }}  </span>
              </div>
              <span class="text-uppercase font-weight-bold text-black ml-1 mt-1 mr-1">ABSTENÇÃO</span>
            </div>
          </div>

          <div class="d-flex justify-content-center mt-2"><span class="text-center text-dark"><strong>SEGUNDA VOTAÇÃO</strong></span></div>
          <div class="d-flex justify-content-center">
            <div class="d-flex justify-content-center">
              <div style="width: 30px; height: 30px; border-radius: 50%;" class="bg-success d-flex justify-content-center">
              <span class="text-white mt-1"> {{ second_vote_favorable }} </span>
              </div>
              <span class="text-uppercase font-weight-bold text-black ml-1 mt-1 mr-1">A FAVOR</span>
            </div>

            <div class="d-flex justify-content-center">
              <div style="width: 30px; height: 30px; border-radius: 50%;" class="bg-dangerlegis  d-flex justify-content-center">
                <span class="text-white mt-1"> {{ second_vote_against }}  </span>
              </div>
              <span class="text-uppercase font-weight-bold text-black ml-1 mt-1 mr-1">CONTRA</span>
            </div>

            <div class="d-flex justify-content-center">
              <div style="width: 30px; height: 30px; border-radius: 50%;" class="bg-secondary  d-flex justify-content-center">
                <span class="text-white mt-1"> {{ second_vote_absten }}  </span>
              </div>
              <span class="text-uppercase font-weight-bold text-black ml-1 mt-1 mr-1">ABSTENÇÃO</span>
            </div>
          </div>

        </div>
          </template>
      </ModalBigger>
      </div>
    </template>
    
    <script>
    import ModalBigger from "./ModalBigger.vue";

    
    export default {
      components : {
            ModalBigger,
        },
        data() {
          return {
            favorable: 0,
            against: 0,
            absten: 0,
            unique_vote: null,
            First_vote: [],
            first_vote_favorable: 0,
            first_vote_against: 0,
            first_vote_absten: 0,
            Second_vote: [],
            second_vote_favorable: 0,
            second_vote_against: 0,
            second_vote_absten: 0,
        };
      },   

        mounted(){

        },
        methods: {
            openModal() {
            this.$refs.viewDocument.openModal();
            this.CaunterVotes();
        },

        CaunterVotes(){
          console.log(this.$pinia.state.value.main.votes)
          let Votes_array = this.$pinia.state.value.main.votes
          let First_vote = []
          let Second_vote = []
          let first_vote_favorable = []
          let first_vote_against = []
          let first_vote_absten = []
          let second_vote_favorable = []
          let second_vote_against = []
          let second_vote_absten = []


          for(let i = 0; i < Votes_array.length; i++){
            if(Votes_array[i].order === 1){
              First_vote.push(Votes_array[i])
            }else{
              Second_vote.push(Votes_array[i])
            }
          }

          this.First_vote = First_vote
          this.Second_vote = Second_vote


          if(this.Second_vote.length > 0){
            this.unique_vote = false

            for (let i = 0; i < First_vote.length; i++) {
              if (First_vote[i].vote_category.id == 1) {
                first_vote_favorable.push(First_vote[i])
              } else if(First_vote[i].vote_category.id == 2) {
                first_vote_against.push(First_vote[i])
              }else{
                first_vote_absten.push(First_vote[i])
              }

              this.first_vote_favorable = first_vote_favorable.length
              this.first_vote_against = first_vote_against.length
              this.first_vote_absten = first_vote_absten.length

            }

            for (let i = 0; i < Second_vote.length; i++) {
              if (Second_vote[i].vote_category.id == 1) {
                second_vote_favorable.push(Second_vote[i])
              } else if(Second_vote[i].vote_category.id == 2) {
                second_vote_against.push(Second_vote[i])
              }else{
                second_vote_absten.push(Second_vote[i])
              }

              this.second_vote_favorable = second_vote_favorable.length
              this.second_vote_against = second_vote_against.length
              this.second_vote_absten = second_vote_absten.length

            }






          }else if(this.First_vote.length <= 0){
            this.unique_vote = null
          }else{
            this.unique_vote = true
          }

          console.log(this.Second_vote.length)
          console.log(this.First_vote.length)

          let votes = this.$pinia.state.value.main.votes
          let favorable = [];
          let against = [];
          let absten = [];

          for (let i = 0; i < votes.length; i++) {
              if (votes[i].vote_category.id == 1) {
                favorable.push(votes[i])
              } else if(votes[i].vote_category.id == 2) {
                against.push(votes[i])
              }else{
                absten.push(votes[i])
              }

              this.favorable = favorable.length
              this.against = against.length
              this.absten = absten.length

            }
        },
        }
    }
    
    
    </script>
    
    <style>

    </style>